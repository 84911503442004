import {ModuleWithProviders} from "@angular/core"
import {Routes, RouterModule} from '@angular/router';
import { NcmEditorComponent } from "./edit/ncm.editor.component"
import { NcmListComponent } from "./list/ncm.list.component";


export const routes: Routes = [
  {
    path: '', redirectTo: 'list', pathMatch: 'full'
  },
  {
    path: 'list',
    component:NcmListComponent,
  },
  {
    path: 'edit',
    component:NcmEditorComponent,
  },
  {
    path: 'edit/:id',
    component:NcmEditorComponent,
  }
];

export const routing = RouterModule.forChild(routes);
