import { Component, OnInit, AfterViewInit,ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { DataTableDirective } from "angular-datatables";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { LoginService } from "src/app/services/login.service";
import { Ncm } from "src/app/services/model/ncm";
import { NcmService } from "src/app/services/ncm.service";
import { NcmImposto } from "src/app/services/model/ncmImposto";
import { NcmImpostoService } from "src/app/services/ncmImposto.service";
import { routerTransition } from "../../../../util/animations";
import { EstadoService } from "src/app/services/estado.service";
import { CfopService } from "src/app/services/cfop.service";
import { SituacaoTributariaService } from "src/app/services/situacaoTributaria.service";
import { environment } from 'src/environments/environment';

declare var $:any;
declare var localStorage:any;

@Component({
  selector: "cp-editor",
  templateUrl: "./ncm.editor.component.html",
  styles: [],
  animations: [routerTransition]
})

export class NcmEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ncmService: NcmService,
    private ncmImpostoService: NcmImpostoService,
    private estadoService: EstadoService,
    private cfopService: CfopService,
    private loginService: LoginService,
    private spinnerService: NgxSpinnerService,
    private icmsSituacaoTributariaService:  SituacaoTributariaService,
    private ipiSituacaoTributariaService: SituacaoTributariaService,
    private pisSituacaoTributariaService: SituacaoTributariaService,
    private cofinsSituacaoTributariaService: SituacaoTributariaService,
  ) {}

  title: string;
  description:string;
  dtOptions: DataTables.Settings = {};
  currentId: number = -1;
  currentRecord: number;
  ncm: Ncm = {} as Ncm;
  ncmImposto: NcmImposto = {} as NcmImposto;
  msgErroServer: string = "Ops, aconteceu algum imprevisto no servidor. ";
  currentPage: number = 0;
  currentPageSize:number = 10;
  idEmpresa: number = localStorage.getItem('idEmpresa');
  maskDecimal = {};
  isSimples: string = 'S';
  disableForm: boolean = true;
  mask: boolean = true;
      
  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  @ViewChild('successMessage')
  public readonly successMessage!: SwalComponent;
  @ViewChild('validationMessage')
  public readonly validationMessage!: SwalComponent;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtTrigger: Subject<any> = new Subject();

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    var self = this;
    this.isSimples = localStorage.getItem('isSimples');
 
    const id: number = parseInt(this.route.snapshot.paramMap.get('id'));
    this.currentId = id;
    this.maskDecimal = 
    { 
        alias:'currency',
        radixPoint: ",",
        prefix:'',
        digits: 2,
        digitsOptional: false,
        autoUnmask: true,
        unmaskAsNumber: true,
        removeMaskOnSubmit: true
    };

    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);

    // this.fillOrigemProduto();
    this.fillStatus();
  
    $('#status').val('A').change();

    $('#ncmpIBPTNac').inputmask(this.maskDecimal);
    $('#ncmpIBPTEst').inputmask(this.maskDecimal);
    $('#ncmpIBPTImp').inputmask(this.maskDecimal);
    $('#ncmpIBPTMun').inputmask(this.maskDecimal);
    $('#ncmNcm').inputmask({mask: "9999.99.99"});
    this.ncm.id = 0;
    this.ncm.isAtivo = 'S';
    this.ncm.ncm = '';
    this.ncm.cest = '';
    this.ncm.pBC = 0;
    this.ncm.pIpi = 0;
    this.ncm.pIBPTNac = 0;
    this.ncm.pIBPTEst = 0;
    this.ncm.pIBPTImp = 0;
    this.ncm.pIBPTMun = 0;

    self.dtOptions = {
      searching:false,
      lengthChange: false
      /* render dynamic buttons */
    };

    this.route.data.subscribe(params => {
    
      if(id) 
      {
        this.disableForm = false;
        this.spinnerService.show();
        this.ncmService.getById(id).subscribe(response=> {
          this.spinnerService.hide();
          this.ncm = response.data;
          //this.updateDataTable();
          if(this.ncm.isAtivo == 'S') {
            $('#status').val('A').change();
         } else {
            $('#status').val('I').change();
         };
        }, error => {
          if(error.status == 401){
            self.loginService.tokenExpired();
          }
        })
      };

    });

    this.dtOptions = {
      processing:true,
      serverSide:true,
      ajax: {
       // url: 'https://kironwebapi.azurewebsites.net/ipiAliqIcms',
       url: environment.apiUri + '/ipiAliqIcms',
        data: function (d) {
          var queryData = {
            page: d['start'] / d['length'] + 1,
            pageSize:d['length'],
            idIpi: self.currentId,
            idEmpresa: self.idEmpresa
          }
          self.currentPage = queryData.page;
          self.currentPageSize = queryData.pageSize;
          return queryData;
        },
        dataSrc : function(json) : any {
          json.recordsTotal = json.count;
          json.recordsFiltered = json.count
          return json.data;
        },
        async: true,
        error: function (xhr, error, code)
          {
            if((xhr.status == 400) || (xhr.status == 401)) {
              self.loginService.tokenExpired();
            }
          },
        headers: { 'x-access-token': this.loginService.getToken() }
      },
      columns : [
        { "data": "ufDest" },
        { "data": "pIva", render: $.fn.dataTable.render.number( '.', ',', 2, ' ' )},
        { "data": "pIvaAjustado", render: $.fn.dataTable.render.number( '.', ',', 2, ' ' )},
        { "data": "pIcms", render: $.fn.dataTable.render.number( '.', ',', 2, ' ' )},
        { "data": "pICMSST", render: $.fn.dataTable.render.number( '.', ',', 2, ' ' )},
        {
          "data": "id",
          render: (data, type, full) => {
            return `<div class='text-center'><a href='javascript:void(0);' onclick=\"functions.editEntity('${data}')\" class='btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1' title='Editar registro'><i class='fal fa-pen'></i></a>
                    <a href='javascript:void(0);' onclick=\"functions.deleteEntity('${data}')\" class='btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1' title='Deletar registro'><i class='fal fa-times'></i></a></div>`;
          }
        }
    ],
      /* render dynamic buttons */
    };
    
    this.title = 'NCM';
    this.description = 'Cadastro de NCM'

    // $('.select2').select2();


  }

  
//   async fillOrigemProduto() {
//     await this.origemProdutoService.get().subscribe(data=> {
//       var finalData = $.map(data.data, function (obj) {
//         obj.id = obj.id || obj.pk; // replace pk with your identifier
//         obj.text = obj.nome;
//         if(obj.id == undefined){ obj.id = 0};
//         return obj;
//       });
//       $('#origemproduto').select2({
//         data: finalData,
//         placeholder: 'Origem do produto'
//       });
//     })
//   };

  fillStatus(){
    var statusData = [
      {'id': "A", 'text': 'Ativo'},
      {'id': "I", 'text': 'Inativo'},
     ];

      
    $('#status').select2({
      data: statusData,
      allowClear: true,
      minimumResultsForSearch: -1
    });
  }
  
  
  deleteEntity(data) {
    this.currentRecord = data;
     this.deleteConfirmationMessage.fire();
  }

  confirmDelete() {
    this.ncmImpostoService.delete(this.currentRecord).subscribe( () => {
      $('#tableList').DataTable().ajax.reload();
      this.currentRecord = -1;
    }, error => {
      Swal.fire({
        title:  "Erro!",
        text: error.error.message,
        icon: 'error'
    });
     this.currentRecord = -1;
    });
  }

  ngOnDestroy() {
    window.functions = null;
    this.dtTrigger.unsubscribe();
  }

  // updateDataTable() {

  //   var self = this;
    
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // Destroy the table first
  //     dtInstance.destroy();
  //     // Call the dtTrigger to rerender again
  //     this.dtTrigger.next();
  //   });
  // }

  cancel() {
      this.router.navigate(['/cadastros/ncm']);    
  }

  save() {
    var self = this;
    this.ncm.ncm = $('#ncmNcm').val().replace(/[^\d]+/g,'');
    this.ncm.pIBPTEst = $('#ncmpIBPTEst').val();
    this.ncm.pIBPTImp = $('#ncmpIBPTImp').val();
    this.ncm.pIBPTMun = $('#ncmpIBPTMun').val();
    this.ncm.pIBPTNac = $('#ncmpIBPTNac').val();
   
    if(this.ncm.ncm == '') {
      this.validationMessage.fire();
    } else {
        this.spinnerService.show();
        if(this.ncm.id > 0) {
          this.ncmService.update(this.ncm).subscribe(response => {
            this.spinnerService.hide();
            this.successMessage.fire();
            this.router.navigate(['/cadastros/ncm']);
          }, error => {
            Swal.fire({
                title:  "Erro!",
                text: error.message,
                icon: 'error'
            });
          });
        } else {
          this.ncmService.create(this.ncm).subscribe(response=> {
            this.spinnerService.hide();
            this.successMessage.fire();
            this.router.navigate(['/cadastros/ncm']);
        }, error => {
          console.log(error);
          Swal.fire({
            title:  "Erro!",
            text: error.error.message,
            icon: 'error'
        });
          });
        }
      }
  }

novoImposto(){
    // this.nfeService.getById(this.idNotafi).subscribe(response=> {
    //   this.nfe = response.data;
    this.fillEstado('');
    this.fillCfop(0);
    this.fillIcmsSituacaoTributaria('');
    this.fillIpiSituacaoTributaria('');
    this.fillPisSituacaoTributaria('');
    this.fillCofinsSituacaoTributaria('');


    this.ncmImposto.csosn = '';
    this.ncmImposto.cstCof = '';
    this.ncmImposto.cstIpi = '';
    this.ncmImposto.cstPis = '';
    this.ncmImposto.cstIcms = '';
    this.ncmImposto.id = 0;
    this.ncmImposto.idIpi = this.currentId;
    this.ncmImposto.idNatura = 0;
    this.ncmImposto.idEmpresa = this.idEmpresa;
    this.ncmImposto.pBc = 0;
    this.ncmImposto.pBcSN = 0;
    this.ncmImposto.pBcSTSN = 0;
    this.ncmImposto.pDifere = 0;
    this.ncmImposto.pFCPUFDest = 0;
    this.ncmImposto.pFCPUFDestST = 0;
    this.ncmImposto.pICMSFrete = 0;
    this.ncmImposto.pICMSST = 0;
    this.ncmImposto.pIcms = 0;
    this.ncmImposto.pIcmsIntra = 0;
    this.ncmImposto.pIva = 0;
    this.ncmImposto.pIvaAjustado = 0;
    this.ncmImposto.pIvaRedSN = 0;
    this.ncmImposto.pOperaPro = 0;
    this.ncmImposto.pRedBc = 0;
    this.ncmImposto.pRedBcSN = 0;
    this.ncmImposto.pRedBcST = 0;
    this.ncmImposto.pRedBcSTSN = 0;
    this.ncmImposto.ufDest = '';
    this.ncmImposto.vPauta = 0;
    this.ncmImposto.vPautaST = 0;
    $('#selectUFDestino').empty();
    $('#selectCfop').val(0).change();
    $('#icmsSituacaoTributaria').val('').change();
    $('#ipiSituacaoTributaria').val('').change();
    $('#pisSituacaoTributaria').val('').change();
    // $('#cofinsSituacaoTributaria').empty();
    $('#cofinsSituacaoTributaria').val(null).trigger('change');
    // $('#cofinsSituacaoTributaria').select2().val([' ']).trigger("change");
    if(this.mask === true){
      this.maskImposto();
      this.mask = false;
    }

    $('#modalImpostos').modal('show');
};

maskImposto(){
  $('#ncmImpostopBc').inputmask(this.maskDecimal);
  $('#ncmImpostopBcSN').inputmask(this.maskDecimal);
  $('#ncmImpostopBcSTSN').inputmask(this.maskDecimal);
  $('#ncmImpostopDifere').inputmask(this.maskDecimal);
  $('#ncmImpostopFCPUFDest').inputmask(this.maskDecimal);
  $('#ncmImpostopFCPUFDestST').inputmask(this.maskDecimal);

  $('#ncmImpostopICMSFrete').inputmask(this.maskDecimal);
  $('#ncmImpostopICMSST').inputmask(this.maskDecimal);
  $('#ncmImpostopIva').inputmask(this.maskDecimal);
  $('#ncmImpostopIvaAjustado').inputmask(this.maskDecimal);
  $('#ncmImpostopIvaRedSN').inputmask(this.maskDecimal);
  $('#ncmImpostopOperaPro').inputmask(this.maskDecimal);
  $('#ncmImpostopRedBc').inputmask(this.maskDecimal);
  $('#ncmImpostopRedBcSN').inputmask(this.maskDecimal);
  $('#ncmImpostopRedBcST').inputmask(this.maskDecimal);
  $('#ncmImpostopRedBcSTSN').inputmask(this.maskDecimal);
  $('#ncmImpostovPauta').inputmask(this.maskDecimal);
  $('#ncmImpostovPautaST').inputmask(this.maskDecimal);
}

editEntity(data) {

  var self = this;
  this.ncmImpostoService.getById(data).subscribe(response=> {
    this.ncmImposto = response.data;
    this.fillEstado(this.ncmImposto.ufDest);
    this.fillCfop(this.ncmImposto.idNatura);
    if(this.isSimples === 'S') {
       this.fillIcmsSituacaoTributaria(this.ncmImposto.csosn);
    } else {
      this.fillIcmsSituacaoTributaria(this.ncmImposto.cstIcms);
    }
    this.fillIpiSituacaoTributaria(this.ncmImposto.cstPis);
    this.fillPisSituacaoTributaria(this.ncmImposto.cstPis);
    this.fillCofinsSituacaoTributaria(this.ncmImposto.cstCof);
    $('#icmsSituacaoTributaria').val(this.ncmImposto.cstIcms).change();
    $('#ncmImpostopBc').val(this.ncmImposto.pBc).change();
    $('#ncmImpostopBcSN').val(this.ncmImposto.pBcSN).change();
    $('#ncmImpostopBcSTSN').val(this.ncmImposto.pBcSTSN).change();
    $('#ncmImpostopDifere').val(this.ncmImposto.pDifere).change();
    $('#ncmImpostopFCPUFDest').val(this.ncmImposto.pFCPUFDest).change();
    $('#ncmImpostopFCPUFDestST').val(this.ncmImposto.pFCPUFDestST).change();
  
    $('#ncmImpostopICMSFrete').val(this.ncmImposto.pICMSFrete).change();
    $('#ncmImpostopICMSST').val(this.ncmImposto.pICMSST).change();
    $('#ncmImpostopIva').val(this.ncmImposto.pIva).change();
    $('#ncmImpostopIvaAjustado').val(this.ncmImposto.pIvaAjustado).change();
    $('#ncmImpostopIvaRedSN').val(this.ncmImposto.pIvaRedSN).change();
    $('#ncmImpostopOperaPro').val(this.ncmImposto.pOperaPro).change();
    $('#ncmImpostopRedBc').val(this.ncmImposto.pRedBc).change();
    $('#ncmImpostopRedBcSN').val(this.ncmImposto.pRedBcSN).change();
    $('#ncmImpostopRedBcST').val(this.ncmImposto.pRedBcST).change();
    $('#ncmImpostopRedBcSTSN').val(this.ncmImposto.pRedBcSTSN).change();
    $('#ncmImpostovPauta').val(this.ncmImposto.vPauta).change();
    $('#ncmImpostovPautaST').val(this.ncmImposto.vPautaST).change();  
    if(this.mask === true){
      this.maskImposto();
      this.mask = false;
    }
    
    $('#modalImpostos').modal('show');
    $('#modalImpostos').click();
  }, error => {
    if(error.status == 401){
      self.loginService.tokenExpired();
    }
  })
};


async fillEstado(uf:string) {
  await this.estadoService.get().subscribe(data=> {

    var finalData = $.map(data.data, function (obj) {
      obj.id = obj.uf || obj.pk; // replace pk with your identifier
      obj.text = obj.uf;
      return obj;
    });
    $('#selectUFDestino').empty();
    $('#selectUFDestino').select2({
      data: finalData,
      placeholder: 'UF',
      dropdownParent: $('#modalImpostos'),
    });
    $('#selectUFDestino').val(uf).change();
  });
}

async fillCfop(id:number){

  await this.cfopService.get(0).subscribe(data=> {
    var finalData = $.map(data.data, function (obj) {
      obj.id = obj.id || obj.pk; // replace pk with your identifier
      obj.text = obj.cfop + ' > ' + obj.nome;
      return obj;
    });
    $('#selectCfop').select2({
      data: finalData,
      allowClear: true,
      placeholder: 'CFOP',
      dropdownParent: $('#modalImpostos'),
    });
     $('#selectCfop').val(id).change();
  }, error => {
    // if(error.status == 401){
    //   self.loginService.tokenExpired();
    // }
  });

}

fillIcmsSituacaoTributaria(csosn_cst: string) {
  var tipo: string;
  var place: string;
  if(this.isSimples === 'S'){
    tipo = 'CSOSN';
    place = 'CSOSN';
  } else {
    tipo = 'ICMS';
    place = 'CST';
  };
  this.icmsSituacaoTributariaService.get(tipo).subscribe(data=> {
    var finalData = $.map(data.data, function (obj) {
      obj.id = obj.codigo || obj.pk; // replace pk with your identifier
      obj.text = obj.nome;
      if(obj.id == undefined){ obj.id = 0};
      return obj;
    });
    $('#icmsSituacaoTributaria').select2({
      data: finalData,
      allowClear: true,
      placeholder: place,
      dropdownParent: $('#modalImpostos'),
    });
    $('#icmsSituacaoTributaria').val(csosn_cst).change();

  }, error => {
    if(error.status == 401){
      this.loginService.tokenExpired();
    }
  });
}

fillIpiSituacaoTributaria(cst: string) {
  this.ipiSituacaoTributariaService.get('IPI').subscribe(data=> {

    var finalData = $.map(data.data, function (obj) {
      obj.id = obj.codigo || obj.pk; // replace pk with your identifier
      obj.text = obj.nome;
      // if(obj.id == undefined){ obj.id = 0};
      return obj;
    });
    $('#ipiSituacaoTributaria').select2({
      data: finalData,
      allowClear: true,
      placeholder: 'Situação tributária',
      dropdownParent: $('#modalImpostos'),
    });
    $('#ipiSituacaoTributaria').val(cst).change();

  }, error => {
    if(error.status == 401){
      this.loginService.tokenExpired();
    }
  });
}

fillPisSituacaoTributaria(cst: string) {
  this.pisSituacaoTributariaService.get('PIS').subscribe(data=> {

    var finalData = $.map(data.data, function (obj) {
      obj.id = obj.codigo || obj.pk; // replace pk with your identifier
      obj.text = obj.nome;
      // if(obj.id == undefined){ obj.id = 0};
      return obj;
    });
    $('#pisSituacaoTributaria').select2({
      data: finalData,
      allowClear: true,
      placeholder: 'Situação tributária',
      dropdownParent: $('#modalImpostos'),
    });
    $('#pisSituacaoTributaria').val(cst).change();
  }, error => {
    if(error.status == 401){
      this.loginService.tokenExpired();
    }
  });
}

fillCofinsSituacaoTributaria(cst: string) {
  this.cofinsSituacaoTributariaService.get('PIS').subscribe(data=> {

    var finalData = $.map(data.data, function (obj) {
      obj.id = obj.codigo || obj.pk; // replace pk with your identifier
      obj.text = obj.nome;
      // if(obj.id == undefined){ obj.id = 0};
      return obj;
    });
    $('#cofinsSituacaoTributaria').select2({
      data: finalData,
      allowClear: true,
      placeholder: 'Situação tributária',
      dropdownParent: $('#modalImpostos'),
    });
    $('#cofinsSituacaoTributaria').val(cst).change();
  }, error => {
    if(error.status == 401){
      this.loginService.tokenExpired();
    }
  });
}

cancelImposto(){
  $('#modalImpostos').modal('hide');
}

saveImposto(){
  this.ncmImposto.ufDest = $('#selectUFDestino').val();
  this.ncmImposto.idNatura = $('#selectCfop').val();
  this.ncmImposto.cstIcms = $('#icmsSituacaoTributaria').val();
  this.ncmImposto.cstIpi = $('#ipiSituacaoTributaria').val();
  this.ncmImposto.cstPis = $('#pisSituacaoTributaria').val();
  this.ncmImposto.cstCof = $('#cofinsSituacaoTributaria').val();
  this.ncmImposto.pBc = $('#ncmImpostopBc').val();
  this.ncmImposto.pBcSN = $('#ncmImpostopBcSN').val();
  this.ncmImposto.pBcSTSN = $('#ncmImpostopBcSTSN').val();
  this.ncmImposto.pDifere = $('#ncmImpostopDifere').val();
  this.ncmImposto.pFCPUFDest = $('#ncmImpostopFCPUFDest').val();
  this.ncmImposto.pFCPUFDestST = $('#ncmImpostopFCPUFDestST').val();

  this.ncmImposto.pICMSFrete = $('#ncmImpostopICMSFrete').val();
  this.ncmImposto.pICMSST = $('#ncmImpostopICMSST').val();
  this.ncmImposto.pIva = $('#ncmImpostopIva').val();
  this.ncmImposto.pIvaAjustado = $('#ncmImpostopIvaAjustado').val();
  this.ncmImposto.pIvaRedSN = $('#ncmImpostopIvaRedSN').val();
  this.ncmImposto.pOperaPro = $('#ncmImpostopOperaPro').val();
  this.ncmImposto.pRedBc = $('#ncmImpostopRedBc').val();
  this.ncmImposto.pRedBcSN = $('#ncmImpostopRedBcSN').val();
  this.ncmImposto.pRedBcST = $('#ncmImpostopRedBcST').val();
  this.ncmImposto.pRedBcSTSN = $('#ncmImpostopRedBcSTSN').val();
  this.ncmImposto.vPauta = $('#ncmImpostovPauta').val();
  this.ncmImposto.vPautaST = $('#ncmImpostovPautaST').val();  
console.log(this.ncmImposto);
  if((this.ncmImposto.ufDest == '') || (this.ncmImposto.ufDest == null)) {
    this.validationMessage.fire();
  } else {
      this.spinnerService.show();
      if(this.ncmImposto.id > 0) {
        this.ncmImpostoService.update(this.ncmImposto).subscribe(response => {
          this.spinnerService.hide();
          this.successMessage.fire();
          $('#tableList').DataTable().ajax.reload();
          $('#modalImpostos').modal('hide');
        }, error => {
          Swal.fire({
              title:  "Erro!",
              text: error.message,
              icon: 'error'
          });
        });
        
      } else {
        this.ncmImpostoService.create(this.ncmImposto).subscribe(response=> {
          this.spinnerService.hide();
          this.successMessage.fire();
          $('#tableList').DataTable().ajax.reload();
          $('#modalImpostos').modal('hide');
      }, error => {
        Swal.fire({
          title:  "Erro!",
          text: error.error.message,
          icon: 'error'
      });
        });
      }
    }
  

}

handleDenial() {

}

  validacao() {
    window.addEventListener('load', function() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('button', function(event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
      }, false);
    });
    }, false);
  };
}
