<!-- <ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item"><a href="" routerLink="/">Kiron</a></li>
    <li class="breadcrumb-item">{{title}}</li>
    <li class="breadcrumb-item active">Edição</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block">
      <span class="js-get-date"></span>
    </li>
  </ol> -->
  <div class="subheader">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-user-cog"></i> {{title}}
      <small>
        Cadastro de {{title}}
      </small>
    </h1>
  </div>
  <div id="panel-1" class="panel">
    <div class="panel-hdr">
      <h2>Dados cadastrais<span class="fw-300"></span></h2>
      
    </div>


    <div class="panel-container show" >
       <div class="panel-content">
        <div class="row">
       
          <div class="form-group col-sm-5">
            <label class="form-label" for="nomeUsuario">Nome do Usuário <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="usuarioNome" [(ngModel)]="usuario.nome">
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label class="form-label" for="entidadeEmail">Email para login</label>
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroupPrepend"><i class="fal fa-envelope"></i></span>
                </div>
                <input type="text" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" id="usuarioLogin" [(ngModel)]="usuario.login" ngModel #emailref="ngModel">
                <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
                    <div [hidden]="!emailref.errors?.pattern">
                      <label class="form-label" style="padding-left: 20px; padding-top: 10px;"><span class="text-danger">Email inválido</span></label>
                    </div>
                </div>
            </div>
          </div>
          <!-- <div class="form-group col-sm-4">
            <label class="form-label" for="status">Senha <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="password" id="senha" class="form-control"  formControlName="senha" placeholder="Senha" required>                    
           </div>
          </div>
          <div class="form-group col-sm-4">
            <label class="form-label" for="status">Confirmar senha <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="password" id="confirmarSenha" class="form-control"  formControlName="confirmarSenha" [(ngModel)]="usuario.senha" placeholder="Senha" required>                    
           </div>
          </div> -->
      </div>

      </div>
    </div>

  </div>
  
<button (click)="save()" style="float:right; margin: 10px;" (click)="save()" class="btn btn-primary ml-auto" type="button">Salvar</button>
<button (click)="cancel()" type="button" style="float:right; margin: 10px;" (click)="cancel()" class="btn btn-secondary">Cancelar</button>

<swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde."  icon="error"></swal>
<swal #successMessage text="Boa, registro salvo com sucesso!" showConfirmButton=true icon="success"></swal>
<swal #validationMessage text="Ops, ainda faltam alguns campos para preencher, vamos lá?" showConfirmButton=true icon="error"></swal>
