import {NgModule} from '@angular/core';
import {ProdutosListComponent } from './list/produtos.list.component'
import {ProdutosEditorComponent } from './editor/produtos.editor.component'
import {routing} from './produtos.routing';
import { SharedModule } from '../../../shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    routing,
  ],
  declarations: [
    ProdutosListComponent,
    ProdutosEditorComponent
  ],
  providers: [],
})
export class ProdutosModule {

}
