import { Component, OnInit, AfterViewInit, ViewChild  } from '@angular/core';
import { routerTransition } from '../../../util/animations';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { SwalComponent, SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { NFeService } from 'src/app/services/nfe.service';
import { NFe } from "src/app/services/model/nfe";
import * as moment from 'moment';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { NgxSpinnerService } from "ngx-spinner";
import { MSKironService } from 'src/app/services/NFe/msKironA3.service';
import { environment } from 'src/environments/environment';
import { VerificarChave } from "src/app/services/validadeChave.service";

// import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
// import { Observable } from 'rxjs';


//import 'moment/locale/pt-br';

declare var $:any

@Component({
  selector: 'fat-list',
  templateUrl: './nfe.list.component.html',
  styles: [],
  animations: [routerTransition],
})
export class NFeListComponent
  implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private nfeService: NFeService,
    private spinnerService: NgxSpinnerService,
    private msKiron: MSKironService,
    private verificarChave: VerificarChave

  ) {}

  dtOptions: DataTables.Settings = {};
  dtOptionsCCe: DataTables.Settings = {};
  title: string;
  description:string;
  currentPage: number = 0;
  currentPageSize:number = 10;
  filterIsAtivo: boolean = true;
  currentRecord: number;
  msgSpinner: string;
  filterStatus: string;
  filterStartDate: string;
  filterEndDate: string;
  currentRow: number;
  editar: boolean = true;
  nfe: NFe = {} as NFe;  
  emailDestinatario: string = '';
  
  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  

  ngAfterViewInit(): void {

    $(document).on("input", ".contador", function () {
      var limite = 1000;
      var caracteresDigitados = $(this).val().length;
      var caracteresRestantes = limite - caracteresDigitados;
  
      $(".caracteres").text(caracteresRestantes);
    });

    $('#filterData').daterangepicker(
      {
        "locale": {
            "format": "DD/MM/YYYY",
            "separator": " - ",
            "applyLabel": "Aplicar",
            "cancelLabel": "Cancelar",
            "fromLabel": "De",
            "toLabel": "Até",
            "customRangeLabel": "Custom",
            "daysOfWeek": [
                "Dom",
                "Seg",
                "Ter",
                "Qua",
                "Qui",
                "Sex",
                "Sáb"
            ],
            "monthNames": [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro"
            ],
            "firstDay": 0,
            // "autoUpdateInput": false,
            // "clearBtn": true,
        }}
    );
    
    this.limparFiltros()

    // $('#filterData').on('apply.daterangepicker', function(ev, picker) {
    //   $('#filterData').data('daterangepicker').startDate
    //   picker.setStartDate(something);
    //   picker.setEndDate(something);
    // });
    $('#filterData').val('').change();
    // $('#filterData').data('daterangepicker').startDate.
    
    $(":input").inputmask();

    $('#filterValorDe, #filterValorAte').inputmask({ alias:'currency', radixPoint: ",", prefix:''});

    $('#vNF').inputmask({ alias:'currency', radixPoint: ",", prefix:'R$'});

    $('#numeroNota').inputmask({ mask: "[999999999]", greedy: false });

    $('#cfopNota').inputmask({ mask: "[9999]", greedy: false });

    $('.select2').select2();

    // $("#destinatarioNota").autocomplete({
    //   minLength: 0,
    //   source: projects,
    //   focus: function( event, ui ) {
    //     $( "#project" ).val( ui.item.label );
    //     return false;
    //   },
    //   select: function( event, ui ) {
    //     $( "#project" ).val( ui.item.label );
    //     $( "#project-id" ).val( ui.item.value );
    //     $( "#project-description" ).html( ui.item.desc );
    //     $( "#project-icon" ).attr( "src", "images/" + ui.item.icon );

    //     return false;
    //   }
    // });
  }

  ngOnInit() {

    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);
    window.functions.cce = this.cce.bind(this);
    window.functions.modalEmail = this.modalEmail.bind(this);
    window.functions.cancelaNFe = this.cancelarNFe.bind(this);
    window.functions.enviarNFe = this.enviarNFe.bind(this);
    window.functions.visualizarDanfe = this.visualizarDanfe.bind(this);
    window.functions.baixarXml = this.baixarXml.bind(this);
    window.functions.consultarStatus = this.consultarStatus.bind(this);
    window.functions.imprimirCCe = this.imprimirCCe.bind(this);
    window.functions.emailCCe = this.emailCCe.bind(this);
    window.functions.duplicar = this.duplicar.bind(this);

    var self = this;
    this.fillStatus();

    this.dtOptions = {
      processing:true,
      serverSide:true,
      ajax: {
      //  url: 'https://kironwebapi.azurewebsites.net/notafi',
         url: environment.apiUri + '/notafi',
        data: function (d) {
          var queryData = {
            idEmpresa: localStorage.getItem('idEmpresa'),
            page: d['start'] / d['length'] + 1,
            pageSize:d['length'],
            status: self.filterStatus,
            numeroNota: $('#filterNumeroNota').val(),
            nomeDestina: $('#filterDestinatario').val(),
            cfop: $('#filterCfop').val(),
            // dataDe: $('#filterData').data('daterangepicker').startDate.format('YYYY-MM-DD'),
            // dataAte: $('#filterData').data('daterangepicker').endDate.format('YYYY-MM-DD'),
            dataDe: self.filterStartDate,
            dataAte: self.filterEndDate,
            valorDe: $('#filterValorDe').val(),
            valorAte: $('#filterValorAte').val(),
          }
          self.currentPage = queryData.page;
          self.currentPageSize = queryData.pageSize;
          return queryData;
        },
        dataSrc : function(json) : any {
          json.recordsTotal = json.count;
          json.recordsFiltered = json.count
          return json.data;
        },
        async: true,
        error: function (xhr, error, code)
          {
            if((xhr.status == 400) || (xhr.status == 401)) {
              self.loginService.tokenExpired();
            }
          },
        headers: { 'x-access-token': this.loginService.getToken() }
        
      },
      columns : [
        { "data": "status.nome",
        render: function(status){ 
          switch(status){
            case 'Transmitida':
              return `<span class="badge badge-pill" style="background-color: rgb(86, 181, 159); font-size:13px; color: beige;">Transmitida</span> `; 
           case "Digitação":  
              return `<span class="badge badge-pill" style="background-color: rgb(247, 197, 72); font-size:13px; color: beige;">Digitação</span>`; 
           case "Cancelada":  
              return `<span class="badge badge-pill" style="background-color: rgb(234, 77, 44); font-size:13px; color: beige;">Cancelada</span>`; 
           case "Aguardando":  
              return `<span class="badge badge-info badge-pill" style="font-size:13px">Aguardando</span>`; 
           case "Denegada":  
              return `<span class="badge badge-secondary badge-pill" style="background-color: rgb(54, 53, 55); font-size:13px; color: beige;">Denegada</span>`; }}
      },
        { "data": "dtEmissao",  render: function(data){ return moment(data).format("DD/MM/YYYY HH:mm");}},
        { "data": "numeroNota"},
        { "data": "entidade.nome" },
        { "data": "vNF", render: $.fn.dataTable.render.number( '.', ',', 2, 'R$ ' ) },
        { "data": "cfop.cfop" },
        {
          "data": "id",
          render: (data, type, full) => {
            return `
            <div class="text-center">
                <a href='javascript:void(0);'onclick=\"functions.editEntity('${data}')\" 
                class="btn btn-primary btn-sm btn-icon rounded-circle"title="Editar">
                <i class='fal fa-pen'></i>
                </a>

                <div class='dropdown d-inline-block position-relative'>
                    <a class='btn btn-primary btn-sm btn-icon rounded-circle' id="maisOpcoes" data-toggle='dropdown' aria-expanded='false' title='Mais opções'>
                    <i class="fal fa-list" style="color:white"></i>
                    </a>
                    <div class='dropdown-menu position-absolute pos-top pos-left' style='position-absolute pos-top pos-left'>
                      <a class='dropdown-item item3' href='javascript:void(0);' onclick=\"functions.modalEmail('${data}')\">Enviar por e-mail</a>
                      <a class='dropdown-item item4' href='javascript:void(0);' onclick=\"functions.cancelaNFe('${data}')\">Cancelar</a>
                      <a class='dropdown-item item5' href='javascript:void(0);' onclick=\"functions.cce('${data}')\">Emitir CC-e</a>
                      <a class='dropdown-item item6' href='javascript:void(0);' onclick=\"functions.baixarXml('${data}')\">Baixar XML</a>
                      <a class='dropdown-item item7' href='javascript:void(0);' onclick=\"functions.consultarStatus('${data}')\">Consultar status</a>
                      <a class='dropdown-item item8' href='javascript:void(0);' onclick=\"functions.deleteEntity('${data}')\">Excluir</a>
                      <a class='dropdown-item item9' href='javascript:void(0);' onclick=\"functions.duplicar('${data}')\">Duplicar</a>
                    </div>
                </div>
            
                <a href='javascript:void(0);'onclick=\"functions.visualizarDanfe('${data}')\" 
                class="btn btn-primary btn-sm btn-icon rounded-circle" title="Visualizar DANFE">
                <i class="fal fa-print"></i>
                </a>

                <a href='javascript:void(0);'onclick=\"functions.enviarNFe('${data}')\" 
                class="btn btn-success btn-sm btn-icon rounded-circle" disabled title="Transmitir nota">
                <i class="fal fa-arrow-alt-from-bottom"></i>
                </a>
            </div>`;             
            
            // <div class='text-center'><a href='javascript:void(0);' onclick=\"functions.editEntity('${data}')\" class='btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1' title='Edit Record'><i class='fal fa-pen'></i></a>
            // <a href='javascript:void(0);' onclick=\"functions.carta('${data}')\" class='btn btn-sm btn-icon btn-outline-warning rounded-circle mr-1' title='Carta'><i class='fal fa-envelope-open-text'></i></a>
            // <a href='javascript:void(0);' onclick=\"functions.deleteEntity('${data}')\" class='btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1' title='Delete Record'><i class='fal fa-times'></i></a></div>`;
          }
        }
    ],
    columnDefs: [
        // { targets: [0], },
    //  { targets: [0, 1], "width": "20%"},
      //{ targets: 2, "width": "33%", render: $.fn.dataTable.render.ellipsis(40, false, true) },
   //   { "targets": [1], render: $.fn.dataTable.render.moment().format('MMMM Do YYYY, h:mm:ss a') },
     // { targets: 4, "width": "11%", render: $.fn.dataTable.render.number(',', '.', 0) }
 ]
      /* render dynamic buttons */
    };
    // this.listCCe(0);
    this.title = 'Nota fiscal';
    this.description = 'Lista de notas fiscais';
    this.listCCe();

    // <a class='dropdown-item item3' href='javascript:void(0);' onclick=\"functions.email('${data}')\">Enviar por e-mail</a>
    // <a class='dropdown-item item3' href='javascript:void(0);' data-toggle="modal" data-target="#default-example-modal-center">Enviar por e-mail</a>

    // $('.dropdown d-inline-block').on('click','a',function() {
  //     alert('aqui');
  //     $('.dropdown-menu').show();
  //   });
  

    // $(".dropdown-menu").click(function(e){
    //   alert('aqui');
    //   e.stopPropagation();
    // })
    
    // $(document).on('click.bs.dropdown-menu', '.keep-open', function (e) {
    //   alert('aqui');
    //    e.stopPropagation();
    // });

    $(document).on("click", ".dropdown d-inline-block position-relative", function (event) {
      // alert('dropdown-menu');
  });

    $('.dropdown d-inline-block position-relative').on('click',function () {
      // alert('dropdown-menu');
    });
    
    $('#tableList tbody').on( 'click', 'tr', function () {
      self.currentRow = this;
      var index = $('#tableList').DataTable().row(this).index();
      var status = $('#tableList').DataTable().column(0).data()[index];
      var id = $('#tableList').DataTable().column(6).data()[index];
      // alert( $('#tableList').DataTable().columns().index() );
    
    //   //get the initialization options
    // var columns = $('#tableList').DataTable().settings().init().columns;
    // //get the index of the clicked cell
    // var colIndex = $('#tableList').DataTable().cell(this).index().column;
    // alert('you clicked on the column with the name '+columns[colIndex].name);

      // if(self.editar) {
      //    self.editEntity(id);
      // };
      switch(status){
        case 'Digitação': {
           $('.item1').removeClass('disabled');
           $('.item2').removeClass('disabled');
           $('.item3').addClass('disabled');
           $('.item4').addClass('disabled');
           $('.item5').addClass('disabled');
           $('.item6').addClass('disabled');
        //   $('.item7').removeClass('disabled');
           $('.item7').addClass('disabled');
           $('.item8').removeClass('disabled');
           $('.item9').removeClass('disabled');
           break;
        };
        case 'Transmitida': {
          $('.item1').addClass('disabled');
          $('.item2').removeClass('disabled');
          $('.item3').removeClass('disabled');
          $('.item4').removeClass('disabled');
          $('.item5').removeClass('disabled');
          $('.item6').removeClass('disabled');
          $('.item7').removeClass('disabled');
      //    $('.item7').addClass('disabled');
          $('.item8').addClass('disabled');
          $('.item9').removeClass('disabled');
          break;
        };
        case 'Cancelada': {
          $('.item1').addClass('disabled');
          $('.item2').removeClass('disabled');
          $('.item3').removeClass('disabled');
          $('.item4').addClass('disabled');
          $('.item5').addClass('disabled');
          $('.item6').removeClass('disabled');
          $('.item7').removeClass('disabled');
       //   $('.item7').addClass('disabled');
          $('.item8').addClass('disabled');
          $('.item9').removeClass('disabled');
          break;
        };
      }
  } );

  }

  // dropdown(){
  //   $('#item1').prop('disabled', false);
  //   $('#item2').prop('disabled', false);
  // }


  ngOnDestroy() {
    window.functions = null;
  }
  
  changeAtivoFilter(data:boolean) {
    this.filterIsAtivo = data;
    $('#tableList').DataTable().ajax.reload();
  }
  
  new() {
     if(this.verificarChave.isEmpresaAtiva()) {
        if(!this.verificarChave.isVencida()){
           this.router.navigate(['/faturamento/edit']);
        }
    }
  }
  
  editEntity(data) {
    if(this.verificarChave.isEmpresaAtiva()) {
      if(!this.verificarChave.isVencida()){
         this.router.navigate(['/faturamento/edit', data]);
      }
    }
  }
  
  deleteEntity(data) {
    if(this.verificarChave.isEmpresaAtiva()) {
      if(!this.verificarChave.isVencida()){
         this.currentRecord = data;
         this.deleteConfirmationMessage.fire();
      }
    }
  }
  
  confirmDelete() {
    this.nfeService.delete(this.currentRecord).subscribe( (res) => {
      Swal.fire({
        title:  "",
        text: res.message,
        icon: 'success'
       });
      $('#tableList').DataTable().ajax.reload();
      this.currentRecord = -1;
    }, error => {
      this.errorMessage.fire();
      this.currentRecord = -1;
    });
  }
  
  duplicar(data) {
    if(this.verificarChave.isEmpresaAtiva()) {
      if(!this.verificarChave.isVencida()){

          const duplic = {id: data };
          Swal.fire({
            title: 'Duplicar?',
            text: "Deseja realmente copiar esta nota fiscal?",
            icon: 'question',
            // denyButtonText:'Fechar',
            showCancelButton: true,
            // confirmButtonColor: '#3085d6',
            // cancelButtonColor: '#d33',
            confirmButtonText: 'Copiar',
            cancelButtonText: 'Fechar',
          }).then((result) => {
            if (result.isConfirmed) {
              this.nfeService.duplicar(duplic).subscribe( (res) => {
                Swal.fire({
                  title:  "",
                  text: res.message,
                  icon: 'success'
                });
                $('#tableList').DataTable().ajax.reload();
                this.currentRecord = -1;
              }, error => {
                this.errorMessage.fire();
                this.currentRecord = -1;
              });
            }
            })
          }
        }
  }  
   

  handleDenial() {
  
  }
  

 async modalEmail(data){
    this.currentRecord = data;
    this.nfeService.getById(data).subscribe(response=> {
      this.nfe = response.data;
      this.emailDestinatario = this.nfe.entidade.email;
      $('#modalEmail').modal('show');
     });
 } 

 async email() {
    if(this.emailDestinatario == ''){
      Swal.fire({
        title:  "Ops!",
        text: 'Falta informar o e-mail.',
        icon: 'error',
        target: document.getElementById('modalEmail')
      });

    } else {
      var nfe = {id: this.currentRecord, email: this.emailDestinatario };
      this.msgSpinner = 'Enviando email...';
      this.spinnerService.show();
      await this.nfeService.emailNFe(nfe).subscribe(res => {
        });
        $('#modalEmail').modal('hide');
        Swal.fire({
          title:  "",
          text: 'e-mail enviado com sucesso.',
          icon: 'success',
          // target: document.getElementById('modalEmail')
        });
        this.spinnerService.hide();
    }
}

async cancelarNFeA1(data: any){
  await this.nfeService.cancelarNFe(data).then((res: any) => {
    this.spinnerService.hide();
    if((res.status == 135) || (res.status == 155)) {
      Swal.fire({
        title:  "",
        text: 'NF-e cancelada com sucesso.',
        icon: 'success',
        // target: document.getElementById('modalCancelar')
      });
      $('#tableList').DataTable().ajax.reload();
      } else {
        Swal.fire({
          title:  "Erro",
          text: res.msg,
          icon: 'error',
          // target: document.getElementById('modalCancelar')
        })
      }  
  })
}

async cancelarNFeA3(xJust: any){
  var cancelar = {
    idEmpresa: localStorage.getItem('idEmpresa'),
    idNota: this.currentRecord,
    UF: localStorage.getItem('UF'),
    urlBase: environment.apiUri,
    tpAmbiente: localStorage.getItem('tpAmbiente'),
    xJust: xJust
  };
  this.spinnerService.show();
  await this.msKiron.cancelarNFe(cancelar).then((res: any) => {
    this.spinnerService.hide();
    if((res.status == 135) || (res.status == 155)) {
      this.nfeService.atualizarNFeA3(res).then((res: any) => {
        Swal.fire({
          title:  "",
          text: 'NF-e cancelada com sucesso.',
          icon: 'success',
        });
        $('#tableList').DataTable().ajax.reload();
       });


      } else {
        Swal.fire({
          title:  "Erro",
          text: res.msg,
          icon: 'error',
        })
      }  
  });
};

async confirmarCancelamento(){
  var xJust = $("#xJust").val();
  if(xJust.length > 15) {
      this.msgSpinner = 'Cancelando nota fiscal...';
      this.spinnerService.show();
      
      if(localStorage.getItem('tipoCertificado') == 'A3'){ 
          this.cancelarNFeA3(xJust);
      } else {
        var nfe = {id: this.currentRecord, xJust: xJust};
        this.cancelarNFeA1(nfe);
      }

     } else {
      Swal.fire({
        title:  "Caracteres Insuficientes.",
        text: 'Digite pelo menos 15 caracteres para enviar o cancelamento.',
        icon: 'warning',
        // target: document.getElementById('modalCancelar')
      });
    }
 



  // this.msgSpinner = 'Cancelando nota fiscal...';
  // this.spinnerService.show();
  // var nfe = {id: this.currentRecord, xJust: $('#xJust').val()};
  // await this.nfeService.cancelarNFe(nfe).then((res: any) => {
  //     this.spinnerService.hide();
  //     if((res.status == 135) || (res.status == 155)) {
  //       Swal.fire({
  //         title:  "",
  //         text: 'NF-e cancelada com sucesso.',
  //         icon: 'success',
  //         // target: document.getElementById('modalCancelar')
  //       });
  //       $('#tableList').DataTable().ajax.reload();
  //       } else {
  //         Swal.fire({
  //           title:  "Ops!",
  //           text: atob(res.message),
  //           icon: 'error',
  //           // target: document.getElementById('modalCancelar')
  //         })
  //       }  
  // });
  
 }

cancelarNFe(data) {
  if(this.verificarChave.isEmpresaAtiva()) {
    if(!this.verificarChave.isVencida()){

      $('#tableList').click();
      this.currentRecord = data;
      $('#xJust').val('').change();
      $('#modalCancelar').modal('show');
    }
  }
}

fillStatus() {
  var status = [
    {'id': 0, 'text': 'Digitação'},
    {'id': 3, 'text': 'Transmitida'},
    {'id': 4, 'text': 'Cancelada'},
    {'id': 5, 'text': 'Denegada'},
   ];
  $('#statusNota').select2({
    data: status,
    allowClear: true,
  });
};

filtrar(){
  this.filterStatus = '';
  var valueStatus =  $('#statusNota').val();
  for(var i = 0; i < valueStatus.length; i++) {
    if(this.filterStatus !== ''){
      this.filterStatus = this.filterStatus + ',';
    }
    this.filterStatus = this.filterStatus + valueStatus[i];
  }
  if($('#filterData').val() !== '') {
      this.filterStartDate = $('#filterData').data('daterangepicker').startDate.format('YYYY-MM-DD');
      this.filterEndDate = $('#filterData').data('daterangepicker').endDate.format('YYYY-MM-DD');
  }
  $('#tableList').DataTable().ajax.reload();
}


limparFiltros(){
  // document.getElementById('filterCfop').value='';
  // document.getElementById('filterCfop').reset(); 
  this.filterStartDate = '';
  this.filterEndDate = '';
  $("input").val("");
  $("#statusNota").val('').change();
}

async enviarNFe(data) {
  if(this.verificarChave.isEmpresaAtiva()) {
    if(!this.verificarChave.isVencida()){
        this.editar = false;
        $('#tableList').click();
        this.currentRecord = data;
        if(localStorage.getItem('tipoCertificado') == 'A3'){
          await this.enviarNFeA3();
        } else {
          await this.enviarNFeA1();
        }
    }
  }
}

async enviarNFeA1(){

  var nfe = {id: this.currentRecord};
  this.msgSpinner = 'Enviando nota para Sefaz, isso pode levar alguns segundos...';
  this.spinnerService.show();
  await this.nfeService.enviarNFe(nfe).then((res: any) => {
    console.log(res);
    console.log('Status:', res.status);
    this.spinnerService.hide();
    if(res.status == 100) {
       Swal.fire({
        text: res.message,
        icon: 'success',
      });
      $('#tableList').DataTable().ajax.reload();
    } else if(res.status == 1) {
      this.spinnerService.hide();
      Swal.fire({
        title:  "Ops!",
        text: res.message,
        icon: 'error',
      })
    }  else {
      this.spinnerService.hide();
      Swal.fire({
        title:  "Ops!",
        text: atob(res.message),
        icon: 'error',
      })
    }
 });
   this.editar = true;
}

async enviarNFeA3(){
  var enviar = {
    idEmpresa: localStorage.getItem('idEmpresa'),
    idNota: this.currentRecord,
    UF: localStorage.getItem('UF'),
    urlBase: environment.apiUri,
    tpAmbiente: localStorage.getItem('tpAmbiente')
  };
  this.msgSpinner = 'Enviando nota para Sefaz, isso pode levar alguns segundos...';
  this.spinnerService.show();

  await this.msKiron.enviarNFe(enviar).then((res: any) => {
    this.spinnerService.hide();
    if(res.status == 100) {
      // res.idEmpresa = localStorage.getItem('idEmpresa');
      // res.idNotafi = this.currentRecord,
      // console.log(res);
       this.nfeService.atualizarNFeA3(res).then((res: any) => {
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          $('#tableList').DataTable().ajax.reload();
        });
    } else {
      this.spinnerService.hide();
      Swal.fire({
        title:  "Ops!",
        text: atob(res.message),
        icon: 'error',
      })
    }
  });
}


async visualizarDanfe(data){
  this.editar = false;
  $('#tableList').click();
  this.currentRecord = data;
  var nfe = {id: this.currentRecord};
  this.msgSpinner = 'Gerando Danfe...';
  this.spinnerService.show();
  await this.nfeService.visualizarNFe(nfe);
  this.spinnerService.hide();
  this.editar = true;
}

async baixarXml(data){
  this.currentRecord = data;
  var nfe = {id: this.currentRecord};
  await this.nfeService.baixarXml(nfe);
  $('#tableList').click();
}

listCCe(){
  var self = this;
  this.dtOptionsCCe = {
    processing:true,
    serverSide:true,
    paging: false,
    ajax: {
    //  url: 'https://kironwebapi.azurewebsites.net/cce',
      url: environment.apiUri + '/cce',
      data: function (d) {
        var queryData = {
          idNotafi: self.currentRecord
        }
        return queryData;
      },
      dataSrc : function(json) : any {
        return json.data;
      },
      async: true,
      error: function (xhr, error, code){},
      headers: { 'x-access-token': this.loginService.getToken() }
      
    },
    columns : [
      { "data": "dtEmissao",  render: function(data){ return moment(data).format("DD/MM/YYYY HH:mm");}},
      { "data": "sequencia" },
      { "data": "chave" },
      {
        "data": "id",
        render: (data, type, full) => {
          return `
              <div class="text-center">
                <a href='javascript:void(0);'onclick=\"functions.imprimirCCe('${data}')\" class="btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1" title="Visualizar CC-e">
                <i class="fal fa-print"></i>
               </a>
                <a href='javascript:void(0);'onclick=\"functions.emailCCe('${data}')\" class="btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1" title="Enviar por E-mail">
                  <i class="fal fa-envelope"></i>
                </a>

              </div>
          `;             
        }
      }
  ],
  };

}

async cce(data) {
  this.currentRecord = data;
  $('#tableList').click();
  $('#xCorrecao').val('').change();
  $('#tableListCCe').DataTable().ajax.reload();
  $('#modalCCe').modal('show');
}

async enviarCCeA1(data: any){
  await this.nfeService.enviarCCe(data).subscribe(res => {
    this.spinnerService.hide();
    if(res.status == 135) {
        Swal.fire({
          title:  "",
          text: res.message,
          icon: 'success',
          target: document.getElementById('modalCCe')
      });
      $('#tableListCCe').DataTable().ajax.reload();
    } else {
      Swal.fire({
        title:  "Ops!",
        text: atob(res.message),
        icon: 'error',
        target: document.getElementById('modalCCe')
    });
   }
  });
}

async enviarCCeA3(xCorrecao: any){
  var seq: number = 1;
  await this.nfeService.sequenciaCCe(this.currentRecord).then(res => {
      seq = res.sequencia + 1;
  });

  var cce = {
    idEmpresa: localStorage.getItem('idEmpresa'),
    idNota: this.currentRecord,
    sequencia: seq,
    UF: localStorage.getItem('UF'),
    urlBase: environment.apiUri,
    tpAmbiente: localStorage.getItem('tpAmbiente'),
    xCorrecao: xCorrecao
  };
  await this.msKiron.enviarCCe(cce).then(res => {
    console.log(res);
    this.spinnerService.hide();
    if(res.status == 135) {
      this.nfeService.atualizarCCeA3(res).then((res: any) => {
      });
        Swal.fire({
          title:  "",
          text: res.message,
          icon: 'success',
          target: document.getElementById('modalCCe')
      });
      $('#tableListCCe').DataTable().ajax.reload();
    }else {
      Swal.fire({
        title:  "Ops!",
        text: atob(res.message),
        icon: 'error',
        target: document.getElementById('modalCCe')
    });
    }
   });
}

async enviarCCe() {
  if(this.verificarChave.isEmpresaAtiva()) {
    if(!this.verificarChave.isVencida()){

        var xCorrecao = $("#xCorrecao").val(); 
        if(xCorrecao.length > 15){   
            this.msgSpinner = 'Enviando carta de correção para Sefaz...';
            this.spinnerService.show();

            if(localStorage.getItem('tipoCertificado') == 'A3'){ 
                this.enviarCCeA3(xCorrecao);
            } else {
              var nfe = {id: this.currentRecord, xCorrecao: xCorrecao};
              this.enviarCCeA1(nfe);
            }
        } else {
          Swal.fire({
            title:  "Caracteres Insuficientes",
            text: 'Digite no minimo 15 caracteres para enviar a carta de correção',
            icon: 'warning',
            target: document.getElementById('modalCCe')
          });   
        }
    }
  }
}

async imprimirCCe(data){
  this.currentRecord = data;
  var cce = {id: this.currentRecord};
  await this.nfeService.imprirmiCCe(cce);
}

async emailCCe(data){
  this.currentRecord = data;
  var nfe = {id: this.currentRecord};
  await this.nfeService.emailCCe(nfe).subscribe(res => {
  });
  Swal.fire({
    title:  "",
    text: 'e-mail enviado com sucesso.',
    icon: 'success',
    target: document.getElementById('modalCCe')

   });  
}

async consultarStatus(data) {
  if(this.verificarChave.isEmpresaAtiva()) {
    if(!this.verificarChave.isVencida()){

        $('#tableList').click();
        this.currentRecord = data;
        var nfe = {id: this.currentRecord};
        this.msgSpinner = 'Consultando nota fiscal, isso pode levar alguns segundos...';
        this.spinnerService.show();
        await this.nfeService.atualizarStatus(nfe).then((res: any) => {
          this.spinnerService.hide();
          if((res.status == 100) || (res.status == 101) || (res.status == 151) || (res.status == 110)){
            Swal.fire({
              text: res.message,
              icon: 'success',
            });
            $('#tableList').DataTable().ajax.reload();
          } else {
            this.spinnerService.hide();
            Swal.fire({
              title:  "Ops!",
              text: atob(res.message),
              icon: 'error',
            })
          }
        });
      }
    }
  }

};


  //   <div class="text-center">
          //   <a href='javascript:void(0);'onclick=\"functions.editEntity('${data}')\" 
          //   class="btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1"title="Editar registro">
          //   <i class="fal fa-pen"></i>
          //   </a>
          

          //  <div class='dropdown d-inline-block'>
          //     <a class='btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1'  data-toggle='dropdown' aria-expanded='false' title='Mais opções'>
          //       <i class="fal fa-ellipsis-v"></i>
          //     </a>
          //     <div class='dropdown-menu  id='maisOpcoes''>
          //       <a class='dropdown-item item1' href='javascript:void(0);' onclick=\"functions.enviarNFe('${data}')\">Emitir</a>
          //       <a class='dropdown-item item2' href='javascript:void(0);' onclick=\"functions.visualizarDanfe('${data}')\" >Visualizar Danfe</a>
          //       <a class='dropdown-item item3' href='javascript:void(0);' onclick=\"functions.email('${data}')\">Enviar por e-mail</a>
          //       <a class='dropdown-item item4' href='javascript:void(0);' onclick=\"functions.cancelaNFe('${data}')\">Cancelar</a>
          //       <a class='dropdown-item item5' href='javascript:void(0);' onclick=\"functions.cce('${data}')\">Emitir CC-e</a>
          //       <a class='dropdown-item item6' href='javascript:void(0);' onclick=\"functions.baixarXml('${data}')\">Baixar XML</a>
          //       <a class='dropdown-item item7' href='javascript:void(0);' onclick=\"functions.consultarStatus('${data}')\">Consultar status</a>
          //     </div>
          //   </div>

          //   <a href='javascript:void(0);'onclick=\"functions.deleteEntity('${data}')\" 
          //   class="btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1" [disabled]="false" title="Deletar registro">
          //   <i class="fal fa-times"></i>
          //   </a>
          // </div>