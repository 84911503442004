import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { inutilizarRouting } from './inutilizar.routing';
import { InutilizarComponent } from "./inutilizar.component";
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    inutilizarRouting
  ],
  declarations: [InutilizarComponent]
})
export class InutilizarModule { }