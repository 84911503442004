  <div class="subheader">
    <h1 class="subheader-title">
        <i class='subheader-icon fal fa-calendar-alt'></i> Relatório de NF-e Por Data
        <small>
            Relatório Nota Fiscal Eletrônica Por Data
        </small>
    </h1>
  </div>
  <div class="row">
    <div class="col-xl-12">
        <div id="panel-1" class="panel">
            <div class="panel-hdr">
                <h2>
                    Filtro NF-e Por data <span class="fw-300"></span>
                </h2>
                <div class="panel-toolbar">
                 
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <label class="form-label">Período: <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <div class="form-group col-sm-5">
                                    <label class="form-label" for="dataNota">Selecione o período desejado:</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Selecione a data" id="filterData1">
                                        <div class="input-group-append">
                                            <span class="input-group-text fs-xl">
                                                <i class="fal fa-calendar"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <label class="form-label">Opções: <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <div class="form-group col-sm-12">
                                    <label class="form-label" for="dataNota">Selecione os status desejados:</label>
                                    <div class="input-group">
                                        <div class="frame-wrap" style="padding-top: 6px;">
                                            <div class="custom-control custom-checkbox custom-control-inline col-sm-5">
                                              <input type="checkbox" checked class="custom-control-input" id="isTransmitida">
                                              <label class="custom-control-label" for="isTransmitida">Transmitidas</label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline col-sm-5">
                                              <input type="checkbox" class="custom-control-input"  id="isCancelada">
                                              <label class="custom-control-label" for="isCancelada">Canceladas</label>
                                            </div>
                                          </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="gerarRelatorio()">Gerar Relatório</button>
            </div>
        </div>

    </div>
  </div>
 