<div class="row">
    <!-- <div class="" style="float:right"> -->
      <!-- <h2 class="text-white fs-xxl fw-500 mt-4 text-white">          
      
            <small class="h3 fw-300 mt-3 mb-5 text-white opacity-60">
  
            </small>
        </h2> -->
        <!--
        <a href="#" class="fs-lg fw-500 text-white opacity-70">Learn more &gt;&gt;</a>
        <div class="d-sm-flex flex-column align-items-center justify-content-center d-md-block">
            <div class="px-0 py-1 mt-5 text-white fs-nano opacity-50">
                Find us on social media
            </div>
            <div class="d-flex flex-row opacity-70">
                <a href="#" class="mr-2 fs-xxl text-white">
                    <i class="fab fa-facebook-square"></i>
                </a>
                <a href="#" class="mr-2 fs-xxl text-white">
                    <i class="fab fa-twitter-square"></i>
                </a>
                <a href="#" class="mr-2 fs-xxl text-white">
                    <i class="fab fa-google-plus-square"></i>
                </a>
                <a href="#" class="mr-2 fs-xxl text-white">
                    <i class="fab fa-linkedin"></i>
                </a>
            </div>
        </div> -->
    <!-- </div> -->
    <!-- <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4 ml-auto"> -->
    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 ml-auto">
        <h1 class="">
            Acessar o sistema
        </h1>
        <div class="card p-4 rounded-plus bg-faded" >
            <div *ngIf="isExpired" class="form-group">
                Para segurança sua sessão expirou. Por favor,
                faça o ligin novamente.
            </div>
            <form [formGroup]="loginForm" (ngSubmit)="login()" id="js-login" novalidate="" action="intel_analytics_dashboard.html">
                <div class="form-group">
                    <label class="form-label" for="username">Email</label>
                    <input type="email" id="email" class="form-control form-control-lg" formControlName="email" placeholder="Email de acesso" required>
                    <div *ngIf="formControls.email.errors && formControls.email.errors.required" class="invalid-feedback">Preenchimento obrigatório.</div>
                    <div class="help-block">Email de acesso</div>
                </div>
                <div class="form-group">
                    <label class="form-label" for="password">Senha</label>
                    <input type="password" id="senha" class="form-control form-control-lg"  formControlName="senha" placeholder="Senha" required>
                    <div *ngIf="formControls.senha.errors && formControls.senha.errors?.required" class="invalid-feedback">Preenchimento obrigatório.</div>
                    <div class="help-block">Senha</div>
                </div>
                <div class="row no-gutters">
                    <div class="col-lg-12">
                        <button id="js-login-btn" type="submit" class="btn btn-primary btn-block btn-lg">Entrar</button>
                    </div>
                </div>
                <br>
                <div class="row no-gutters">
                  <div class="col-lg-12" style="text-align: center;">
                    <a href="" data-toggle="modal" data-target="#default-example-modal-center" title="Recuperar Senha">
                        <span class="nav-link-text">Esqueci Minha Senha</span>
                      </a>
                      <br>
                      <!-- <label class=" "><a href="#" routerLink="/recuperaSenha" class='text-white opacity-40 fw-500'>Esqueci minha senha</a></label> -->
                      
                  </div>
              </div>
            </form>
  
        </div>
        <br><br><br><br><br><br><br><br>
        <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
          2021 © Kiron by&nbsp;<a href='https://www.kiron.net.br' class='text-white opacity-40 fw-500' title='kiron.net.br' target='_blank'>kiron.net.br</a>
        </div>
    </div>
  </div>

      <!-- Modal Esqueci a senha -->
<div class="modal fade" id="default-example-modal-center" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    Esqueceu sua senha?
                    <small class="m-0 text-muted">
                       Digite o endereço de e-mail cadastrado na sua conta:
                    </small>
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label class="form-label" for="emailSenha">Email</label>
                    <input type="email" id="emailSenha" class="form-control form-control-lg" formControlName="emailSenha" placeholder="Email cadastrado na conta" required>
                    <div *ngIf="formControls.email.errors && formControls.email.errors.required" class="invalid-feedback">Preenchimento obrigatório.</div>
                    <!-- <div class="help-block">Email cadastrado na sua conta</div> -->
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary" (click)="recuperarSenha()" data-dismiss="modal">Recuperar Senha</button>
            </div>
        </div>
    </div>
</div>

  
  <swal #invalidLoginMessage title="Conferir login e senha" text="Login e/ou senha inválidos. Por favor tente novamente" icon="warning"></swal>
  
  <swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde." icon="error"></swal>
  