import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { routerTransition } from '../../../../util/animations';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { UsuarioService } from 'src/app/services/usuarios.service';
import { environment } from 'src/environments/environment';

declare var localStorage:any;

@Component({
  selector: 'cp-list',
  templateUrl: './usuarios.list.component.html',
  styles: [],
  animations: [routerTransition],
})
export class UsuariosListComponent
  implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private usuariosService: UsuarioService
  ) {}

  dtOptions:  DataTables.Settings = {};
  title: string;
  description:string;
  currentPage: number = 0;
  currentPageSize:number = 10;
  filterIsAtivo: boolean = true;
  currentRecord: number;
  search: string = '';
  msgErroServer: string = "Ops, aconteceu algum imprevisto no servidor. ";
  
  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  @ViewChild('ativarDesativarConfirmationMessage')
  public readonly ativarDesativarConfirmationMessage!: SwalComponent;

  ngAfterViewInit(): void {}

  ngOnInit() {
    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);
    window.functions.ativarDesativarEntity = this.ativarDesativarEntity.bind(this);

    var self = this;
    this.dtOptions = {
      processing:true,
      serverSide:true,
      ajax: {
       // url: 'https://kironwebapi.azurewebsites.net/usuario',
        url: environment.apiUri + '/usuario',
        data: function (d) {
          var queryData = {
            page: d['start'] / d['length'] + 1,
            pageSize:d['length'],
            status: self.filterIsAtivo ? 'A' : 'I',
            idEmpresa: localStorage.getItem('idEmpresa'),
            search: self.search
          }
          self.currentPage = queryData.page;
          self.currentPageSize = queryData.pageSize;
          return queryData;
        },
        dataSrc : function(json) : any {
          json.recordsTotal = json.count;
          json.recordsFiltered = json.count
          return json.data;
        },
        async: true,
        error: function (xhr, error, code)
          {
            if((xhr.status == 400) || (xhr.status == 401)) {
              self.loginService.tokenExpired();
            }
          },
        headers: { 'x-access-token': this.loginService.getToken() }
      },
      columns : [
        { "data": "id" },
        { "data": "nome" },
        { "data": "login" },
        {
          "data": "id",
          render: (data, type, full) => {
            return `<div class='text-center'><a href='javascript:void(0);' onclick=\"functions.editEntity('${data}')\" class='btn btn-primary btn-sm btn-icon rounded-circle' title='Editar registro'><i class='fal fa-pen'></i></a>
                    <a href='javascript:void(0);' onclick=\"functions.ativarDesativarEntity('${data}')\" class='btn btn-primary btn-sm btn-icon rounded-circle' title='Ativar/Desativar'><i class='fal fa-check'></i></a>
                    <a href='javascript:void(0);' onclick=\"functions.deleteEntity('${data}')\" class='btn btn-danger btn-sm btn-icon rounded-circle' title='Deletar registro'><i class='fal fa-times'></i></a></div>`;
          }
        }
      ],
      /* render dynamic buttons */
    };

   
   
    this.title = 'Usuarios';
    this.description = 'Lista de usuarios ativos e inativos'
}

searchLista() {
   $('#tableListUsuario').DataTable().ajax.reload();
}

ngOnDestroy() {
  window.functions = null;
}

changeAtivoFilter(data:boolean) {
  this.filterIsAtivo = data;
  $('#tableListUsuario').DataTable().ajax.reload();
}

new() {
   this.router.navigate(['/cadastros/usuarios/edit'])
}

editEntity(data) {
    this.router.navigate(['/cadastros/usuarios/edit', data])
}

deleteEntity(data) {
  this.currentRecord = data;
  this.deleteConfirmationMessage.fire();
}


handleDenial() {
};


ativarDesativarEntity(data) {
  var msgAtivo: string = this.filterIsAtivo ? 'desativar' : 'ativar';
  this.currentRecord = data;
  Swal.fire({
    title:  "",
    text: 'Tem certeza que deseja ' + msgAtivo + ' este cadastro?',
    icon: "question",
    showDenyButton: true,
    showConfirmButton:true,
    denyButtonText:"Cancelar",
    confirmButtonText:"Confirmar",
    }).then((result) => {
       if (result.value) { 
          this.confirmAtivarDesativar();
       }
   });
  this.ativarDesativarConfirmationMessage.fire();
}

confirmAtivarDesativar() {
  var isAtivo: string = this.filterIsAtivo ? 'N' : 'S';

  this.usuariosService.getById(this.currentRecord).subscribe( response=> {
    console.log(response);
    response.data.isAtivo = isAtivo;
    this.usuariosService.update(response.data).subscribe( response=> {
         $('#tableListUsuario').DataTable().ajax.reload();
    } , error => {
      Swal.fire({
        title:  "Erro!",
        text: this.msgErroServer + error.error.message,
        icon: 'error'
    });
    this.currentRecord = -1;
  });
     this.currentRecord = -1;
  });}

}