

<!-- BEGIN Page Wrapper -->
<div class="page-wrapper">
  <div class="page-inner">
    <sa-navigation></sa-navigation>
    <div class="page-content-wrapper">
      <!-- BEGIN Page Header -->
      <header class="page-header" id="pageHeader" role="banner">
        <!-- we need this logo when user switches to nav-function-top -->
        <div class="page-logo ">
          <a
            href="#"
            class="page-logo-link press-scale-down d-flex align-items-center position-relative"
          >
            <img src="img/logoNovo.png" alt="Kiron" aria-roledescription="logo" />
          </a>
        </div>
        <div class="col-sm-11" style="vertical-align: middle;">
          <!-- <div class="col-sm-1" style="float: right;">
            <button class="btn btn-outline-default btn-icon rounded-circle">
              <i class="fal fa-bell"></i>
            </button>
          </div> -->

          <!-- <div class="col-sm-1" style="float: right;">
            <a href="javascript:void(0);" style="float: right;" id="olvy-target" class="btn btn-default btn-lg btn-icon rounded-circle">
              <i class="fal fa-bell"></i>
            </a>
          </div> -->
          <div class="col-sm-4" style="float: right; position: relative; margin-top: 5px; vertical-align: middle;" >
            <select class="select2 form-control w-100" id="selectMainEmpresa">
            </select>
          </div>

        </div>
        <!-- <div class="col-sm-11">
          <div class="col-sm-4" style="float: right;">
            <select class="select2 form-control w-100" id="selectMainEmpresa">
            </select>
          </div>
        </div> -->
        <!-- DOC: mobile button appears during mobile width -->
        <div class="hidden-lg-up">
          <a
            href="#"
            class="header-btn btn press-scale-down"
            data-action="toggle"
            data-class="mobile-nav-on"
          >
            <i class="ni ni-menu"></i>
          </a>
        </div>
 
        <!-- ALTERAÇÃO ICONE DA NOTIFICAÇÃO -->
        <div class="" style="float: right;" >
          <button id="olvy-target" class="btn btn-outline-primary btn-icon rounded-circle"><i class="fal fa-bell"></i></button>
        </div>
       <!-- FIM ALTERAÇÃO ICONE DA NOTIFICAÇÃO -->   

        
        <div class="ml-auto d-flex">
          <!-- app user menu -->
          <div>
            <a
              href="#"
              data-toggle="dropdown"
              title={{userNome}}
              class="header-icon d-flex align-items-center justify-content-center ml-2"
            >

            <!-- ALTERAÇÃO ICONE MENU -->
            <a href="javascript:void(0);" style="float: right;" class="btn btn-outline-primary btn-icon rounded-circle">
              <i class="fal fa-user-alt"></i>
            </a>
              <!-- <img
                src="img/demo/avatars/avatar-oazul.PNG"
                class="profile-image rounded-circle"
              /> -->

            <!-- FIM ALTERAÇÃO ICONE MENU -->
              <!-- you can also add username next to the avatar with the codes below:
        <span class="ml-1 mr-1 text-truncate text-truncate-header hidden-xs-down">Me</span>
        <i class="ni ni-chevron-down hidden-xs-down"></i> -->
            </a>
            <div class="dropdown-menu dropdown-menu-animated dropdown-lg">
              <div
                class="dropdown-header bg-primary-600 d-flex flex-row py-4 rounded-top"
              >
                <div
                  class="d-flex flex-row align-items-center mt-1 mb-1 color-white"
                >
                  <span class="mr-2">
                    <img
                      src="img/demo/avatars/avatar-obranco.PNG"
                      class="rounded-circle profile-image"
                      alt="Kiron"
                    />
                  </span>
                  <div class="info-card-text">
                    <div class="fs-lg text-truncate text-truncate-lg">
                      {{userNome}}
                    </div>
                    <span class="text-truncate text-truncate-md opacity-80"
                      >{{userEmail}}</span>
                  </div>
                </div>
              </div>
              <div class="dropdown-divider m-0"></div>
              <a (click)="empresa()" class="dropdown-item">
                <span data-i18n="drpdwn.reset_layout">Minha Empresa</span>
              </a>
              <a (click)="usuarios()" class="dropdown-item">
                <span data-i18n="drpdwn.reset_layout">Usuários</span>
              </a>
              <a (click)="certificado()" class="dropdown-item">
                <span data-i18n="drpdwn.reset_layout">Certificado Digital</span>
              </a>
              <a (click)="inutilizar()"  class="dropdown-item">
                <span data-i18n="drpdwn.reset_layout">Inutilizar NFe</span>
              </a>
              <a (click)="logout()" class="dropdown-item fw-500 pt-3 pb-3">
                <span data-i18n="drpdwn.page-logout">Logout</span>
                <span class="float-right fw-n"></span>
              </a>
            </div>
          </div>
        </div>
      </header>
      <!-- END Page Header -->
      <!-- BEGIN Page Content -->
      <!-- the #js-page-content id is needed for some plugins to initialize -->
      <main id="js-page-content" role="main" class="page-content">
        <router-outlet #routerOutlet="outlet"></router-outlet>
      </main>
      <!-- this overlay is activated only when mobile menu is triggered -->
      <div
        class="page-content-overlay"
        data-action="toggle"
        data-class="mobile-nav-on"
      ></div>
      <!-- END Page Content -->
      <!-- BEGIN Page Footer -->
      <footer class="page-footer" role="contentinfo">
        <div class="d-flex align-items-center flex-1 text-muted">
          <span class="hidden-md-down fw-700">2022 © Kiron Sistemas - v2.30a</span>
        </div>
      </footer>
      <!-- END Page Footer -->
    </div>
  </div>
</div>
<!-- END Page Wrapper -->
