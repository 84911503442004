import { Component, OnInit, AfterViewInit } from "@angular/core";
import { routerTransition } from "../util/animations";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoginService } from "src/app/services/login.service";
import { LoginData } from "src/app/services/model/loginData";
import { HttpErrorResponse } from "@angular/common/http";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { UsuarioService } from "src/app/services/usuarios.service";
import { RecuperaSenhaService } from "../services/recuperaSenha.service";
import { Usuario } from "src/app/services/model/usuario";
import {Md5} from 'ts-md5/dist/md5';
import { JwtHelperService } from '@auth0/angular-jwt';

let myString = "https://kironadmin.azurewebsites.net/novaSenha/1681";
let encodedString = encodeURIComponent(myString);

declare function initMe(): any;
declare var $:any

@Component({
  selector: "app-recuperaSenha-layout",
  templateUrl: "./recuperaSenha-layout.component.html",
  styles: [],
  animations: [routerTransition]
})
export class RecuperaSenhaLayoutComponent implements OnInit, AfterViewInit {
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private recuperaSenhaService: RecuperaSenhaService,
  ) {}

  loginForm: FormGroup;
  isSubmitted  =  false;
  isExpired = false;
  id: string;
  usuario: Usuario = {} as Usuario;
  

  ngAfterViewInit(): void {

    $.extend( true, $.fn.dataTable.defaults, {
      "searching": false,
      "ordering": false,
      "language":  {
        url: "https://cdn.datatables.net/plug-ins/1.10.22/i18n/Portuguese-Brasil.json"
      }
    });


    initMe();
  }

  ngOnInit() {
    localStorage.clear();

    this.id =  this.route.snapshot.paramMap.get('id');
    const jwt = new JwtHelperService();

    const decodedToken = jwt.decodeToken(this.id);
    const expirationDate = jwt.getTokenExpirationDate(this.id);
    this.isExpired= jwt.isTokenExpired(this.id);

    console.log('decodedToken: ',decodedToken);
    console.log('expirationDate: ',expirationDate);
    console.log('isExpired: ', this.isExpired);

    this.usuario.id = decodedToken.id;



    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      senha: ['', Validators.required]
    });
  }

  getState(outlet) {
    if(!outlet.activatedRoute) return;
    let ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }
  saveSenha(){
   // alert(encodedString)
    if($('#senha1').val() !== $('#senha2').val()){
      Swal.fire({
        title:  "Erro!",
        text: 'Ops, as senhas estão diferentes.',
        icon: 'error',
       });
    } else {
      const md5 = new Md5();
      this.usuario.senha = md5.appendStr($('#senha1').val()).end().toString();
    //   this.usuario.id = parseInt(this.id);
    //  this.usuario.id = 1981;
    console.log(this.usuario);
      this.recuperaSenhaService.update(this.usuario).subscribe(response => {
          Swal.fire({
            title:  "",
            text: 'Boa, senha alterada com sucesso.',
            icon: 'success',
          });
          this.router.navigate(['/login']);
        });
    }
  }
  get formControls() { return this.loginForm.controls; }
  
}

 