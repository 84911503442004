<!-- <ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item"><a href="" routerLink="/">Kiron</a></li>
    <li class="breadcrumb-item">NCM</li>
    <li class="breadcrumb-item active">Edição</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block">
      <span class="js-get-date"></span>
    </li>
  </ol> -->
  <div class="subheader">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-hand-holding-usd"></i> NCM
      <small>
        Cadastro de NCM
      </small>
    </h1>
  </div>
  <div id="panel-1" class="panel">
    <div class="panel-hdr">
      <h2>NCM<span class="fw-300"></span></h2>
      <div class="panel-toolbar">
  
      </div>
    </div>
    <div class="panel-container show">
      <div class="panel-content">
        <div class="row">
          <div class="form-group col-sm-2">
            <label class="form-label" for="cfop">NCM <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" [(ngModel)]="ncm.ncm" class="form-control" placeholder="" id="ncmNcm">
            </div>
          </div>
          <div class="form-group col-sm-2">
            <label class="form-label" for="aliquota">Aliquota</label>
            <div class="input-group">
                <input type="text" [(ngModel)]="ncm.pIpi" class="form-control" placeholder="" id="ncmpIpi">
            </div>
          </div>
          <div class="form-group col-sm-2">
            <label class="form-label" for="basecalc">% Base de Cálculo </label>
            <div class="input-group">
                <input type="text" [(ngModel)]="ncm.pBC" class="form-control" placeholder="" id="ncmpBC">
            </div>
          </div>
          <div class="form-group col-sm-2">
            <label class="form-label" for="cest">CEST <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" [(ngModel)]="ncm.cest" class="form-control" placeholder="" id="ncmCest">
            </div>
          </div>

        </div>
        <label class="form-label">% Alíquota IBPT (Lei Transparência)</label>
        <div class="row">
            
            <div class="form-group col-sm-2">
                <label class="form-label" for="federal">Federal </label>
                <div class="input-group">
                    <input type="text" [(ngModel)]="ncm.pIBPTNac" class="form-control" placeholder="" id="ncmpIBPTNac">
                </div>
              </div>
              <div class="form-group col-sm-2">
                <label class="form-label" for="estadual">Estadual </label>
                <div class="input-group">
                    <input type="text" [(ngModel)]="ncm.pIBPTEst" class="form-control" placeholder="" id="ncmpIBPTEst">
                </div>
              </div>
              <div class="form-group col-sm-2">
                <label class="form-label" for="municipal">Municipal </label>
                <div class="input-group">
                    <input type="text" [(ngModel)]="ncm.pIBPTMun" class="form-control" placeholder="" id="ncmpIBPTMun">
                </div>
              </div>
              <div class="form-group col-sm-2">
                <label class="form-label" for="importado">Importado</label>
                <div class="input-group">
                    <input type="text" [(ngModel)]="ncm.pIBPTImp" class="form-control" placeholder="" id="ncmpIBPTImp">
                </div>
              </div> 
         </div>
      </div>
    </div>
  </div>
  
  <div  class="accordion accordion-outline" id="js_demo_accordion-4" style="background-color: #fff; box-shadow: 0 3px 3px -1px #e2e2e2;">
   <div class="card" style="background-color: #fff;">
     <div class="card-header" style="background-color: #fff;">
       <a href="javascript:void(0);" class="card-title" data-toggle="collapse" data-target="#js_demo_accordion-4a" aria-expanded="false">        
        
        <span class="fw-100" style="color: rgb(10, 10, 10);">Configurações de Impostos</span>
        <span class="ml-auto">          
          <span class="collapsed-hidden">
            <i class="fal fa-plus fs-xl"></i>
          </span>
          <span class="collapsed-reveal">
            <i class="fal fa-minus fs-xl"></i>
          </span>
        </span>
          
        </a>
      </div>
      <div id="js_demo_accordion-4a" class="collapse hide" data-parent="#js_demo_accordion-4" style="background-color: #fff;">
        <div class="col-xl-12">
            <!-- <div id="panel-1" class="panel"> -->
                <!-- <div class="panel-hdr"> -->
                    <!-- <h2>
                        Regras de Impostos<span class="fw-300"></span>
                    </h2> -->
                    <div class="panel-toolbar" style="float: right;">
                      <a>
                      <button  [disabled]="disableForm" data-toggle="modal" (click)="novoImposto()" type="button" class="btn btn-sm btn-primary">
                        <span class="fal fa-plus mr-1"></span>
                        Novo
                      </button>
                    </a>
                    </div>
                    <br><br>
                <!-- </div> -->
                <div class="panel-container show">
                    <div class="panel-content">
                        <!-- <nav class="nav nav-pills">
                          <a  class="nav-item nav-link active btn-xs" href="#" data-toggle="pill">Ativo</a>
                          <a  class="nav-item nav-link btn-xs" href="#" data-toggle="pill">Inativo</a>
                        </nav>
                        <br/> -->
                        <!-- datatable start -->
                        <table [dtOptions]='dtOptions' datatable id="tableList" class="table table-hover table-striped w-100">
                          <thead class="bg-primary-600">
                              <tr>
                                  <th>UF Destino</th>
                                  <th>% IVA</th>
                                  <th>% IVA Ajustado</th>
                                  <th>% Icms</th>
                                  <th>% Icms ST</th>
                                  <th></th>
                              </tr>
                          </thead>
                          <tbody>
    
                          </tbody>
                      </table>
                        <!-- datatable end -->
                    </div>
                    <br>
                </div>
            <!-- </div> -->
        </div>
    </div>  
  </div>
  </div>

  <!-- MODAL IMPOSTOS -->
<div *ngIf="true" class="modal fade default-example-modal-right-lg" id="modalImpostos" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-right modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title h4">Cadastrar Regra de Imposto</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body p-2">
                <div class="card p-1">
                  <div class="card-body p-3">
                    <!-- PRIMEIRO CARD -->
                    <div class="row">
                      <!-- Primeira linha -->
                        <div class="form-group col-sm-3">
                            <label class="form-label" >UF Destino</label>
                            <select class="select2 form-control w-100" [(ngModel)]="ncmImposto.ufDest" id="selectUFDestino">
                            
                            </select>
                        </div>
                        <div class="form-group col-sm-9">
                          <label class="form-label" >CFOP</label>
                          <select class="select2 form-control w-100"  id="selectCfop">
                          </select>
                      </div>
                      
                    </div>
                       <br>
                    
                      <div class="row">
                        <div class="form-group col-sm-6">
                            <label class="form-label" >ICMS</label>
                            <select class="select2 form-control w-100" id="icmsSituacaoTributaria">
                               
                            </select>
                        </div>
                        <div class="form-group col-sm-6">
                            <label class="form-label" >CST - IPI</label>
                            <select class="select2 form-control w-100" id="ipiSituacaoTributaria">
                            
                            </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-sm-6">
                            <label class="form-label" >CST - PIS</label>
                            <select class="select2 form-control w-100" id="pisSituacaoTributaria">
                            
                            </select>
                        </div>
                        <div class="form-group col-sm-6">
                            <label class="form-label" >CST - Cofins</label>
                            <select class="select2 form-control w-100"  id="cofinsSituacaoTributaria">
                            
                            </select>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        <!-- FIM PRIMEIRO CARD -->

        <!-- SEGUNDO CARD -->
            <div class="modal-body p-2">
                <div class="card p-1">
                    <div class="card-body p-3">
                    
                    <div class="row">
                        <span style="margin-left: 5px;">ICMS</span>
                    </div>
                    <div class="row"> 
                      <!-- terceira linha -->
                        <div class="form-group col-sm-3">
                            <label class="form-label" >Alíquota ICMS</label>
                            <input type="number" [(ngModel)]="ncmImposto.pIcms" id="ncmImpostopIcms" class="form-control" >
                        </div>
                        <div class="form-group col-sm-3">
                          <label class="form-label" >% Base de Cálculo</label>
                          <input type="text" [(ngModel)]="ncmImposto.pBc" id="ncmImpostopBc" class="form-control" >
                        </div>
                        <div class="form-group col-sm-3">
                          <label class="form-label">% Redução Base de Cálc.</label>
                          <input type="text" [(ngModel)]="ncmImposto.pRedBc" id="ncmImpostopRedBc" class="form-control" >
                        </div>
                        <div class="form-group col-sm-3">
                           <label class="form-label">% Base de Cálculo (S.N.)</label>
                          <input type="text" [(ngModel)]="ncmImposto.pBcSN" id="ncmImpostopBcSN" class="form-control">
                        </div>
                    </div>  
                    <div class="row"> 
                      <div class="form-group col-sm-3">
                        <label class="form-label" >% Red. Base de Cálc. (S.N.)</label>
                        <input type="text" [(ngModel)]="ncmImposto.pRedBcSN" id="ncmImpostopRedBcSN" class="form-control">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label">% Op Própria (S.N.)</label>
                      <input type="text"  [(ngModel)]="ncmImposto.pOperaPro" id="ncmImpostopOperaPro" class="form-control">
                   </div>
                    <div class="form-group col-sm-3">
                            <label class="form-label">Alíquota ICMS Interna</label>
                            <input type="number" [(ngModel)]="ncmImposto.pIcmsIntra" id="ncmImpostopIcmsIntra" class="form-control">
                      </div>
                      <div class="form-group col-sm-3">
                          <label class="form-label" >Alíquota ICMS (Frete)</label>
                          <input type="text" [(ngModel)]="ncmImposto.pICMSFrete" id="ncmImpostopICMSFrete" class="form-control">
                      </div>
                       
                    </div>  
                    <div class="row"> 
                      <!-- terceira linha -->
                        <div class="form-group col-sm-3">
                            <label class="form-label" >Alíquota Diferencial</label>
                            <input type="text" [(ngModel)]="ncmImposto.pDifere" id="ncmImpostopDifere" class="form-control">
                        </div>
                        <div class="form-group col-sm-3">
                          <label class="form-label">Valor Pauta</label>
                          <input type="text"  [(ngModel)]="ncmImposto.vPauta" id="ncmImpostovPauta" class="form-control" >
                      </div>
                      <div class="form-group col-sm-3">
                        <label class="form-label" >% FCP UF Destino</label>
                        <input type="text" [(ngModel)]="ncmImposto.pFCPUFDest" id="ncmImpostopFCPUFDest" class="form-control">
                     </div>
                   </div>  
                </div>
            </div>
        </div>
    <!-- FIM SEGUNDO CARD -->

    <!-- INICIO TERCEIRO CARD -->
        <div class="modal-body p-2">
            <div class="card p-1">
                <div class="card-body p-3">
                    <div class="row">
                        <span style="margin-left: 5px;">ICMS - ST</span>
                    </div>
                    <div class="row"> 
                      <!-- terceira linha -->
                        <div class="form-group col-sm-3">
                            <label class="form-label" >Alíquota ST</label>
                            <input type="text" [(ngModel)]="ncmImposto.pICMSST" id="ncmImpostopICMSST" class="form-control" >
                        </div>
                        <div class="form-group col-sm-3">
                          <label class="form-label" for="aliIcms">% Base de Cálculo ST</label>
                          <input type="text" [(ngModel)]="ncmImposto.pBcST" id="ncmImpostopBcST" class="form-control" >
                      </div>
                      <div class="form-group col-sm-3">
                        <label class="form-label" >% Redução Base de Cálc.</label>
                        <input type="text" [(ngModel)]="ncmImposto.pRedBcST" id="ncmImpostopRedBcST" class="form-control" >
                      </div>
                      <div class="form-group col-sm-3">
                          <label class="form-label" >% Base de Cálculo (S.N.)</label>
                          <input type="text" [(ngModel)]="ncmImposto.pBcSTSN" id="ncmImpostopBcSTSN" class="form-control">
                      </div>                   
                     
                    </div>  
                    <div class="row"> 
                      <!-- terceira linha -->
                      <div class="form-group col-sm-3">
                        <label class="form-label">% Red. Base de Cálc. (S.N.)</label>
                        <input type="text" [(ngModel)]="ncmImposto.pRedBcSTSN" id="ncmImpostopRedBcSTSN" class="form-control">
                    </div>                      
                      <div class="form-group col-sm-3">
                        <label class="form-label">% IVA Original</label>
                        <input type="text" [(ngModel)]="ncmImposto.pIva" id="ncmImpostopIva" class="form-control">
                    </div>                  
                        <div class="form-group col-sm-3">
                            <label class="form-label" >% IVA Ajustado</label>
                            <input type="text" [(ngModel)]="ncmImposto.pIvaAjustado" id="ncmImpostopIvaAjustado" class="form-control">
                        </div>
                        <div class="form-group col-sm-3">
                          <label class="form-label">% IVA Ajust. red. (S.N.)</label>
                          <input type="text" [(ngModel)]="ncmImposto.pIvaRedSN" id="ncmImpostopIvaRedSN" class="form-control">
                      </div>
                    </div>  
                      <div class="row"> 
                      <!-- terceira linha -->
                        <div class="form-group col-sm-3">
                            <label class="form-label">Valor Pauta ST</label>
                            <input type="text" [(ngModel)]="ncmImposto.vPautaST" id="ncmImpostovPautaST" class="form-control" >
                        </div>
                        <div class="form-group col-sm-3">
                            <label class="form-label" >% FCP UF Destino - ST</label>
                            <input type="text" [(ngModel)]="ncmImposto.pFCPUFDestST" id="ncmImpostopFCPUFDestST" class="form-control">
                        </div>
                    </div>       
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="cancelImposto()" type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                <button (click)="saveImposto()" type="button" class="btn btn-primary" data-dismiss="modal">Salvar</button>
            </div>
        </div>
    </div>
  </div>
</div>
  
  <button (click)="save()" style="float:right; margin: 10px;" class="btn btn-primary ml-auto" type="button">Salvar</button>
  <button (click)="cancel()" type="button" style="float:right; margin: 10px;" class="btn btn-secondary">Cancelar</button>
  
  <swal #deleteConfirmationMessage showDenyButton="true" (deny)="handleDenial()" (confirm)="confirmDelete()" denyButtonText="Cancelar" showConfirmButton="true" confirmButtonText="Confirmar" title="Excluir?" text="Tem certeza que deseja excluir este registro?" icon="warning"></swal>
  <swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde." icon="error"></swal>
  <swal #successMessage text="Boa, registro salvo com sucesso!" showConfirmButton=true icon="success"></swal>
  <swal #validationMessage text="Ops, ainda faltam alguns campos para preencher, vamos lá?" showConfirmButton=true icon="error"></swal>
  