import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';

@Injectable({
  providedIn: 'root'
})
export class MSKironService {
  constructor(private httpClient: HttpClient,
    private loginService: LoginService,
    private router: Router) { }

 //   url:string = 'https://kironwebapi.azurewebsites.net';
    url:string = 'http://localhost:9001';
 // url:string = 'http://192.168.15.31:9001';
//url:string = 'http://192.168.0.6:9001';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-access-token': this.loginService.getToken() })
  }

  async enviarNFe(data:any): Promise<any> {

    return await this.httpClient.post( this.url + '/enviarNFe',data, this.httpOptions).toPromise()
      .catch(this.handleError);
  }  

  async cancelarNFe(data: any) : Promise<any> {
    return this.httpClient.post(this.url + '/cancelarNFe', data, this.httpOptions).toPromise()
    .catch(this.handleError);
  }

  async testarCertificadoA3(data: any) : Promise<any> {
    return this.httpClient.post(this.url + '/testarCertificadoA3', data, this.httpOptions).toPromise()
    .catch(this.handleError);
  }

  async enviarCCe(data: any) : Promise<any> {
    return this.httpClient.post(this.url + '/enviarCCe', data, this.httpOptions)
    .toPromise()
    .catch(this.handleError);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(error);
  };

}
