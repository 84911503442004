<!-- <ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item"><a href="" routerLink="/">Kiron</a></li>
  <li class="breadcrumb-item">Nota fiscal</li>
  <li class="breadcrumb-item active">Edição</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol> -->
<div class="subheader">
  <h1 class="subheader-title">
    <i class="subheader-icon fal fa-file-invoice-dollar"></i> Nota fiscal
    <small>
      Cadastro de nota fiscal
    </small>
  </h1>
</div>
<div id="panel-1" class="panel">
  <div class="panel-hdr">
    <h2>Nota fiscal      #{{nfe.id}} <span class="fw-300"></span></h2>

  </div>
  <div class="panel-container show" id="notaFiscal">
    <div class="panel-content">
      <div class="row">
        <div class="form-group col-sm-6">        
          <label class="form-label">Destinatário</label>
          <div class="input-group">
              <select  class="js-data-example-ajax form-control" id="select2Destinatario">
              </select>
          </div>
        </div>
        <!-- TESTE DATA -->
        <div class="form-group col-sm-2">
          <label class="form-label" for="dtEmissao">Data de emissão</label>
            <div class="input-group">
              <!-- <input type="date" [ngModel] ="nfe.dtEmissao | date:'yyyy-MM-dd '" (ngModelChange)="nfe.dtEmissao = $event" class="form-control" id="dtEmissao">               -->
              <!-- <input type="datetime-local" [(ngModel)]="nfe.dtEmissao"  class="form-control"  placeholder="yyyy-MM-ddTHH:mm" id="dtEmissao">               -->
              <!-- <input type="datetime" datetime="yyyy-MM-dd HH:mm" [(ngModel)]="nfe.dtEmissao" class="form-control" ng-model-options="{timezone : 'utc'}" id="dtEmissao">               -->
              <!-- <input type="datetime-local" [ngModel]="nfe.dtEmissao | date:'yyyy-MM-ddTHH:mm' :'+0000'" (ngModelChange)="nfe.dtEmissao = $event"  class="form-control" id="dtEmissao"> -->
              <input type="date" [ngModel]="nfe.dtEmissao | date:'yyyy-MM-dd'" class="form-control" id="nfedtEmissao">
            </div>
        </div>
        <div class="form-group col-sm-2">
          <label class="form-label" for="hrEmissao">Hora</label>
            <div class="input-group">
                <input [ngModel]="hrEmissao" class="form-control"  id="hrEmissao" type="time">
            </div>
        </div>
  
        <!-- <div class="form-group col-sm-1,5">
          <label class="form-label" for="horaEmissao">Hora da emissão</label>
            <div class="input-group">
              <input type="time" class="form-control" id="horaEmissao" >
            </div>
        </div> -->


        <div class="form-group col-sm-2">
          <label class="form-label" for="dataSaidaEntradaNota">Data de saída/entrada</label>    
          <div class="input-group">
            <input type="date" [ngModel]="nfe.dtSaida | date:'yyyy-MM-dd'" class="form-control" id="nfedtSaida">
            <!-- <input type="date" class="form-control " placeholder="Selecione a data"  id="dataSaidaEntradaNota"> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-8">
          <label class="form-label">Finalidade da nota</label>
          <div class="input-group">
            <select class="select2 form-control w-100" id="finalidadeNota">
            </select>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</div>
<div id="panel-2" class="panel">
  <div class="panel-hdr">
    <h2>Produtos <span class="fw-300"></span></h2>
   
  </div>
  <div class="panel-container show" id="produtos">
    <div class="panel-content">
        <div class="row">

            <div class="form-group col-sm-12">
              <button style="vertical-align:middle; float:left" data-toggle="modal" (click)="adicionarProduto()" type="button" id="btnAdicionarProduto" class="btn btn-sm btn-primary">
                  <span class="fal fa-plus mr-1"></span>
                Adicionar
              </button>
           <!-- </div>
           <div class="form-group col-sm-2"> -->
              <button style="vertical-align:middle; float:right" data-toggle="modal" (click)="atualizarImpostos(true)" type="button" id="btnAtualizarImpostos" class="btn btn-sm btn-primary">
              <span class="fal fa-sync mr-1"></span>
              Atualizar impostos
            </button>
          </div>




            <div class="form-group col-sm-12">
              <label class="form-label" >Produtos adicionados</label>
            <table [dtOptions]='dtOptions' datatable id="itensNota" class="table table-bordered table-hover table-striped w-100">
              <thead class="bg-primary-600">
                <tr>
                  <th width="10%">Código</th>
                  <th width="25%">Nome</th>
                  <th width="6%">Un</th>
                  <th width="5%">CFOP</th>
                  <th width="5%">NCM</th>
                  <th width="10%">Quantidade</th>
                  <th width="11%">Preço</th>
                  <th width="10%">Valor total</th>
                  <th width="17%"></th>
              </tr>
              </thead>
              <tbody>
                  <tr>
                        <div class='text-center'>
                      </div>
                  </tr>
              </tbody>
            </table>
            </div>
      </div>
    </div>
  </div>
</div>
<div class="row">

  <div class="form-group col-sm-4">
    <button type="button" data-toggle="modal" (click)="adicionarTransportadora()" id="btnAdicionarTransp" class="btn btn-block btn-primary">
      Adicionar Transportadora
    </button>
  </div>
  <!-- <div class="form-group col-sm-3">
    <button type="button" data-toggle="modal" data-target="#modalNfReferenciada" id="btnAdicionarProduto" class="btn btn-block btn-primary">
      Adicionar Nota Referenciada
    </button>
  </div> -->
  <div class="form-group col-sm-4">
    <button type="button" data-toggle="modal" (click)="configuracaoAvancada()" id="btnConfiguracaoAvancada" class="btn btn-block btn-primary">
      Configurações Avançadas
    </button>
  </div>
  <div class="form-group col-sm-4">
    <!-- <button id="modalValores" data-toggle="modal" data-target="#modalValores" class="btn btn-block btn-primary"> -->
    <button data-toggle="modal" (click)="btnModalValores()" class="btn btn-block btn-primary">
      Valores Totais
    </button>
  </div>
</div>

<div class="accordion accordion-outline" id="js_demo_accordion-3" style="background-color: #fff; box-shadow: 0 3px 3px -1px #e2e2e2;">
  <div class="card" style="background-color: #fff;">
    <div class="card-header" style="background-color: #fff;">
      <a href="javascript:void(0);" class="card-title" data-toggle="collapse" data-target="#js_demo_accordion-3a" aria-expanded="false">        
        
        <span class="fw-100" style="color: rgb(10, 10, 10);">  Notas Referenciadas</span>
        <span class="ml-auto">          
          <span class="collapsed-hidden">
            <i class="fal fa-plus fs-xl"></i>
          </span>
          <span class="collapsed-reveal">
            <i class="fal fa-minus fs-xl"></i>
          </span>
        </span>
      </a>
    </div>
    <div id="js_demo_accordion-3a" class="collapse hide" data-parent="#js_demo_accordion-3" style="background-color: #fff;">
      <div class="card-body" style="background-color: #fff;">
        <div class="panel-content" style="background-color: #fff;" id="notasReferenciadas">
          <div class="row" style="background-color: #fff;">
            <div class="form-group col-sm-2" style="background-color: #fff;">
              <button style="vertical-align:middle;" type="button" (click)="adicionarNFeReferenciada()" data-toggle="modal" id="btnNfReferenciada" class="btn btn-sm btn-primary">
                <span class="fal fa-plus mr-1"></span>
                Adicionar
              </button>
            </div>
          </div><br>
          <div class="row">
            <div class="form-group col-sm-12">
              <label class="form-label" for="nfeReferenciada">Notas Referenciadas adicionadas</label>
              <table datatable [dtOptions]='dtOptionsNFeReferenciada' id='nfeReferenciada' class="table table-bordered table-hover table-striped w-100">
                <thead class="bg-primary-600">
                    <tr>
                        <th>Chave de Acesso</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
               </tbody>
            </table>
          </div>
        </div>
      </div>      
    </div>    
  </div>  
</div>
</div>

<!-- Exportacao -->
<div class="accordion accordion-outline" id="js_demo_accordion-4" id="exportacao" style="background-color: #fff; box-shadow: 0 3px 3px -1px #e2e2e2;">
  <div class="card" style="background-color: #fff;">
    <div class="card-header" style="background-color: #fff;">
      <a href="javascript:void(0);" class="card-title" data-toggle="collapse" data-target="#js_demo_accordion-4a"  aria-expanded="true">        
        
        <span class="fw-100" style="color: rgb(10, 10, 10);">Dados da Exportação </span>
        <span class="ml-auto">          
          <span class="collapsed-reveal">
            <i class="fal fa-minus fs-xl"></i>
          </span>
          <span class="collapsed-hidden">
            <i class="fal fa-plus fs-xl"></i>
          </span>
        </span>
      </a>
    </div>
    <div id="js_demo_accordion-4a" class="collapse show" data-parent="#js_demo_accordion-4" style="background-color: #fff;">
      <div class="card-body" style="background-color: #fff;">
        <div class="panel-content" style="background-color: #fff;"  >
          <div class="row">
            
          </div>
          <div class="row">
  
            <div class="form-group col-sm-2">
              <label class="form-label" >UF de Embarque</label>
              <div class="input-group">
                  <input type="text" [(ngModel)]="nfe.ufEmbarq"  class="form-control" placeholder="" id="nfeUFembarq">
              </div>
            </div>
            <div class="form-group col-sm-10">
              <label class="form-label" >Local de Embarque</label>
              <div class="input-group">
                  <input type="text" [(ngModel)]="nfe.xLocEmbarq"  class="form-control" placeholder="" id="nfexLocEmbarq">
              </div>
            </div>
  
            <div class="form-group col-sm-2">
              <label class="form-label" for="nfeufSaidaPais">UF de saída</label>
              <div class="input-group">
                  <input type="text" [(ngModel)]="nfe.ufSaidaPais" class="form-control" placeholder="" id="nfeufSaidaPais">
              </div>
            </div>
  
            <div class="form-group col-sm-5">
              <label class="form-label" for="nfexLocExporta">Local de Saída p/Embarque</label>
            <div class="input-group">
                <input type="text" [(ngModel)]="nfe.xLocExporta" class="form-control" placeholder="" id="nfexLocExporta">
            </div>
            </div>
            <div class="form-group col-sm-5">
              <label class="form-label" for="nfexLocDespacho">Local do despacho</label>
            <div class="input-group">
                <input type="text" [(ngModel)]="nfe.xLocDespacho" class="form-control" placeholder="" id="nfexLocDespacho">
            </div>
            </div>
        </div>
      </div>      
    </div>    
  </div>  
</div>
</div>
<!-- 
<div class="row">
  <div class="col-sm-12">
    <div id="panel-1" class="panel">
      <div class="panel-hdr">
        <h2>Adicionar Notas Referenciadas <span class="fw-300"></span></h2>
        <div class="panel-toolbar">
          <button
            class="btn btn-panel"
            data-action="panel-collapse"
            data-toggle="tooltip"
            data-offset="0,10"
            data-original-title="Esconder/Mostrar"
          ></button>
        </div>
      </div>
      <div class="panel-container collapse">
        <div class="panel-content">
          <div class="row">
            <div class="form-group col-sm-2">
             // <button style="vertical-align:middle;" data-toggle="modal" data-target=".default-example-modal-right" (click)="adicionarProduto()" type="button" id="btnAdicionarProduto" class="btn btn-sm btn-primary">
               // <span class="fal fa-plus mr-1"></span>
              //Adicionar
            //</button>
              <button style="vertical-align:middle;" type="button" data-toggle="modal" data-target="#modalNfReferenciada" id="btnAdicionarProduto" class="btn btn-sm btn-primary">
                <span class="fal fa-plus mr-1"></span>
                Adicionar
              </button>
            </div>
          </div><br>
          <div class="row">
            <div class="form-group col-sm-12">
              <label class="form-label" for="nfReferenciada">Notas Referenciadas adicionadas</label>
              <table datatable [dtOptions]='dtOptions' class="table table-bordered table-hover table-striped w-100">
                <thead class="bg-primary-600">
                    <tr>
                        <th>Código</th>
                        <th>Nome</th>
                        <th>UM</th>
                        <th>CFOP</th>
                        <th>NCM</th>
                        <th>Quantidade</th>
                        <th>Preço</th>
                        <th>Valor Total</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="accordion accordion-outline" id="js_demo_accordion-4" style="background-color: #fff; box-shadow: 0 3px 3px -1px #e2e2e2;">
  <div class="card" style="background-color: #fff;">
    <div class="card-header" style="background-color: #fff;">
      <a href="javascript:void(0);" class="card-title" data-toggle="collapse" data-target="#js_demo_accordion-4a" aria-expanded="true">        
        
        <span class="fw-100" style="color: rgb(10, 10, 10);">Cobrança </span>
        <span class="ml-auto">          
          <span class="collapsed-reveal">
            <i class="fal fa-minus fs-xl"></i>
          </span>
          <span class="collapsed-hidden">
            <i class="fal fa-plus fs-xl"></i>
          </span>
        </span>
      </a>
    </div>
    <div id="js_demo_accordion-4a" class="collapse show" data-parent="#js_demo_accordion-4" style="background-color: #fff;">
      <div class="card-body" style="background-color: #fff;">
        <div class="panel-content" style="background-color: #fff;" id="cobranca">
          <div class="row">
            
          </div>
          <div class="row">
            <div class="form-group col-sm-3">
              <label class="form-label" >Condições de pagamento</label>
              <div class="input-group">
                <select class="select2 form-control w-100" id="nfeaVista">
                </select>
              </div>
            </div>
            <div class="form-group col-sm-3">
              <label class="form-label" >Valor de entrada</label>
              <div class="input-group">
                  <input type="text" [(ngModel)]="nfe.vEntrada"  class="form-control" placeholder="" id="nfevEntrada">
              </div>
            </div>
            <div class="form-group col-sm-3">
              <label class="form-label" >Valor total</label>
              <div class="input-group">
                  <input type="text" [(ngModel)]="nfe.vCobranca"  class="form-control" placeholder="" id="cobrancaValor">
              </div>
            </div>
            <div class="form-group col-sm-3">
              <label class="form-label" for="nfedtBase">Data base</label>
              <div class="input-group">
                <input type="date" [ngModel] = "nfe.dtBase | date:'yyyy-MM-dd'" (ngModelChange)="nfe.dtBase = $event"  class="form-control" id="nfedtBase">
             </div>
            </div>


             <div class="form-group col-sm-10">
             
            </div>
  
            <div class="form-group col-sm-3">
              <label class="form-label" >Meio de pagamento</label>
              <div class="input-group">
                <select class="select2 form-control w-100" id="meioPagto">
                </select>
              </div>
            </div>
  
            <div class="form-group col-sm-3">
              <label class="form-label" for="nfenParcelas">Parcelas</label>
              <div class="input-group">
                  <input type="number" [(ngModel)]="nfe.nParcelas" class="form-control" placeholder="" id="nfenParcelas">
              </div>
            </div>
  
            <div class="form-group col-sm-3">
              <label class="form-label" for="nfeIntervalo">Intervalo entre parcelas</label>
            <div class="input-group">
                <input type="number" [(ngModel)]="nfe.intervalo" class="form-control" placeholder="" id="nfeIntervalo">
            </div>
            </div>
  
            <div class="form-group col-sm-2" style="padding-top: 26px;">
              <button style="vertical-align:middle;" type="button" (click)="calcularCobranca()" id="btnCalcularCobranca" class="btn btn-sm btn-primary">
                <span class="fal fa-plus mr-1"></span>
                Calcular
              </button>
            </div>
  
  
              <div class="form-group col-sm-12" >
                <!-- <label class="form-label">Parcelas pagamento</label> -->
              <table datatable [dtOptions]="dtOptionsCobranca" id="parcelasCobranca" class="table table-bordered table-hover table-striped w-100">
                <thead class="bg-primary-600">
                    <tr>
                        <th>Parcela</th>
                        <th>Vencimento</th>
                        <th>Valor</th>
                        <th>Meio de pagamento</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
              </div>         
        </div>
      </div>      
    </div>    
  </div>  
</div>
</div>

<!-- <div id="panel-3" class="panel">
  <div class="panel-hdr">
    <h2>Cobrança <span class="fw-300"></span></h2>
  </div>
  <div class="panel-container show">
    <div class="panel-content">
        <div class="row">


          <div class="form-group col-sm-2">
            <label class="form-label" for="dataSaidaEntradaNota">Valor total</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="dataSaidaEntradaNota">
            </div>
          </div>

          <div class="form-group col-sm-10">
           
          </div>

          <div class="form-group col-sm-4">
            <label class="form-label" for="finalidadeNota">Meio de pagamento</label>
            <div class="input-group">
              <select class="select2 form-control w-100" id="finalidadeNota">
                <option value="1">Dinheiro</option>
                <option value="1">Cartão</option>
              </select>
            </div>
          </div>

          <div class="form-group col-sm-4">
            <label class="form-label" for="dataSaidaEntradaNota">Parcelas</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="dataSaidaEntradaNota">
            </div>
          </div>

          <div class="form-group col-sm-4">
            <label class="form-label" for="dataSaidaEntradaNota">Intervalo entre parcelas</label>
          <div class="input-group">
              <input type="text" class="form-control" placeholder="" id="dataSaidaEntradaNota">
          </div>
          </div>

          <div class="form-group col-sm-2">
            <button style="vertical-align:middle;" type="button" id="btnAdicionarProduto" class="btn btn-sm btn-primary">
              <span class="fal fa-plus mr-1"></span>
              Calcular
            </button>
          </div>


            <div class="form-group col-sm-12">
              <label class="form-label" for="cfopNota">Parcelas pagamento</label>
            <table datatable [dtOptions]='dtOptions' class="table table-bordered table-hover table-striped w-100">
              <thead class="bg-primary-600">
                  <tr>
                      <th>Parcela</th>
                      <th>Vencimento</th>
                      <th>Valor</th>
                      <th>Meio de pagamento</th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>1</td>
                      <td>02/02/2000</td>
                      <td>R$100,00</td>
                      <td>Dinheiro</td>
                      <td>
                        <div class='text-center'>
                          <a href='javascript:void(0);' class='btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1' title='Edit Record' data-toggle="modal" data-target="#modalCobranca">
                            <i class="fal fa-pen"></i>
                          </a>
                      </div>
                    </td>
                  </tr>
              </tbody>
            </table>
            </div>
      </div>
    </div>
  </div>
</div> -->
<div id="panel-4" class="panel">
  <div class="panel-hdr">
    <h2>Observação <span class="fw-300"></span></h2>
    <div class="panel-toolbar">
      <button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#modalArtigo">
        <span class="fal fa-plus mr-1"></span>
        Nova Observação
      </button>
    </div>
  </div>

  <div class="panel-container show"  id="observacao">
    <div class="panel-content">
        <div class="row">
          <div class="form-group col-sm-12">
            <textarea [(ngModel)]="nfe.obs" class="form-control" rows="8"></textarea>
          </div>
      </div>
    </div>
  </div>
</div>

<!-- <button style="float:right; margin: 10px;" (click)="saveNFe(0)" [disabled]="disableForm" class="btn btn-primary ml-auto" type="button">Salvar e transmitir</button>
<button style="float:right; margin: 10px;" (click)="saveNFe(1)" [disabled]="disableForm" class="btn btn-primary ml-auto" type="button">Salvar</button>
<button type="button" (click)="cancel()" style="float:right; margin: 10px;" class="btn btn-secondary">Cancelar</button> -->
        
<button style="float:right; margin: 10px;" (click)="enviarNFe()" [disabled]="disableForm" class="btn btn-success ml-auto" type="button">Transmitir</button>
<button style="float:right; margin: 10px;" (click)="danfe()" [disabled]="disableForm" class="btn btn-primary ml-auto" type="button">DANFE</button>
<button style="float:right; margin: 10px;" (click)="saveNFe(0)" [disabled]="disableForm" class="btn btn-primary ml-auto" type="button">Salvar</button>
<button type="button" (click)="cancel()" style="float:right; margin: 10px;" class="btn btn-secondary">Cancelar</button>
<!-- <form action="https://autenticador.certisign.com.br/CertisignLogin/certificado/login?id={idDaChave}&nome={nomeDaChave}&retorno={urlDeRetorno}"
method="get">
<input type="submit" value="enviar" />
</form> -->

  <!-- Modal center Large -->
  <div class="modal fade" id="modalArtigo" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Selecione a Observação</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body">
              <div class="form-group col-sm-12">        
                <label class="form-label">Observação</label>
                <div class="input-group">
                    <select  class="js-data-example-ajax form-control" id="select2Artigo">
                    </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary" (click)="adicionarArtigo()">Adicionar</button>
            </div>
        </div>
    </div>
</div>


<div *ngIf="true" class="modal fade default-example-modal-right" id="modalItens" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right modal-lg">
      <div class="modal-content p-1">
          <div class="modal-header p-1">
              <h5 class="modal-title h4">Item da nota</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>

          <div class="modal-body">
              <div class="card" >
                  <div class="card-body">
                      <div class="row" >
                        <div class="form-group col-sm-12">
                          <label class="form-label" for="select2Produto">Produto <span class="text-danger">*</span></label>
                          <div class="input-group">                         
                              <select [disabled]="disableForm" class="js-data-example-ajax form-control" id="select2Produto">
                              </select>
                          </div>
                        </div>
                        <div class="form-group col-sm-12">
                          <label class="form-label" >CFOP <span class="text-danger">*</span></label>
                          <div class="input-group">  
                            <select [disabled]="disableForm" class="select2 form-control w-100" id="select2Cfop">
                              <option value="">Pesquise o Cfop... </option>
                            </select>
                          </div>
                        </div>
              
                        <div class="form-group col-sm-4">
                          <label class="form-label">Quantidade</label>
                          <input type="number" [disabled]="disableForm" [(ngModel)]="itemNFe.qtda" id="itemNFeqtda" class="form-control" (blur)="calcularTotal()">
                        </div>
              
                        <div class="form-group col-sm-4">
                          <label class="form-label" >Preço</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="itemNFe.preco" id="itemNFepreco" class="form-control" (blur)="calcularTotal()">
                          <!-- <input type="text" [disabled]="disableForm" mask="separator.3" suffix="R$" thousandSeparator="." [(ngModel)]="itemNFe.preco"  class="form-control" (blur)="calcularTotal()"> -->

                        </div>
              
                        <div class="form-group col-sm-4">
                          <label class="form-label" >Valor Total</label>
                          <input type="text" [disabled]="true" [(ngModel)]="itemNFe.vProd" id="itemNFevProd" class="form-control" >
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" >Frete</label>
                          <input type="text" [disabled]="!disableValorFrete" [(ngModel)]="itemNFe.vFrete" id="itemNFevFrete" class="form-control">
                        </div>
                        <div class="form-group col-sm-4">
                          <label class="form-label" >Seguro</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="itemNFe.vSeguro" id="itemNFevSeguro" class="form-control">
                        </div>
              
                        <div class="form-group col-sm-4">
                          <label class="form-label" >Desconto</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="itemNFe.vDesconto" id="itemNFevDesconto" class="form-control">
                        </div> 
              
                        <div class="form-group col-sm-3">
                          <label class="form-label" >Outros</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="itemNFe.vOutro" id="itemNFevOutro" class="form-control">
                        </div>

                        <div class="form-group col-sm-3">
                          <label class="form-label" >Valor Lucro</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="itemNFe.vLucro" id="itemNFevLucro" class="form-control">
                        </div>
                        <div class="form-group col-sm-3">
                          <label class="form-label" >Numero pedido</label>
                          <input type="text"  [disabled]="disableForm" [(ngModel)]="itemNFe.xPed" class="form-control">
                        </div>
              
                        <div class="form-group col-sm-3">
                          <label class="form-label" >Item</label>
                          <input type="number" [disabled]="disableForm" [(ngModel)]="itemNFe.nItemPed" class="form-control">
                        </div>

                        <div class="form-group col-sm-12">
                          <label class="form-label" >Complemento do item</label>
                          <textarea [disabled]="disableForm" [(ngModel)]="itemNFe.complemento" class="form-control" rows="2">
                          </textarea>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer p-0">
              <button type="button" class="btn btn-secondary"  (click)="cancelItem()" data-dismiss="modal">Fechar</button>
              <button type="button" class="btn btn-primary" [disabled]="disableForm" (click)="saveItem()">Salvar</button>
          </div>
      </div>
  </div>
</div>


<!-- <div *ngIf="true" class="modal fade default-example-modal-right" id="modalTransportadora" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right">
      <div class="modal-content p-1">
          <div class="modal-header p-1">
              <h5 class="modal-title h4">Transportadora</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">
              <div class="card">
                  <div class="card-body p-3">
                      <div class="row">
                        <div class="form-group col-sm-12">
                          <label class="form-label" for="select2Transportadora">Transportadora</label>
                          <select [disabled]="disableForm" class="js-data-example-ajax form-control" id="select2Transportadora">
                          </select>
                          </div>

                        <div class="form-group col-sm-6">
                          <label class="form-label" for="nfe.veicPlaca">Placa do veiculo</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="nfe.veicPlaca" id="nfe.veicPlaca" class="form-control">
                        </div>
              
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="ufTransportadora">UF</label>
                          <select [disabled]="disableForm" class="select2 form-control w-100" id="ufTransportadora">
                            <option value="">Sem (UF)</option>
                          </select>
                        </div>

                        <div class="form-group col-sm-8">
                          <label class="form-label" for="tipoFrete">Frete</label>
                          <select [disabled]="disableForm" class="select2 form-control w-100" id="tipoFrete">
                          </select>
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" for="nfevFrete">Valor frete</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="nfe.vFrete" id="nfevFrete" name="produtoQuantidade" class="form-control">
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" for="volumeQtda">Qtde Volumes</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.qVol" id="produtoQuantidade" name="volumeQtda" class="form-control">
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" for="volume.especie">Espécie</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.especie" id="volumeEspecie"  class="form-control">
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" for="volumeMarca">Marca</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.marca" id="volumeMarca"  class="form-control">
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" for="volumenVol">Numeração volume</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.nVol" id="volumenVol" class="form-control">
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" for="volumePesoL">Peso Liquido</label>
                          <input type="number" [disabled]="disableForm" id="volumePesoL" class="form-control">
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" for="volumePesoB">Peso bruto</label>
                          <input type="number" [disabled]="disableForm" id="volumePesoB"  class="form-control">
                        </div>

                        <div class="form-group col-sm-12">
                          <label class="form-label" for="volumenLacre">Numero lacre</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.nLacre" id="volumenLacre" class="form-control">
                        </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
              <button type="button" class="btn btn-primary" [disabled]="disableForm" (click)="saveTransportadora()">Salvar</button>
          </div>
      </div>
  </div>
</div>
</div> -->

<div *ngIf="true" class="modal fade default-example-modal-right-lg" id="modalTransportadora" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right modal-dialog-scrollable modal-lg">
      <div class="modal-content p-1">
          <div class="modal-header p-1">
              <h5 class="modal-title h4">Transportadora</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">
              <div class="card">
                  <div class="card-body p-2">
                      <div class="row">
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="select2Transportadora">Transportadora</label>
                          <select [disabled]="disableForm" class="js-data-example-ajax form-control" id="select2Transportadora">
                          </select>
                          </div>

                        <div class="form-group col-sm-3">
                          <label class="form-label" for="nfe.veicPlaca">Placa do veiculo</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="nfe.veicPlaca" id="nfe.veicPlaca" class="form-control">
                        </div>
              
                        <div class="form-group col-sm-3">
                          <label class="form-label" for="ufTransportadora">UF</label>
                          <select [disabled]="disableForm" class="select2 form-control w-100" id="ufTransportadora">
                            <option value="">Sem (UF)</option>
                          </select>
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" for="tipoFrete">Responsável pelo frete</label>
                          <select [disabled]="disableForm" class="select2 form-control w-100" id="tipoFrete">
                          </select>
                        </div>

                        <div class="form-group col-sm-5">
                          <label class="form-label" for="tipoRateioFrete">Como deseja informar o valor do frete?</label>
                          <select [disabled]="disableForm"  class="select2 form-control w-100" id="tipoRateioFrete">
                          </select>
                        </div>

                        <div class="form-group col-sm-3">
                          <label class="form-label" for="nfevFrete">Valor frete</label>
                          <!-- <input type="text" [disabled]="disableForm" [(ngModel)]="nfe.vFrete" id="nfevFrete" class="form-control"> -->
                          <input type="text" [disabled]="disableValorFrete" [(ngModel)]="nfe.vFrete" id="nfevFrete" class="form-control">
                        </div>
                        
                        <div class="col-sm-12" style="align-items: center;">
                          <label class="form-label">Volumes</label>
                        <!-- <div class="card col-sm-12"> -->
                          <div class="card-body col-sm-12">
                            <div class="form-group col-sm-4">
                              <button style="vertical-align:middle; float:left" data-toggle="modal" (click)="adicionarVolume()" class="btn btn-sm btn-primary">
                                <span class="fal fa-plus mr-1"></span>
                              Adicionar
                            </button>                            
                            </div>
                            <br>
                        <!-- Modal Transportadora -->
                        <div class="form-group col-sm-12">
                          <table  datatable [dtOptions]='dtOptionsVolumes' id='tableVolumes' style="overflow:auto; width:100px" class="table table-bordered table-hover table-striped w-100">
                              <thead class="bg-primary-600">
                                <tr>
                                    <th width="20%">Qtd</th>
                                    <th width="35%">Espécie</th>
                                    <th width="20%">Peso Líquido</th>
                                    <th width="20%">Peso Bruto</th>
                                    <th width="15%"></th>   
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <tr>
                                    <td [ngModel]="volume.qVol">{{volume.qVol}}</td>
                                    <td (ngModel)="volume.especie">{{volume.especie}}</td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <div class="text-center">

                                        <a href='javascript:void(0);' onclick="" 
                                        class="btn btn-primary btn-sm btn-icon rounded-circle" title="Editar" style="margin-right: 3px;">
                                        <i class="fal fa-pen"></i>
                                        </a>
                                        
                                        <a href='javascript:void(0);' onclick="" 
                                        class="btn btn-danger btn-sm btn-icon rounded-circle"title="Excluir">
                                        <i class="fal fa-times"></i>
                                        </a>
                                      </div>
                                    </td>
                                </tr> -->
                            </tbody>
                          </table>
                        </div>
                      <!-- </div> -->
                    </div>
                  </div><br>
                        <!-- <div class="form-group col-sm-4">
                          <label class="form-label" for="volumeQtda">Qtde Volumes</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.qVol" id="produtoQuantidade" name="volumeQtda" class="form-control">
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" for="volume.especie">Espécie</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.especie" id="volumeEspecie"  class="form-control">
                        </div> -->
                        <!-- 
                        <div class="form-group col-sm-4"><br>
                          <label class="form-label" for="volumeMarca">Marca</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.marca" id="volumeMarca"  class="form-control">
                        </div> -->

                        <!-- <div class="form-group col-sm-4"><br>
                          <label class="form-label" for="volumenVol">Numeração volume</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.nVol" id="volumenVol" class="form-control">
                        </div> -->

                        <!-- <div class="form-group col-sm-4">
                          <label class="form-label" for="volumePesoL">Peso Liquido</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.pesoL" id="volumePesoL" class="form-control">
                        </div>

                        <div class="form-group col-sm-4">
                          <label class="form-label" for="volumePesoB">Peso bruto</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.pesoB" id="volumePesoB"  class="form-control">
                        </div> -->

                        <!-- <div class="form-group col-sm-4"><br>
                          <label class="form-label" for="volumenLacre">Numero lacre</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.nLacre" id="volumenLacre" class="form-control">
                        </div> -->
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
              <button type="button" class="btn btn-primary" (click)="saveTransportadora()" [disabled]="disableForm">Salvar</button>
          </div>
      </div>
  </div>
</div>
</div>

<div class="modal fade" id="modalVolumes" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Volumes</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">
              <div class="row col-sm-12">
                <div class="col-sm-3">
                  <label class="form-label" for="volumeQtda">Qtde Volumes</label>
                  <input type="text" [disabled]="disableForm" [(ngModel)]="volume.qVol" id="produtoQuantidade" name="volumeQtda" class="form-control">
                </div>
                <div class="col-sm-5">
                  <label class="form-label" for="volume.especie">Espécie</label>
                  <input type="text" [disabled]="disableForm" [(ngModel)]="volume.especie" id="volumeEspecie"  class="form-control">
                </div>
                <div class="col-sm-4">
                  <label class="form-label" for="volumeMarca">Marca</label>
                  <input type="text" [disabled]="disableForm" [(ngModel)]="volume.marca" id="volumeMarca"  class="form-control">
                </div>

              </div><br>

              <div class="row col-sm-12">
                <div class="col-sm-3">
                  <label class="form-label" for="volumePesoL">Peso Liquido</label>
                  <input type="text" [disabled]="disableForm" [(ngModel)]="volume.pesoL" id="volumePesoL" class="form-control">
                </div>
                <div class="col-sm-3">
                  <label class="form-label" for="volumePesoB">Peso bruto</label>
                  <input type="text" [disabled]="disableForm" [(ngModel)]="volume.pesoB" id="volumePesoB"  class="form-control">
                </div>
                <div class="col-sm-3">
                  <label class="form-label" for="volumenVol">Nº volume</label>
                  <input type="text" [disabled]="disableForm" [(ngModel)]="volume.nVol" id="volumenVol" class="form-control">
                </div>
                <div class="col-sm-3">
                  <label class="form-label" for="volumenLacre">Numero lacre</label>
                  <input type="text" [disabled]="disableForm" [(ngModel)]="volume.nLacre" id="volumenLacre" class="form-control">
                </div>
              </div>

                        <!-- <div class="form-group col-sm-4">
                          <label class="form-label" for="volume.especie">Espécie</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.especie" id="volumeEspecie"  class="form-control">
                        </div> -->

                        <!-- <div class="form-group col-sm-4">
                          <label class="form-label" for="volumeMarca">Marca</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.marca" id="volumeMarca"  class="form-control">
                        </div> -->
<!-- 
                        <div class="form-group col-sm-4">
                          <label class="form-label" for="volumenVol">Numeração volume</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.nVol" id="volumenVol" class="form-control">
                        </div> -->

                        <!-- <div class="form-group col-sm-4">
                          <label class="form-label" for="volumePesoL">Peso Liquido</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.pesoL" id="volumePesoL" class="form-control">
                        </div> -->
<!-- 
                        <div class="form-group col-sm-4">
                          <label class="form-label" for="volumePesoB">Peso bruto</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.pesoB" id="volumePesoB"  class="form-control">
                        </div> -->

                        <!-- <div class="form-group col-sm-4">
                          <label class="form-label" for="volumenLacre">Numero lacre</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="volume.nLacre" id="volumenLacre" class="form-control">
                        </div> -->
              
          </div>
          <div class="modal-footer">
              <button type="button" (click)="closeVolume()" class="btn btn-secondary">Fechar</button>
              <button type="button" (click)="saveVolume()" class="btn btn-primary">Salvar</button>
          </div>
      </div>
  </div>
</div>

<div *ngIf="true" class="modal fade default-example-modal-right" id="modalNFeReferenciada" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title h4">Notas Referenciadas</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">
              <div class="card mb-g" id="div0">
                  <div class="card-body p-3">
                      <div class="row">
                        <div class="form-group col-sm-12">
                          <label class="form-label" for="tipoNFeReferenciada">Modelo do Documento</label>
                          <select class="select2 form-control w-100" id="tipoNFeReferenciada">
                            <!-- <option value="">Selecione</option> -->
                          </select>
                        </div>
                      </div>
                  </div>
              </div>
              
              <div class="DivPai">
                <!-- DIV NF-e / NFC-e -->
                <div class="card mb-g" id="div1" style="display: none;">
                  <div class="card-body p-3">
                      <div class="row">
                        <div class="form-group col-sm-12">
                          <label class="form-label" for="modelo">Chave de acesso da NF-e/NFC-e</label>
                          <input type="text"  [(ngModel)]="nfeRefere.refNFe" class="form-control" placeholder="Digite a chave de acesso">
                        </div>
                      </div>
                  </div>
                </div>
                
                <!-- DIV NF modelo 1/1A -->
               
                <div class="card mb-g"  id="div2" style="display: none;">
                  <div class="card-body p-3">
                      <div class="row">
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="modelo">Data da Emissão</label>
                          <input type="date" class="form-control" placeholder="DD-MM-AAAA" id="dataEmissao">
                        </div>
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="modelo">CNPJ/CPF do emitente</label>
                          <input type="text" class="form-control" placeholder="CPF/CNPJ" id="CpfCnpjEmitente">
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="modelo">IE do Emitente</label>
                          <input type="text" class="form-control" placeholder="Inscrição Estadual" id="IeEmitente">
                        </div>
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="modelo">Modelo</label>
                          <select class="select2 form-control w-100" id="modeloEmitente">
                            <option value="1">Nota Fiscal</option>
                          </select>
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="modelo">Série</label>
                          <input type="text" class="form-control" placeholder="Série" id="serieEmissao">
                        </div>
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="modelo">Número</label>
                          <input type="text" class="form-control" placeholder="Número" id="numero">
                        </div>
                      </div>
                  </div>
                </div>

                <!-- NF de produtor rural -->
               
                <div class="card mb-g"  id="div3" style="display: none;">
                  <div class="card-body p-3">
                      <div class="row">
                        <div class="form-group col-sm-12">
                          <label class="form-label" for="chaveCTe">Chave de acesso da CT-e</label>
                          <input type="text" class="form-control" placeholder="Digite a chave de acesso" id="chaveCTe">
                        </div>
                      </div><br>
                      <div class="row">
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="estado">Estado do Emitente</label>                          
                            <select class="select2 form-control w-100"  id="estado">
                              <option>SP</option>
                            </select>                          
                        </div>
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="dataEmissao">Data da Emissão</label>
                          <input type="date" class="form-control" placeholder="DD-MM-AAAA" id="dataEmissao">
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="CpfCnpjEmitente">CNPJ/CPF do emitente</label>
                          <input type="text" class="form-control" placeholder="CPF/CNPJ" id="CpfCnpjEmitente">
                        </div>
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="IeEmitente">IE do Emitente</label>
                          <input type="text" class="form-control" placeholder="Inscrição Estadual" id="IeEmitente">
                        </div>
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="modeloEmitente">Modelo</label>
                          <select class="select2 form-control w-100" id="modeloEmitente">
                            <option value="1">NF de Produtor</option>
                            <option value="2">Nota Fiscal</option>
                          </select>
                        </div>
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="serieEmissao">Série</label>
                          <input type="text" class="form-control" placeholder="Série" id="serieEmissao">
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="numero">Número</label>
                          <input type="text" class="form-control" placeholder="Número" id="numero">
                        </div>
                      </div>
                  </div>
                </div>

                <!-- DIV Cupom Fiscal -->
                
                <div class="card mb-g" id="div4" style="display: none;">
                  <div class="card-body p-3">
                      <div class="row">
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="modeloEmitente">Modelo</label>
                          <select class="select2 form-control w-100" id="modeloEmitente">
                            <option value="1">Cupom Fiscal PDV</option>
                            <option value="2">Cupom Fiscal emitido por máquina registradora</option>
                            <option value="2">Cupom Fiscal (emitido por ECF)</option>
                          </select>
                        </div>
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="numeroECF">Número Sequencial ECF</label>
                          <input type="text" class="form-control" placeholder="Número" id="numeroECF">
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="coo">C.O.O</label>
                          <input type="text" class="form-control" placeholder="C.O.O" id="coo">
                        </div>
                      </div>
                  </div>
                </div>
              </div>
 
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"  (click)="cancelNFeReferenciada()" data-dismiss="modal">Fechar</button>
                  <button type="button" class="btn btn-primary" [disabled]="disableForm" (click)="saveNFeReferenciada()">Salvar</button>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- MODAL COBRANÇA -->
<div *ngIf="true" class="modal fade default-example-modal-right" id="modalCobranca" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title h4">Cobrança</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">
              <div class="card mb-g" id="div1">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="form-group col-sm-6">
                        <label class="form-label" for="duplicParcela">Parcela</label>
                        <input type="text" [disabled]="disableForm" [(ngModel)]="duplic.parcela" class="form-control" placeholder="Parcela" id="duplicParcela">
                      </div>
                      <div class="form-group col-sm-6">
                        <label class="form-label" for="duplicVencto">Vencimento</label>
                        <input type="date" [disabled]="disableForm" [(ngModel)]="duplic.vencto" class="form-control" id="duplicVencto">              
                      </div>
                    </div>

                      <div class="row">
                        <div class="form-group col-sm-8">
                          <label class="form-label" for="meioPagtoEdit">Meio de Pagamento</label>
                          <select [disabled]="disableForm" class="select2 form-control w-100" id="meioPagtoEdit">
                          </select>
                        </div>
                        <div class="form-group col-sm-4">
                          <label class="form-label" for="duplicValor">Valor</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="duplic.valor" class="form-control" id="duplicValor">
                        </div>
                      </div>                     
                  </div>
              </div> 
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" (click)="cancelCobranca()" data-dismiss="modal">Fechar</button>
                  <button type="button" class="btn btn-primary" [disabled]="disableForm" (click)="saveCobranca()">Salvar</button>
              </div>
          </div>
      </div>
  </div>
</div>
<!-- fIM MODAL COBRAÇA -->



<div *ngIf="true" class="modal fade default-example-modal-right-lg" id="modalImposto" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title h4">Impostos</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <!-- <div class="col-sm-12">
            <div class="custom-control custom-switch">              
              <input type="checkbox" [checked]="true" class="custom-control-input" id="icmsAtualizar">
              <label class="custom-control-label">Cálculo automático?</label>
            </div>
          </div> -->
          <div class="modal-body">   
            <!-- <div class="custom-control custom-switch">              
              <input type="checkbox" [checked]="hasInscricao" (change)="updateHasInscricao()" class="custom-control-input" id="customSwitch1">
              <label class="custom-control-label" for="customSwitch1">Atualização Automática?</label>
            </div>
            <br> -->
            <div class="row">
              <div class="col-sm-12">
                <ul class="nav nav-tabs nav-fill" role="tablist">
                  <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#tab_justified-1" role="tab">ICMS</a></li>
                  <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab_justified-2" role="tab">IPI</a></li>
                  <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab_justified-3" role="tab">PIS</a></li>
                  <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab_justified-4" role="tab">COFINS</a></li>
                </ul>
                <div class="tab-content border border-top-0 p-0">
                  <div class="tab-pane fade show active" id="tab_justified-1" role="tabpanel">
                    <div id="panel-2" class="panel">
                      <div class="panel-hdr">
                        <h2>ICMS<span class="fw-300"></span></h2>
                            <div class="col-sm-4">
                              <div class="custom-control custom-switch">
                                <input type="checkbox" [disabled]="disableForm" [(ngModel)]="chqIcmsAtualizar" class="custom-control-input" id="icmsAtualizar" checked>
                                <label class="custom-control-label" for="icmsAtualizar">Cálculo automático?</label>
                              </div>                           
                            </div>
                        </div>
            
                      <div class="panel-container show">
                        <div class="panel-content p-2">
                            <div class="row">    
                              <div class="form-group col-sm-12">
                                <label class="form-label" for="icmsSituacaoTributaria">{{labelSituacaoTributaria}}</label>
                                <select class="select2 form-control w-100" [disabled]="chqIcmsAtualizar" id="icmsSituacaoTributaria">
                                </select>
                              </div>
                              <!-- <div class="form-group col-sm-4">
                                <label class="form-label" for="icmsModalidadeBC">Modalidade B. C.</label>
                                <select class="select2 form-control w-100" id="icmsModalidadeBC">
                                  <option value="1">Modalidade 1</option>
                                  <option value="2">Modalidade 2</option>
                                </select>
                              </div>
    
                              <div class="form-group col-sm-4">
                                <label class="form-label" for="icmsEsquemaCalculo">Esquema de Cálculo</label>
                                <select class="select2 form-control w-100" id="icmsEsquemaCalculo">
                                  <option value="1">Esquema Cálculo 1</option>
                                  <option value="2">Esquema Cálculo 2</option>
                                </select>
                              </div> -->
                            </div>
                            <br>
                            <div class="row">
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="baseCalculo">Base de Cálculo:</label>
                                <input type="text" [(ngModel)]="itemIcms.vBC" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmsvBC" (blur)="calcularIcms()">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="RedBaseCalculo">% Red. Base Cálc:</label>
                                <input type="text" [(ngModel)]="itemIcms.pRedBC" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmspRedBC">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="porcenICMS">% ICMS:</label>
                                <input type="text" [(ngModel)]="itemIcms.pIcms" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmspIcms" (blur)="calcularIcms()">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="valorIcms">Valor ICMS:</label>
                                <input type="text" [(ngModel)]="itemIcms.vIcms" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmsvIcms">
                              </div>
                              <!-- <div class="form-group col-sm-6">
                                <label class="form-label" for="icmsEsquemaCalculoSubst">Esquema de Cálculo Substituição Tributária</label>
                                <select class="select2 form-control w-100" id="icmsEsquemaCalculoSubst">
                                  <option value="1">Esquema Cálculo 1</option>
                                  <option value="2">Esquema Cálculo 2</option>
                                </select>
                              </div> -->
                            </div>
                            <div class="row">
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="porcenMargemIva">% Margem - IVA:</label>
                                <input type="text" [(ngModel)]="itemIcms.pIva" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmspIva">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="baseCalculoST">Base de Cálculo - ST:</label>
                                <input type="text" [(ngModel)]="itemIcms.vBCST" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmsvBCST">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="porcenRedCalcST">% Red. Base Cálc. ST:</label>
                                <input type="text" [(ngModel)]="itemIcms.pRedBCST" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmspRedBCST">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="porcenIcmsSt">% ICMS ST:</label>
                                <input type="text" [(ngModel)]="itemIcms.pIcmsST"  [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmspIcmsST">
                              </div>
                            </div>
                            
                            <div class="row">
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="porcenCreditoSN">% Crédito S.N.:</label>
                                <input type="text" [(ngModel)]="itemIcms.pCredSN" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmspCredSN" (blur)="calcularCredICMSSN()">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="valorCreditoSN">Valor Créd. S.N.:</label>
                                <input type="text" [(ngModel)]="itemIcms.vCredICMSSN" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmsvCredICMSSN">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="vlAproxTributos">Vl. Aprox. dos Tríbutos:</label>
                                <input type="text" [(ngModel)]="itemNFe.vTotTrib" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemNFevTotTrib">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="valorIcmsST">Valor ICMS ST:</label>
                                <input type="text"[(ngModel)]="itemIcms.vIcmsST" [disabled]="chqIcmsAtualizar" class="form-control" placeholder="" id="itemIcmsvIcmsST">
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="tab_justified-2" role="tabpanel">
                    <div id="panel-21" class="panel">
                      <div class="panel-hdr">
                        <h2>IPI <span class="fw-300"></span></h2>

                        <div class="col-sm-4">
                          <div class="custom-control custom-switch">
                            <input type="checkbox" [disabled]="disableForm" [(ngModel)]="chqIpiAtualizar" class="custom-control-input" id="ipiAtualizar" checked>
                            <label class="custom-control-label" for="ipiAtualizar">Cálculo automático?</label>
                          </div>                           
                        </div>
                      </div>
                      <div class="panel-container show">
                        <div class="panel-content p-2">
                          <div class="row">
                            <div class="form-group col-sm-12">
                              <label class="form-label" for="icmsCST">CST</label>
                              <select class="select2 form-control w-100" [disabled]="chqIpiAtualizar" id="ipiSituacaoTributaria">
                              </select>
                            </div>
                          </div>
                          <br>
                          <div class="row">
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="baseCalculo">Base de Cálculo:</label>
                              <input type="text" [(ngModel)]="itemIpi.vBC" [disabled]="chqIpiAtualizar" class="form-control" placeholder="0,00" id="itemIpivBC" (blur)="calcularIpi()">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="porcenIPI">% IPI:</label>
                              <input type="text" [(ngModel)]="itemIpi.pIpi" [disabled]="chqIpiAtualizar" class="form-control" placeholder="0,00" id="itemIpipIpi" (blur)="calcularIpi()">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="valorIpi">Valor IPI:</label>
                              <input type="text" [(ngModel)]="itemIpi.vIpi" [disabled]="chqIpiAtualizar" class="form-control" placeholder="0,00" id="itemIpivIpi">
                            </div>
                          </div> 
                          <div class="row">
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="classeEnquad">Classe Enquad:</label>
                              <input type="text" [(ngModel)]="itemIpi.clEnq" [disabled]="chqIpiAtualizar" class="form-control" placeholder="" id="itemIpiclEnq">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="codigoEnquad">Código Enquad:</label>
                              <input type="text" [(ngModel)]="itemIpi.cEnq" [disabled]="chqIpiAtualizar" class="form-control" placeholder="" id="itemIpicEnq">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="cnpjProdutor">CNPJ Produtor:</label>
                              <input type="text" [(ngModel)]="itemIpi.cnpjProd" [disabled]="chqIpiAtualizar" class="form-control" placeholder="" id="itemIpicnpjProd">
                            </div>
                          </div>                          
                          <div class="row">
                            <div class="form-group col-sm-8">
                              <label class="form-label" for="codigoSelo">Código Selo:</label>
                              <input type="text" [(ngModel)]="itemIpi.cSelo" [disabled]="chqIpiAtualizar" class="form-control" placeholder="" id="itemIpicSelo">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="qtdSelo">Qtd Selo:</label>
                              <input type="text" [(ngModel)]="itemIpi.qSelo" [disabled]="chqIpiAtualizar" class="form-control" placeholder="0" id="itemIpiqSelo">
                            </div>
                          </div> 
                            <!-- <div class="form-group col-sm-4">
                              <label class="form-label" for="ipiEsquemaCalculo">Esquema de Cálculo</label>
                              <select class="select2 form-control w-100" id="icmsEsquemaCalculo">
                                <option value="1">Esquema Cálculo 1</option>
                                <option value="2">Esquema Cálculo 2</option>
                              </select>
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="ipiCodigoEnquadramento">Código de Enquadramento</label>
                              <select class="select2 form-control w-100" id="ipiCodigoEnquadramento">
                                <option value="1">Enquadramento 1</option>
                                <option value="2">Enquadramento 2</option>
                              </select>
                            </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="tab_justified-3" role="tabpanel">
                    <div id="panel-22" class="panel">
                      <div class="panel-hdr">
                        <h2>PIS <span class="fw-300"></span></h2>
                          <div class="col-sm-4">
                            <div class="custom-control custom-switch">
                              <input type="checkbox" [disabled]="disableForm" [(ngModel)]="chqPisAtualizar" class="custom-control-input" id="pisAtualizar" checked>
                              <label class="custom-control-label" for="pisAtualizar">Cálculo automático?</label>
                            </div>                           
                          </div>
                    </div>
                      <div class="panel-container show">
                        <div class="panel-content p-2">
                          <div class="row">
                            <div class="form-group col-sm-12">
                              <label class="form-label" for="icmsCST">CST</label>
                              <select class="select2 form-control w-100" [disabled]="chqPisAtualizar" id="pisSituacaoTributaria">
                              </select>
                            </div>
                          </div>
                          <br>
                          <div class="row">
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="baseCalculo">Base de Cálculo:</label>
                              <input type="text"  [(ngModel)]="itemPis.vBC" [disabled]="chqPisAtualizar" class="form-control"  id="itemPisvBC" (blur)="calcularPis()">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="porcenPis">% PIS:</label>
                              <input type="text" [(ngModel)]="itemPis.pPis" [disabled]="chqPisAtualizar" class="form-control" id="itemPispPis" (blur)="calcularPis()">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="valorPis">Valor Pis:</label>
                              <input type="text" [(ngModel)]="itemPis.vPis" [disabled]="chqPisAtualizar" class="form-control"  id="itemPisvPis">
                            </div>
                          </div> 
                          <div class="row">
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="quantidade">Quantidade:</label>
                              <input type="text" [(ngModel)]="itemPis.qBCProd" [disabled]="chqPisAtualizar" class="form-control"  id="itemPisqBCProd">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="valroAliquota">Valor Aliquota:</label>
                              <input type="text" [(ngModel)]="itemPis.vAliqProd" [disabled]="chqPisAtualizar" class="form-control"  id="itemPisvAliqProd">
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="baseCalculoST">Base de Cálculo ST:</label>
                              <input type="text" [(ngModel)]="itemPis.vBCST" [disabled]="chqPisAtualizar" class="form-control"  id="itemPisvBCST">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="porcenPisST">% PIS ST:</label>
                              <input type="text" [(ngModel)]="itemPis.pPisST" [disabled]="chqPisAtualizar" class="form-control"  id="itemPispPisST">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="valorPisST">Valor PIS ST:</label>
                              <input type="text" [(ngModel)]="itemPis.vPisST" [disabled]="chqPisAtualizar" class="form-control"  id="itemPisvPisST">
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="quantidadeST">Quantidade ST:</label>
                              <input type="text" [(ngModel)]="itemPis.qBCProdST" [disabled]="chqPisAtualizar" class="form-control"  id="itemPisqBCProdST">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="valroAliquotaST">Valor Aliquota ST:</label>
                              <input type="text" [(ngModel)]="itemPis.vAliqProdST" [disabled]="chqPisAtualizar" class="form-control"  id="itemPisvAliqProdST">
                            </div>
                          </div>
                          <!-- <div class="row">
                            <div class="form-group col-sm-6">
                              <label class="form-label" for="pisCST">CST</label>
                              <select class="select2 form-control w-100" id="pisCST">
                                <option value="1">CST 1</option>
                                <option value="2">CST 2</option>
                              </select>
                            </div>
                          </div> -->
                          <!-- <div class="row">
                            <div class="form-group col-sm-6">
                              <label class="form-label" for="pisEsquemaCalculo">Esquema de Cálculo</label>
                              <select class="select2 form-control w-100" id="pisEsquemaCalculo">
                                <option value="1">Esquema Cálculo 1</option>
                                <option value="2">Esquema Cálculo 2</option>
                              </select>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="tab_justified-4" role="tabpanel">
                    <div id="panel-23" class="panel">
                      <div class="panel-hdr">
                        <h2>COFINS <span class="fw-300"></span></h2>
                            <div class="col-sm-4">
                              <div class="custom-control custom-switch">
                                <input type="checkbox" [disabled]="disableForm" [(ngModel)]="chqCofinsAtualizar" class="custom-control-input" id="cofinsAtualizar" checked>
                                <label class="custom-control-label" for="cofinsAtualizar">Cálculo automático?</label>
                              </div>                           
                            </div>

                      </div>
                      <div class="panel-container show">
                        <div class="panel-content">
                          <div class="row">
                            <div class="form-group col-sm-12">
                              <label class="form-label" for="icmsCST">CST</label>
                              <select class="select2 form-control w-100" [disabled]="chqCofinsAtualizar" id="cofinsSituacaoTributaria">
                              </select>
                            </div>
                          </div>
                          <br>
                          <div class="row">
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="baseCalculo">Base de Cálculo:</label>
                              <input type="text" [(ngModel)]="itemCofins.vBC" [disabled]="chqCofinsAtualizar" class="form-control" id="itemCofinsvBC">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="porcenCofins">% COFINS:</label>
                              <input type="text" [(ngModel)]="itemCofins.pCofins"  [disabled]="chqCofinsAtualizar" class="form-control"  id="itemCofinspCofins">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="valorCofins">Valor COFINS:</label>
                              <input type="text" [(ngModel)]="itemCofins.vCofins" [disabled]="chqCofinsAtualizar" class="form-control" id="itemCofinsvCofins">
                            </div>
                          </div> 
                          <div class="row">
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="quantidade">Quantidade:</label>
                              <input type="text" [(ngModel)]="itemCofins.qBCProd" [disabled]="chqCofinsAtualizar" class="form-control"  id="itemCofinsqBCProd">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="valorAliquota">Valor Aliquota:</label>
                              <input type="text" [(ngModel)]="itemCofins.vAliqProd" [disabled]="chqCofinsAtualizar" class="form-control"  id="itemCofinsvAliqProd">
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="baseCalculoST">Base de Cálculo ST:</label>
                              <input type="text" [(ngModel)]="itemCofins.vBCST" [disabled]="chqCofinsAtualizar" class="form-control"  id="itemCofinsvBCST">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="porcenCofinsST">% COFINS ST:</label>
                              <input type="text" [(ngModel)]="itemCofins.pCofinsST" [disabled]="chqCofinsAtualizar" class="form-control"  id="itemCofinspCofinsST">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="valorCofins">Valor COFINS ST:</label>
                              <input type="text" [(ngModel)]="itemCofins.vCofinsST" [disabled]="chqCofinsAtualizar" class="form-control"  id="itemCofinsvCofinsST">
                            </div>
                          </div>
                          <div class="row">
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="quantidade">Quantidade:</label>
                              <input type="text" [(ngModel)]="itemCofins.qBCProdST" [disabled]="chqCofinsAtualizar" class="form-control"  id="itemCofinsqBCProdST">
                            </div>
                            <div class="form-group col-sm-4">
                              <label class="form-label" for="valroAliquota">Valor Aliquota:</label>
                              <input type="text" [(ngModel)]="itemCofins.vAliqProdST" [disabled]="chqCofinsAtualizar" class="form-control"  id="itemCofinsvAliqProdST">
                            </div>
                          </div>
                          <!-- <div class="row">
                            <div class="form-group col-sm-6">
                              <label class="form-label" for="cofinsCST">CST</label>
                              <select class="select2 form-control w-100" id="cofinsCST">
                                <option value="1">CST 1</option>
                                <option value="2">CST 2</option>
                              </select>
                            </div>
                            <div class="form-group col-sm-6">
                              <label class="form-label" for="cofinsEsquemaCalculo">Esquema de Cálculo</label>
                              <select class="select2 form-control w-100" id="cofinsEsquemaCalculo">
                                <option value="1">Esquema Cálculo 1</option>
                                <option value="2">Esquema Cálculo 2</option>
                              </select>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
              </div>
            </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
              <button type="button" class="btn btn-primary" [disabled]="disableForm" (click)="saveImposto()">Salvar</button>
          </div>
      </div>
  </div>
</div>

<!-- modalCombustiveis -->
<div class="modal fade default-example-modal-right-lg" id="modalCombustiveis" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title h4">Combustiveis</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">  
                    <div id="panel-2" class="panel">
                      <div class="panel-container show">
                        <div class="panel-content p-2">
                            <div class="row">    
                              <div class="form-group col-sm-12">
                                <label class="form-label" for="icmsSituacaoTributaria">Código ANP</label>
                                <select class="select2 form-control w-100" id="icmsSituacaoTributaria">
                                  <option>210101001</option>
                                  <option>450105001</option>
                                  <option>234301001</option>
                                  <option>219753201</option>
                                </select>
                              </div>
                              <div class="form-group col-sm-12">
                                <label class="form-label" for="">Descrição ANP:</label>
                                <input type="text"  class="form-control" placeholder="">
                              </div>
                            </div>
                            <br>
                            <div class="row">
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="">%GLP:</label>
                                <input type="number" class="form-control" placeholder="" >
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="">Valor de Partida:</label>
                                <input type="number" class="form-control" placeholder="">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="">% Gás Nat. (pGNn):</label>
                                <input type="number" class="form-control" placeholder="">
                              </div>
                              <div class="form-group col-sm-3">
                                <label class="form-label" for="">% Gás Nat. Import. (pGNi):</label>
                                <input type="number" class="form-control" placeholder="">
                              </div>
                            </div>                            
                            <div class="row">
                              <div class="form-group col-sm-4">
                                <label class="form-label" for="">CODIF:</label>
                                <input type="number" class="form-control" placeholder="" >
                              </div>
                              <div class="form-group col-sm-4">
                                <label class="form-label" for="">UF:</label>
                                <input type="number" class="form-control" placeholder="">
                              </div>
                              <div class="form-group col-sm-4">
                                <label class="form-label" for="">Qtd. Fat. Temp. Ambiente :</label>
                                <input type="number" class="form-control" placeholder="">
                              </div>
                            </div>
                            <label class="form-label" for="">CIDE</label><br>
                            <div class="row">

                              <div class="form-group col-sm-4">
                                <label class="form-label" for="">Base de Cálculo:</label>
                                <input type="number" class="form-control" placeholder="" >
                              </div>
                              <div class="form-group col-sm-4">
                                <label class="form-label" for="">Alíquota:</label>
                                <input type="number" class="form-control" placeholder="">
                              </div>
                              <div class="form-group col-sm-4">
                                <label class="form-label" for="">Valor:</label>
                                <input type="number" class="form-control" placeholder="">
                              </div>
                            </div>
                            <button type="button" class="btn btn-primary" [disabled]="disableForm" style="float: right;" (click)="saveImposto()">Salvar</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" style="float: right;">Fechar</button>
                          </div>
                          
                      </div>
                    </div>
                  </div>
          <div class="modal-footer">
              
          </div>
      </div>
  </div>
</div>

<!-- modalMedicamentos -->
<div class="modal fade default-example-modal-right-lg" id="modalMedicamentos" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title h4">Lotes / Medicamentos</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">  
                    <div id="panel-2" class="panel">
                      <div class="panel-container show">
                        <div class="panel-content p-2">
                            <div class="row">

                              <div class="form-group col-sm-4">
                                <button style="float:left; margin-top: 25px; margin-left: 40px;" class="btn btn-sm btn-primary" (click)="adicionarItemMed()">
                                  <span class="fal fa-plus mr-1"></span>
                                Adicionar
                              </button>                            
                              </div>
                            </div>
                           
                          </div>
                          <div class="col-sm-12" style="align-items: center;">
                            <br>
                            <label class="form-label">Lotes e Medicamentos</label>
                          <!-- <div class="card col-sm-12"> -->
                            <div class="card-body col-sm-12">
                          <!-- Modal Transportadora -->
                          <div class="form-group col-sm-12">
                            <table  datatable [dtOptions]='dtOptionsItemMed' id='tableItemMed' class="table table-bordered table-hover table-striped w-100">
                                <thead class="bg-primary-600">
                                  <tr>
                                      <th width="20%">Lote</th>
                                      <th width="5%">QTD</th>
                                      <!-- <th width="9%">Dt. Fab</th> -->
                                      <th width="7%">Dt. Validade</th>
                                      <th width="7%">Preço Max</th>
                                      <th width="7%">Cód. ANVISA</th>
                                      <th width="10%"></th>   
                                  </tr>
                              </thead>
                              <tbody>
                              </tbody>
                            </table>
                          </div>
                          <!-- <button type="button" class="btn btn-primary" [disabled]="disableForm" style="float: right;" (click)="saveMed()">Salvar</button> -->
                          <button type="button" class="btn btn-secondary" data-dismiss="modal" style="float: right; margin-right: 15px;">Fechar</button>
                        <!-- </div> -->
                      </div>
                    </div><br>
                      </div>
                    </div>
                  </div>
          <div class="modal-footer">
              
          </div>
      </div>
  </div>
</div>

<!-- modalAddMed -->
<div class="modal fade" id="modalAddMed" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal modal-dialog-centered" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Adicionar Lote</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-sm-6">
                <label class="form-label" for="itemMedNLote">Lote:</label>
                <input type="text" class="form-control" [(ngModel)]="itemMed.nLote" id="itemMedNLote" placeholder="" >
              </div>
              <div class="form-group col-sm-6">
                <label class="form-label" for="itemMedQLote">QTD:</label>
                <input type="number" class="form-control" [(ngModel)]="itemMed.qLote" id="itemMedQLote" placeholder="">
              </div>
            </div>
            <div class="row">
            <div class="form-group col-sm-6">
              <label class="form-label" for="itemMedDtFab">Dt. Fabricação:</label>
              <input type="date" class="form-control" [(ngModel)]="itemMed.dtFab" id="itemMedDtFab" placeholder="">
            </div>
            <div class="form-group col-sm-6">
              <label class="form-label" for="itemMedDtVal">Dt. Validade:</label>
              <input type="date" class="form-control" [(ngModel)]="itemMed.dtVal" id="itemMedDtVal" placeholder="">
            </div>
          </div>                          
          <div class="row">
            <div class="form-group col-sm-6">
              <label class="form-label" for="itemMedVPMC">Preço Max.:</label>
              <input type="text" class="form-control" [(ngModel)]="itemMed.vPMC" id="itemMedVPMC" placeholder="" >
            </div>
            <div class="form-group col-sm-6">
              <label class="form-label" for="itemMedCProdANVISA">Cód. ANVISA:</label>
              <input type="text" class="form-control" [(ngModel)]="itemMed.cProdANVISA" id="itemMedCProdANVISA" placeholder="">
            </div>
          </div>
          <div class="modal-footer">
              <button type="button" (click)="closeAddItemMed()" class="btn btn-secondary">Fechar</button>
              <button type="button" (click)="saveMed()" class="btn btn-primary">Salvar</button>
          </div>
      </div>
   </div>
   </div>
</div>


<div *ngIf="true" class="modal fade default-example-modal-right-lg" id="modalConfiguracaoAvancada" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title h4">Configurações</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">
              <div class="card">
                  <div class="card-body p-3">
                      <div class="row">
                        <div class="form-group col-sm-12">
                          <label class="form-label" >Tipo de emissão</label>
                          <select class="select2 form-control w-100" [disabled]="disableForm" [(ngModel)]="nfe.tpEmis" id="tipoEmissao">
                          </select>
                        </div>

                        <div class="form-group col-sm-12">
                          <label class="form-label" >Tipo de atendimento</label>
                          <select [disabled]="disableForm" class="select2 form-control w-100" [(ngModel)]="nfe.indPres" id="tipoAtendimento">
                          </select>
                        </div>
                        <div class="form-group col-sm-12">
                          <label class="form-label" >Tipo de operação do destinatário</label>
                          <select [disabled]="disableForm" class="select2 form-control w-100" [(ngModel)]="nfe.idDest" id="tipoOperacao">
                            </select>
                        </div>
              
                        <div class="form-group col-sm-4">
                          <label class="form-label" >Número de série</label>
                          <input type="text" [disabled]="true" [(ngModel)]="nfe.serie" id="nfeSerie"  class="form-control">
                        </div>
                        <div class="form-group col-sm-4">
                          <label class="form-label" >Número da Nota Fiscal</label>
                          <input type="number" [disabled]="disableForm" [(ngModel)]="nfe.numeroNota" id="nfeNumeroNota"  class="form-control">
                        </div>
                        <div class="form-group col-sm-4">
                          <label class="form-label" >Código da Nota (cNF)</label>
                          <input type="text" [disabled]="disableForm" [(ngModel)]="nfe.cNF" id="nfecNF"  class="form-control">
                        </div>

                        <div class="form-group col-sm-12">
                          <label class="form-label" >Chave de acesso</label>
                          <input type="text" [disabled]="true" [(ngModel)]="nfe.chNFe" id="nfeChave"  class="form-control">
                        </div>
                        
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
              <button type="button" class="btn btn-primary" [disabled]="disableForm" (click)="saveConfiguracao()">Salvar</button>
          </div>
      </div>
  </div>
</div>
</div>

<!-- MODAL VALORES TOTAIS -->
<div *ngIf="true" class="modal fade default-example-modal-right-lg" id="modalValores" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title h4">Valores Totais</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">
              <div class="card">
                <div class="card-body p-3">
                  <div class="row">
                    <!-- Primeira linha -->
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="baseCalcICMS">Base Cálculo ICMS:</label>
                      <input type="text"  [(ngModel)]="nfe.vBC" id="nfevBC" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalICMS">Valor Total ICMS:</label>
                      <input type="text" [(ngModel)]="nfe.vIcms" id="nfevIcms" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="baseCalcIcmsST">Base Cálculo ICMS ST:</label>
                      <input type="text" [(ngModel)]="nfe.vBCST" id="nfevBCST" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalIcmsST">Valor Total ICMS ST:</label>
                      <input type="text" [(ngModel)]="nfe.vST" id="nfevST" class="form-control" disabled="" placeholder="0">
                    </div>

                    <!-- Segunda linha -->
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="icmsDesonerado">ICMS Desonerado:</label>
                      <input type="text" [(ngModel)]="nfe.vICMSDeson" id="nfevICMSDeson" class="form-control" disabled="" placeholder="0">
                    </div>                    
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalFCP">Valor Total FCP:</label>
                      <input type="text" [(ngModel)]="nfe.vFCP" id="nfevFCP" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalFcpSt">Valor Total FCP-ST:</label>
                      <input type="text" [(ngModel)]="nfe.vFCPST"  id="nfevFCPST" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalFcpStRetido">Valor Total FCP-ST (retido):</label>
                      <input type="text" [(ngModel)]="nfe.vFCPSTRet"  id="nfevFCPSTRet" class="form-control" disabled="" placeholder="0">
                    </div>

                    <!-- terceira linha -->
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="nfevFrete2">Frete:</label>
                      <input type="text" [(ngModel)]="nfe.vFrete" id="nfevFrete2" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="seguros">Seguros:</label>
                      <input type="text" [(ngModel)]="nfe.vSeguro"  id="nfevSeguro" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="reducao">Redução:</label>
                      <input type="text"   id="nfevReducao" class="form-control" disabled="" >
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="outrasDespesas">Outras Despesas</label>
                      <input type="text" [(ngModel)]="nfe.vOutro"  id="nfevOutro" class="form-control" disabled="" placeholder="0">
                    </div>

                    <!-- Quarta linha -->
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalIpi">Valor Total IPI:</label>
                      <input type="text" [(ngModel)]="nfe.vIPI"  id="nfevIPI" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalImpostoImp">Valor Total Imposto Imp.:</label>
                      <input type="text" [(ngModel)]="nfe.vII"  id="nfevII" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalPis">Valor Total PIS:</label>
                      <input type="text" [(ngModel)]="nfe.vPIS"  id="nfevPIS" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalCof">Valor Total COFINS:</label>
                      <input type="text" [(ngModel)]="nfe.vCOFINS"  id="nfevCofins" class="form-control" disabled="" placeholder="0">
                    </div>                  

                    <!-- Quinta linha -->
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalProdServ">Vl. Total Produtos/Serv.:</label>
                      <input type="text" [(ngModel)]="nfe.vProd" id="nfevProd" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorTotalIpiDev">Valor Total IPI Devolução:</label>
                      <input type="text" [(ngModel)]="nfe.vIPIDevol" id="nfevIPIDevol" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label" for="valorAproxTributos">Valor Aprox. dos Tributos:</label>
                      <input type="text" [(ngModel)]="nfe.vTotTrib" id="nfevTotTrib" class="form-control" disabled="" placeholder="0">
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-label"><b>Valor Total:</b></label>
                      <input type="text" [(ngModel)]="nfe.vNF" id="nfevNF" class="form-control" disabled="" >
                    </div>                    
                </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
          </div>
      </div>
  </div>
</div>
</div>

<div *ngIf="true" class="modal fade default-example-modal-right" id="modalCobranca" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-right">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title h4">Cobrança</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true"><i class="fal fa-times"></i></span>
              </button>
          </div>
          <div class="modal-body">
              <div class="card mb-g">
                  <div class="card-body p-3">
                      <div class="row">
                        <div class="form-group col-sm-12">
                          <label class="form-label" for="produtoNome">Vencimento</label>
                          <input type="text" id="produtoNome" name="produtoNome" class="form-control">
                        </div>
                        <div class="form-group col-sm-12">
                          <label class="form-label" for="produtoNome">Valor</label>
                          <input type="text" id="produtoNome" name="produtoNome" class="form-control">
                        </div>
                        <div class="form-group col-sm-12">
                          <label class="form-label" for="parcelasMeioPagto">Meio de pagamento</label>
                          <select class="select2 form-control w-100" id="parcelasMeioPagto">
                          </select>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
              <button type="button" class="btn btn-primary" [disabled]="disableForm" (click)="saveCobranca()">Salvar</button>
          </div>
      </div>
  </div>
</div>


<swal #deleteConfirmationMessage showDenyButton="true" (deny)="handleDenial()" (confirm)="confirmDeleteItem()" denyButtonText="Cancelar" showConfirmButton="true" confirmButtonText="Confirmar" title="Excluir?" text="Tem certeza que deseja excluir este registro?" icon="warning"></swal>
<swal #deleteConfirmationNFeRefere showDenyButton="true" (deny)="handleDenial()" (confirm)="confirmDeleteNFeRefere()" denyButtonText="Cancelar" showConfirmButton="true" confirmButtonText="Confirmar" title="Excluir?" text="Tem certeza que deseja excluir este registro?" icon="warning"></swal>
<swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde." icon="error"></swal>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#2196f3" type = "ball-running-dots" [fullScreen] = "true"><p style="color: white" > {{msgSpinner}} </p></ngx-spinner>
