import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MainLayoutComponent } from "./layout/main-layout.component";
import { LoginLayoutComponent } from "./layout/login-layout.component";

import {LoginModule} from "./features/login/login.module"


import {DashboardModule} from "./features/dashboard/dashboard.module"
import {CadastrosModule} from "./features/cadastros/cadastros.module"
import {FaturamentoModule} from "./features/faturamento/nfe.module"
import {EmpresaModule} from "./features/empresa/empresa.module"
import { CertificadoModule } from './features/certificado/certificado.module';
import {InutilizarModule} from "./features/inutilizar/inutilizar.module"

import {AuthGuard} from "./auth/auth.guard"
import { RecuperaSenhaLayoutComponent } from "./layout/recuperaSenha-layout.component";
import { Relatorio1Module } from "./features/relatorios/relatorio1/relatorio1.module";

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: "",
    component: MainLayoutComponent,
    data: { pageTitle: "Home" },
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: ()=> DashboardModule,
        data: { pageTitle: "Dashboard" }
      },
      {
        path: "cadastros",
        loadChildren: ()=> CadastrosModule,
      },
      {
        path: "empresa",
        loadChildren: ()=> EmpresaModule,
      },
      {
        path: "certificado",
        loadChildren: ()=> CertificadoModule,
      },
      {
        path: "inutilizar",
        loadChildren: ()=> InutilizarModule,
      },
      {
        path: "faturamento",
        loadChildren: ()=> FaturamentoModule,
      },
      {
        path: "relatorio1",
        loadChildren: ()=> Relatorio1Module,
      }
    ]
  },
  {
    path: "login",
    component: LoginLayoutComponent,
    data: { pageTitle: "Login" },
    children: [
      {
        path: "",
        loadChildren: ()=> LoginModule,
        data: { pageTitle: "Login" }
      }
    ]
  },
 
  {
    path: "recuperaSenha/:id",
    component: RecuperaSenhaLayoutComponent,
    data: { pageTitle: "Recuperar Senha" },

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
