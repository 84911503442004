import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NavigationModule} from "./navigation.module"
import { MainLayoutComponent } from './main-layout.component';
import { LoginLayoutComponent } from './login-layout.component';
import { RecuperaSenhaLayoutComponent } from './recuperaSenha-layout.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NavigationModule
  ],
  declarations: [
    MainLayoutComponent,
    LoginLayoutComponent,
    RecuperaSenhaLayoutComponent,

  ],
  exports:[

  ]
})
export class LayoutModule{

}
