<div class="page-wrapper auth">
    <div class="page-inner bg-brand-gradient ">
        <div class="page-content-wrapper bg-transparent m-0">
            <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                <!-- <div class="d-flex align-items-left container p-0" style="background-color: rgb(143, 7, 255);"> -->
                    <div class="page-logo width-mobile-auto m-0 align-items-left justify-content-left p-0 bg-transparent bg-img-none shadow-0 height-9 border-0">
                        <a href="javascript:void(0)" class="page-logo-link press-scale-down d-flex align-items-center">
                            <img src="img/logoNovo.png" alt="Kiron" aria-roledescription="logo">
                        </a>
                    </div>
  
                    <!-- <a href="page_register.html" class="btn-link text-white ml-auto">
                        Create Account
                    </a> -->
                <!-- </div> -->
            </div>
            <div class="flex-1 col-xl-12" style="background: url(img/svg/fundologin6.jpg) no-repeat center bottom fixed; background-size: cover;">
                <div class="col-xl-12 py-4 py-lg-0 my-lg-0 px-9 px-sm-0">
                    <router-outlet #routerOutlet="outlet"></router-outlet>
                </div>              
            </div>                    
        </div>
    </div>
  </div>
