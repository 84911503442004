import { EmpresaModule } from './empresa.module';

import {ModuleWithProviders} from "@angular/core"
import { Routes, RouterModule } from '@angular/router';
import {EmpresaComponent} from "./empresa.component"


export const empresaRoutes: Routes = [{
  path: '',
  component: EmpresaComponent
}];

export const empresaRouting = RouterModule.forChild(empresaRoutes);