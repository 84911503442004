<!-- <ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item"><a href="" routerLink="/">Kiron</a></li>
    <li class="breadcrumb-item">{{title}}</li>
    <li class="breadcrumb-item active">Edição</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block">
      <span class="js-get-date"></span>
    </li>
  </ol> -->
  <div class="subheader">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-user-edit"></i> {{title}}
      <small>
        Cadastro de {{title}}
      </small>
    </h1>
  </div>
  <div id="panel-1" class="panel">
    <div class="panel-hdr">      
      <h2>Dados cadastrais <span class="fw-300"></span></h2>
    </div>
    <div class="panel-container show">
      <div class="panel-content">
        <div class="row">
          <div class="form-group col-sm-3">
            <label class="form-label" for="pessoa">Tipo Pessoa <span class="text-danger">*</span></label>
            <div class="input-group">
              <select class="js-hide-search form-control" id="pessoa" required>
                <!-- <option selected="">Selecione o tipo de pessoa</option> -->
              </select>                        
           </div>
          </div>
          <div class="form-group col-sm-3">
            <label class="form-label" for="status">Status <span class="text-danger">*</span></label>
            <div class="input-group">
              <select class="js-hide-search form-control" id="status" required>
              </select>                        
           </div>
          </div>
          <!-- <label class="form-label" style="padding-left: 20px; padding-top: 10px;"></label> -->
          <div class="form-group col-sm-6">
            <label class="form-label" for="tipoCadastro"  >Tipo de Cadastro <span class="text-danger">*</span></label>         
            <div class="frame-wrap" style="padding-top: 10px;">
              <div class="custom-control custom-checkbox custom-control-inline col-sm-3">
                <input type="checkbox" checked class="custom-control-input" id="isCliente">
                <label class="custom-control-label" for="isCliente">Cliente</label>
              </div>
              <div class="custom-control custom-checkbox custom-control-inline col-sm-3">
                <input type="checkbox" checked class="custom-control-input"  id="isFornecedor">
                <label class="custom-control-label" for="isFornecedor">Fornecedor</label>
              </div>
              <div class="custom-control custom-checkbox custom-control-inline col-sm-3">
                <input type="checkbox" checked class="custom-control-input"  id="isTransportadora">
                <label class="custom-control-label" for="isTransportadora">Transportadora</label>
              </div>
            </div>
          </div>
          <br>
        </div>
       
        <div class="row">
          <div class="form-group col-sm-3">
            <label class="form-label"  for="cnpjcpf">{{labelCnpjCpf}} <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="" id="cnpjcpf" (blur)="validarCpfCnpj()" required>
             </div>             
          </div>
          <div class="form-group col-sm-2">
            <div>
              <label class="form-label" for="inscEstadual">Tem Inscrição Estadual?</label><br>
            </div>
            <div class="form-group">              
              <select class="js-hide-search form-control" id="inscEstadual" required>
             </select>                        
           </div>
          </div>

          <!-- <div *ngIf="hasInscricao" class="form-group col-sm-4"> -->
          <div id="hasInscricao" class="form-group col-sm-4">
              <label class="form-label" for="inscricaoEstadual">Número Inscrição Estadual</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="" [(ngModel)]="entidade.inscEst" id="inscricaoEstadual">
            </div>
          </div>
          <div class="form-group col-sm-2">
            <div>
              <label class="form-label" for="inscEstadual">É consumidor final?</label><br>
            </div>
            <div class="form-group">              
              <select class="js-hide-search form-control"  id="consumidorFinal">
             </select>                        
           </div>
          </div>

        </div>
        <div class="row">
          <div class="form-group col-sm-12">
            <label class="form-label" for="nomeFantasia">Nome Fantasia</label>
            <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="entidade.nome" maxlength="60" placeholder="" id="nomeFantasia" required>
            </div>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="form-group col-sm-12">
            <label class="form-label" for="destinatarioNota">Razão Social <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="entidade.razao"  maxlength="60" placeholder="" id="nomeRazaoSocial" required>
            </div>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="form-group col-sm-2">
            <label class="form-label" for="cep">Cep <span class="text-danger">*</span></label>
            <div class="input-group">
              <!-- Validar e preencher campos de acordo com o cep -->
                <input type="text" required class="form-control" [(ngModel)]="entidade.cep" placeholder="" id="cep" (blur)="validarCep()">
            </div>
          </div>
          
          <div class="form-group col-sm-4">
            <label class="form-label" for="pais">País <span class="text-danger">*</span></label>
            <div class="input-group">
              <select class="select2 form-control w-100" id="pais">

              </select>
            </div>
          </div>
          <div class="form-group col-sm-2">
            <label class="form-label" for="estado">Estado <span class="text-danger">*</span></label>
            <div class="input-group">
                  <select class="select2 form-control w-100"  id="estado">
                  </select>
            </div>
          </div>
          <div class="form-group col-sm-4">
            <label class="form-label" for="cidade">Cidade <span class="text-danger">*</span></label>
            <div class="input-group">
              <select class="select2 form-control w-100" id="cidade">
              </select>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="form-group col-sm-8">
            <label class="form-label" for="endereco">Endereço <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" required class="form-control" placeholder="" maxlength="60" [(ngModel)]="entidade.endereco" id="endereco">
            </div>
          </div>
          <div class="form-group col-sm-4">
            <label class="form-label" for="numero">Número <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" required class="form-control" placeholder="" [(ngModel)]="entidade.numero" id="numero">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <label class="form-label" for="complemento">Complemento</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="" maxlength="60" [(ngModel)]="entidade.complemento" id="complemento">
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label class="form-label" for="bairro">Bairro</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="" [(ngModel)]="entidade.bairro" id="bairro">
            </div>
          </div>
        </div>
        <div class="row">

          <div class="form-group col-sm-8">
            <label class="form-label" for="entidadeEmail">Email para envio da NF-e</label>
            <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupPrepend"><i class="fal fa-envelope"></i></span>
                </div>
                <!-- <input type="email" name="email" ngModel [email]="true"> -->
                <input type="text" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [(ngModel)]="entidade.email" id="email"name="email" ngModel #emailref="ngModel">
                <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
                    <div [hidden]="!emailref.errors?.pattern">
                      <label class="form-label" style="padding-left: 20px; padding-top: 10px;"><span class="text-danger">Email inválido</span></label>
                  </div>
                </div>
              </div>
              <span class="help-block">Separar os e-mails utilizando ';'</span>  

            </div>
            <div class="form-group col-sm-4">
              <label class="form-label" for="contatoTelefone">Telefone/Celular</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroupPrepend">
                    <i class="fal fa-phone-alt"></i>
                  </span>
                </div>
                  <input type="text" id="entidadeFone" [(ngModel)]="entidade.fone" name="entidadeFone" class="form-control">
              </div>
            </div>
          </div>


        <!-- <div class="row">
          <div class="form-group col-sm-6">
            <label class="form-label" for="perfil">Perfil da Empresa</label>
            <div class="input-group">
                <select class="select2 form-control w-100" id="perfilEmpresa">
                
                </select>
            </div>
          </div>
          <div class="form-group col-sm-6">
            <label class="form-label" for="vendedor">Vendedor</label>
            <div class="input-group">
              <select class="select2 form-control w-100" id="vendedor">
                
              </select>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <!-- Painel do contato -->

  <!-- <div id="panel-2" class="panel">
    <div class="panel-hdr">
      <h2>Contatos<span class="fw-300"></span></h2>
      <div class="panel-toolbar">
        <button
          class="btn btn-panel"
          data-action="panel-fullscreen"
          data-toggle="tooltip"
          data-offset="0,10"
          data-original-title="Tela cheia"
        ></button>
      </div>
    </div>
    <div class="panel-container show">
      <div class="panel-content">
        <div class="row">
          <div class="form-group col-sm-2">
            <div class="input-group">
                <button id="button" type="button" id="btnAdicionarContato" (click)="adicionarContato()"  class="form-control btn btn-sm btn-primary">
                  <span class="fal fa-plus mr-1"></span>
                Adicionar
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" id='contatoTable' class="table table-bordered table-hover table-striped w-100">
              <thead class="bg-primary-600">
                  <tr>
                      <th width="25%">Nome</th>
                      <th width="25%">Email</th>
                      <th width="15%">Data de Aniversário</th>
                      <th width="10%">Telefone</th>
                      <th width="15%">Cargo</th>
                      <th width="10%"></th>
                  </tr>
              </thead>
              <tbody>
                  
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div> -->

  
<button (click)="save()" style="float:right; margin: 10px;" class="btn btn-primary ml-auto" type="button">Salvar</button>
<button (click)="cancel()" type="button" style="float:right; margin: 10px;" class="btn btn-secondary">Cancelar</button>
        
  



  <div *ngIf="true" class="modal fade default-example-modal-right" id="modalContato" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-right">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title h4">Contato</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="card mb-g">
                    <div class="card-body p-3">
                        <div class="row">
                          <div class="form-group col-sm-12">
                            <label class="form-label" for="contatoNome">Nome <span class="text-danger">*</span></label>
                            <input type="text" [(ngModel)]="currentContact.nome" id="contatoNome" name="contatoNome" class="form-control">
                          </div>

                          <div class="form-group col-sm-12">
                            <label class="form-label" for="contatoEmail">Email</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="inputGroupPrepend">@</span>
                                </div>
                                <input type="text" class="form-control" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [(ngModel)]="currentContact.email" id="contatoEmail"name="contatoEmail" ngModel #emailref="ngModel">
                                  <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
                                    <div [hidden]="!emailref.errors?.pattern">
                                      <label class="form-label" style="padding-left: 20px; padding-top: 10px;"><span class="text-danger">Email inválido</span></label>
                                  </div>
                                </div>
                            </div>
                          </div>
                         
                         
                          <!-- <div class="form-group col-sm-12">
                            <label class="form-label" for="contatoEmail">Email</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text" id="inputGroupPrepend">@</span>
                                </div>
                                <input type="text" [(ngModel)]="currentContact.email" id="contatoEmail" name="contatoEmail" class="form-control">
                              </div>
                          </div> -->
                
                          <div class="form-group col-sm-12">
                            <label class="form-label" for="contatodtAniversario">Data de Aniversário</label>
                            <input type="text" [(ngModel)]="currentContact.dtAniversario" id="contatodtAniversario" placeholder="dd/mm/aaaa" name="contatodtAniversario" class="form-control">
                            <span class="help-block">dd/mm/aaaa</span>
                          </div> 
        
                          <div class="form-group col-sm-12">
                            <label class="form-label" for="contatoTelefone">Telefone/Celular</label>
                            <input type="text" id="contatoTelefone" [(ngModel)]="currentContact.fone" name="contatoTelefone" class="form-control">
                          </div>
                
                          <div class="form-group col-sm-12">
                            <label class="form-label" for="contatoCargo">Cargo</label>
                            <input type="text" id="contatoCargo" [(ngModel)]="currentContact.cargo" name="contatoCargo" class="form-control">
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary" (click)="saveContact()">Salvar</button>
            </div>
        </div>
    </div>
</div>

<swal #deleteConfirmationMessage showDenyButton="true" (deny)="handleDenial()" (confirm)="confirmDelete()" denyButtonText="Cancelar" showConfirmButton="true" confirmButtonText="Confirmar" title="Excluir?" text="Tem certeza que deseja excluir este registro?" icon="warning"></swal>
<swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde." icon="error"></swal>
<swal #successMessage text="Boa, registro salvo com sucesso!" showConfirmButton=true icon="success"></swal>
<swal #errorCnpj title="Erro!" text="Ops, CNPJ inválido." icon="error"></swal>
<swal #errorCpf title="Erro!" text="Ops, CPF inválido." icon="error"></swal>
<swal #validationMessage text="Ops, ainda faltam alguns campos para preencher, vamos lá?" showConfirmButton=true icon="error"></swal>


<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#2196f3" type = "ball-running-dots" [fullScreen] = "true"><p style="color: white" > Carregando... </p></ngx-spinner>



