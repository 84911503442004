import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthToken } from '../model/authToken';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { Relatorio1, Relatorio1Response } from '../model/relatorios';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class Relatorio1Service {
  constructor(private httpClient: HttpClient,
    private loginService: LoginService,
    private router: Router) { }

  servicePath: string = '/relatorios'

  url:string = environment.apiUri + this.servicePath;

  httpOptions = {
   headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-access-token': this.loginService.getToken()}),
   responseType: 'blob' as 'json'
   
  }

  // relatorio1(relato:any) : Observable<Relatorio1Response> {
  //   return this.httpClient.post<Relatorio1Response>('http://localhost:8086/relatorios/relatorio1', relato, this.httpOptions)
  //   .pipe( 
  //     catchError(this.handleError)
  //   )
  // }

  relatorio1(relato:any)  {

  // return this.httpClient.post('http://localhost:8086/relatorios/relatorio1', relato,this.httpOptions)
    return this.httpClient.post(this.url + '/relatorio1', relato,this.httpOptions)
    .subscribe((res: any) => {
      const file = new Blob([res], {
        type: res.type
      });
      if(file.size == 0){
        Swal.fire({
          title:  "Ops...",
          text: 'Não existe movimentação neste período.',
          icon: 'error'
         });
      } else {
          const blob = window.URL.createObjectURL(file);

          //Download
          //  const link = document.createElement('a');
          // link.href = blob;
          // link.download = 'relatorio1.pdf';

          // // link.click();
          // link.dispatchEvent(new MouseEvent('click', {
          //   bubbles: true,
          //   cancelable: true,
          //   view: window
          // }));

          window.open(blob, 'download_window');
          window.focus();
       //   setTimeout(() => { // firefox
            window.URL.revokeObjectURL(blob);
       //     link.remove();
       //   }, 100);
       }
      }
      
      )
 

  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(error);
  };

}
