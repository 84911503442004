import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { empresaRouting } from './empresa.routing';
import { EmpresaComponent } from "./empresa.component";
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    empresaRouting
  ],
  declarations: [EmpresaComponent]
})
export class EmpresaModule { }