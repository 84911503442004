<div class="subheader">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-cash-register"></i> {{title}}
      <small>
        Cadastro de {{title}}
      </small>
    </h1>
  </div>
  <div id="panel-1" class="panel">
    <div class="panel-hdr">
      <h2>Faturamento Mensal<span class="fw-300"></span></h2>
      <div class="panel-toolbar">
  
      </div>
    </div>
    <div class="panel-container show">
      <div class="panel-content">
        <div class="row">
          <div class="form-group col-sm-3">
            <label class="form-label" for="ano">Ano <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="number" class="form-control" [(ngModel)]="fatura.ano" placeholder="" id="ano" minlength="4" maxlength="4">
            </div>
          </div>
          <div class="form-group col-sm-3">
            <label class="form-label" for="mes">Mês <span class="text-danger">*</span></label>
            <div class="input-group">
              <select class="js-hide-search form-control" [(ngModel)]="fatura.mes" id="mes" required>
                <option value="01">Janeiro</option>
                <option value="02">Fevereiro</option>
                <option value="03">Março</option>
                <option value="04">Abril</option>
                <option value="05">Maio</option>
                <option value="06">Junho</option>
                <option value="07">Julho</option>
                <option value="08">Agosto</option>
                <option value="09">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </select>    
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-3">
            <label class="form-label" for="valor">(+) Total Fat NF-e <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="fatura.valor" placeholder="" id="valor" >
            </div>
          </div>
          <div class="form-group col-sm-3">
            <label class="form-label" for="vlCancelada">(-) Notas Canceladas <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="fatura.vlCancelada" placeholder="" id="vlCancelada">
            </div>
          </div>
          <div class="form-group col-sm-3">
            <label class="form-label" for="vlDevolucao">(-) Total Devoluções <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="fatura.vlDevolucao" placeholder="" id="vlDevolucao" >
            </div>
          </div>
          <div class="form-group col-sm-3">
            <label class="form-label" for="vOutro">(+) Outros Faturamentos <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="fatura.vOutro" placeholder="" id="vOutro" >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <button (click)="save()" style="float:right; margin: 10px;" class="btn btn-primary ml-auto" type="button">Salvar</button>
  <button (click)="cancel()" type="button" style="float:right; margin: 10px;" class="btn btn-secondary">Cancelar</button>
  
  <swal #deleteConfirmationMessage showDenyButton="true" (deny)="handleDenial()" (confirm)="confirmDelete()" denyButtonText="Cancelar" showConfirmButton="true" confirmButtonText="Confirmar" title="Excluir?" text="Tem certeza que deseja excluir este registro?" icon="warning"></swal>
  <swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde." icon="error"></swal>
  <swal #successMessage text="Boa, registro salvo com sucesso!" showConfirmButton=true icon="success"></swal>
  <swal #validationMessage text="Ops, ainda faltam alguns campos para preencher, vamos lá?" showConfirmButton=true icon="error"></swal>
  