import { Component, OnInit, AfterViewInit,ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DataTableDirective } from "angular-datatables";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { LoginService } from "src/app/services/login.service";
import { routerTransition } from "../../../../util/animations";
import { FaturaService } from "src/app/services/fatura.service";
import { Fatura } from "src/app/services/model/fatura";

declare var $:any
declare var localStorage:any;


@Component({
  selector: "ci-editor",
  templateUrl: "./fatura.editor.component.html",
  styles: [],
  animations: [routerTransition]
})
export class FaturaEditorComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private spinnerService: NgxSpinnerService,
    private faturaService: FaturaService,
  ) {}

  title: string;
  description:string;
  isAtivo: boolean;
  dtOptions: DataTables.Settings = {};
  currentId: number = -1;
  labelSituacaoTributaria: string;
 
  
  fatura: Fatura = {} as Fatura;
  isSimples: string = 'S';
 
  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  @ViewChild('successMessage')
  public readonly successMessage!: SwalComponent;
  @ViewChild('validationMessage')
  public readonly validationMessage!: SwalComponent;
  
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtTrigger: Subject<any> = new Subject();

  ngAfterViewInit(): void {
    this.dtTrigger.next();
      this.inputMask();
  }

  ngOnInit() {
    var self = this;
    
   

    const id: number = parseInt(this.route.snapshot.paramMap.get('id'));
    this.currentId = id;

    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);
    this.isSimples = localStorage.getItem('isSimples');

    this.fillStatus();
    $('#status').val('A').change();

    this.fillCampos();
    $('#ano').val(0).change(); 
    $('#mes').val(0).change(); 
    $('#valor').val(0).change(); 
    $('#vlCancelada').val(0).change(); 
    $('#vlDevolucao').val(0).change(); 
    $('#vOutro').val(0).change(); 
 
    
    self.dtOptions = {
      searching:false,
      lengthChange:false,
    };
    
    this.route.data.subscribe(params => {
      if(id) 
      {
        this.spinnerService.show();
        this.faturaService.getById(id).subscribe(response=> {
          this.spinnerService.hide();
          this.fatura = response.data;
          //this.updateDataTable();
          // $('#origemproduto').val(this.artigo.id).change();
          if(this.fatura.isAtivo == 'S') {
            $('#status').val('A').change();
         } else {
            $('#status').val('I').change();
         };
        }, error => {
          if(error.status == 401){
            self.loginService.tokenExpired();
          }
        })
      } else {
        this.ultimoId();
      } 

    });
    this.title = 'Faturamento Mensal';
    this.description = 'Cadastro de Faturamento Mensal'

//    $('.select2').select2();
  }
  
  fillCampos() {
  }

 fillStatus(){
    var statusData = [
      {'id': "A", 'text': 'Ativo'},
      {'id': "I", 'text': 'Inativo'},
     ];
    $('#status').select2({
      data: statusData,
      allowClear: true,
      minimumResultsForSearch: -1
    });
  }
  
  async ultimoId(){
    await this.faturaService.getUltimoid().subscribe(response=> {
        const codigo = response.id;
        this.fatura.id = codigo.toString();
        console.log(codigo);
      });
    
}

  editEntity(data) {
    var self = this;
  }

  deleteEntity(data) {
     this.deleteConfirmationMessage.fire();
  }

  confirmDelete() {
    this.updateDataTable();
  }

  ngOnDestroy() {
    window.functions = null;
    this.dtTrigger.unsubscribe();
  }

  updateDataTable() {

    var self = this;
    
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  cancel() {
      this.router.navigate(['/cadastros/fatura']);    
  }

  save() {
    var self = this;
    // var datateste = new Date();

    if((this.fatura.ano == '') ){
      this.validationMessage.fire();
    } else {

        this.spinnerService.show();
        this.fatura.ano = $('#ano').val();
        this.fatura.valor = $('#valor').val();
        this.fatura.vlCancelada = $('#vlCancelada').val();
        this.fatura.vlDevolucao = $('#vlDevolucao').val();
        this.fatura.vOutro = $('#vOutro').val();
        this.fatura.isAtivo = ($('#status').val() == 'A' ? 'S': 'N');
        this.fatura.dtInclusao =new Date();
        this.fatura.idEmpresa = localStorage.getItem('idEmpresa');
        
        if(this.fatura.id > 0) {
          this.faturaService.update(this.fatura).subscribe(response => {
            this.spinnerService.hide();
            this.successMessage.fire();
            this.router.navigate(['/cadastros/fatura']);
          }, error => {
            // Swal.fire({
            //     title:  "Erro!",
            //     text: this.msgErroServer + error.error.message,
            //     icon: 'error'
            // });
            alert("Deu erro")
          });
        } else {
          this.faturaService.create(this.fatura).subscribe(id=> {
            this.spinnerService.hide();
            this.successMessage.fire();
            this.router.navigate(['/cadastros/fatura']);
        }, error => {
            this.errorMessage.fire();
          });
        }
      }
  }

  handleDenial() {

  }


  inputMask(){
    let maskCurrency = 
        { 
            alias:'currency',
            radixPoint: ",",
            prefix:'R$ ',
            digits: 2,
            digitsOptional: false,
            // numericInput: true,
            autoUnmask: true,
            unmaskAsNumber: true,
            removeMaskOnSubmit: true
      };
  
  let maskDecimal = 
      { 
          alias:'currency',
          radixPoint: ",",
          prefix:'',
          digits: 2,
          digitsOptional: false,
          autoUnmask: true,
          unmaskAsNumber: true,
          removeMaskOnSubmit: true
      };
  
  
    $('#valor').inputmask(maskCurrency);
    $('#vlCancelada').inputmask(maskCurrency);
    $('#vlDevolucao').inputmask(maskCurrency);
    $('#vOutro').inputmask(maskCurrency);
  }

}
