import { Component, OnInit, AfterViewInit,ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DataTableDirective } from "angular-datatables";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { LoginService } from "src/app/services/login.service";
import { routerTransition } from "../../../../util/animations";
import { EsquemaService } from "src/app/services/esquema.service";
import { SituacaoTributariaService } from "src/app/services/situacaoTributaria.service";
import { ModalidadeBCService } from "src/app/services/modalidadeBC.service";
import { ModalidadeBCSTService } from "src/app/services/modalidadeBCST.service";

import { Cfop } from "src/app/services/model/cfop";
import { CfopIcms } from "src/app/services/model/cfopIcms";
import { CfopIpi } from "src/app/services/model/cfopIpi";
import { CfopPis } from "src/app/services/model/cfopPis";
import { CfopCofins } from "src/app/services/model/cfopCofins";

import { CfopService } from "src/app/services/cfop.service";
import { CfopIcmsService } from "src/app/services/cfopIcms.service";
import { CfopIpiService } from "src/app/services/cfopIpi.service";
import { CfopPisService } from "src/app/services/cfopPis.service";
import { CfopCofinsService } from "src/app/services/cfopCofins.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $:any
declare var localStorage:any;

@Component({
  selector: "ci-editor",
  templateUrl: "./cfop.editor.component.html",
  styles: [],
  animations: [routerTransition]
})
export class CfopEditorComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cfopService: CfopService,
    private loginService: LoginService,
    private spinnerService: NgxSpinnerService,
    private icmsEsquemaService: EsquemaService,
    private icmsSituacaoTributariaService:  SituacaoTributariaService,
    private ipiSituacaoTributariaService: SituacaoTributariaService,
    private pisSituacaoTributariaService: SituacaoTributariaService,
    private cofinsSituacaoTributariaService: SituacaoTributariaService,
    private modalidadeBCService: ModalidadeBCService,
    private modalidadeBCSTService: ModalidadeBCSTService,
    private cfopIcmsService: CfopIcmsService,
    private cfopIpiService: CfopIpiService,
    private cfopPisService: CfopPisService,
    private cfopCofinsService: CfopCofinsService,
  ) {}

  title: string;
  description:string;
  isAtivo: boolean;
  dtOptions: DataTables.Settings = {};
  currentId: number = -1;
  labelSituacaoTributaria: string;
  
  cfop: Cfop = {} as Cfop;
  cfopIcms: CfopIcms = {} as CfopIcms;
  cfopIpi: CfopIpi = {} as CfopIpi;
  cfopPis: CfopPis = {} as CfopPis;
  cfopCofins: CfopCofins = {} as CfopCofins;
  isSimples: string = 'S';
 
  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  @ViewChild('successMessage')
  public readonly successMessage!: SwalComponent;
  @ViewChild('validationMessage')
  public readonly validationMessage!: SwalComponent;
  
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtTrigger: Subject<any> = new Subject();

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    var self = this;
   

    const id: number = parseInt(this.route.snapshot.paramMap.get('id'));
    this.currentId = id;

    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);
    this.isSimples = localStorage.getItem('isSimples');
    
    this.cfopIcms.id = 0;
    this.cfopIcms.idNatura =0;
    this.cfopIcms.cst = '';
    this.cfopIcms.modBC = 0;
    this.cfopIcms.modBCST = 0;
    this.cfopIcms.esquema= 0;
    this.cfopIcms.esquemaST= 0;
    this.cfopIcms.tpAliq= 0;
    this.cfopIcms.csosn = '';

    this.cfopIpi.id=0;
    this.cfopIpi.idNatura = 0;
    this.cfopIpi.cst = '';
    this.cfopIpi.esquema = 0;
    this.cfopIpi.tpAliq =0;
    this.cfopIpi.cEnq = '';

    this.cfopPis.id = 0;
    this.cfopPis.idNatura = 0;
    this.cfopPis.cst = '';
    this.cfopPis.esquema = 0;
    this.cfopPis.cstST = '';
    this.cfopPis.esquemaST= 0;
    this.cfopPis.tpAliq = 0;

    this.cfopCofins.id = 0;
    this.cfopCofins.idNatura= 0;
    this.cfopCofins.cst  = '';
    this.cfopCofins.esquema= 0;
    this.cfopCofins.cstST  = '';
    this.cfopCofins.esquemaST = 0;
    this.cfopCofins.tpAliq = 0;
    
    //Cfop
    this.fillStatus();
    $('#status').val('A').change();
    this.fillCreditoSN();
    $('#creditoSN').val('N').change();
    this.fillSomaFat();
    $('#somaFat').val('N').change();

    //Icms
    if(this.isSimples === 'S') {
      this.labelSituacaoTributaria = 'CSOSN';
      this.fillIcmsSituacaoTributaria('CSOSN');    
      $('#icmsSituacaoTributaria').val('101').change();
    } else {
      this.labelSituacaoTributaria = 'CST';
      this.fillIcmsSituacaoTributaria('ICMS');  
      $('#icmsSituacaoTributaria').val('00').change(); 
    };
    this.fillModalidadeBC();    
    $('#modalidadeBC').val(0).change(); 
    this.fillModalidadeBCST();    
    $('#modalidadeBCST').val(0).change(); 

    this.fillEsquema();
    $('#icmsEsquema').val(0).change(); 
    $('#icmsEsquemaST').val(0).change(); 

    this.fillTipoAliquota();
    $('#icmsTipoAliquota').val(0).change(); 
 
    //Ipi
    this.fillIpiSituacaoTributaria();    
    $('#ipiSituacaoTributaria').val('99').change();  
    $('#ipiEsquema').val(0).change(); 
    $('#ipiTipoAliquota').val(0).change(); 

    //Pis
    this.fillPisSituacaoTributaria();    
    $('#pisSituacaoTributaria').val('99').change();  
    $('#pisEsquema').val(0).change(); 
    $('#pisTipoAliquota').val(0).change(); 
    
    this.fillCofinsSituacaoTributaria();    
    $('#cofinsSituacaoTributaria').val('99').change();  
    $('#cofinsEsquema').val(0).change(); 
    $('#cofinsTipoAliquota').val(0).change(); 

    
    self.dtOptions = {
      searching:false,
      lengthChange:false,
    };
    
    this.route.data.subscribe(params => {

      if(id)
      {
    //    this.spinnerService.show();
        this.cfopService.getById(id).subscribe(response=> {
         // this.spinnerService.hide();
          this.cfop = response.data;
          if(this.cfop.isAtivo == 'S') {
            $('#status').val('A').change();
           } else {
             $('#status').val('I').change();
           };
           $('#creditoSN').val(this.cfop.permiteSN).change();
           $('#somaFat').val(this.cfop.somaFaturamento).change();

          //this.updateDataTable();
          
          // //CfopIcms
          this.cfopIcmsService.getById(id).subscribe(response=> {
            if(response.data != null) {
               this.cfopIcms = response.data;
            }

            if(this.isSimples === 'S'){
              $('#icmsSituacaoTributaria').val(this.cfopIcms.csosn).change();
            } else {
              $('#icmsSituacaoTributaria').val(this.cfopIcms.cst).change();
            };
            $('#icmsModalidade').val(this.cfopIcms.modBC).change();
            $('#icmsModalidadeST').val(this.cfopIcms.modBCST).change();
            $('#icmsEsquema').val(this.cfopIcms.esquema).change();
            $('#icmsEsquemaST').val(this.cfopIcms.esquemaST).change();
            $('#icmsTipoAliquota').val(this.cfopIcms.tpAliq).change();
          });

          //CfopIpi
          this.cfopIpiService.getById(id).subscribe(res => {
            if(res.data != null) {
                this.cfopIpi = res.data;
            };
             $('#ipiSituacaoTributaria').val(this.cfopIpi.cst).change();
             $('#ipiEsquema').val(this.cfopIpi.esquema).change();
             $('#ipiTipoAliquota').val(this.cfopIpi.tpAliq).change();
          });

          //CfopPis
          this.cfopPisService.getById(id).subscribe(res => {
            if(res.data != null) {
               this.cfopPis = res.data;
            };
             $('#pisSituacaoTributaria').val(this.cfopPis.cst).change();
             $('#pisEsquema').val(this.cfopPis.esquema).change();
             $('#pisTipoAliquota').val(this.cfopPis.tpAliq).change();
          });

          //CfopCofins
           this.cfopCofinsService.getById(id).subscribe(res => {
            if(res.data != null) {
               this.cfopCofins = res.data;
            };
            $('#cofinsSituacaoTributaria').val(this.cfopCofins.cst).change();
            $('#cofinsEsquema').val(this.cfopCofins.esquema).change();
            $('#cofinsTipoAliquota').val(this.cfopCofins.tpAliq).change();
          });
        }, error => {
          if(error.status == 401){
             self.loginService.tokenExpired();
          }
        });
      };
    });
    this.title = 'Cfop';
    this.description = 'Cadastro de impostos'

//    $('.select2').select2();
  }
  
  fillTipoAliquota() {
    var tipoData = [
      {'id': "0", 'text': 'Alíquota ZERO'},
      {'id': "1", 'text': 'Calculada'}
      
     ];
    $('#icmsTipoAliquota').select2({
      data: tipoData,
      allowClear: false,
      minimumResultsForSearch: -1
    });
    $('#ipiTipoAliquota').select2({
      data: tipoData,
      allowClear: false,
      minimumResultsForSearch: -1
    });
    $('#pisTipoAliquota').select2({
      data: tipoData,
      allowClear: false,
      minimumResultsForSearch: -1
    });
    $('#cofinsTipoAliquota').select2({
      data: tipoData,
      allowClear: false,
      minimumResultsForSearch: -1
    });
  }

  fillEsquema() {
    this.icmsEsquemaService.get().subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.id || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#icmsEsquema').select2({
        data: finalData,
        placeholder: 'Esquema de cálculo'
      });
      $('#icmsEsquemaST').select2({
        data: finalData,
        placeholder: 'Esquema de cálculo S.T.'
      });
      $('#ipiEsquema').select2({
        data: finalData,
        placeholder: 'Esquema de cálculo'
      });
      $('#pisEsquema').select2({
        data: finalData,
        placeholder: 'Esquema de cálculo'
      });
      $('#cofinsEsquema').select2({
        data: finalData,
        placeholder: 'Esquema de cálculo'
      });
    }, error => {
      if(error.status == 401){
        this.loginService.tokenExpired();
      }
    });
  }

  fillIcmsSituacaoTributaria(tipo:string) {
    this.icmsSituacaoTributariaService.get(tipo).subscribe(data=> {
      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.codigo || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#icmsSituacaoTributaria').select2({
        data: finalData,
        placeholder: 'Situação tributária'
      });
    }, error => {
      if(error.status == 401){
        this.loginService.tokenExpired();
      }
    });
 }
  
  fillIpiSituacaoTributaria() {
    this.ipiSituacaoTributariaService.get('IPI').subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.codigo || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        // if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#ipiSituacaoTributaria').select2({
        data: finalData,
        placeholder: 'Situação tributária'
      });
    }, error => {
      if(error.status == 401){
        this.loginService.tokenExpired();
      }
    });
  }

  fillPisSituacaoTributaria() {
    this.pisSituacaoTributariaService.get('PIS').subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.codigo || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        // if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#pisSituacaoTributaria').select2({
        data: finalData,
        placeholder: 'Situação tributária'
      });
    }, error => {
      if(error.status == 401){
        this.loginService.tokenExpired();
      }
    });
  }

  fillCofinsSituacaoTributaria() {
    this.cofinsSituacaoTributariaService.get('PIS').subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.codigo || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        // if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#cofinsSituacaoTributaria').select2({
        data: finalData,
        placeholder: 'Situação tributária'
      });
    }, error => {
      if(error.status == 401){
        this.loginService.tokenExpired();
      }
    });
  }

  fillModalidadeBC() {
    this.modalidadeBCService.get().subscribe(data=> {
      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.id || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#modalidadeBC').select2({
        data: finalData,
        placeholder: 'Modalidade'
      });
    }, error => {
      if(error.status == 401){
        this.loginService.tokenExpired();
      }
    });
  }

  
  fillModalidadeBCST() {
    this.modalidadeBCSTService.get().subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.id || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#modalidadeBCST').select2({
        data: finalData,
        placeholder: 'Modalidade'
      });
    }, error => {
      if(error.status == 401){
        this.loginService.tokenExpired();
      }
    });
  };

 fillStatus(){
    var statusData = [
      {'id': "A", 'text': 'Ativo'},
      {'id': "I", 'text': 'Inativo'},
     ];
    $('#status').select2({
      data: statusData,
      allowClear: true,
      minimumResultsForSearch: -1
    });
  }
  
 fillCreditoSN(){
    var creditoData = [
      {'id': "S", 'text': 'Sim'},
      {'id': "N", 'text': 'Não'},
     ];
    $('#creditoSN').select2({
      data: creditoData,
      allowClear: true,
      minimumResultsForSearch: -1
    });
 }
  
 fillSomaFat(){
  var somaData = [
    {'id': "S", 'text': 'Sim'},
    {'id': "N", 'text': 'Não'},
   ];
  $('#somaFat').select2({
    data: somaData,
    allowClear: true,
    minimumResultsForSearch: -1
  });
}

  editEntity(data) {
    var self = this;
  }

  deleteEntity(data) {
     this.deleteConfirmationMessage.fire();
  }

  confirmDelete() {
    this.updateDataTable();
  }

  ngOnDestroy() {
    window.functions = null;
    this.dtTrigger.unsubscribe();
  }

  updateDataTable() {

    var self = this;
    
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  cancel() {
      this.router.navigate(['/cadastros/cfop']);    
  }

  async save() {
    var self = this;
    this.cfop.isAtivo = ($('#status').val() == 'A' ? 'S': 'N');
    this.cfop.permiteSN = $('#creditoSN').val();
    this.cfop.somaFaturamento = $('#somaFat').val();

    //Icms
    if(this.isSimples === 'S'){
       this.cfopIcms.csosn = $('#icmsSituacaoTributaria').val();
    } else {
      this.cfopIcms.cst = $('#icmsSituacaoTributaria').val();
    };
    this.cfopIcms.modBC = $('#modalidadeBC').val();
    this.cfopIcms.modBCST = $('#modalidadeBCST').val();
    this.cfopIcms.esquema = $('#icmsEsquema').val();
    this.cfopIcms.esquemaST = $('#icmsEsquemaST').val();
    this.cfopIcms.tpAliq = $('#icmsTipoAliquota').val();

    //Ipi
    console.log($('#ipiSituacaoTributaria').val());
    console.log(this.cfopIpi.cst);
    this.cfopIpi.esquema = $('#ipiEsquema').val();
    this.cfopIpi.cst = $('#ipiSituacaoTributaria').val();
    this.cfopIpi.tpAliq = $('#ipiTipoAliquota').val();

    //Pis
    this.cfopPis.esquema = $('#pisEsquema').val();
    this.cfopPis.cst = $('#pisSituacaoTributaria').val();
    this.cfopPis.tpAliq = $('#pisTipoAliquota').val();

    //Cofins
    this.cfopCofins.esquema = $('#cofinsEsquema').val();
    this.cfopCofins.cst = $('#cofinsSituacaoTributaria').val();
    this.cfopCofins.tpAliq = $('#cofinsTipoAliquota').val();
    
    if( (this.cfop.cfop == '') || (this.cfop.nome == '') ) {
      this.validationMessage.fire();
    } else {
       this.spinnerService.show();
       if(this.cfop.id > 0)  {
          await this.cfopService.update(this.cfop).subscribe(response => {
          
            this.saveCfopIcms();
            this.saveCfopIpi();
            this.saveCfopPis();
            this.saveCfopCofins();
            this.spinnerService.hide();
            this.successMessage.fire();
          }, error => {
            this.errorMessage.fire();
          });
        } else {

          this.cfopService.create(this.cfop).subscribe(id=> {
            this.spinnerService.hide();
            this.cfop.id = id.id;
            this.saveCfopIcms();
            this.saveCfopIpi();
            this.saveCfopPis();
            this.saveCfopCofins();
            this.successMessage.fire();
          }, error => {
            this.errorMessage.fire();
          });
        }
        this.router.navigate(['/cadastros/cfop']); 
    }
  }

  async saveCfopIcms(){
    console.log(this.cfopIcms);
    if(this.cfopIcms.id > 0) {
      await this.cfopIcmsService.update(this.cfopIcms).subscribe(response => {
      });
    } else {
      this.cfopIcms.idNatura = this.cfop.id;
      this.cfopIcmsService.create(this.cfopIcms).subscribe(id=> {
      }, error => {
      });
    }
  }

  async saveCfopIpi(){
    console.log(this.cfopIpi);
    if(this.cfopIpi.id > 0) {
      await this.cfopIpiService.update(this.cfopIpi).subscribe(response => {
      });
    } else {
      this.cfopIpi.idNatura = this.cfop.id;
      this.cfopIpiService.create(this.cfopIpi).subscribe(id=> {
      }, error => {
      });
    }
  }

  async saveCfopPis(){
    console.log(this.cfopPis);
    if(this.cfopPis.id > 0) {
      await this.cfopPisService.update(this.cfopPis).subscribe(response => {
      });
    } else {
      this.cfopPis.idNatura = this.cfop.id;
      this.cfopPisService.create(this.cfopPis).subscribe(id=> {
      }, error => {
      });
    }
  }

  async saveCfopCofins(){
    console.log(this.cfopCofins);
    if(this.cfopCofins.id > 0) {
      await this.cfopCofinsService.update(this.cfopCofins).subscribe(response => {
      });
    } else {
      this.cfopCofins.idNatura = this.cfop.id;
      this.cfopCofinsService.create(this.cfopCofins).subscribe(id=> {
      }, error => {
      });
    }
  }
  handleDenial() {

  }


}
