import { Component, OnInit, AfterViewInit } from "@angular/core";
import { routerTransition } from "../util/animations";

declare function initMe(): any;
declare var $:any

@Component({
  selector: "app-login-layout",
  templateUrl: "./login-layout.component.html",
  styles: [],
  animations: [routerTransition]
})
export class LoginLayoutComponent implements OnInit, AfterViewInit {
  constructor() {}
  ngAfterViewInit(): void {

    $.extend( true, $.fn.dataTable.defaults, {
      "searching": false,
      "ordering": false,
      "language":  {
        url: "https://cdn.datatables.net/plug-ins/1.10.22/i18n/Portuguese-Brasil.json"
      }
    });


    initMe();
  }

  ngOnInit() {}

  getState(outlet) {
    if(!outlet.activatedRoute) return;
    let ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }
}
