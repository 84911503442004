import { Component, OnInit, AfterViewInit, ViewChild} from "@angular/core";
import {Location} from '@angular/common';
import { EntidadeAdmService } from "src/app/services/entidadeAdm.service";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { Relatorio1Service } from 'src/app/services/relatorios/relatorio1.service';

declare var $:any;


@Component({
  selector: 'sa-relatorio1',
  templateUrl: './relatorio1.component.html',
})
export class Relatorio1Component implements OnInit {

  uploadForm: FormGroup;  

  
  constructor(
    private relatorio1Service: Relatorio1Service
  ) {     
  }

  ngAfterViewInit(): void {
  }  
             
 // ngOnInit() {
 // }

  ngOnInit(): void {

    $('#filterData1').daterangepicker(
        {
        "locale": {
            "format": "DD/MM/YYYY",
            "separator": " - ",
            "applyLabel": "Aplicar",
            "cancelLabel": "Cancelar",
            "fromLabel": "De",
            "toLabel": "Até",
            "customRangeLabel": "Custom",
            "daysOfWeek": [
                "Dom",
                "Seg",
                "Ter",
                "Qua",
                "Qui",
                "Sex",
                "Sáb"
            ],
            "monthNames": [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro"
            ],
            "firstDay": 0,
            // "autoUpdateInput": false,
            // "clearBtn": true,
        }}
    );


    }

    async gerarRelatorio(){
    
        let isCancelada = $('#isCancelada');
        let isTransmitida = $('#isTransmitida')
        if(isCancelada.is(":checked") || isTransmitida.is(":checked")) {
            var tipo;
            if(isCancelada.is(":checked") && isTransmitida.is(":checked")) {
                tipo = 'X';
            } else if(isTransmitida.is(":checked")) {
                tipo = 'T';
            } else if(isCancelada.is(":checked")) {
                tipo = 'C';
            };
            var relato = {
                idEmpresa: localStorage.getItem('idEmpresa'),
                tipo: tipo,    
                data1:  $('#filterData1').data('daterangepicker').startDate.format('YYYY-MM-DD') + ' 00:00:00',
                data2: $('#filterData1').data('daterangepicker').endDate.format('YYYY-MM-DD')  + ' 23:59:59',
        
              //  data1: "2021-10-01 00:00:00",
              //  data2: "2021-11-15 23:59:59"
            }
            console.log(relato);
            await this.relatorio1Service.relatorio1(relato);
            // this.relatorio1Service.relatorio1(relato).subscribe((res) => {
            //     console.log(res);
            // });
            
        } else {
            Swal.fire({
                title:  "Ops...",
                text: "Selecione pelo menos um status da NF-e",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
              });
            // alert("Nenhum checkbox selecionado")
        }
    }
   
}
