import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthToken } from './model/authToken';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { Volume, VolumeResponse } from './model/volume';

@Injectable({
  providedIn: 'root'
})
export class VolumeService {
  constructor(private httpClient: HttpClient,
    private loginService: LoginService,
    private router: Router) { }

  servicePath: string = '/volume'

  url:string = environment.apiUri + this.servicePath;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-access-token': this.loginService.getToken() })
  }

  delete(id:number) : Observable<any> {
    return this.httpClient.delete(this.url + '/' + id, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  get(idNotafi:number) : Observable<VolumeResponse> {
    return this.httpClient.get<VolumeResponse>(this.url + '?idNotafi=' + idNotafi, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  getById(id:number) : Observable<VolumeResponse> {
    return this.httpClient.get<VolumeResponse>(this.url + '/' + id, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  create(entity:Volume) : Observable<any> {
    return this.httpClient.post(this.url, entity, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  update(entity:Volume) : Observable<any> {
    return this.httpClient.put(this.url + '/' + entity.id,entity, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(error);
  };

}
