<!-- <ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item"><a href="" routerLink="/">Kiron</a></li>
  <li class="breadcrumb-item">Cadastros</li>
  <li class="breadcrumb-item active">{{title}}</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol> -->
<div class="subheader">
  <h1 class="subheader-title">
      <i class='subheader-icon fal fa-shopping-basket'></i> {{title}}
      <small>
          {{description}}
      </small>
  </h1>
</div>
<div class="row">

  <div class="col-md-12">
    <div class="input-group bg-white shadow-inset-1 col-md-4">
      <!-- <input type="text" class="form-control border-left-1 bg-transparent pl-2"  (blur)="buscarCliente()" placeholder="Pesquisar...">           -->
      <input type="text" (keyup.enter)="searchLista()" class="form-control border-left-1 bg-transparent pl-2" [(ngModel)]="search" id="search"  placeholder="Pesquisar...">          
        <div class="input-group-prepend">
          <button class="btn btn-primary" (click)="searchLista()">
            <i class="fal fa-search"></i>
          </button>
        </div>      
    </div>
    <br>
  </div>
  <div class="col-xl-12">
      <div id="panel-1" class="panel">
          <div class="panel-hdr">
              <h2>
                  {{title}} <span class="fw-300"></span>
              </h2>
              <div class="panel-toolbar">
                <button (click)="new()" type="button" class="btn btn-sm btn-primary">
                  <span class="fal fa-plus mr-1"></span>
                  Novo
                </button>
              </div>
          </div>
          <div class="panel-container show">
              <div class="panel-content">
                  <nav class="nav nav-pills">
                    <a (click)='changeAtivoFilter(true)' class="nav-item nav-link active btn-xs" href="#" data-toggle="pill">Ativo</a>
                    <a (click)='changeAtivoFilter(false)' class="nav-item nav-link btn-xs" href="#" data-toggle="pill">Inativo</a>

                    <!-- <a class="nav-item nav-link active btn-xs" href="#" data-toggle="pill">Ativo</a>
                    <a class="nav-item nav-link btn-xs" href="#" data-toggle="pill">Inativo</a> -->
                  </nav>
                  <br/>
                  <!-- datatable start -->
                  <!-- <table datatable class="table table-bordered table-hover table-striped w-100"> -->
                    <table [dtOptions]='dtOptions'  datatable id="tableList" class="table table-hover table-striped w-100">
                      <thead class="bg-primary-600">
                        <tr>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Un</th>
                            <th>Ncm</th>
                            <th>Preço</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr>
                            <td>Tiger Nixon</td>
                            <td>System Architect</td>
                            <td>61</td>
                            <td>
                              <div class='text-center'>
                                <a href='javascript:void(0);' class='btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1' title='Edit Record'>
                                  <i class="fal fa-pen"></i>
                                </a>
                                <a href='javascript:void(0);' class='btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1' title='Delete Record'>
                                  <i class="fal fa-times"></i>
                                </a>
                            </div>
                          </td>
                        </tr> -->
                    </tbody>
                </table>
                  <!-- datatable end -->
              </div>
          </div>
      </div>
  </div>
</div>
<swal #deleteConfirmationMessage showDenyButton="true" (deny)="handleDenial()" (confirm)="confirmDelete()" denyButtonText="Cancelar" showConfirmButton="true" confirmButtonText="Confirmar" title="Excluir?" text="Tem certeza que deseja excluir este registro?" icon="warning"></swal>
<swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde." icon="error"></swal>


