import {ModuleWithProviders} from "@angular/core"
import {Routes, RouterModule} from '@angular/router';
import { ClientesFornecedoresModule } from './clientesfornecedores/clientesfornecedores.module'
import { ProdutosModule } from './produtos/produtos.module'
import { CfopModule } from './cfop/cfop.module'
import { UsuariosModule } from './usuarios/usuarios.module';
import { NcmModule } from "./ncm/ncm.module";
import { ArtigoModule } from "./artigo/artigo.module";
import { FaturaModule } from "./fatura/fatura.module";

export const routes: Routes = [
  {
    path: '', redirectTo: 'clientes', pathMatch: 'full'
  },
  {
    path: 'clientes',
    loadChildren:()=> ClientesFornecedoresModule,
    data: { type: 'cliente' }
  },
  {
    path: 'fornecedores',
    loadChildren:()=> ClientesFornecedoresModule,
    data: { type: 'fornecedor' }
  },
  {
    path: 'transportadoras',
    loadChildren:()=> ClientesFornecedoresModule,
    data: { type: 'transportadora' }
  },
  {
    path: 'produtos',
    loadChildren:()=> ProdutosModule,
  },
  {
    path: 'ncm',
    loadChildren:()=> NcmModule,
  },
  {
    path: 'usuarios',
    loadChildren:()=> UsuariosModule,
  },
  {
    path: 'cfop',
    loadChildren:()=> CfopModule,
  },
  {
    path: 'artigo',
    loadChildren:()=> ArtigoModule,
  },
  {
    path: 'fatura',
    loadChildren:()=> FaturaModule,
  }
  
];

export const routing = RouterModule.forChild(routes);
