import { Component, OnInit, AfterViewInit,ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DataTableDirective } from "angular-datatables";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { LoginService } from "src/app/services/login.service";
import { routerTransition } from "../../../../util/animations";
import { ArtigoService } from "src/app/services/artigo.service";
import { Artigo } from "src/app/services/model/artigo";

declare var $:any
declare var localStorage:any;

@Component({
  selector: "ci-editor",
  templateUrl: "./artigo.editor.component.html",
  styles: [],
  animations: [routerTransition]
})
export class ArtigoEditorComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private spinnerService: NgxSpinnerService,
    private artigoService: ArtigoService,
  ) {}

  title: string;
  description:string;
  isAtivo: boolean;
  dtOptions: DataTables.Settings = {};
  currentId: number = -1;
  labelSituacaoTributaria: string;
  
  artigo: Artigo = {} as Artigo;
  isSimples: string = 'S';
 
  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  @ViewChild('successMessage')
  public readonly successMessage!: SwalComponent;
  @ViewChild('validationMessage')
  public readonly validationMessage!: SwalComponent;
  
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtTrigger: Subject<any> = new Subject();

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    var self = this;
   

    const id: number = parseInt(this.route.snapshot.paramMap.get('id'));
    this.currentId = id;

    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);
    this.isSimples = localStorage.getItem('isSimples');

    this.fillStatus();
    $('#status').val('A').change();

    this.fillCampos();
    $('#nome').val(0).change(); 
    $('#obs').val(0).change(); 

    
    self.dtOptions = {
      searching:false,
      lengthChange:false,
    };
    
    this.route.data.subscribe(params => {
        if(id) 
        {
          this.spinnerService.show();
          this.artigoService.getById(id).subscribe(response=> {
            this.spinnerService.hide();
            this.artigo = response.data;
            //this.updateDataTable();
            // $('#origemproduto').val(this.artigo.id).change();
            if(this.artigo.isAtivo == 'S') {
              $('#status').val('A').change();
           } else {
              $('#status').val('I').change();
           };
          }, error => {
            if(error.status == 401){
              self.loginService.tokenExpired();
            }
          })
        } else {
          this.ultimoId();
        } 

    });
    this.title = 'Artigo';
    this.description = 'Cadastro de artigos'

//    $('.select2').select2();
  }
  
  fillCampos() {
  }

  async ultimoId(){
    await this.artigoService.getUltimoid().subscribe(response=> {
        const codigo = response.id;
        this.artigo.id = codigo.toString();
        console.log(codigo);
      });
    
}

 fillStatus(){
    var statusData = [
      {'id': "A", 'text': 'Ativo'},
      {'id': "I", 'text': 'Inativo'},
     ];
    $('#status').select2({
      data: statusData,
      allowClear: true,
      minimumResultsForSearch: -1
    });
  }

  editEntity(data) {
    var self = this;
  }

  deleteEntity(data) {
     this.deleteConfirmationMessage.fire();
  }

  confirmDelete() {
    this.updateDataTable();
  }

  ngOnDestroy() {
    window.functions = null;
    this.dtTrigger.unsubscribe();
  }

  updateDataTable() {

    var self = this;
    
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  cancel() {
      this.router.navigate(['/cadastros/artigo']);    
  }

  save() {
    var self = this;

    if((this.artigo.nome == '') || (this.artigo.obs == '')) {
      this.validationMessage.fire();
    } else {

        this.spinnerService.show();
        this.artigo.nome = $('#nome').val()
        this.artigo.obs = $('#obs').val()
        this.artigo.isAtivo = ($('#status').val() == 'A' ? 'S': 'N');


        if(this.artigo.id > 0) {
          this.artigoService.update(this.artigo).subscribe(response => {
            this.spinnerService.hide();
            this.successMessage.fire();
            this.router.navigate(['/cadastros/artigo']);
          }, error => {
            // Swal.fire({
            //     title:  "Erro!",
            //     text: this.msgErroServer + error.error.message,
            //     icon: 'error'
            // });
            alert("Deu erro")
          });
        } else {
          this.artigoService.create(this.artigo).subscribe(id=> {
            this.spinnerService.hide();
            this.successMessage.fire();
            this.router.navigate(['/cadastros/artigo']);
        }, error => {
            this.errorMessage.fire();
          });
        }
      }
  }


  handleDenial() {

  }


}
