import { Component, OnInit, AfterViewInit, ViewChild} from "@angular/core";
import { routerTransition } from "../../../util/animations";
import { LoginService } from 'src/app/services/login.service';
import { EntidadeService } from 'src/app/services/entidade.service';
import { SwalComponent, SweetAlert2LoaderService } from '@sweetalert2/ngx-sweetalert2';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

import { NFe } from "src/app/services/model/nfe";
import { NFeService } from 'src/app/services/nfe.service';
import { ItemNFe } from "src/app/services/model/itemNFe";
import { ItemNFeService } from 'src/app/services/itemNFe.service';
import { ItemIcms } from "src/app/services/model/itemIcms";
import { ItemIcmsService } from 'src/app/services/itemIcms.service';
import { ItemIpi } from "src/app/services/model/itemIpi";
import { ItemIpiService } from 'src/app/services/itemIpi.service';
import { ItemPis } from "src/app/services/model/itemPis";
import { ItemPisService } from 'src/app/services/itemPis.service';
import { ItemCofins } from "src/app/services/model/itemCofins";
import { ItemCofinsService } from 'src/app/services/itemCofins.service';
import { SituacaoTributariaService } from "src/app/services/situacaoTributaria.service";
import { EstadoService } from "src/app/services/estado.service";
import { Volume } from "src/app/services/model/volume";
import { VolumeService } from "src/app/services/volume.service";
import { MeioPagtoService } from "src/app/services/meioPagto.service";
import { CfopService } from "src/app/services/cfop.service";
import { Duplic } from "src/app/services/model/duplic";
import { DuplicService } from "src/app/services/duplic.service";
import { NFeRefere } from "src/app/services/model/nfeRefere";
import { NFeRefereService } from "src/app/services/nfeRefere.service";
import { MicroService } from "src/app/services/NFe/microService.service";
import { ArtigoService } from "src/app/services/artigo.service";
import { ItemMed, ItemMedResponse } from './../../../services/model/itemMed';
import { ItemMedService } from "src/app/services/itemMed.service";
import { environment } from 'src/environments/environment';


declare var $:any
declare var localStorage:any;

@Component({
  selector: "fat-editor",
  templateUrl: "./nfe.editor.component.html",
  styles: [],
  animations: [routerTransition]
})
export class NFeEditorComponent implements OnInit, AfterViewInit {
  constructor(
     
     private route: ActivatedRoute,
     private router: Router,
     private loginService: LoginService,
     private entidadeService: EntidadeService,
     private itemNFeService: ItemNFeService,
     private itemIcmsService: ItemIcmsService,
     private itemIpiService: ItemIpiService,
     private itemPisService: ItemPisService,
     private itemCofinsService: ItemCofinsService,
     private nfeService: NFeService,
     private spinnerService: NgxSpinnerService,
     private icmsSituacaoTributariaService:  SituacaoTributariaService,
     private ipiSituacaoTributariaService: SituacaoTributariaService,
     private pisSituacaoTributariaService: SituacaoTributariaService,
     private cofinsSituacaoTributariaService: SituacaoTributariaService,
     private estadoService: EstadoService,
     private volumeService: VolumeService,
     private meioPagtoService: MeioPagtoService,
     private duplicService: DuplicService,
     private nfeRefereService: NFeRefereService,
     private cfopService: CfopService,
     private artigoService: ArtigoService,
     private itemMedService: ItemMedService,
     private microService: MicroService,
     public datepipe: DatePipe,
  
 
     ) {}

  dtOptions: DataTables.Settings = {};
  dtOptionsCobranca: DataTables.Settings = {};
  dtOptionsNFeReferenciada: DataTables.Settings = {};
  dtOptionsVolumes: DataTables.Settings = {};
  dtOptionsItemMed: DataTables.Settings = {};
  dtOptionsMed: DataTables.Settings = {};

  idNotafi: number;
  idItemNot: number;
  nfe: NFe = {} as NFe;
  itemNFe : ItemNFe = {} as ItemNFe;
  itemIcms: ItemIcms = {} as ItemIcms;
  itemIpi: ItemIpi = {} as ItemIpi;
  itemPis: ItemPis = {} as ItemPis;
  itemCofins: ItemCofins = {} as ItemCofins;
  volume: Volume = {} as Volume;
  duplic: Duplic = {} as Duplic;
  nfeRefere: NFeRefere = {} as NFeRefere;
  itemMed: ItemMed = {} as ItemMed;
  week = [];
  disableForm: boolean = false;
  disableValorFrete: boolean = false;
  uf: string;
  tpOperacao: number;


  isSimples: string = 'S';
  labelSituacaoTributaria: string = 'CSOSN';
  msgSpinner: string;
  vUnCom: number = 2;

  hrEmissao: string;
  
  total: number;
  itemNFeqtda: number;
  placeProduto: string;
  // placeCfop: string;
  placeDestinatario: string;
  placeTransportadora: string;
  placeSituacaoTributaria: string;
  msgErroServer: string = "Ops, aconteceu algum imprevisto no servidor. ";
  currentItem: number;
  currentNFeRefere: number;
  currentVolume: number;
  repeatCfop: number = 0;
  chqIcmsAtualizar: boolean = true;
  chqIpiAtualizar: boolean = true;
  chqPisAtualizar: boolean = true;
  chqCofinsAtualizar: boolean = true;
  isNew: boolean;
  isInitModalFrete: boolean;
  placeArtigo: string;
  

  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  @ViewChild('deleteConfirmationNFeRefere')
  public readonly deleteConfirmationNFeRefere!: SwalComponent;
  @ViewChild('deleteConfirmationVolume')
  public readonly deleteConfirmationVolume!: SwalComponent; 

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  ngAfterViewInit(): void {
    this.dtOptions.lengthChange = false;
    this.inputMask();
  }
  
 
  ngOnInit() {
    var self = this;

    const id: number = parseInt(this.route.snapshot.paramMap.get('id'));
    if(id > 0 ) {
      this.idNotafi = id;
      this.isNew = false;
    } else {
      this.idNotafi = 0;
      this.isNew = true;
    }

    const hoje = new Date();
    var dateNow = new Date();
    //let latest_date =this.datepipe.transform(hoje, 'yyyy-MM-dd');
    
    this.nfe.dtEmissao = hoje;
    this.nfe.dtBase = hoje;
    //  $('#horaEmissao').val(hoje.getHours()+':'+hoje.getMinutes()).change();
    this.hrEmissao = ("0" + dateNow.getHours()).slice(-2)+':'+("0" + dateNow.getMinutes()).slice(-2);

     this.week[0] ='Dom';
     this.week[1] ='Seg';
     this.week[2] ='Ter';
     this.week[3] ='Qua';
     this.week[4] ='Qui';
     this.week[5] ='Sex';
     this.week[6] ='Sab';

    window.functions = window.functions || {};
    window.functions.editItem = this.editItem.bind(this);
    window.functions.deleteItem = this.deleteItem.bind(this);
    window.functions.impostoItem = this.impostoItem.bind(this);
    window.functions.editCobranca = this.editCobranca.bind(this);
    window.functions.deleteNFeRefere = this.deleteNFeRefere.bind(this);
    window.functions.editVolume = this.editVolume.bind(this);
    window.functions.deleteVolume = this.deleteVolume.bind(this);
    window.functions.editItemMed = this.editItemMed.bind(this);
    window.functions.deleteItemMed = this.deleteItemMed.bind(this);
    window.functions.modalCombustiveis = this.modalCombustiveis.bind(this);
    window.functions.modalMedicamentos = this.modalMedicamentos.bind(this);


    //Iniciar Recursos e variáveis
    this.isSimples = localStorage.getItem('isSimples');
    this.vUnCom = localStorage.getItem('vUnCom');
    this.nfe.idEntidade = 0;
    this.placeDestinatario = 'Pesquise o destinatário...';
    this.nfe.nParcelas = 1;
    this.nfe.intervalo = 0;
    this.nfe.vEntrada = 0;
    this.nfe.entsai = 'S';
    this.fillFinalidade();
    $('#finalidadeNota').val(1).change();
    this.fillCondicoesPagto();
   // this.tpOperacao = 1;
    this.nfe.idDest = 1;
    this.isInitModalFrete = false;
    this.placeArtigo = 'Pesquise a observação...';
    this.idItemNot = 0;


    //Icms
    this.fillIpiSituacaoTributaria();
    this.fillPisSituacaoTributaria();
    this.fillCofinsSituacaoTributaria();

    //Transportadora
    $('#select2Transportadora').empty();
    this.fillEstado();
    //$('#ufTransportadora').empty();
    this.disableValorFrete = true;

    this.fillMeioPagto();
    $('#meioPagto').val(1).change();

    $('#exportacao').hide();

    this.route.data.subscribe(params => {

      if(this.idNotafi > 0)
      {
        this.spinnerService.show();
        this.nfeService.getById(this.idNotafi).subscribe(response=> {
          this.spinnerService.hide();
          this.nfe = response.data;
          this.placeDestinatario = response.data.entidade.nome;
          this.uf = response.data.entidade.uf;
          if(response.data.transportadora != null){
             this.placeTransportadora = response.data.transportadora.nome;
          }
          //   this.updateDataTable();

          var data1 = new Date(this.nfe.dtEmissao);
          this.hrEmissao = ("0" + data1.getHours()).slice(-2)+':'+("0" + data1.getMinutes()).slice(-2);
 
          this.searchDestinatario();
          this.bindNFe();
          this.disableForm = this.nfe.idStatus !== 0 ? true : false;
          this.disableValorFrete = this.nfe.tpRateioFrete == 0 ? true : false;
          this.visualizar();
        }, error => {
          this.spinnerService.hide();
          if(error.status == 401){
            self.loginService.tokenExpired();
          }
        });
      } else  {
        this.visualizar();
      };

          //Itens da Nota
          this.dtOptions = {
            processing:true,
            serverSide:true,
            ajax: {
            //  url: 'https://kironwebapi.azurewebsites.net/itemnot',
              url: environment.apiUri + '/itemnot',
              data: function (d) {
                var queryData = {
                  page: d['start'] / d['length'] + 1,
                  pageSize:d['length'],
                  idNotafi: self.idNotafi,
                }
              // self.currentPage = queryData.page;
              // self.currentPageSize = queryData.pageSize;
                return queryData;
              },
              
              dataSrc : function(json) : any {
                json.recordsTotal = json.count;
                json.recordsFiltered = json.count
                return json.data;
              },
              async: true,
              error: function (xhr, error, code){},
              headers: { 'x-access-token': this.loginService.getToken() }
              
            },
            columns : [
              { "data": "produto.codigo" },
              { "data": "produto.nome"},
              { "data": "codigoUnimed" },
              { "data": "cfop.cfop" },
              { "data": "ncm" },
              { "data": "qtda", render: $.fn.dataTable.render.number( '.', ',', 2, '' ) },
              { "data": "preco", render: $.fn.dataTable.render.number( '.', ',', 4, 'R$ ' ) },
              { "data": "vProd", render: $.fn.dataTable.render.number( '.', ',', 2, 'R$ ' ) },
              {
                "data": "id",
                render: (data, type, full) => {
                  return  `<div class='text-center'>
                          <a href='javascript:void(0);' onclick=\"functions.editItem('${data}')\" class='btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1' title='Editar item' data-toggle="modal" ><i class="fal fa-pen"></i></a>
                          <a href='javascript:void(0);' onclick=\"functions.impostoItem('${data}')\"class='btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1' title='Impostos' data-toggle="modal"><i class="fal fa-coins"></i></a> 

                          <div class='dropdown d-inline-block position-relative'>
                            <a class='btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1' id="maisOpcoes" data-toggle='dropdown' aria-expanded='false' title='Mais opções'>
                            <i class="fal fa-list" style="color:#0C85E2"></i>
                            </a>
                            <div class='dropdown-menu position-absolute pos-top pos-right' style='position-absolute pos-top pos-right'>
                            <!--   <a class='dropdown-item' href='javascript:void(0);' onclick=\"functions.modalCombustiveis('${data}')\">Combustivel</a> -->
                              <a class='dropdown-item' href='javascript:void(0);' onclick=\"functions.modalMedicamentos('${data}')\">Medicamentos</a>
                            </div>
                          </div>

                           
                          <a href='javascript:void(0);' onclick=\"functions.deleteItem('${data}')\" class='btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1' title='Excluir item'><i class='fal fa-times'></i></a></div>`;
                }
              }
          ],
          };
    

      //Cobrança
      this.dtOptionsCobranca = {
        processing:true,
        serverSide:true,
        ajax: {
          url: environment.apiUri + '/duplic',
          data: function (d) {
            var queryData = {
              page: d['start'] / d['length'] + 1,
              pageSize:d['length'],
              idNotafi: self.idNotafi,
            }
          // self.currentPage = queryData.page;
          // self.currentPageSize = queryData.pageSize;
            return queryData;
          },
          
          dataSrc : function(json) : any {
            json.recordsTotal = json.count;
            json.recordsFiltered = json.count
            return json.data;
          },
          async: true,
          error: function (xhr, error, code) { },
          // statusCode: {
          //   401: function() {
          //     self.loginService.tokenExpired();
          //    },
          // },
          headers: { 'x-access-token': this.loginService.getToken() }
          
        },
        columns : [
          { "data": "parcela" },
          { "data": "vencto", render: function(data){ return moment(data).format("DD/MM/YYYY") + ' - ' + 
                      self.week[ moment(data).day() ]}},
          { "data": "valor", render: $.fn.dataTable.render.number( '.', ',', 2, 'R$ ' ) },
          { "data": "meiopagto.nome" },
          {
            "data": "id",
            render: (data, type, full) => {
              return `<div class='text-center'>
                      <a href='javascript:void(0);' onclick=\"functions.editCobranca('${data}')\" class='btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1' title='Editar item' data-toggle="modal" ><i class="fal fa-pen"></i></a></div>`;
            }
          }
      ],
      };
      //NFeReferenciada
      this.dtOptionsNFeReferenciada = {
        processing:false,
        serverSide:true,
         ajax: {
      //    url: 'https://kironwebapi.azurewebsites.net/nfeRefere',
          url: environment.apiUri + '/nfeRefere',
          data: function (d) {
            var queryData = {
              page: d['start'] / d['length'] + 1,
              pageSize:d['length'],
              idNotafi: self.idNotafi,
            }
          // self.currentPage = queryData.page;
          // self.currentPageSize = queryData.pageSize;
            return queryData;
          },
          
          dataSrc : function(json) : any {
            json.recordsTotal = json.count;
            json.recordsFiltered = json.count
            return json.data;
          },
          async: true,
          error: function (xhr, error, code){},
          headers: { 'x-access-token': this.loginService.getToken() }
          
        },
        columns : [
          { "data": "refNFe" },
          {
            "data": "id",
            render: (data, type, full) => {
              return `<div class='text-center'>
                          <a href='javascript:void(0);' onclick=\"functions.deleteNFeRefere('${data}')\" class='btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1' title='Excluir registro'><i class='fal fa-times'></i></a></div>`;
              ;
            }
          }
        ],
      };

      //Volumes
      this.dtOptionsVolumes = {
        processing:false,
        serverSide:true,
       // deferRender: true,
       // scrollCollapse: true,
      //  scrollY: '100px',
      //  scrollX: true,
      //  autoWidth: true,
      //  scrollCollapse: true,
        paging: false,
        ajax: {
          url: environment.apiUri + '/volume',
          data: function (d) {
            var queryData = {
              idNotafi: self.idNotafi,
              page: d['start'] / d['length'] + 1,
              pageSize:d['length']
            }
            return queryData;
          },
          
          dataSrc : function(json) : any {
            json.recordsTotal = json.count;
            json.recordsFiltered = json.count
            return json.data;
          },
          async: true,
          error: function (xhr, error, code){},
          headers: { 'x-access-token': this.loginService.getToken() },
          
          
        },
        
        columns : [
          { "data": "qVol" },
          { "data": "especie" },
          { "data": "pesoL" },
          { "data": "pesoB" },
          {
            "data": "id",
            render: (data, type, full) => {
              return `
              <div class='text-center'>
                <a href='javascript:void(0);' data-toggle="modal" onclick=\"functions.editVolume('${data}')\"
                class="btn btn-primary btn-sm btn-icon rounded-circle" title="Editar" style="margin-right: 3px;">
                <i class="fal fa-pen"></i>
                </a>
                
                <a href='javascript:void(0);' onclick=\"functions.deleteVolume('${data}')\" 
                class="btn btn-danger btn-sm btn-icon rounded-circle"title="Excluir">
                <i class="fal fa-times"></i>
                </a>
              </div>`;
              ;
            }
          }
        ],
      };

   this.dtOptionsItemMed = {
    processing:false,
    serverSide:true,
    paging: false,
    ajax: {
      url: environment.apiUri + '/itemMed',
      dataType: 'json',
      type: 'GET',
      headers: { 'x-access-token': this.loginService.getToken() },
      data: function (d) {
        var queryData = {
          idItemNot: self.idItemNot,
          page: d['start'] / d['length'] + 1,
          pageSize:d['length']
        }
        return queryData;
      },        
      dataSrc : function(json) : any {
        json.recordsTotal = json.count;
        json.recordsFiltered = json.count
        return json.data;
      },
      async: true,
      error: function (xhr, error, code){},
    },        
    columns : [
      { "data": "nLote" },
      { "data": "qLote" },
      // { "data": "dtFab", render: function(data){ return moment(data).format("DD/MM/YYYY") } },
      { "data": "dtVal", render: function(data){ return moment(data).format("DD/MM/YYYY") } },
      { "data": "vPMC",render: $.fn.dataTable.render.number( '.', ',', 2, 'R$ ' ) },
      { "data": "cProdANVISA" },
      {
        "data": "id",
        render: (data, type, full) => {
          return `
          <div class='text-center'>
            <a href='javascript:void(0);' data-toggle="modal" onclick=\"functions.editItemMed('${data}')\"
            class="btn btn-primary btn-sm btn-icon rounded-circle" title="Editar">
            <i class="fal fa-pen"></i>
            </a>
            <a href='javascript:void(0);' onclick=\"functions.deleteItemMed('${data}')\" 
            class="btn btn-danger btn-sm btn-icon rounded-circle"title="Excluir">
            <i class="fal fa-times"></i>
            </a>
          </div>`;
          ;
        }
      }
    ],
  };


  
    });  
    

    this.searchDestinatario();
    this.searchProduto();
    this.searchArtigo();


    // $(document).ready(function() {
    
    //   $('#modelo').on('change',function(){
    //     var selectValor = '#' +$(this).val();

    //     $('pai').children('div').hide();
    //     $('pai').children(selectValor).show();
    //   });

  
  }


  fillIcmsSituacaoTributaria(tipo:string) {
    var self = this;
    this.icmsSituacaoTributariaService.get(tipo).subscribe(data=> {
      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.codigo || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        if(obj.id == undefined){ obj.id = 0};
        return obj;
      }, error => {
        // if(error.status == 401){
        //   self.loginService.tokenExpired();
        // }
      });
      $('#icmsSituacaoTributaria').select2({
        data: finalData,
        // placeholder: self.placeSituacaoTributaria,
        dropdownParent: $('#modalImposto'),
      });
    })
  }
  
  fillIpiSituacaoTributaria() {
    this.ipiSituacaoTributariaService.get('IPI').subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.codigo || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#ipiSituacaoTributaria').select2({
        data: finalData,
        placeholder: 'Situação tributária',
        dropdownParent: $('#modalImposto'),

      });
    }, error => {
      // if(error.status == 401){
      //   self.loginService.tokenExpired();
      // }
    });
}

  fillPisSituacaoTributaria() {
    this.pisSituacaoTributariaService.get('PIS').subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.codigo || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#pisSituacaoTributaria').select2({
        data: finalData,
        placeholder: 'Situação tributária',
        dropdownParent: $('#modalImposto'),
      });
    }, error => {
      // if(error.status == 401){
      //   self.loginService.tokenExpired();
      // }
    });
}

  fillCofinsSituacaoTributaria() {
    this.cofinsSituacaoTributariaService.get('PIS').subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.codigo || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#cofinsSituacaoTributaria').select2({
        data: finalData,
        placeholder: 'Situação tributária',
        dropdownParent: $('#modalImposto'),
      });
    }, error => {
      // if(error.status == 401){
      //   self.loginService.tokenExpired();
      // }
    });
}

  
  fillEstado() {
    this.estadoService.get().subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.uf || obj.pk; // replace pk with your identifier
        obj.text = obj.uf;
        return obj;
      });
    //  $('#ufTransportadora').empty();
      $('#ufTransportadora').select2({
        data: finalData,
//        placeholder: 'Estado (UF)',
      //  allowClear: true,

        dropdownParent: $('#modalTransportadora'),
      });
    }, error => {
      // if(error.status == 401){
      //   self.loginService.tokenExpired();
      // }
    });
}

  fillMeioPagto() {
    this.meioPagtoService.get().subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.id || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        return obj;
      });
      // $('#meioPagto').empty();
      $('#meioPagto').select2({
        data: finalData,
        placeholder: 'Meio de pagamento',
     //   dropdownParent: $('#modalCobranca'),
      });
      $('#meioPagtoEdit').select2({
        data: finalData,
        placeholder: 'Meio de pagamento',
        dropdownParent: $('#modalCobranca'),
      });
    }, error => {
      // if(error.status == 401){
      //   self.loginService.tokenExpired();
      // }
    });
}

  searchDestinatario() {
    var self = this;
    $('#select2Destinatario').select2({
      placeholder: self.placeDestinatario,
      minimumInputLength: 2,
      ajax: {
        headers: { 'x-access-token': this.loginService.getToken() },
       // url: 'https://kironwebapi.azurewebsites.net/entidade',
        url: environment.apiUri + '/entidade',
        dataType: 'json',
        delay: 250,
          data: function (params) {
            return {
              page: 0,
              pageSize: 50,
              status: 'A',
              tipo: 'CF',
              search: params.term,
            };
          },
          async: true,
          error: function (xhr, error, code){},

          processResults: function (data, params) {
            params.page = params.page || 1;

            return {
              results: $.map(data.data, function (obj) {
                return { 
                   text: obj.nome,
                   id: obj.id,
                   uf: obj.uf
              }
              }),
              pagination: {
             //   more: (params.page * 30) < data.total_count
              }
            };
          },
          cache: true
        },
    });
    $('#select2Destinatario').on('select2:select', function (e) {
      $('#exportacao').hide();
      var data = e.params.data;
      self.uf  = data.uf;
      if(self.uf == localStorage.getItem('UF')){
     //    self.tpOperacao = 1;
         self.nfe.idDest = 1;
      } else if(self.uf !== localStorage.getItem('UF')){
      //  self.tpOperacao = 2;
         self.nfe.idDest = 2;
      };
      if(self.uf == 'EX'){
      //  self.tpOperacao = 3;
        self.nfe.idDest = 3;
        $('#exportacao').show();
      };
  });
}


searchArtigo() {
  var self = this;
  $('#select2Artigo').select2({
    placeholder: self.placeArtigo,
    minimumInputLength: 2,
    ajax: {
      headers: { 'x-access-token': this.loginService.getToken() },
      url: environment.apiUri + '/artigo',
      dataType: 'json',
      delay: 250,
        data: function (params) {
          return {
            page: 0,
            pageSize: 50,
            status: 'A',
            search: params.term,
          };
        },
        async: true,
        error: function (xhr, error, code){},

        processResults: function (data, params) {
          params.page = params.page || 1;

          return {
            results: $.map(data.data, function (obj) {
              return { 
                 text: obj.nome,
                 id: obj.id,
                 uf: obj.uf
            }
            }),
            pagination: {
           //   more: (params.page * 30) < data.total_count
            }
          };
        },
        cache: true
      },
      dropdownParent: $('#modalArtigo'),
  });
}

searchTransportadora() {
  var self = this;
  $('#select2Transportadora').select2({
    placeholder: self.placeTransportadora,
    minimumInputLength: 2,
    ajax: {
      headers: { 'x-access-token': this.loginService.getToken() },
    //  url: 'https://kironwebapi.azurewebsites.net/entidade',
      url: environment.apiUri + '/entidade',
      dataType: 'json',
      delay: 250,
        data: function (params) {
          return {
            page: 0,
            pageSize: 50,
            status: 'A',
            tipo: 'T',
            search: params.term,
          };
        },
        async: true,
        error: function (xhr, error, code){},

        processResults: function (data, params) {
          params.page = params.page || 1;

          return {
            results: $.map(data.data, function (obj) {
              return { 
                 text: obj.nome,
                 id: obj.id
            }
            }),
            pagination: {
           //   more: (params.page * 30) < data.total_count
            }
          };
        },
        cache: true
      },
       //Importante sem esta linha não funciona o select2 no dialog
       dropdownParent: $('#modalTransportadora'),
  });
}


searchProduto() {
  var self = this;
  $('#select2Produto').select2({
    placeholder: self.placeProduto,
    minimumInputLength: 2,
    ajax: {
      headers: { 'x-access-token': this.loginService.getToken() },
     // url: 'https://kironwebapi.azurewebsites.net/produto',
      url: environment.apiUri + '/produto',
      dataType: 'json',
      delay: 250,
        data: function (params) {
          return {
            page: 0,
            pageSize: 50,
            status: 'A',
            search: params.term,
          };
        },
        async: true,
        error: function (xhr, error, code){},

        processResults: function (data, params) {
          params.page = params.page || 1;

          return {
            results: $.map(data.data, function (obj) {
              return { 
                 text: obj.nome,
                 id: obj.id,
                 ncm: obj.ncm,
                 preco: obj.preco,
                 codigoUnimed: obj.codigoUnimed
            }
            }),
            pagination: {
           //   more: (params.page * 30) < data.total_count
            }
          };
        },
        cache: true
      },
      //Importante sem esta linha não funciona o select2 no dialog
      dropdownParent: $('#modalItens'),
      

  });

  $('#select2Produto').on('select2:select', function (e) {
    var data = e.params.data;
    self.itemNFe.ncm  = data.ncm;
    self.itemNFe.preco  = data.preco;
    self.itemNFe.codigoUnimed = data.codigoUnimed
});
}

async fillCfop(id:number){
  var tipo: number = 1; 
  // if(this.uf == localStorage.getItem('UF')){
  //    tipo = 1;
  // } else if(this.uf != localStorage.getItem('UF')){
  //   tipo = 2;
  // };
  // if(this.uf == 'EX'){
  //   tipo = 3;
  // };
  // if($('#tipoOperacao').val() == 1){
  //   tipo = 1;
  // };
 // tipo = this.tpOperacao;
  await this.cfopService.get(this.nfe.idDest).subscribe(data=> {
    var finalData = $.map(data.data, function (obj) {
      obj.id = obj.id || obj.pk; // replace pk with your identifier
      obj.text = obj.cfop + ' > ' + obj.nome;
      return obj;
    });
    $('#select2Cfop').select2({
      data: finalData,
      allowClear: true,
      dropdownParent: $('#modalItens'),
    });
    $('#select2Cfop').val(id).change();
  }, error => {
    // if(error.status == 401){
    //   self.loginService.tokenExpired();
    // }
  });

}

fillFinalidade(){
  var statusData = [
    {'id': "1", 'text': 'NF-e Normal'},
    {'id': "2", 'text': 'NF-e Complementar'},
    {'id': "3", 'text': 'NF-e Ajustes'},
    {'id': "4", 'text': 'Devolução ou Retorno'},
    
   ];
  $('#finalidadeNota').select2({
    data: statusData,
    allowClear: false,
    minimumResultsForSearch: -1
  });
}

fillCondicoesPagto(){
  var statusData = [
    {'id': "S", 'text': 'à vista'},
    {'id': "N", 'text': 'à prazo'},
   ];
  $('#nfeaVista').select2({
    data: statusData,
    allowClear: false,
  });
}

fillTipoEmissao(){
  var tipoData = [
    {'id': "1", 'text': 'Normal'},
    {'id': "2", 'text': 'Contingência FS'},
    {'id': "3", 'text': 'Contingência SCAN'},
    {'id': "4", 'text': 'Contingência DPEC'},
    {'id': "5", 'text': 'Contingência FS-DA'},
   ];
  $('#tipoEmissao').select2({
    data: tipoData,
    allowClear: true,
    minimumResultsForSearch: -1,
    dropdownParent: $('#modalConfiguracaoAvancada'),
  });
}

fillTipoAtendimento(){
  var tipoData = [
    {'id': "0", 'text': '0 - Não se aplica'},
    {'id': "1", 'text': '1 - Operação presencial'},
    {'id': "2", 'text': '2 - Operação NÃO presencial, pela Internet'},
    {'id': "3", 'text': '3 - Operação NÃO presencial, pelo Teleatendimento'},
    {'id': "4", 'text': '4 - Operação NÃO presencial, Entrega a Domicílio'},
    {'id': "5", 'text': '5 - Operação presencial, fora do estabelecimento'},
    {'id': "9", 'text': '9 - Operação NÃO presencial, Outros'},
   ];
  $('#tipoAtendimento').select2({
    data: tipoData,
    allowClear: true,
    minimumResultsForSearch: -1,
    dropdownParent: $('#modalConfiguracaoAvancada'),
  });
}

fillTipoOperacao(){
  var tipoOp = [
    {'id': "1", 'text': 'Interna'},
    {'id': "2", 'text': 'Interestadual'},
    {'id': "3", 'text': 'Exterior'},
   ];
  $('#tipoOperacao').select2({
    data: tipoOp,
    allowClear: true,
    minimumResultsForSearch: -1,
    dropdownParent: $('#modalConfiguracaoAvancada'),
  });
  $('#tipoOperacao').val(this.nfe.idDest).change();
  $('#exportacao').hide();
  if(this.nfe.idDest == 3){
    $('#exportacao').show();
  }
}


fillTipoFrete(){
  var tipoData = [
    {'id': "0", 'text': 'Por conta do Emitente (CIF)'},
    {'id': "1", 'text': 'Por conta do Destinatário/Remetente (FOB)'},
    {'id': "2", 'text': 'Por conta de Terceiros'},
    {'id': "3", 'text': 'Transporte próprio por conta do Remetente'},
    {'id': "4", 'text': 'Transporte próprio por conta do Destinatário'},
    {'id': "9", 'text': 'Sem Frete'},
   ];
  $('#tipoFrete').select2({
    data: tipoData,
    allowClear: true,
    minimumResultsForSearch: -1,
    dropdownParent: $('#modalTransportadora'),
  });
}

fillTipoRateioFrete(){
  var tipoData = [
    {'id': "0", 'text': 'No item'},
    {'id': "1", 'text': 'Valor total do frete'},
   ];
  $('#tipoRateioFrete').select2({
    data: tipoData,
    allowClear: true,
    minimumResultsForSearch: -1,
    dropdownParent: $('#modalTransportadora'),
  });
}

fillTipoNFeRefere(){
  var tipoData = [
    {'id': "0", 'text': 'Selecione o tipo'},
    {'id': "1", 'text': 'NF-e / NFC-e'},
    {'id': "2", 'text': 'NF modelo 1/1A'},
    {'id': "3", 'text': 'NF de produtor rural'},
    {'id': "4", 'text': 'Cupom Fiscal'},
   ];
  $('#tipoNFeReferenciada').select2({
    data: tipoData,
    allowClear: true,
    minimumResultsForSearch: -1,
    dropdownParent: $('#modalNFeReferenciada'),
  });
}


configuracaoAvancada(){
  this.fillTipoEmissao();
  this.fillTipoAtendimento();
  this.fillTipoOperacao();
  $('#modalConfiguracaoAvancada').modal('show');
}

calcularTotal(){
  // alert($('#itemNFepreco').val());
  // alert($('#itemNFepreco').val());
  this.itemNFe.qtda = $('#itemNFeqtda').val();
  this.itemNFe.preco = $('#itemNFepreco').val();
  this.itemNFe.vProd = this.itemNFe.qtda * this.itemNFe.preco;
  // $('#itemNFevProd').val(this.itemNFe.vProd).change();
  // if ((this.itemNFe.qtda != null) && (this.itemNFe.preco != null))
  // {
    // this.itemNFe.vProd = this.itemNFe.qtda * this.itemNFe.preco;
  // }
}

async adicionarTransportadora(){

  var self = this;
  if(this.nfe.idTransportadora == 0) {
     this.placeTransportadora = 'Pesquise a transportadora...';
  }

  await this.searchTransportadora();
  this.fillTipoFrete();
  this.fillTipoRateioFrete();

  $('#tipoFrete').val(this.nfe.modFrete).change();

  if(!self.isInitModalFrete){ 
      self.isInitModalFrete = true;
      $('#tipoRateioFrete').val(this.nfe.tpRateioFrete).change();
  }
 this.disableValorFrete = $('#tipoRateioFrete').val() == 0 ? true : false;

 $('#modalTransportadora').click();
 $('#modalTransportadora').modal('show');
   
    $('#tipoRateioFrete').on("change", function() {
      self.disableValorFrete = $(this).val() == 0 ? true : false;
    if($(this).val() == 1){
        if(self.nfe.vFrete > 0) {
            Swal.fire({
              showDenyButton: true,
              showConfirmButton: true,
              denyButtonText: "Não",
              confirmButtonText: "Sim",
              title:  "Confirma?",
              text: 'Os valores de frete informados nos itens serão zerados, deseja continuar?',
              icon: 'question',
              target: document.getElementById('modalTransportadora')
            }).then( function(isConfirm){
              if(isConfirm.value){ 
                //  self.disableValorFrete = false;
                  self.nfe.vFrete = 0;
                  $('#nfevFrete').val(0).change();
              } else {
              //  self.disableValorFrete = true;
                $('#tipoRateioFrete').val(0).change();
              }
            });    
          };
    } 
  else {
        self.disableValorFrete = true;
    }; 
    });      

}

adicionarArtigo(){
  if($('#select2Artigo').val() !== null){
      this.artigoService.getById($('#select2Artigo').val()).subscribe((res)=>{
        if(this.nfe.obs == null){
          this.nfe.obs = '';     
        }
        this.nfe.obs = this.nfe.obs + res.data.obs;
      });
    }
  $('#modalArtigo').modal('hide');
}

adicionarVolume(){
  this.volume.id = 0;
  this.volume.especie = '';
  this.volume.marca = '';
  this.volume.nLacre = '';
  this.volume.nVol = 0;
  this.volume.pesoB = 0;
  this.volume.pesoL = 0;
  this.volume.qVol = 0;
  $('#modalVolumes').modal('show');
}

editVolume(id:number){
  this.volumeService.getById(id).subscribe(res => {
    this.volume = res.data;
  });

  $('#modalVolumes').modal('show');
}

editItemMed(id:number){
  
  this.itemMedService.getById(id).subscribe(res => {
    this.itemMed = res.data;
  });

  $('#modalAddMed').modal('show');
}

adicionarProduto() {
  if((this.nfe.idEntidade == 0) && ($('#select2Destinatario').val() == null)){
    Swal.fire({
      title:  "Erro!",
      text: 'Ops, falta informar o destinatário.',
      icon: 'error',
     });
  } else {
      this.placeProduto = 'Pesquise o produto...';
      // this.placeCfop = 'Pesquise o cfop...';
      // if(this.repeatCfop == 0 ) {
      //   this.fillCfop();
      //   $('#select2Cfop').val(0).change(); 
        
      // } else {
      //   $('#select2Cfop').val(this.repeatCfop);
      // }
      this.fillCfop(this.repeatCfop);
      this.searchProduto();
      $('#select2Produto').empty();
      this.itemNFe.id = 0;
      this.itemNFe.idProduto = 0;
      this.itemNFe.idNatura = 0;
      this.itemNFe.qtda = 0;
      this.itemNFe.preco = 0;
      this.itemNFe.vProd = 0;
      this.itemNFe.vSeguro = 0;
      this.itemNFe.vDesconto = 0;
      this.itemNFe.vLucro = 0;
      this.itemNFe.vFrete = 0;
      this.itemNFe.vOutro = 0;
      this.itemNFe.xPed = '';
      this.itemNFe.nItemPed = 0;
      this.itemNFe.complemento = '';
      $('#modalItens').modal('show');
  }
}

adicionarNFeReferenciada(){
  // $(document).ready(function() {
    $('#tipoNFeReferenciada').on('change',function(){
        var SelectValue='#div'+$(this).val();
        $('#DivPai div').hide();
        $('#div1').hide();
        $('#div2').hide();
        $('#div3').hide();
        $('#div4').hide();
        $(SelectValue).show();
    }); 
  // });
  this.nfeRefere.id = 0;
  this.nfeRefere.idNotafi = this.idNotafi;
  this.nfeRefere.refNFe = '';
  this.fillTipoNFeRefere();
 // $('#tipoNFeReferenciada').val(0);
  $('#modalNFeReferenciada').modal('show');
}

deleteItem(data) {
  if(this.disableForm == true){
    Swal.fire({
      title:  "Erro",
      text: 'Nota já enviada pra Sefaz, impossível exclusão! ',
      icon: 'error',
  });
  } else {
    this.currentItem = data;
    this.deleteConfirmationMessage.fire();
  }
}

confirmDeleteItem() {
  this.itemNFeService.delete(this.currentItem).subscribe( async() => {
    await this.refreshNFe();
    $('#itensNota').DataTable().ajax.reload();
  }, error => {
    Swal.fire({
      title:  "Erro!",
      text: this.msgErroServer + error.error.message,
      icon: 'error',

  });
  });
}


deleteNFeRefere(data) {
  this.currentNFeRefere = data;
   this.deleteConfirmationNFeRefere.fire();
}

deleteItemMed(id:number) {
  var self = this;
  Swal.fire({
    showDenyButton: true,
    showConfirmButton: true,
    denyButtonText: "Cancela",
    confirmButtonText: "Confirmar",
    title:  "Confirma?",
    text: 'Deseja realmente excluir este item?',
    icon: 'question',
    target: document.getElementById('modalMedicamentos')
   }).then( function(isConfirm){
     if(isConfirm.value){
      self.itemMedService.delete(id).subscribe( (res) => {
        $('#tableItemMed').dataTable().fnClearTable();
      }, error => {
        Swal.fire({
          title:  "Erro!",
          text: this.msgErroServer + error.error.message,
          icon: 'error'
      });
      });
     }
   });    
}


confirmDeleteNFeRefere() {
  this.nfeRefereService.delete(this.currentNFeRefere).subscribe( () => {
    $('#nfeReferenciada').dataTable().fnClearTable();
  }, error => {
    Swal.fire({
      title:  "Erro!",
      text: this.msgErroServer + error.error.message,
      icon: 'error'
  });
  });
}


deleteVolume(id:number) {
      var self = this;
      Swal.fire({
        showDenyButton: true,
        showConfirmButton: true,
        denyButtonText: "Cancela",
        confirmButtonText: "Confirma",
        title:  "Confirma?",
        text: 'Deseja realmente excluir este volume?',
        icon: 'question',
        target: document.getElementById('modalTransportadora')
       }).then( function(isConfirm){
         if(isConfirm.value){
          self.volumeService.delete(id).subscribe( (res) => {
            $('#tableVolumes').dataTable().fnClearTable();
          }, error => {
            Swal.fire({
              title:  "Erro!",
              text: this.msgErroServer + error.error.message,
              icon: 'error'
          });
          });
         }
       });    
   
}


btnModalValores(){
    this.nfeService.getById(this.idNotafi).subscribe(response=> {
      this.nfe = response.data;
      $('#modalValores').modal('show');
      $('#modalValores').click();

  });
  
};

modalCombustiveis(){
  // $('#modalCombustiveis').click();
  // $('#modalCombustiveis').modal('show');
}

showMedicamentos(id:number){
  this.route.data.subscribe(params => {
     this.itemMedService.get(id).subscribe(res =>{
       this.dtOptionsItemMed.data = [res.data];
     })
  });


//   });
//}
//  });

}
modalMedicamentos(id:number){
    //Lote / Medicamentos
  this.idItemNot = id;
//  this.showMedicamentos(id);

  $('#modalMedicamentos').click();
  $('#modalMedicamentos').modal('show');
  $('#tableItemMed').DataTable().ajax.reload();
  $('#tableItemMed').dataTable().fnClearTable();
}


calcularCobranca(){
  this.nfe.aVista =  $('#nfeaVista').val();
  this.nfe.idMeioPagto =  $('#meioPagto').val();
  // this.nfe.dtBase =  $('#nfedtBase').val()+'T03:00:00';
  this.nfe.dtBase =  $('#nfedtBase').val();
  this.nfe.vEntrada = $('#nfevEntrada').val();
  if((this.nfe.aVista == 'S') && (this.nfe.nParcelas > 1)){
     Swal.fire({
      title:  "Erro!",
      text: "Ops, quantidade de parcelas inválida para condições de pagamento a vista!",
      icon: 'error',
  });   
  } else {
       this.nfeService.calcularCobranca(this.nfe).subscribe(response=> {
          $('#parcelasCobranca').DataTable().ajax.reload();
          $('#parcelasCobranca').dataTable().fnClearTable();
      });
    }
}

updateDataTable() {

  var self = this;
  
  this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    // Destroy the table first
    dtInstance.destroy();
    // Call the dtTrigger to rerender again
    this.dtTrigger.next();
  });
}

editItem(data) {
  this.route.data.subscribe(params => {
    if(data)
    {
      this.itemNFeService.getById(data).subscribe(response=> {
        this.itemNFe = response.data;
        this.fillCfop(this.itemNFe.idNatura);
        $('#select2Produto').val(this.itemNFe.idProduto).change();
        this.placeProduto = response.data.produto.nome;
        // this.placeCfop = response.data.cfop.cfop + ' > ' + response.data.cfop.nome;
        this.searchProduto(); 
        //document.location.reload();
        //$('#modalItens').modal('')
        //$('#modalItens').trigger('focus');
        //$('#modalItens').modal({remote: 'show', refresh: true, keyboard: true, focus: true });
        //$('#produtoQuantidade').trigger('focus');
        $('#modalItens').modal('show');
        $('#modalItens').click();
      }, error => {

      })
    }

  }); 
 
}


editCobranca(data) {
  this.route.data.subscribe(params => {
    if(data)
    {
      this.duplicService.getById(data).subscribe(response=> {
          this.duplic = response.data;
          $('#meioPagtoEdit').val(this.duplic.idMeioPagto).change();
          //$('#duplicVencto').val(this.duplic.vencto).change();
          $('#duplicValor').prop( "disabled", true );
          $('#duplicParcela').prop( "disabled", true );
          $('#modalCobranca').modal('show');
          $('#modalCobranca').click();
      }, error => {

      })
    }

  }); 
 
}

handleDenial(){

}

async impostoItem(data) {
    this.route.data.subscribe(params => {
    if(data)
      {
        this.itemIcmsService.getByIdItem(data).subscribe(response=> {
          this.itemIcms = response.data[0];
          if(this.isSimples === 'S'){
            this.labelSituacaoTributaria = 'CSOSN';
            this.fillIcmsSituacaoTributaria('CSOSN');    
            $('#icmsSituacaoTributaria').val(this.itemIcms.csosn).change();
          } else {
            this.labelSituacaoTributaria = 'CST';
            this.fillIcmsSituacaoTributaria('ICMS');  
            $('#icmsSituacaoTributaria').val(this.itemIcms.cst).change();
          }
          this.chqIcmsAtualizar = ( this.itemIcms.atualiza == 'S' ? true : false);
          $('#modalImposto').click();
        }, error => {

        })
      }
   });  
  //Ipi
  this.route.data.subscribe(params => {
    if(data)
      {
        this.itemIpiService.getByIdItem(data).subscribe(response=> {
          this.itemIpi = response.data[0];
          this.chqIpiAtualizar = ( this.itemIpi.atualiza == 'S' ? true : false);
          $('#ipiSituacaoTributaria').val(this.itemIpi.cst).change();
        }, error => {

        })
      }
   });  
   //Pis
   this.route.data.subscribe(params => {
    if(data)
      {
        this.itemPisService.getByIdItem(data).subscribe(response=> {
          this.itemPis = response.data[0];
          this.chqPisAtualizar = ( this.itemPis.atualiza == 'S' ? true : false);
          $('#pisSituacaoTributaria').val(this.itemPis.cst).change();
        }, error => {

        })
      }
   });  
   //Cofins
   this.route.data.subscribe(params => {
    if(data)
      {
        this.itemCofinsService.getByIdItem(data).subscribe(response=> {
          this.itemCofins = response.data[0];
          this.chqCofinsAtualizar = ( this.itemCofins.atualiza == 'S' ? true : false);
          $('#cofinsSituacaoTributaria').val(this.itemCofins.cst).change();
        }, error => {

        })
      }
   });  
   $('#modalImposto').modal('show');
}

ngOnDestroy() {
  window.functions = null;
  this.dtTrigger.unsubscribe();
}

cancel(){
  if((this.isNew) && (this.idNotafi > 0) && (this.nfe.idStatus == 0)){
    this.nfeService.delete(this.idNotafi).subscribe( (res) => {});
  }
  this.router.navigate(['/faturamento']);
}

async saveNFe(tipo:number){
 //  const emissao = $('#nfedtEmissao').val()+'T07:00:00';
   const emissao = $('#nfedtEmissao').val()+'T'+$('#hrEmissao').val()+':00';
   this.nfe.dtEmissao = new Date(emissao);
  if($('#nfedtSaida').val() !== ''){
     this.nfe.dtSaida = $('#nfedtSaida').val()+'T03:00:00';     
  } else {
    this.nfe.dtSaida = null;
  }
  


  this.nfe.finNFe = $('#finalidadeNota').val();
  if($('#select2Destinatario').val() !== null){
     this.nfe.idEntidade = $('#select2Destinatario').val();
  };
  if($('#select2Transportadora').val() !== null){
     this.nfe.idTransportadora = $('#select2Transportadora').val();
  }
 // this.nfe.idDest = $('#tipoOperacao').val();


  if(this.nfe.id == null){
    Swal.fire({
      title:  "Erro!",
      text: "Ops, falta adicionar o produto!",
      icon: 'error',
     // target: document.getElementById('modalItens')
  });
  } else {
    // var dt = new Date($('#dtEmissao').val() + 'T' + $('#horaEmissao').val());
    // var dt = new Date(this.nfe.dtEmissao + 'T' + $('#horaEmissao').val());
    // alert(dt);
    // this.nfe.dtEmissao = dt;
    // alert(this.nfe.dtEmissao);

    this.nfe.idMeioPagto = $("#meioPagto").val();
    this.nfe.aVista = $('#nfeaVista').val();
    this.nfe.vEntrada = $('#nfevEntrada').val();
   
    await this.nfeService.update(this.nfe).subscribe(response => {
     
    }, error => {
      Swal.fire({
        title:  "Erro!",
        text: error.message,
        icon: 'error'
    });
    });
  }
  if(tipo == 0){
     this.router.navigate(['/faturamento']);
  };
}

async enviarNFe(){
  this.msgSpinner = 'Enviando nota para Sefaz, isso pode levar alguns segundos...';
  this.spinnerService.show();

  this.saveNFe(1);
  var data = {id: this.nfe.id};
    await this.nfeService.enviarNFe(data).then((res: any) => {
      this.spinnerService.hide();
      if(res.status == 100) {
          Swal.fire({
            text: res.message,
            icon: 'success',
          });
          this.router.navigate(['/faturamento']);
        } else {
          this.spinnerService.hide();
          Swal.fire({
            title:  "Ops!",
            text: atob(res.message),
            icon: 'error',
          })
        }
   });
}

async saveItem(){
  this.nfe.idEmpresa = localStorage.getItem('idEmpresa');
  this.nfe.finNFe = $('#finalidadeNota').val();
  if($('#select2Destinatario').val() !== null){
     this.nfe.idEntidade = $('#select2Destinatario').val();
  };
  if($('#select2Produto').val() != null) {
    this.itemNFe.idProduto = $('#select2Produto').val();
  }
  if($('#select2Cfop').val() != null ){
    this.itemNFe.idNatura = $('#select2Cfop').val();
    this.nfe.idNatura = this.itemNFe.idNatura;
    this.repeatCfop = this.itemNFe.idNatura;
  }
  //Erro
  // if($('#select2Produto').select2('data')[0]['ncm'] !== undefined){
  //   this.itemNFe.ncm = $('#select2Produto').select2('data')[0]['ncm'];
  // }
  this.beforeSaveItem();
  if((this.itemNFe.idProduto == 0 ) || (this.itemNFe.idNatura == 0)){
    Swal.fire({
      title:  "Erro!",
      text: "Ops, falta preencher campo obrigatório!",
      icon: 'error',
      target: document.getElementById('modalItens')
  });
  } else {
      if( (this.nfe.id == null ) && (this.itemNFe.id == 0)){
        await this.nfeService.create(this.nfe).subscribe(async res=> {
          this.idNotafi = res.data.id;
          this.nfe.id = res.data.id;
          this.itemNFe.idNotafi = res.data.id;
          this.itemNFe.id = 0;
          this.itemNFe.simplesNacional = (this.isSimples == 'S' ? true : false );
          await this.itemNFeService.create(this.itemNFe).subscribe(async res=> {
              await this.refreshNFe();
              $('#modalItens').modal('hide');

          });
        } );
      } else 
      {   
        if(this.itemNFe.id > 0) {
            this.itemNFe.simplesNacional = (this.isSimples == 'S' ? true : false );

            await this.itemNFeService.update(this.itemNFe).subscribe(async res=> {
              await this.refreshNFe();
              $('#modalItens').modal('hide');

            });
          } else {
            this.itemNFe.idNotafi = this.nfe.id;
            this.itemNFe.id = 0;
            this.itemNFe.simplesNacional = (this.isSimples == 'S' ? true : false );
            await this.itemNFeService.create(this.itemNFe).subscribe(id=> {
                this.refreshNFe();
                $('#modalItens').modal('hide');
            }, error => {
            });
          }
      }
  }
}

async refreshNFe(){
  
  var vEntrada = this.nfe.vEntrada;
  var dtBase = this.nfe.dtBase;
  var nParcelas = this.nfe.nParcelas;
  var intervalo = this.nfe.intervalo;

  await this.nfeService.getById(this.idNotafi).subscribe(response => {
      this.nfe = response.data;
      console.log(this.nfe);

     this.nfe.vEntrada = vEntrada;
     this.nfe.dtBase = dtBase;
     this.nfe.nParcelas = nParcelas;
     this.nfe.intervalo = intervalo;
      $('#itensNota').DataTable().ajax.reload();
      this.calcularCobranca();
  });
}

cancelItem(){
  $('#modalItens').modal('hide');
}

async saveTransportadora(){
  if($('#select2Tranportadora').val() != null) {
    this.nfe.idTransportadora = $('#select2Transportadora').val();
  }
  this.nfe.veicUF = $('#ufTransportadora').val();
  this.nfe.modFrete = $('#tipoFrete').val();
  this.nfe.vFrete = $('#nfevFrete').val();
  this.nfe.tpRateioFrete = $('#tipoRateioFrete').val();
  if(this.nfe.tpRateioFrete == 1){
     await this.nfeService.transportadora(this.nfe).subscribe(async res => {
       await this.atualizarImpostos(false);
     });
  }
  $('#modalTransportadora').modal('hide');
  
}

async saveVolume(){
  if(this.volume.especie == null) {
    Swal.fire({
      title:  "Erro!",
      text: "Ops, falta preencher o campo Espécie!",
      icon: 'error',
      target: document.getElementById('modalVolumes')
  });    
  }
  this.volume.pesoL = $('#volumePesoL').val();
  this.volume.pesoB = $('#volumePesoB').val();
  if($('#volumePesoL').val() == '') {
    this.volume.pesoL = 0;
  }
  if($('#volumePesoB').val() == '') {
    this.volume.pesoB = 0;
  };
  this.volume.idNotafi = this.nfe.id;
  if(this.volume.id == 0){
      await this.volumeService.create(this.volume).subscribe(async res => {
        $('#tableVolumes').DataTable().ajax.reload();
        $('#tableVolumes').dataTable().fnClearTable();
      });
  } else {
    await this.volumeService.update(this.volume).subscribe(async res => {
      $('#tableVolumes').DataTable().ajax.reload();
      $('#tableVolumes').dataTable().fnClearTable();
    });
 
  }

   $('#modalVolumes').modal('hide');
  
}

closeVolume(){
  $('#modalVolumes').modal('hide');
  $('#modalTransportadora').reload();
}

async adicionarItemMed(){
  this.itemMed.id = 0;
  this.itemMed.nLote = '';
  this.itemMed.qLote = 0;
  this.itemMed.dtFab = new Date;
  this.itemMed.dtVal = new Date;
  this.itemMed.vPMC = 0
  this.itemMed.cProdANVISA = ''


  $('#modalAddMed').modal('show');

}
closeAddItemMed(){
  $('#modalAddMed').modal('hide');
}

async saveMed() {
  this.itemMed.nLote = $('#itemMedNLote').val();
  this.itemMed.qLote = $('#itemMedQLote').val();
  this.itemMed.dtFab = $('#itemMedDtFab').val();
  this.itemMed.dtVal = $('#itemMedDtVal').val();
  this.itemMed.vPMC = $('#itemMedVPMC').val();
  this.itemMed.cProdANVISA = $('#itemMedCProdANVISA').val();
  this.itemMed.idNotafi = this.nfe.id;
  this.itemMed.idItemNot = this.idItemNot;
   if(this.itemMed.id == 0){
      await this.itemMedService.create(this.itemMed).subscribe(async res => {
        $('#tableItemMed').DataTable().ajax.reload();
        $('#tableItemMed').dataTable().fnClearTable();
      });
    } else {
      await this.itemMedService.update(this.itemMed).subscribe(async res => {
        $('#tableItemMed').DataTable().ajax.reload();
        $('#tableItemMed').dataTable().fnClearTable();
      });

    }

  $('#modalAddMed').modal('hide');
}

async saveImposto() {
  //Icms
  this.itemIcms.atualiza = (this.chqIcmsAtualizar == true ? 'S' : 'N');
  if(!this.chqIcmsAtualizar){
      if(this.isSimples === 'S')  {
        this.itemIcms.csosn = $('#icmsSituacaoTributaria').val();
      } else {
        this.itemIcms.cst = $('#icmsSituacaoTributaria').val();
      }
  }
  this.beforeSaveImposto();
  await this.itemIcmsService.update(this.itemIcms).subscribe(res => {
  });

  //Ipi
  this.itemIpi.atualiza = (this.chqIpiAtualizar == true ? 'S' : 'N');
  await this.itemIpiService.update(this.itemIpi).subscribe(res => {
    });

  //Pis
  this.itemPis.atualiza = (this.chqPisAtualizar == true ? 'S' : 'N');
  await this.itemPisService.update(this.itemPis).subscribe(res => {
    });
  
  //Cofins
  this.itemCofins.atualiza = (this.chqCofinsAtualizar == true ? 'S' : 'N');
  await this.itemCofinsService.update(this.itemCofins).subscribe(res => {
    });
  
  await this.refreshNFe();  
  $('#modalImposto').modal('hide');
}

saveConfiguracao(){
  this.nfe.idDest = $('#tipoOperacao').val();
 // this.tpOperacao = $('#tipoOperacao').val();
  $('#exportacao').hide();
  if(this.nfe.idDest == 3){
    $('#exportacao').show();
  }  
  $('#modalConfiguracaoAvancada').modal('hide');
}

saveCobranca(){
  this.duplic.idMeioPagto =  $('#meioPagtoEdit').val();
  this.duplic.vencto =  new Date($('#duplicVencto').val()+'T03:00:00');
  // this.duplic.vencto =  $('#duplicVencto').val()+'T03:00:00';
  this.duplicService.update(this.duplic).subscribe(response => {
    $('#parcelasCobranca').dataTable().fnClearTable();
  });
  $('#modalCobranca').modal('hide');
}

cancelCobranca(){
  $('#modalCobranca').modal('hide');
}

saveNFeReferenciada(){
  if(this.nfeRefere.refNFe == '' ){
    Swal.fire({
      title:  "Erro!",
      text: "Ops, falta preencher a chave de acesso!",
      icon: 'error',
      target: document.getElementById('modalNFeReferenciada')
    });
  } else {
      this.nfeRefere.tipo = 1;// $('#tipoNFeReferenciada').val();
      this.nfeRefereService.create(this.nfeRefere).subscribe(response => {
        $('#nfeReferenciada').DataTable().ajax.reload();
        $('#nfeReferenciada').dataTable().fnClearTable();
      });
      $('#modalNFeReferenciada').modal('hide'); 
  }
}

cancelNFeReferenciada(){
  $('#modalNFeReferenciada').modal('hide'); 
}

async atualizarImpostos(showMsg: boolean){
  var data = {
     id: this.nfe.id,
     simplesNacional: (this.isSimples == 'S' ? true : false )
   };
  this.msgSpinner = 'Atualizando os impostos, isso pode levar alguns segundos...';
  this.spinnerService.show();
  await this.nfeService.atualizarImpostos(data).then(async res => {
    await this.refreshNFe();
    this.spinnerService.hide();
    if(showMsg){
        Swal.fire({
          text: res.message,
          icon: 'success',
          });
        }
      });

 }

 visualizar() {
  $("#notaFiscal :input").attr("disabled", this.disableForm);
  $("#produtos :input").attr("disabled", this.disableForm);
  $("#notasReferenciadas :input").attr("disabled", this.disableForm);
  $("#cobranca :input").attr("disabled", this.disableForm);
  $("#observacao :input").attr("disabled", this.disableForm);
  $("#exportacao :input").attr("disabled", this.disableForm);
  // $("#transp :input").attr("disabled", this.disableForm);
  // $("#btnacao :input").attr("disabled", this.disableForm);
  // $("#itensNota :datatable").attr("disabled", this.disableForm);
  // $("#parcelasCobranca :datatable").attr("disabled", this.disableForm);
}

async danfe(){
  var nfeDanfe = {id: this.nfe.id};
  await this.saveNFe(1);
  await this.nfeService.visualizarNFe(nfeDanfe);
}

inputMask(){
  let maskCurrency = 
      { 
          alias:'currency',
          radixPoint: ",",
          prefix:'R$ ',
          digits: 2,
          digitsOptional: false,
          // numericInput: true,
          autoUnmask: true,
          unmaskAsNumber: true,
          removeMaskOnSubmit: true
    };
  let maskPreco = 
    { 
        alias:'currency',
        radixPoint: ",",
        prefix:'R$ ',
        digits: this.vUnCom,
        digitsOptional: false,
        // numericInput: true,
        autoUnmask: true,
        unmaskAsNumber: true,
        removeMaskOnSubmit: true
  };
let maskDecimal = 
    { 
        alias:'currency',
        radixPoint: ",",
        prefix:'',
        digits: 2,
        digitsOptional: false,
        autoUnmask: true,
        unmaskAsNumber: true,
        removeMaskOnSubmit: true
    };


  // $('#itemNFepreco').inputmask(maskCurrency);
  $('#itemNFepreco').inputmask(maskPreco);
  $('#itemNFevProd').inputmask(maskCurrency);
  $('#itemNFevSeguro').inputmask(maskCurrency);
  $('#itemNFevDesconto').inputmask(maskCurrency);
  $('#itemNFevLucro').inputmask(maskCurrency);
  $('#itemNFevOutro').inputmask(maskCurrency);
  $('#itemNFevFrete').inputmask(maskCurrency);

  $('#itemIcmsvBC').inputmask(maskCurrency);
  $('#itemIcmsvIcms').inputmask(maskCurrency);
  $('#itemIcmsvBCST').inputmask(maskCurrency);
  $('#itemIcmspIcms').inputmask(maskDecimal);
  $('#itemIcmspRedBC').inputmask(maskDecimal);
  
  $('#itemIcmsvCredICMSSN').inputmask(maskCurrency);
  $('#itemNFevTotTrib').inputmask(maskCurrency);
  $('#itemIcmspIva').inputmask(maskDecimal);
  $('#itemIcmspRedBCST').inputmask(maskDecimal);
  $('#itemIcmspIcmsST').inputmask(maskDecimal);
  $('#itemIcmspCredSN').inputmask(maskDecimal);
  
  $('#itemIcmsvIcmsST').inputmask(maskCurrency);

  $('#itemIpivBC').inputmask(maskCurrency);
  $('#itemIpivIpi').inputmask(maskCurrency);
  $('#itemIpipIpi').inputmask(maskDecimal);
  

  $('#itemPisvBC').inputmask(maskCurrency);
  $('#itemPispPis').inputmask(maskDecimal);
  $('#itemPisvPis').inputmask(maskCurrency);
  $('#itemPisvBCST').inputmask(maskCurrency);
  $('#itemPisvPisST').inputmask(maskCurrency);
  $('#itemPisqBCProd').inputmask(maskDecimal);
  $('#itemPisvAliqProd').inputmask(maskDecimal);
  $('#itemPispPisST').inputmask(maskDecimal);
  $('#itemPisqBCProdST').inputmask(maskDecimal);
  $('#itemPisvAliqProdST').inputmask(maskDecimal);

   
  $('#itemCofinsvBC').inputmask(maskCurrency);
  $('#itemCofinspCofins').inputmask(maskDecimal);
  $('#itemCofinsvCofins').inputmask(maskCurrency);
  $('#itemCofinsvBCST').inputmask(maskCurrency);
  $('#itemCofinspCofinsST').inputmask(maskDecimal);
  $('#itemCofinsvCofinsST').inputmask(maskCurrency);
  $('#itemCofinsqBCProd').inputmask(maskDecimal);
  $('#itemCofinsvAliqProd').inputmask(maskDecimal);
  $('#itemCofinspBCST').inputmask(maskDecimal);
  $('#itemCofinsqBCProdST').inputmask(maskDecimal);
  $('#itemCofinsvAliqProdST').inputmask(maskDecimal);


  $('#duplicValor').inputmask(maskCurrency);

  $('#nfevBC').inputmask(maskCurrency);
  $('#nfevIcms').inputmask(maskCurrency);
  $('#nfevBCST').inputmask(maskCurrency);
  $('#nfevST').inputmask(maskCurrency);
  $('#nfevICMSDeson').inputmask(maskCurrency);
  $('#nfevFCP').inputmask(maskCurrency);
  $('#nfevFCPST').inputmask(maskCurrency);
  $('#nfevFCPSTRet').inputmask(maskCurrency);
  $('#nfevFrete').inputmask(maskCurrency);
  $('#nfevFrete2').inputmask(maskCurrency);
  $('#nfevSeguro').inputmask(maskCurrency);
  $('#nfevOutro').inputmask(maskCurrency);
  $('#nfevReducao').inputmask(maskCurrency);
  $('#nfevIPI').inputmask(maskCurrency);
  $('#nfevII').inputmask(maskCurrency);
  $('#nfevPIS').inputmask(maskCurrency);
  $('#nfevCofins').inputmask(maskCurrency);
  $('#nfevProd').inputmask(maskCurrency);
  $('#nfevIPIDevol').inputmask(maskCurrency);
  $('#nfevTotTrib').inputmask(maskCurrency);
  $('#nfevNF').inputmask(maskCurrency);
  $('#nfevEntrada').inputmask(maskCurrency);

  $('#cobrancaValor').inputmask(maskCurrency);
  $('#cobrancaValor').prop( "disabled", true );

  $('#volumePesoL').inputmask(maskDecimal);
  $('#volumePesoB').inputmask(maskDecimal);
}

bindNFe(){
  $('#finalidadeNota').val(this.nfe.finNFe).change();
  $('#tipoOperacao').val(this.nfe.idDest).change();
//  this.tpOperacao = this.nfe.idDest;
  $('#exportacao').hide();
  if(this.nfe.idDest == 3){
    $('#exportacao').show();   
  };
 
  $('#select2Destinatario').val(this.nfe.idEntidade).change();
  $("#meioPagto").val(this.nfe.idMeioPagto).change();
  $('#nfeaVista').val(this.nfe.aVista).change();
  $('#select2Transportadora').val(this.nfe.idTransportadora).change();


  // $('#nfedtEmissao').val(this.nfe.dtEmissao).change(); //ERRO
  // var hora = new Date(this.nfe.dtEmissao);
  // $('#horaEmissao').val(hora.getHours()+':'+hora.getMinutes()).change();

  $('#itemNFeqtda').val(this.itemNFe.qtda).change(); 
  $('#itemNFepreco').val(this.itemNFe.preco).change(); 
  $('#itemNFevProd').val(this.itemNFe.vProd).change(); 
  $('#itemNFevSeguro').val(this.itemNFe.vSeguro).change(); 
  $('#itemNFevDesconto').val(this.itemNFe.vDesconto).change(); 
  $('#itemNFevLucro').val(this.itemNFe.vLucro).change(); 
  $('#itemNFevOutro').val(this.itemNFe.vOutro).change(); 
  $('#itemNFevFrete').val(this.itemNFe.vFrete).change(); 

  $('#itemIcmsvBC').val(this.itemIcms.vBC).change();
  $('#itemIcmsvIcms').val(this.itemIcms.vIcms).change();
  $('#itemIcmsvBCST').val(this.itemIcms.vBCST).change();

  $('#itemIcmspIcms').val(this.itemIcms.pIcms).change();
  $('#itemIcmspRedBC').val(this.itemIcms.pRedBC).change();
  $('#itemIcmspIva').val(this.itemIcms.pIva).change();
  $('#itemIcmspRedBCST').val(this.itemIcms.pRedBCST).change();
  $('#itemIcmspIcmsST').val(this.itemIcms.pIcmsST).change();
  $('#itemIcmspCredSN').val(this.itemIcms.pCredSN).change();

  
  $('#itemIcmsvCredICMSSN').val(this.itemIcms.vCredICMSSN).change();
  $('#itemNFevTotTrib').val(this.itemNFe.vTotTrib).change();
  $('#itemIcmsvIcmsST').val(this.itemIcms.vIcmsST).change();

  $('#itemIpivBC').val(this.itemIpi.vBC).change();
  $('#itemIpivIpi').val(this.itemIpi.vIpi).change();
  $('#itemIpipIpi').val(this.itemIpi.pIpi).change();
 

  $('#itemPisvBC').val(this.itemPis.vBC).change();
  $('#itemPispPis').val(this.itemPis.pPis).change();
  $('#itemPisvPis').val(this.itemPis.vPis).change();
  $('#itemPisvBCST').val(this.itemPis.vBCST).change();
  $('#itemPisvPisST').val(this.itemPis.vPisST).change();
  $('#itemPisqBCProd').val(this.itemPis.qBCProd).change();
  $('#itemPisvAliqProd').val(this.itemPis.vAliqProd).change();
  $('#itemPispPisST').val(this.itemPis.pPisST).change();
  $('#itemPisqBCProdST').val(this.itemPis.qBCProdST).change();
  $('#itemPisvAliqProdST').val(this.itemPis.vAliqProdST).change();

  $('#itemCofinsvBC').val(this.itemCofins.vBC).change();
  $('#itemCofinspCofins').val(this.itemCofins.pCofins).change();
  $('#itemCofinsvCofins').val(this.itemCofins.vCofins).change();
  $('#itemCofinsvBCST').val(this.itemCofins.vBCST).change();
  $('#itemCofinsvCofinsST').val(this.itemCofins.vCofinsST).change();
  $('#itemCofinspCofinsST').val(this.itemCofins.pCofinsST).change();

  $('#itemCofinsqBCProd').val(this.itemCofins.qBCProd).change();
  $('#itemCofinsvAliqProd').val(this.itemCofins.vAliqProd).change();
  $('#itemCofinsqBCProdST').val(this.itemCofins.qBCProdST).change();
  $('#itemCofinsvAliqProdST').val(this.itemCofins.vAliqProdST).change();


  $('#duplicValor').val(this.duplic.valor).change();

  $('#nfevBC').val(this.nfe.vBC).change();
  $('#nfevIcms').val(this.nfe.vIcms).change();
  $('#nfevBCST').val(this.nfe.vBCST).change();
  $('#nfevST').val(this.nfe.vST).change();
  $('#nfevICMSDeson').val(this.nfe.vICMSDeson).change();
  $('#nfevFCP').val(this.nfe.vFCP).change();
  $('#nfevFCPST').val(this.nfe.vFCPST).change();
  $('#nfevFCPSTRet').val(this.nfe.vFCPSTRet).change();
  $('#nfevFrete').val(this.nfe.vFrete).change();
  $('#nfevSeguro').val(this.nfe.vSeguro).change();
  $('#nfevOutro').val(this.nfe.vOutro).change();
  $('#nfevReducao').val(0).change();
  $('#nfevIPI').val(this.nfe.vIPI).change();
  $('#nfevII').val(this.nfe.vII).change();
  $('#nfevPIS').val(this.nfe.vPIS).change();
  $('#nfevCofins').val(this.nfe.vCOFINS).change();
  $('#nfevProd').val(this.nfe.vProd).change();
  $('#nfevIPIDevol').val(this.nfe.vIPIDevol).change();
  $('#nfevTotTrib').val(this.nfe.vTotTrib).change();
  $('#nfevNF').val(this.nfe.vNF).change();
  $('#nfevEntrada').val(this.nfe.vEntrada).change();
 
}

beforeSaveItem(){

  //Item_not
  this.itemNFe.qtda = $('#itemNFeqtda').val(); 
  this.itemNFe.preco = $('#itemNFepreco').val(); 
  this.itemNFe.vProd = $('#itemNFevProd').val(); 
  this.itemNFe.vSeguro = $('#itemNFevSeguro').val(); 
  this.itemNFe.vDesconto = $('#itemNFevDesconto').val(); 
  this.itemNFe.vLucro = $('#itemNFevLucro').val(); 
  this.itemNFe.vOutro = $('#itemNFevOutro').val();
  this.itemNFe.vFrete = $('#itemNFevFrete').val();

}

beforeSaveImposto(){
    //Item_Icms
    this.itemIcms.vBC = $('#itemIcmsvBC').val();
    this.itemIcms.vIcms = $('#itemIcmsvIcms').val();
    this.itemIcms.vBCST = $('#itemIcmsvBCST').val();
    this.itemIcms.vIcmsST = $('#itemIcmsvIcmsST').val();
    this.itemIcms.vCredICMSSN = $('#itemIcmsvCredICMSSN').val();
    this.itemIcms.pIcms= $('#itemIcmspIcms').val();
    this.itemIcms.pRedBC = $('#itemIcmspRedBC').val();
    this.itemIcms.pIva = $('#itemIcmspIva').val();
    this.itemIcms.pRedBCST = $('#itemIcmspRedBCST').val();
    this.itemIcms.pIcmsST = $('#itemIcmspIcmsST').val();
    this.itemIcms.pCredSN = $('#itemIcmspCredSN').val();
    this.itemNFe.vTotTrib = $('#itemNFevTotTrib').val();

    //Item_Ipi
    this.itemIpi.vBC = $('#itemIpivBC').val();
    this.itemIpi.vIpi = $('#itemIpivIpi').val();
    this.itemIpi.pIpi = $('#itemIpipIpi').val();

    //Item_Pis
    this.itemPis.vBC = $('#itemPisvBC').val();
    this.itemPis.pPis = $('#itemPispPis').val();
    this.itemPis.vPis = $('#itemPisvPis').val();
    this.itemPis.vBCST = $('#itemPisvBCST').val();
    this.itemPis.vPisST = $('#itemPisvPisST').val();
    this.itemPis.qBCProd = $('#itemPisqBCProd').val();
    this.itemPis.vAliqProd = $('#itemPisvAliqProd').val();
    this.itemPis.pPisST = $('#itemPispPisST').val();
    this.itemPis.qBCProdST = $('#itemPisqBCProdST').val();
    this.itemPis.vAliqProdST = $('#itemPisvAliqProdST').val();

    //Item_Cofins
    this.itemCofins.vBC = $('#itemCofinsvBC').val();
    this.itemCofins.pCofins = $('#itemCofinspCofins').val();
    this.itemCofins.vCofins = $('#itemCofinsvCofins').val();
    this.itemCofins.vBCST = $('#itemCofinsvBCST').val();
    this.itemCofins.vCofinsST = $('#itemCofinsvCofinsST').val();
    this.itemCofins.pCofinsST = $('#itemCofinspCofinsST').val();
    this.itemCofins.qBCProd = $('#itemCofinsqBCProd').val();
    this.itemCofins.vAliqProd = $('#itemCofinsvAliqProd').val();
    this.itemCofins.qBCProdST = $('#itemCofinsqBCProdST').val();
    this.itemCofins.vAliqProdST = $('#itemCofinsvAliqProdST').val();


}

calcularIcms(){ 
  this.itemIcms.vIcms = ( ( $('#itemIcmsvBC').val() * $('#itemIcmspIcms').val() ) / 100);
  $('#itemIcmsvIcms').val(this.itemIcms.vIcms).change();

}

calcularCredICMSSN(){ 
  this.itemIcms.vCredICMSSN = ($('#itemIcmsvBC').val() * $('#itemIcmspCredSN').val())/100;
  $('#itemIcmsvCredICMSSN').val(this.itemIcms.vCredICMSSN).change();
}


calcularIpi(){ 
  this.itemIpi.vIpi = ($('#itemIpivBC').val() * $('#itemIpipIpi').val())/100;
  $('#itemIpivIpi').val(this.itemIpi.vIpi).change();
}

calcularPis(){ 
  this.itemPis.vPis = ($('#itemPisvBC').val() * $('#itemPispPis').val())/100;
  $('#itemPisvPis').val(this.itemPis.vPis).change();
}

calcularCofins(){ 
  this.itemCofins.vCofins = ($('#itemCofinsvBC').val() * $('#itemCofinspPis').val())/100;
  $('#itemCofinsvPis').val(this.itemPis.vPis).change();
}
}

