import {NgModule} from '@angular/core';
import {UsuariosListComponent } from './list/usuarios.list.component'
import {UsuariosEditorComponent } from './editor/usuarios.editor.component'
import {routing} from './usuarios.routing';
import { SharedModule } from '../../../shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    routing,
  ],
  declarations: [
    UsuariosListComponent,
    UsuariosEditorComponent
  ],
  providers: [],
})
export class UsuariosModule {

}