import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthToken } from './model/authToken';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { NFe, NFeResponse } from './model/nfe';
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Injectable({
  providedIn: 'root'
})
export class NFeService {
  constructor(private httpClient: HttpClient,
    private loginService: LoginService,
    private router: Router) { }

  servicePath: string = '/notafi';
  serviceNFePath: string = '/nfe';

  url:string = environment.apiUri + this.servicePath;
  urlNFe:string = environment.apiUri + this.serviceNFePath;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-access-token': this.loginService.getToken() })
  }

  delete(id:number) : Observable<any> {
    return this.httpClient.delete(this.url + '/' + id, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  getById(id:number) : Observable<NFeResponse> {
    return this.httpClient.get<NFeResponse>(this.url + '/' + id, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  create(entity:NFe) : Observable<any> {
    return this.httpClient.post(this.url, entity, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  update(entity:NFe) : Observable<any> {
    return this.httpClient.put(this.url + '/' + entity.id,entity, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  calcularCobranca(entity:NFe) : Observable<any> {
    return this.httpClient.post(this.url + '/calcularcobranca',entity, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  transportadora(entity:NFe) : Observable<any> {
    return this.httpClient.post(this.urlNFe + '/transportadora',entity, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

 async atualizarImpostos(entity:any) : Promise<any> {
    return await this.httpClient.post(this.url + '/atualizarImpostos',entity, this.httpOptions).toPromise()
     .catch(this.handleError);
  }

 async enviarNFe(data:any): Promise<any> {
    return await this.httpClient.post(this.urlNFe + '/EnviarNFe',data, this.httpOptions).toPromise()
      .catch(this.handleError);
  }

  async validarNFe(data:any): Promise<any> {
    return await this.httpClient.post(this.urlNFe + '/ValidarNFe',data, this.httpOptions).toPromise()
      .catch(this.handleError);
  }

  
 async atualizarStatus(data:any): Promise<any> {
    return await this.httpClient.post(this.urlNFe + '/AtualizarStatus',data, this.httpOptions).toPromise()
      .catch(this.handleError);
  }

  async atualizarNFeA3(data:any): Promise<any> {
    return await this.httpClient.post(this.urlNFe + '/atualizarnfeA3',data, this.httpOptions).toPromise()
      .catch(this.handleError);
  }

  async atualizarCCeA3(data:any): Promise<any> {
    return await this.httpClient.post(this.urlNFe + '/atualizarcceA3',data, this.httpOptions).toPromise()
      .catch(this.handleError);
  }
  
  async sequenciaCCe(id:number): Promise<any> {
    return await this.httpClient.get(this.urlNFe + '/sequenciacce/' + id, this.httpOptions).toPromise()
      .catch(this.handleError);
  }
  
  async statusServico(): Promise<any> {
    return await this.httpClient.get(this.urlNFe + '/StatusServico', this.httpOptions).toPromise()
      .catch(this.handleError);
  }

  visualizarNFe(data:any): void  {
    this.httpClient.post(this.urlNFe + '/ImprimirNFe',data,this.httpOptions).subscribe((res:any)=>{
      window.open(res.urlPdf)
    });
  }

  async inutilizarNFe(data:any): Promise<any> {
    return await this.httpClient.post(this.urlNFe + '/InutilizarNFe',data, this.httpOptions).toPromise()
      .catch(this.handleError);
  }
  async cancelarNFe(data:any): Promise<any> {
    return await this.httpClient.post(this.urlNFe + '/CancelarNFe',data,this.httpOptions).toPromise();
  }

  emailNFe(data:any):  Observable<any>  {
    return this.httpClient.post(this.urlNFe + '/EmailNFe',data, this.httpOptions)
    .pipe( 
      catchError(this.handleError)
    )
  }

  emailCCe(data:any):  Observable<any>  {
    return this.httpClient.post(this.urlNFe + '/EmailCCe',data, this.httpOptions)
    .pipe( 
      catchError(this.handleError)
    )
  }

  imprirmiCCe(data:any): void  {
    this.httpClient.post(this.urlNFe + '/ImprimirCCe',data,this.httpOptions).subscribe((res:any)=>{
      window.open(res.urlPdf)
    });
  }


  baixarXml(data:any): void  {
    this.httpClient.post(this.urlNFe + '/ImprimirNFe',data,this.httpOptions).subscribe((res:any)=>{
      window.open(res.urlXml)
    });
  }

  enviarCCe(data:any) : Observable<any> {
    return this.httpClient.post(this.urlNFe + '/EnviarCCe',data, this.httpOptions)
    .pipe( 
      catchError(this.handleError)
    )
  };
  duplicar(data:any) : Observable<any> {
    return this.httpClient.post(this.urlNFe + '/duplicarNFe',data, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    if(error.status == 401){
      Swal.fire({
        title:  "Desculpe!",
        text: "É necessário estar logado para acessar esta página.",
        icon: 'warning'
      });
      this.router.navigate(['/login']); 
    }
    return throwError(error);
  };

}
