import {ModuleWithProviders} from "@angular/core"
import {Routes, RouterModule} from '@angular/router';
import {FaturaListComponent} from './list/fatura.list.component'
import {FaturaEditorComponent} from './editor/fatura.editor.component'


export const routes: Routes = [
  {
    path: '', redirectTo: 'list', pathMatch: 'full'
  },
  {
    path: 'list',
    component:FaturaListComponent,
  },
  {
    path: 'edit',
    component:FaturaEditorComponent,
  },
  {
    path: 'edit/:id',
    component:FaturaEditorComponent,
  }

];

export const routing = RouterModule.forChild(routes);
