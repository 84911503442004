import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { routerTransition } from "../../util/animations";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoginService } from "src/app/services/login.service";
import { LoginData } from "src/app/services/model/loginData";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styles: [],
  animations: [routerTransition]
})
export class LoginComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private formBuilder: FormBuilder
  ) {}

  @ViewChild('invalidLoginMessage')
  public readonly invalidLoginMessage!: SwalComponent;

  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;

  loginForm: FormGroup;
  isSubmitted  =  false;
  isExpired = false;

  ngAfterViewInit(): void {

  }

  ngOnInit() {
    localStorage.clear();
    const expired: number = parseInt(this.route.snapshot.paramMap.get('isExpired'));
    this.isExpired = (expired==1);

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      senha: ['', Validators.required]
    });
  }

  get formControls() { return this.loginForm.controls; }

  login() {
    this.isSubmitted = true;

    if (this.loginForm.invalid) {
      return;
    }
    this.loginService.login(this.loginForm.value).subscribe(loginData => {
     
      localStorage.setItem('authToken', JSON.stringify(loginData))
      localStorage.setItem('userEmail',  loginData.data.email );
      localStorage.setItem('userNome',  loginData.data.nome );
      localStorage.setItem('idEmpresa',  loginData.data.entidades[0].idEntidade.toString() );
      localStorage.setItem('login','S');

      this.router.navigateByUrl('/dashboard');
    }, (error: HttpErrorResponse) =>{
      if(error.status == 404) {
        this.invalidLoginMessage.fire();
      } else {
        this.errorMessage.fire();
      }
    });
  }

  recuperarSenha(){
    var email = {email: $('#emailSenha').val() }
    this.loginService.recuperarSenha(email).subscribe(res => {
      Swal.fire({
        title:  "",
        // text: 'Ok, verifique seu e-mail para redefinir sua senha.',
        text: res.message,
        icon: 'success',
      });
      // this.router.navigate(['/login']);
    });
}


}
