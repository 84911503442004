import { Relatorio1Module } from './relatorio1.module';
import { Routes, RouterModule } from '@angular/router';
import { Relatorio1Component } from "./relatorio1.component"


export const relatorio1Routes: Routes = [{
  path: '',
  component: Relatorio1Component
}];

export const relatorio1Routing = RouterModule.forChild(relatorio1Routes);