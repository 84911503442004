<!-- <ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item"><a href="" routerLink="/">Kiron</a></li>
    <li class="breadcrumb-item active">Certificado</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
  </ol> -->
  <div class="subheader">
    <h1 class="subheader-title">
        <i class='subheader-icon fal fa-fingerprint'></i> Certificado
        <small>
            Configurações de certificado
        </small>
    </h1>
  </div>
  <div class="row">
    <div class="col-xl-12">
        <div id="panel-1" class="panel">
            <div class="panel-hdr">
                <h2>
                    Certificado <span class="fw-300"></span>
                </h2>
                <div class="panel-toolbar">
                 
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <label class="form-label">Tipo de Certificado: <span class="text-danger">*</span></label>
                            <div class="input-group">
                                  <select class="select2 form-control w-100"  id="tipoCertificado">
                                      <option value="">Selecione</option>
                                      <!-- <option value="div2">A1 (Arquivo)</option>
                                      <option value="div3">A3 (Cartão / Token)</option> -->
                                  </select>
                            </div>
                        </div>
                    </div>

            <div class="DivPai">    
                <div class="panel-container show">
                    <div class="panel-content">
                        <div class="row">
                        
                            <div class="card mb-g col-sm-12" id="A1" style="display: none;">
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="form-group col-sm-7">
                                            <label class="form-label">Selecionar o Certificado</label>
                                            <div class="custom-file">
                                                <input type="file" (change)="onFileSelected($event)" class="custom-file-input" id="customFile">
                                                <label class="custom-file-label"   for="customFile">{{nomeArquivoPFX}}</label>
                                            </div>
                                        </div>
                                        <div class="form-group col-sm-2">
                                            <label class="form-label" for="bairro">Senha</label>
                                            <div class="input-group">
                                                <input type="password" class="form-control" placeholder="" id="senha">
                                            </div>
                                        </div>
                                        <div class="form-group col-sm-3">
                                            
                                            <button type="button"  (click)="validarCertificado()" style="margin-top: 24px;" data-toggle="modal" data-target="#modalConfig" id="btnIncluirCertificado" class="btn btn-block btn-primary">
                                              Validar Certificado
                                            </button>
                                          </div>

                                        
                                        <!-- <div class="col-sm-12">
                                        <h4>
                                            Certificado Digital Configurado: <span class="fw-300"></span>
                                        </h4>
                                         </div>
                                        <div class="form-group col-sm-9">
                                            <label class="form-label" for="empresaCertificado">Empresa do Certificado</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" [(ngModel)]="entidadeAdm.certRazao" id="entidadeAdmCertRazao" disabled="">
                                            </div>
                                        </div>
                                        <div class="form-group col-sm-3">
                                            <label class="form-label" for="bairro">Data de Expiração</label>
                                            <div class="input-group">
                                                <input type="datetime-local" class="form-control" [ngModel]="entidadeAdm.certDtVencto | date:'yyyy-MM-ddTHH:mm'" id="entidadeAdmCcertDtVencto" disabled="">
                                            </div>
                                        </div> -->

                                    </div>
                                </div>
                            </div>

                            <div class="card mb-g col-sm-12" id="A3" style="display: none;">
                                <div class="card-body p-3">
                                    <div class="row">                                        
                                        <div class="col-sm-12">
                                            <h4>
                                                <span class="fw-300">Configure o Modulo de Segurança Kiron e teste seu certificado A3 </span>
                                            </h4>
                                            <div class="row">
                                            <div class="form-group col-sm-3">
                                                <a href="https://www.kiron.net.br/downloads/InstaladorMSKironA3.exe" target="_blank">
                                                    <button type="button" class="btn btn-block btn-primary">
                                                        <span class="fal fa-download mr-2"></span>
                                                        Download MSKiron
                                                    </button>
                                                </a>
                                            </div>
                                            <div class="form-group col-sm-3">
                                                <!-- <button type="button" (click)="testeA3()" data-toggle="modal" data-target="#modalConfig" id="btnIncluirCertificado" class="btn btn-block btn-success"> -->
                                                <button type="button" (click)="validarCertificado()"  data-toggle="modal" data-target="#modalConfig" id="btnIncluirCertificado" class="btn btn-block btn-success">
                                                    <span class="fal fa-check mr-2"></span>
                                                    Validar Certificado
                                                </button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <h4>
                                    Certificado Digital Configurado: <span class="fw-300"></span>
                                </h4>
                                 </div>
                                <div class="form-group col-sm-9">
                                    <label class="form-label" for="empresaCertificado">Empresa do Certificado</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" [(ngModel)]="entidadeAdm.certRazao" id="entidadeAdmCertRazao" disabled="">
                                    </div>
                                </div>
                                <div class="form-group col-sm-3">
                                    <label class="form-label" for="bairro">Data de Expiração</label>
                                    <div class="input-group">
                                        <input type="datetime-local" class="form-control" [ngModel]="entidadeAdm.certDtVencto | date:'yyyy-MM-ddTHH:mm'" id="entidadeAdmCcertDtVencto" disabled="">
                                    </div>
                                </div>


                        </div>
                    </div>   
                    </div> 
                </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="cancel()" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="save()" >Salvar</button>
            </div>
        </div>

    </div>
  </div>
 