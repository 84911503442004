<!-- <ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item"><a href="" routerLink="/">Kiron</a></li>
    <li class="breadcrumb-item active">Inutilizar</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
  </ol> -->
  <div class="subheader">
    <h1 class="subheader-title">
        <i class='subheader-icon fal fa-book-dead'></i> Inutilizar NF-e
        <small>
            inutilização de NF-e
        </small>
    </h1>
  </div>
  <div class="row">
    <div class="col-xl-12">
        <div id="panel-1" class="panel">
            <div class="panel-hdr">
                <h2>
                    Inutilização <span class="fw-300"></span>
                </h2>
                <div class="panel-toolbar">
                 
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="row">
                        <div class="form-group col-sm-2">
                            <label class="form-label" for="ano">Ano:<span class="text-danger">*</span></label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="" id="ano" required>
                            </div>
                        </div>
                        <div class="form-group col-sm-2">
                            <label class="form-label" for="modelo">Modelo:<span class="text-danger">*</span></label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="55" id="modelo" required>
                            </div>
                        </div>
                        <div class="form-group col-sm-2">
                            <label class="form-label" for="serie">Serie:<span class="text-danger">*</span></label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="" id="serie" required>
                            </div>
                        </div>
                        <div class="form-group col-sm-3">
                            <label class="form-label" for="de">A partir da nota:<span class="text-danger">*</span></label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="" id="nfde" required>
                            </div>
                        </div>
                        <div class="form-group col-sm-3">
                            <label class="form-label" for="ate">Até a nota:<span class="text-danger">*</span></label>
                            <div class="input-group">
                                <input type="number" class="form-control" placeholder="" id="nfate" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <label class="form-label" for="textCarta">Justificativa para a Inutilização da NF-e:<span class="text-danger">*</span></label>
                            <textarea class="form-control contador"  id="xJust" rows="3" maxlength="1000" minlength="15" ></textarea>
                            <!-- <label>Caracteres restantes <b><span class="caracteres">1000</span></b></label> -->
                          </div> 
                    </div>


            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="cancel()" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="inutilizar()" >Inutilizar</button>
            </div>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-12">
        <div id="panel-1" class="panel">
            <div class="panel-hdr">
                <h2>
                    Inutilização <span class="fw-300"></span>
                </h2>
                <div class="panel-toolbar">
                 
                </div>
            </div>
            <div class="panel-container show">
                <div class="panel-content">
                    <div class="row">
                        <div class="form-group col-sm-12">
                            <table datatable  [dtOptions]='dtOptions' id='inutilizadoTable' class="table table-bordered table-hover table-striped w-100">
                              <thead class="bg-primary-600">
                                  <tr>
                                      <th width="15%">Ano</th>
                                      <th width="25%">Modelo</th>
                                      <th width="25%">Serie</th>
                                      <th width="15%">A partir</th>
                                      <th width="15%">Até</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  
                              </tbody>
                            </table>
                
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#2196f3" type = "ball-running-dots" [fullScreen] = "true"><p style="color: white" > {{msgSpinner}} </p></ngx-spinner>