import {NgModule} from '@angular/core';
import { CfopListComponent } from './list/cfop.list.component'
import { CfopEditorComponent } from './editor/cfop.editor.component'
import { routing} from './cfop.routing';
import { SharedModule } from '../../../shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    routing,
  ],
  declarations: [
    CfopListComponent,
    CfopEditorComponent
  ],
  providers: [],
})
export class CfopModule {

}
