import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { LayoutModule } from '../layout/layout.module'
import { DataTablesModule } from "angular-datatables";
import { HttpClientModule } from "@angular/common/http";
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMaskModule, IConfig } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    LayoutModule,
    DataTablesModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    NgxSpinnerModule,
    NgxMaskModule,

  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    LayoutModule,
    DataTablesModule,
    HttpClientModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    NgxSpinnerModule
  ]
})

export class SharedModule {}