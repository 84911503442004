<!-- <ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item"><a href="" routerLink="/">Kiron</a></li>
  <li class="breadcrumb-item active">Empresa</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block"><span class="js-get-date"></span></li>
</ol> -->
<div class="subheader">
  <h1 class="subheader-title">
      <i class='subheader-icon fal fa-cog'></i> Empresa
      <small>
          Configurações da empresa
      </small>
  </h1>
</div>
<div class="row">
  <div class="col-xl-12">
      <div id="panel-1" class="panel">
          <div class="panel-hdr">
              <h2>
                  Empresa <span class="fw-300"></span>
              </h2>
              <div class="panel-toolbar">

               
              </div>
          </div>
          <div class="panel-container show">
              <div class="panel-content">
                  <div class="row">
                      <div class="form-group col-sm-12">
                          <label class="form-label" for="nome">Nome Fantasia <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <input type="text" class="form-control" [(ngModel)]="empresa.nome" placeholder="Nome fantasia da empresa" id="nome">
                          </div>
                      </div>
                      <!-- [(ngModel)]="empresa.nome" -->
                      <div class="form-group col-sm-12">
                          <label class="form-label" for="razaoSocial">Razão Social <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <input type="text" class="form-control" [(ngModel)]="empresa.razao" placeholder="Razão social da empresa" id="razaoSocial">
                          </div>
                      </div>

                      <div class="form-group col-sm-6">
                          <label class="form-label" for="cnpj">CNPJ <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <input type="text" class="form-control" [(ngModel)]="empresa.cnpj" placeholder="CNPJ da empresa" id="cnpj">
                          </div>
                      </div>
                      <div class="form-group col-sm-6">
                          <label class="form-label" for="ie">Inscrição Estadual <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <input type="text" class="form-control" [(ngModel)]="empresa.inscEst" placeholder="Inscrição estadual da empresa" id="ie">
                          </div>
                      </div>

                      <div class="form-group col-sm-2">
                          <label class="form-label" for="cep">Cep <span class="text-danger">*</span></label>
                          <div class="input-group">
                            <!-- Validar e preencher campos de acordo com o cep -->
                              <input type="text" required class="form-control" [(ngModel)]="empresa.cep" placeholder="" id="cep">
                          </div>
                        </div>
                        
                        <!-- <div class="form-group col-sm-4">
                          <label class="form-label" for="pais">País <span class="text-danger">*</span></label>
                          <div class="input-group">
                            <select class="select2 form-control w-100" [(ngModel)]="empresa.idPais" id="pais">
              
                            </select>
                          </div>
                        </div> -->
                        <div class="form-group col-sm-2">
                          <label class="form-label">Estado <span class="text-danger">*</span></label>
                          <div class="input-group">
                                <select class="select2 form-control w-100" id="estado">
                                </select>
                          </div>
                        </div>
                        <div class="form-group col-sm-4">
                          <label class="form-label">Cidade <span class="text-danger">*</span></label>
                          <div class="input-group">
                            <select class="select2 form-control w-100" id="cidade">
                            </select>
                          </div>
                        </div>
                  

                      <div class="form-group col-sm-8">
                          <label class="form-label" for="endereco">Endereço <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <input type="text" required class="form-control" [(ngModel)]="empresa.endereco" placeholder="Endereço" id="endereco">
                          </div>
                      </div>
                      <div class="form-group col-sm-4">
                          <label class="form-label" for="numero">Número <span class="text-danger">*</span></label>
                          <div class="input-group">
                              <input type="text" required class="form-control" [(ngModel)]="empresa.numero" placeholder="Número" id="numero">
                          </div>
                      </div>

                      <div class="form-group col-sm-6">
                          <label class="form-label" for="complemento">Complemento</label>
                          <div class="input-group">
                              <input type="text" class="form-control" [(ngModel)]="empresa.complemento" placeholder="Complemento" id="complemento">
                          </div>
                        </div>
                        <div class="form-group col-sm-6">
                          <label class="form-label" for="bairro">Bairro</label>
                          <div class="input-group">
                              <input type="text" class="form-control" [(ngModel)]="empresa.bairro" placeholder="Bairro" id="bairro">
                          </div>
                        </div>

                        <div class="form-group col-sm-8">
                          <label class="form-label" for="entidadeEmail">Email</label>
                          <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend"><i class="fal fa-envelope"></i></span>
                              </div>
                              <!-- <input type="email" name="email" ngModel [email]="true"> -->
                              <input type="text" class="form-control" [(ngModel)]="empresa.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" id="email"name="email" ngModel #emailref="ngModel">
                              <div *ngIf="emailref.errors &&(emailref.touched || emailref.dirty)">
                                  <div [hidden]="!emailref.errors?.pattern">
                                    <label class="form-label" style="padding-left: 20px; padding-top: 10px;"><span class="text-danger">Email inválido</span></label>
                                </div>
                              </div>
                            </div>
                            <span class="help-block">E-mail que será exibido na Nota Fiscal</span>  
              
                          </div>
                          <div class="form-group col-sm-4">
                            <label class="form-label" for="contatoTelefone">Telefone/Celular</label>
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupPrepend">
                                  <i class="fal fa-phone-alt"></i>
                                </span>
                              </div>
                                <input type="text" id="contatoTelefone" [(ngModel)]="empresa.fone" name="contatoTelefone" class="form-control">
                            </div>
                          </div>
                          
                          <div class="form-group col-sm-6">
                              <label class="form-label">Logomarca</label>
                              <div class="custom-file">
                                
                                  <input type="file" (change)="onFileSelected($event)" class="custom-file-input" accept="image/png, image/gif, image/jpeg" id="customFile">
                                  <label class="custom-file-label" for="customFile">Selecione o arquivo PNG</label>
                              </div>
                          </div>
                          <div class="form-group col-sm-3">
                            <label class="form-label">Optante do Simples Nacional? <span class="text-danger">*</span></label>
                            <div class="input-group">
                              <select class="js-hide-search form-control" id="simplesNacional" required>
                              </select>                        
                           </div>
                          </div>
                          <div class="form-group col-sm-3">
                            <label class="form-label" for="ultNota">Número da NF-e (início)</label>
                            <div class="input-group">
                                <input type="text" class="form-control" [(ngModel)]="empresa.ultNota" placeholder="ultNota" id="ultNota">
                            </div>
                          </div>

                  </div>

              </div>

          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="cancel()" data-dismiss="modal">Cancelar</button>
              <button type="button" class="btn btn-primary" (click)="save()" >Salvar</button>
          </div>
      </div>

  </div>
</div>
