<div class="page-wrapper auth">
    <div class="page-inner bg-brand-gradient ">
        <div class="page-content-wrapper bg-transparent m-0">
            <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                <div class="page-logo width-mobile-auto m-0 align-items-left justify-content-left p-0 bg-transparent bg-img-none shadow-0 height-9 border-0">
                    <a href="javascript:void(0)" class="page-logo-link press-scale-down d-flex align-items-center">
                        <img src="img/logoNovo.png" alt="Kiron" aria-roledescription="logo">
                    </a>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4" style="margin-top: 5%; align-items: center; align-self: center; align-content: center;">
                <h3 class="">
                    Nova Senha
                </h3>
                <div class="col-xl-12 card p-4 rounded-plus bg-faded" >
                    <form id="js-login" novalidate="" action="intel_analytics_dashboard.html">
                        <div class="form-group">
                            <label class="form-label" for="password">Nova Senha</label>
                            <input type="password" id="senha1" class="form-control form-control-lg"  placeholder="Digite a nova senha" required>
                        </div>
                        <div class="form-group">
                            <label class="form-label" for="password">Confirmar Nova Senha</label>
                            <input type="password" id="senha2" class="form-control form-control-lg"   placeholder="Confirme a nova senha" required>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-lg-12">
                                <button id="js-login-btn" (click)="saveSenha()" type="submit" class="btn btn-primary btn-block btn-lg" >Redefinir Senha</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
