import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { certificadoRouting } from './certificado.routing';
import { CertificadoComponent } from "./certificado.component";
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    certificadoRouting
  ],
  declarations: [CertificadoComponent]
})
export class CertificadoModule { }