import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthToken } from './model/authToken';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { NFeRefere, NFeRefereResponse } from './model/nfeRefere';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class NFeRefereService {
  constructor(private httpClient: HttpClient,
    private loginService: LoginService,
    private router: Router) { }

  servicePath: string = '/nfeRefere'

  url:string = environment.apiUri + this.servicePath;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-access-token': this.loginService.getToken() })
  }


  get() : Observable<NFeRefereResponse> {
    return this.httpClient.get<NFeRefereResponse>(this.url, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  getByIdNotafi(idNotafi:number) : Observable<NFeRefereResponse> {
    return this.httpClient.get<NFeRefereResponse>(this.url + '?idNotafi=' + idNotafi, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }
  getById(id:number) : Observable<NFeRefereResponse> {
    return this.httpClient.get<NFeRefereResponse>(this.url + '/' + id, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  create(entity:NFeRefere) : Observable<any> {
    return this.httpClient.post(this.url, entity, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  update(entity:NFeRefere) : Observable<any> {
    return this.httpClient.put(this.url + '/' + entity.id,entity, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  delete(id:number) : Observable<any> {
    return this.httpClient.delete(this.url + '/' + id, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
 
    }
    console.log(errorMessage);
    if(error.status == 401){
      Swal.fire({
        title:  "Desculpe!",
        text: "É necessário estar logado para acessar esta página.",
        icon: 'warning'
      });
      this.router.navigate(['/login']); 
    }
    return throwError(error);
  };

}
