import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { routerTransition } from '../../../../util/animations';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CfopService } from 'src/app/services/cfop.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'cp-list',
  templateUrl: './cfop.list.component.html',
  styles: [],
  animations: [routerTransition],
})
export class CfopListComponent
  implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private cfopService: CfopService
  ) {}

  dtOptions:  DataTables.Settings = {};
  title: string;
  description:string;
  currentPage: number = 0;
  currentPageSize:number = 10;
  filterIsAtivo: boolean = true;
  currentRecord: number;
  search: string = '';

  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('ativarDesativarConfirmationMessage')
  public readonly ativarDesativarConfirmationMessage!: SwalComponent;
  
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  ngAfterViewInit(): void {}

  ngOnInit() {
    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);
    window.functions.ativarDesativarEntity = this.ativarDesativarEntity.bind(this);

    var self = this;
    this.dtOptions = {
      processing:true,
      serverSide:true,
      ajax: {
     //   url: 'https://kironwebapi.azurewebsites.net/natura',
        url: environment.apiUri + '/natura',
        data: function (d) {
          var queryData = {
            page: d['start'] / d['length'] + 1,
            pageSize:d['length'],
            status: self.filterIsAtivo ? 'A' : 'I',
            search: self.search
          }
          self.currentPage = queryData.page;
          self.currentPageSize = queryData.pageSize;
          return queryData;
        },
        dataSrc : function(json) : any {
          json.recordsTotal = json.count;
          json.recordsFiltered = json.count
          return json.data;
        },
        async: true,
        error: function (xhr, error, code)
          {
            if((xhr.status == 400) || (xhr.status == 401)) {
              self.loginService.tokenExpired();
            }
          },
      headers: { 'x-access-token': this.loginService.getToken() }
      },
      columns : [
        { "data": "nome" },
        { "data": "cfop" },
        {
          "data": "id",
          render: (data, type, full) => {
            return `<div class='text-center'><a href='javascript:void(0);' onclick=\"functions.editEntity('${data}')\" class='btn btn-primary btn-sm btn-icon rounded-circle' title='Editar registro'><i class='fal fa-pen'></i></a>
                                              <a href='javascript:void(0);' onclick=\"functions.ativarDesativarEntity('${data}')\" class='btn btn-primary btn-sm btn-icon rounded-circle' title='Ativar/Desativar'><i class='fal fa-check'></i></a>
                                              <a href='javascript:void(0);' onclick=\"functions.deleteEntity('${data}')\" class='btn btn-danger btn-sm btn-icon rounded-circle' title='Deletar registro'><i class='fal fa-times'></i></a>
                    </div>`;
          }
        }
    ]
      /* render dynamic buttons */
    };

    //Exibe tela de editar no evento onclick na tabela
    // $('#tableList').on( 'click', 'tr', function () {
    //    var id = $('#tableList').DataTable().cell( this, 2 ).data();
    //    self.editEntity( id );
    // } );


    this.title = 'Natureza de Operação (CFOP)';
    this.description = 'Lista de natureza de operação ativos e inativos'
  }

searchLista() {
  $('#tableList').DataTable().ajax.reload();
 }
  
ngOnDestroy() {
  window.functions = null;
}

changeAtivoFilter(data:boolean) {
  this.filterIsAtivo = data;
  $('#tableList').DataTable().ajax.reload();
}

new() {
   this.router.navigate(['/cadastros/cfop/edit'])
}

editEntity(data) {
    this.router.navigate(['/cadastros/cfop/edit', data])
}

deleteEntity(data) {
  this.currentRecord = data;
  this.deleteConfirmationMessage.fire();
}

ativarDesativarEntity(data) {
  var msgAtivo: string = this.filterIsAtivo ? 'desativar' : 'ativar';
  this.currentRecord = data;
  Swal.fire({
    title:  "",
    text: 'Tem certeza que deseja ' + msgAtivo + ' este cadastro?',
    icon: "question",
    showDenyButton: true,
    showConfirmButton:true,
    denyButtonText:"Cancelar",
    confirmButtonText:"Confirmar",
    }).then((result) => {
       if (result.value) { 
          this.confirmAtivarDesativar();
       }
   });

  this.ativarDesativarConfirmationMessage.fire();
}

confirmDelete() {
  this.cfopService.delete(this.currentRecord).subscribe( () => {
    $('#tableList').DataTable().ajax.reload();
    this.currentRecord = -1;
  }, error => {
    this.errorMessage.fire();
    this.currentRecord = -1;
  });
}

confirmAtivarDesativar() {
  var isAtivo: string = this.filterIsAtivo ? 'N' : 'S';

  this.cfopService.getById(this.currentRecord).subscribe( response=> {
    response.data.isAtivo = isAtivo;
    response.data.isUso = isAtivo;
    this.cfopService.update(response.data).subscribe( response=> {
         $('#tableList').DataTable().ajax.reload();
    } , error => {
      Swal.fire({
        title:  "Erro!",
        text: error.error.message,
        icon: 'error'
    });
    this.currentRecord = -1;
  });
     this.currentRecord = -1;
  });
}


handleDenial() {

}

onKeyPesquisar(event: any) {
    this.search = event.target.value;
  // console.log(event.target.value);
}

}
