import { NgModule } from '@angular/core';
import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { LoginService } from 'src/app/services/login.service';
import { NFeService } from 'src/app/services/nfe.service';
import {Location} from '@angular/common';
import { environment } from 'src/environments/environment';

declare var $:any
declare var localStorage:any;

@Component({
  selector: 'sa-inutilizar',
  templateUrl: './inutilizar.component.html',
  styles: [],
})

export class InutilizarComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private nfeService: NFeService,
    private _location: Location,
    private spinnerService: NgxSpinnerService,

  ) {}
  dtOptions:  DataTables.Settings = {};
  title: string;
  description:string;
  currentPage: number = 0;
  currentPageSize:number = 10;
  currentId: number = -1;
  msgSpinner: string;

  dtTrigger: Subject<any> = new Subject();

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {

    var self = this;
    $("#modelo").val('55').change();

    this.dtOptions = {
      processing:true,
      serverSide:true,
      ajax: {
       // url: 'https://kironwebapi.azurewebsites.net/inutilizar',
        url: environment.apiUri + '/inutilizar',
        data: function (d) {
          var queryData = {
            page: d['start'] / d['length'] + 1,
            pageSize:d['length'],
            idEmpresa: localStorage.getItem('idEmpresa')
          }
          self.currentPage = queryData.page;
          self.currentPageSize = queryData.pageSize;
          return queryData;
        },
        dataSrc : function(json) : any {
          json.recordsTotal = json.count;
          json.recordsFiltered = json.count
          return json.data;
        },
        statusCode: {
          401: function() {
            self.loginService.tokenExpired();
           },
        },

        headers: { 'x-access-token': this.loginService.getToken() }
      },

      columns : [
        { "data": "ano" },
        { "data": "modelo" },
        { "data": "serie" },
        { "data": "nfDe" },
        { "data": "nfAte" },
        // { "data": "id" }
      ],
      /* render dynamic buttons */
    };

   
   
    this.title = 'Inutilizar NF-e';
    this.description = 'Lista de notas inutilizadas'

  }

  ngOnDestroy() {
    window.functions = null;
    this.dtTrigger.unsubscribe();
  }

  desabilita(){
    // document.getElementById("myFieldset").disabled=true;

  // $('#formTeste').collapse('hide');
  $("#formTeste2 :input").attr("disabled", true)
  
  // var controls = document.getElementById("#formTeste").getElementsByTagName("input");

  // for (var i = 0; i < controls.length; i++)
  // controls[i].disabled = true;
  }

  cancel() {
    this._location.back();
  }
  
  async inutilizar(){
    if(($("#ano").val() == '') || ($("#modelo").val() == '') || ($("#serie").val() == '') || 
       ($("#nfde").val() == '') || ($("#nfate").val() == '') || ($("#xJust").val() == '')) {
       Swal.fire({
         title:  "",
         text: 'Ops, falta informar campos obrigatórios.',
         icon: 'error',
       });
    } else {
      this.msgSpinner = 'Inutilizando nota fiscal...';
      this.spinnerService.show();
      var inutilizar = 
          { 
            idEmpresa: localStorage.getItem('idEmpresa'),
            ano: $('#ano').val(), 
            modelo: $('#modelo').val(), 
            serie: $('#serie').val(), 
            nfde: $('#nfde').val(), 
            nfate: $('#nfate').val(), 
            xJust: $('#xJust').val()
          };
      await this.nfeService.inutilizarNFe(inutilizar).then((res: any) => {
          this.spinnerService.hide();
          if(res.status == 102) {
            Swal.fire({
              title:  "",
              text: 'Inutiliazação realizada com sucesso.',
              icon: 'success',
            });
            $('#tableList').DataTable().ajax.reload();
            } else {
              Swal.fire({
                title:  "Ops!",
                text: atob(res.message),
                icon: 'error',
              })
            }  
      });
    }
  }

}
