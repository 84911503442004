import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthToken } from './model/authToken';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { VendedorResponse } from './model/vendedor';

@Injectable({
  providedIn: 'root'
})
export class VendedorService {
  constructor(private httpClient: HttpClient,
    private loginService: LoginService,
    private router: Router) { }

  servicePath: string = '/vende'

  url:string = environment.apiUri + this.servicePath;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-access-token': this.loginService.getToken() })
  }

  get() : Observable<VendedorResponse> {
    return this.httpClient.get<VendedorResponse>(this.url, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(error);
  };

}
