import {ModuleWithProviders} from "@angular/core"
import {Routes, RouterModule} from '@angular/router';
import {CfopListComponent} from './list/cfop.list.component'
import {CfopEditorComponent} from './editor/cfop.editor.component'


export const routes: Routes = [
  {
    path: '', redirectTo: 'list', pathMatch: 'full'
  },
  {
    path: 'list',
    component:CfopListComponent,
  },
  {
    path: 'edit',
    component:CfopEditorComponent,
  },
  {
    path: 'edit/:id',
    component:CfopEditorComponent,
  }

];

export const routing = RouterModule.forChild(routes);
