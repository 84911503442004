import { Component, OnInit, AfterViewInit } from "@angular/core";
import { LoginService } from "../services/login.service";
import { routerTransition } from "../util/animations";
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { EmpresaService } from "src/app/services/empresa.service";
import { EntidadeAdmService } from "src/app/services/entidadeAdm.service";


declare function initMe(): any;
declare var $:any
declare var moment:any
declare var Inputmask:any
declare var localStorage:any;

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styles: [],
  animations: [routerTransition]
})


export class MainLayoutComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private location: Location,
    private empresaService: EmpresaService,
    private entidadeAdmService: EntidadeAdmService,
  ) {
  
  
  }
  
  idEmpresa: number;
  userEmail: string;
  userNome: string;
  
  ngAfterViewInit(): void {

    $.extend( true, $.fn.dataTable.defaults, {
      "searching": false,
      "ordering": false,
      "language":  {
        url: "https://cdn.datatables.net/plug-ins/1.10.22/i18n/Portuguese-Brasil.json"
      }
    });


    initMe();
  }

  ngOnInit() {
    var self = this;
    self.fillEmpresas();
    self.idEmpresa = localStorage.getItem('idEmpresa');
    self.userEmail = localStorage.getItem('userEmail');
    self.userNome = localStorage.getItem('userNome');
  
    if(localStorage.getItem('login') == 'S'){
        localStorage.setItem('login','N');
        self.selecionarEmpresa(self.idEmpresa);
       // self.router.navigate(['/dashboard']);
    }
    $('#selectMainEmpresa').on('select2:select', function(e) {
      localStorage.setItem('idEmpresa',$('#selectMainEmpresa').val());
      self.selecionarEmpresa($('#selectMainEmpresa').val());
      let currentUrl = self.router.url;
      if(currentUrl == '/dashboard'){     
          self.router.navigate(['faturamento/list']);
      } else {
          self.router.navigate(['/dashboard']);

      }
   //   self.router.navigateByUrl('dashboard', { skipLocationChange: true })
      
        //    var data = e.params.data;
             // localStorage.setItem('Login','N');
            // localStorage.setItem('idEmpresa',id);
            // localStorage.setItem('isEmpresaAtiva', data.isEmpresaAtiva);
            // localStorage.setItem('dtValidadeChave', data.dtValidadeChave);
            // localStorage.setItem('diasValidade', data.diasValidade);

            // localStorage.setItem('isEmpresaAtiva', data.isEmpresaAtiva);
            // localStorage.setItem('dtValidadeChave', data.dtValidadeChave);
            // localStorage.setItem('diasValidade', data.diasValidade);
           // window.location.reload();
    });
    (<any>window).Olvy.init({
      organisation: "kiron",
      target: "#olvy-target",
      type: "modal",
      view: {
      showSearch: false,
      compact: false,
      showHeader: true,
      showUnreadIndicator: true,
      unreadIndicatorColor: "#cc1919",
      unreadIndicatorPosition: "top-right",
      },
      });

  };

  getState(outlet) {
    if(!outlet.activatedRoute) return;
    let ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }

  empresa(){
    $('#pageHeader').click();
    this.router.navigate(['/empresa']);
  }

  usuarios(){
    $('#pageHeader').click();
    this.router.navigate(['/cadastros/usuarios']);
  }

  certificado(){
    $('#pageHeader').click();
    this.router.navigate(['/certificado']);
  }

  inutilizar(){
    $('#pageHeader').click();
    this.router.navigate(['/inutilizar']);
  }
  
  logout() {
    this.loginService.logout();
  }

  
  // fillEmpresas() {
  //   var finalData = $.map(this.loginService.getEmpresas(), function (obj) {
  //     obj.id = obj.entidadeAdm.id// replace pk with your identifier
  //     obj.text = obj.entidadeAdm.id + ' - ' + obj.entidadeAdm.nome,
  //      obj.isEmpresaAtiva =  obj.entidadeAdm.isAtivo;
  //     // obj.dtValidadeChave = obj.entidadeAdm.dtValidadeChave,
  //     // obj.diasValidade =  obj.entidadeAdm.diasValidade;
  //     alert('fillEmpresas - mani-lout');
  //     return obj;
  //   });
    
  //   $('#selectMainEmpresa').select2({
  //     data: finalData,
  //     placeholder: 'Empresas',
  //     //dropdownParent: $('#modalEmpresa'),
  //   });
  // };


  fillEmpresas() {
  //  alert('filleEmpresas');
    var self = this;
    var idEmp;
    var isEmpresaAtiva,dtValidadeChave,diasValidade;
    var finalData = $.map(this.loginService.getEmpresas(), function (obj) {
      return {
          idEmp:  obj.entidadeAdm.id,
          isEmpresaAtiva:  obj.entidadeAdm.isAtivo,
          dtValidadeChave: obj.entidadeAdm.dtValidadeChave,
          diasValidade:  obj.entidadeAdm.diasValidade,
          id: obj.entidadeAdm.id, // replace pk with your identifier
          text: obj.entidadeAdm.id + ' - ' +obj.entidadeAdm.nome
      } 
     // return obj;
    });

    $('#selectMainEmpresa').select2({
      data: finalData,
      placeholder: 'Empresas',
    //  dropdownParent: $('#modalEmpresa'),
    });
    // localStorage.setItem('idEmpresa',idEmp);
    // localStorage.setItem('isEmpresaAtiva', isEmpresaAtiva);
    // localStorage.setItem('dtValidadeChave', dtValidadeChave);
    // localStorage.setItem('diasValidade', diasValidade);
    // this.selecionarEmpresa();
   };
  
 
async selecionarEmpresa(id:number){
//   var id;
//    alert('selecionarEmpresa');
//   id = localStorage.getItem('idEmpresa');
  await this.empresaService.getById(id).subscribe(res => {
      //  localStorage.setItem('isSimples',  res.data.isSimples == 'S' ? true : false ); // cuidado boolean não funciona no localStorage

      localStorage.setItem('isSimples',  res.data.isSimples );
       localStorage.setItem('UF',  res.data.uf );
       localStorage.setItem('tpAmbiente', res.data.tpAmb);
       localStorage.setItem('vUnCom', res.data.vUnCom);
  //     window.location.reload();
//         document.location.reload(); 
  });
  await this.entidadeAdmService.getById(id).subscribe(res => {
       localStorage.setItem('tipoCertificado',  res.data.certTipo );
       localStorage.setItem('isEmpresaAtiva', res.data.isAtivo);
       localStorage.setItem('dtValidadeChave', res.data.dtValidadeChave);
      //  localStorage.setItem('diasValidade', res.data.diasValidade);
   });
  }
}
