import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthToken,AuthEntity } from './model/authToken';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginData } from './model/loginData';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private httpClient: HttpClient,
    private router: Router) { }

  servicePath: string = '/usuario/authenticate';

  url:string = environment.apiUri + this.servicePath;

  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json' })
  }

  login(loginData:LoginData) : Observable<AuthToken> {
    return this.httpClient.post<AuthToken>(this.url, JSON.stringify(loginData), this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  logout () {
    localStorage.removeItem('authToken');
    this.router.navigate(['/login']);
  }

  refreshToken() : Observable<AuthToken> {
    var token: AuthEntity = JSON.parse(localStorage.getItem('authToken'));
    const urlRefresh:string = environment.apiUri + '/usuario/refresh-token';
    
    return this.httpClient.post<AuthToken>(urlRefresh, JSON.stringify(token), this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    );
  }

  getToken() : string {
    var token: AuthToken = JSON.parse(localStorage.getItem('authToken'))
    return token.token;
  }

  
  getEmpresas() {
    var empresas: AuthToken = JSON.parse(localStorage.getItem('authToken'));
    console.log('Empresas:', empresas.data.entidades);
    return empresas.data.entidades;
  }
  
  tokenExpired(){
    var refreshToken = this.refreshToken();
    localStorage.setItem('authToken', JSON.stringify(refreshToken));
    // Swal.fire({
    //   title:  "Desculpe!",
    //   text: "É necessário estar logado para acessar esta página.",
    //   icon: 'warning'
    // });
    // this.router.navigate(['/login']); 
  }

  recuperarSenha(email:any) : Observable<any> {
    return this.httpClient.post(environment.apiUri + '/usuario/recuperarsenha',email, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }
  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(error);
  };

}
