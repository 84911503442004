import { Component, OnInit, AfterViewInit,ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { DataTableDirective } from "angular-datatables";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { UsuarioService } from "src/app/services/usuarios.service";
import { Usuario } from "src/app/services/model/usuario";
import { LoginService } from "src/app/services/login.service";
import { routerTransition } from "../../../../util/animations";

declare var $:any

@Component({
  selector: "cp-editor",
  templateUrl: "./usuarios.editor.component.html",
  styles: [],
  animations: [routerTransition]
})

export class UsuariosEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuarioService,
    private loginService: LoginService,
    private spinnerService: NgxSpinnerService,
  ) {}

  title: string;
  description:string;
  dtOptions: DataTables.Settings = {};
  currentId: number = -1;
  usuario: Usuario = {} as Usuario;
  msgErroServer: string = "Ops, aconteceu algum imprevisto no servidor. ";
  
  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  @ViewChild('successMessage')
  public readonly successMessage!: SwalComponent;
  @ViewChild('validationMessage')
  public readonly validationMessage!: SwalComponent;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtTrigger: Subject<any> = new Subject();

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    var self = this;

    const id: number = parseInt(this.route.snapshot.paramMap.get('id'));
    this.currentId = id;

    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);

    // this.fillOrigemProduto();
    this.fillStatus();
    $('#status').val('A').change();

    self.dtOptions = {
      searching:false,
      lengthChange: false
      /* render dynamic buttons */
    };

    this.route.data.subscribe(params => {
    
      if(id) 
      {
        this.spinnerService.show();
        this.usuarioService.getById(id).subscribe(response=> {
          this.spinnerService.hide();
          this.usuario = response.data;
          //this.updateDataTable();
          
          if(this.usuario.isAtivo == 'S') {
            $('#status').val('A').change();
         } else {
            $('#status').val('I').change();
         };
        }, error => {
          if(error.status == 401){
            self.loginService.tokenExpired();
          }

        })
      } 
    });
    
    this.title = 'Usuarios';
    this.description = 'Cadastro de usuarios'

//    $('.select2').select2();


  }
 
//   async fillOrigemProduto() {
//     await this.origemProdutoService.get().subscribe(data=> {
//       var finalData = $.map(data.data, function (obj) {
//         obj.id = obj.id || obj.pk; // replace pk with your identifier
//         obj.text = obj.nome;
//         if(obj.id == undefined){ obj.id = 0};
//         return obj;
//       });
//       $('#origemproduto').select2({
//         data: finalData,
//         placeholder: 'Origem do produto'
//       });
//     })
//   };

  fillStatus(){
    var statusData = [
      {'id': "A", 'text': 'Ativo'},
      {'id': "I", 'text': 'Inativo'},
     ];

      
    $('#status').select2({
      data: statusData,
      allowClear: true,
      minimumResultsForSearch: -1
    });
  }
  
  editEntity(data) {
    var self = this;
  }

  deleteEntity(data) {
     this.deleteConfirmationMessage.fire();
  }

  confirmDelete() {
   // this.updateDataTable();
  }

  ngOnDestroy() {
    window.functions = null;
    this.dtTrigger.unsubscribe();
  }

  // updateDataTable() {

  //   var self = this;
    
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // Destroy the table first
  //     dtInstance.destroy();
  //     // Call the dtTrigger to rerender again
  //     this.dtTrigger.next();
  //   });
  // }

  cancel() {
      this.router.navigate(['/cadastros/usuarios']);    
  }

  async save() {
    var self = this;
    this.usuario.isAtivo = ($('#status').val() == 'A' ? 'S': 'N');
    this.usuario.idEmpresa = parseInt(localStorage.getItem('idEmpresa'));
    
    if( (this.usuario.nome == '')) {
      this.validationMessage.fire();
    } else {

        this.spinnerService.show();
        if(this.usuario.id > 0) {
          this.usuarioService.update(this.usuario).subscribe(res => {
            this.spinnerService.hide();
            Swal.fire({
              title:  "",
              text: res.message,
              icon: 'success'
          });
            this.router.navigate(['/cadastros/usuarios']);
          }, error => {
            Swal.fire({
                title:  "Erro!",
                text: this.msgErroServer + error.error.message,
                icon: 'error'
            });
          });
        } else {
          await this.usuarioService.create(this.usuario).subscribe(res=> {
            this.spinnerService.hide();
            Swal.fire({
              title:  "",
              text: res.message,
              icon: 'success'
          });
            this.router.navigate(['/cadastros/usuarios']);
        }, error => {
            this.errorMessage.fire();
          });
        }
      }
  }

  handleDenial() {

  }

  validacao() {
    window.addEventListener('load', function() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('button', function(event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
      }, false);
    });
    }, false);
  };

  
}