<div class="subheader">
  <h1 class="subheader-title">
    <i class="subheader-icon fal fa-file-invoice-dollar"></i> {{ title }}
    <small>
      {{ description }}
    </small>
  </h1>
</div>

<div class="accordion accordion-outline" id="js_demo_accordion-3" style="background-color: #fff; box-shadow: 0 3px 3px -1px #e2e2e2;">
  <div class="card" style="background-color: #fff;">
    <div class="card-header" style="background-color: #fff;">
      <a href="javascript:void(0);" class="card-title" data-toggle="collapse" data-target="#js_demo_accordion-3a" aria-expanded="false">        
        
        <span class="fw-100" style="color: rgb(10, 10, 10);">Filtro</span>
        <span class="ml-auto">          
          <span class="collapsed-hidden">
            <i class="fal fa-plus fs-xl"></i>
          </span>
          <span class="collapsed-reveal">
            <i class="fal fa-minus fs-xl"></i>
          </span>
        </span>
      </a>
    </div>
    <div id="js_demo_accordion-3a" class="collapse hide" data-parent="#js_demo_accordion-3" style="background-color: #fff;">
      <div class="card-body" style="background-color: #fff;">
        <div class="panel-content" style="background-color: #fff;">

          <div class="row">
            <div class="form-group col-sm-4">
              <label class="form-label" for="dataNota">Data</label>

              <div class="input-group">
                  <input type="text" class="form-control" placeholder="Selecione a data" id="filterData">
                  <div class="input-group-append">
                      <span class="input-group-text fs-xl">
                          <i class="fal fa-calendar"></i>
                      </span>
                  </div>
              </div>

            </div>
            <div class="form-group col-sm-2">
              <label class="form-label" for="numeroNota">Número</label>
              <input type="text" id="filterNumeroNota" class="form-control">
            </div>
            <div class="form-group col-sm-6">
              <label class="form-label" for="destinatarioNota">Destinatário</label>
              <input type="text" id="filterDestinatario" class="form-control">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-sm-4">
              <label class="form-label" for="valorNotaMinimo">Valor(de)</label>
              <input type="text" id="filterValorDe" class="form-control">
            </div>
            <div class="form-group col-sm-4">
              <label class="form-label" for="valorNotaMaximo">Valor(até)</label>
              <input type="text" id="filterValorAte" class="form-control" >
            </div>
            <div class="form-group col-sm-4">
              <label class="form-label" for="cfopNota">CFOP</label>
              <input type="text" id="filterCfop" class="form-control">
            </div>
          </div>

          <div class="row">
            <div class="form-group col-sm-4">
              <label class="form-label" for="statusNota">Status da Nota</label>
              <select class="select2 form-control" multiple="multiple" id="statusNota">
            </select>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="form-group col-sm-12">             
              <button style="vertical-align:middle; float:right; margin: 5px;" type="button" (click)="filtrar()" class="btn btn-sm btn-primary">
                <span class="fal fa-filter mr-1"></span>
                Filtrar
              </button>
              <button style="vertical-align:middle; float:right; margin: 5px;" type="button" (click)="limparFiltros()"  id="btnLimparFiltros" class="btn btn-sm btn-secondary">
                <span class="fal fa-sync mr-1"></span>
                Limpar Filtros
              </button>
              
            </div>
          </div>
      </div>      
    </div>    
  </div>  
  </div>
</div>

<!-- <div class="row">
  <div class="col-sm-12">
    <div id="panel-1" class="panel">
      <div class="panel-hdr">
        <h2>Filtros <span class="fw-300"></span></h2>
        <div class="panel-toolbar">
          <button
            class="btn btn-panel"
            data-action="panel-collapse"
            data-toggle="tooltip"
            data-offset="0,10"
            data-original-title="Esconder/Mostrar"
          ></button>
        </div>
      </div>
      <div class="panel-container collapse">
        <div class="panel-content">
          <div class="row">
            <div class="form-group col-sm-4">
              <label class="form-label" for="dataNota">Data</label>

              <div class="input-group">
                  <input type="text" class="form-control" placeholder="Selecione a data" id="dataNota">
                  <div class="input-group-append">
                      <span class="input-group-text fs-xl">
                          <i class="fal fa-calendar"></i>
                      </span>
                  </div>
              </div>

            </div>
            <div class="form-group col-sm-2">
              <label class="form-label" for="numeroNota">Número</label>
              <input type="text" id="numeroNota" class="form-control">
            </div>
            <div class="form-group col-sm-6">
              <label class="form-label" for="destinatarioNota">Destinatário</label>
              <input type="text" id="destinatarioNota" class="form-control">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-4">
              <label class="form-label" for="valorNotaMinimo">Valor(de)</label>
              <input type="text" id="valorNotaMinimo" class="form-control" value="0">
            </div>
            <div class="form-group col-sm-4">
              <label class="form-label" for="valorNotaMaximo">Valor(até)</label>
              <input type="text" id="valorNotaMaximo" class="form-control" value="0">
            </div>
            <div class="form-group col-sm-4">
              <label class="form-label" for="cfopNota">CFOP</label>
              <input type="text" id="cfopNota" class="form-control">
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-4">
              <label class="form-label" for="statusNota">Status da Nota</label>
              <select class="select2 form-control" multiple="multiple" id="statusNota">
                <option value="AK">Em digitação</option>
                <option value="AK">Transmitindo</option>
                <option value="HI">Aprovada</option>
                <option value="AK">Cancelada</option>
                <option value="AK">Denegada</option>
            </select>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="form-group col-sm-2">
              <button type="button" class="btn btn-sm btn-primary">
                <span class="fal fa-filter mr-1"></span>
                Filtrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->


<div class="row">
  <div class="col-sm-12">
    <div id="panel-1" class="panel">
      <div class="panel-hdr">
        <h2>{{ title }} <span class="fw-300"></span></h2>
        <div class="panel-toolbar">
          <!-- <a routerLink="/faturamento/edit"> -->
            <button type="button" (click)="new()"  class="btn btn-sm btn-primary">
              <span class="fal fa-plus mr-1"></span>
              Novo
            </button>
          <!-- </a> -->
          
        </div>
      </div>
      <div class="panel-container show">
        <div class="panel-content">
          <!-- datatable start -->
          <table [dtOptions]='dtOptions' datatable id="tableList" class="table table-hover table-striped w-100">
            <thead class="bg-primary-600">
              <tr>
                <th>Status</th>
                <th data-order="dd/MM/yyyy" width="14%">Emissão</th>
                <th>Número</th>
                <th>Destinatário</th>
                <th DataFormatString = "{0:c2}" width="11%">Valor</th>
                <th>Cfop</th>
                <th width="17%"></th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr>
                <td>
                  <div class="text-center">
                    <a
                      href="javascript:void(0);"
                      class="btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1"
                      title="Edit Record"
                    >
                      <i class="fal fa-pen"></i>
                    </a>
                    <a
                      href="javascript:void(0);"
                      class="btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1"
                      title="Delete Record"
                    >
                      <i class="fal fa-times"></i>
                    </a>
                    <div class='dropdown d-inline-block'>
                      <a class='btn btn-sm btn-icon btn-outline-primary rounded-circle shadow-0' data-toggle='dropdown' aria-expanded='false' title='Mais opções'>
                        <i class="fal fa-ellipsis-v"></i>
                      </a>
                      <div class='dropdown-menu'>
                        <a class='dropdown-item' href='javascript:void(0);'>Emitir</a>
                        <a class='dropdown-item' href='javascript:void(0);'>Visualizar Danfe</a>
                        <a class='dropdown-item' href='javascript:void(0);'>Enviar por e-mail</a>
                        <a class='dropdown-item' href='javascript:void(0);'>Cancelar</a>
                        <a class='dropdown-item' href='javascript:void(0);'>Emitir CC-e</a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr> -->
            </tbody>
          </table>
          <!-- datatable end -->
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modalEmail" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    Enviar NF-e por e-mail
                    <small class="m-0 text-muted">
                        Acrescente abaixo os e-mails para envio da NF-e separando com ;
                    </small>
                </h4>
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i class="fal fa-times"></i></span>
                </button> -->
            </div>
            <div class="modal-body">
              <div class="form-group col-sm-12">
                <label class="form-label">E-mails</label>
                <!-- <input type="email" [(ngModel)]="nfe.entidade.email" class="form-control"> -->
                <input type="email" [(ngModel)]="emailDestinatario" class="form-control">
              </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
                <button type="button" class="btn btn-primary" (click)="email()">Enviar</button>
            </div>
        </div>
    </div>
  </div>

  <div class="modal fade" id="modalCCe" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Carta de Correção Eletrônica (CC-e)</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-secondary alert-dismissible fade show" role="alert">
            <div class="d-flex align-items-center">
              <div class="alert-icon">
                <i class="fal fa-exclamation-circle"></i> 
              </div>
              <div class="flex-1">
                <span class="m-0 fw-400" style="font-size: 12px;">
                  A Carta de Correção é disciplinada pelo 1º-A do art. 7º do Convênio S/N, de 15 de dezembro de 1970 e pode ser utilizada para regularização de erro ocorrido na emissão de 
                  documento fiscal, desde que o erro não esteja relacionado com:<br />
                     I - as variáveis que determinam o valor do imposto tais como: base de cálculo, alíquota, diferença de preço, quantidade, valor da operação ou da prestação;<br />
                     II - a correção de dados cadastrais que implique mudança do remtente ou do destinatário;<br />
                     III - a data de emissão ou de saída.</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" for="textCarta">Descrição da carta</label>
            <!-- <textarea class="form-control" id="xCorrecao" rows="4"></textarea> -->
            <textarea class="form-control" id="xCorrecao" rows="4" minlength="15" placeholder="Digite pelo menos 15 caracteres"></textarea>
          </div>
          <div class="modal-footer">        
            <button type="button" class="btn btn-primary" data-toggle="modal" (click)="enviarCCe()">Enviar Carta</button>
          </div>
          <div class="panel-content col-12">
            <table [dtOptions]='dtOptionsCCe' datatable id="tableListCCe" class="table table-hover table-striped w-100">
              <thead class="bg-primary-600">
                <tr>
                  <th width="15%">Data</th>
                  <th width="5%">Sequencia</th>
                  <th width="50%">Chave</th>
                  <th width="30%"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="modalCarregando" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Enviando para o SEFAZ</h5>
          <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="fal fa-times"></i></span>
          </button> -->
        </div>
        <div class="modal-body">
          <div class="progress progress-lg">
            <div class="progress-bar bg-primary" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">100%</div>
          </div><br>
          <div class="alert alert-success">
            <label class="form-label" >Enviado com sucesso!</label>
          </div>
          <div class="modal-footer" style="float: right;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
            <button type="button"style="padding-right: 18px;" onclick="window.print()" class="btn btn-primary" data-dismiss="modal">Imprimir</button>
          </div>
        </div>
        
      </div>
    </div>
  </div>

    <!-- MODAL JUSTIFICATIVA CANCELAMENTO DE NOTA -->
    <div class="modal fade" id="modalCancelar" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Cancelar NF-e</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fal fa-times"></i></span>
            </button>
          </div>
          <div class="modal-body">
          <div class="form-group">
            <label class="form-label" for="textCarta">Justificativa para o cancelamento da NF-e:</label>
            <!-- <textarea class="form-control contador"  id="xJust" rows="7" maxlength="1000" minlength="15" ></textarea> -->
            <textarea class="form-control contador" id="xJust" rows="7" maxlength="1000" minlength="15" placeholder="Digite pelo menos 15 caracteres"></textarea>
            <label>Caracteres restantes <b><span class="caracteres">1000</span></b></label>
          </div> 
        </div>               
          <div class="modal-footer">        
              <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">Fechar</button>
              <button type="button" class="btn btn-danger" (click)="confirmarCancelamento()" data-toggle="modal" data-dismiss="modal" aria-label="Close">Cancelar Nota</button>         
          </div>                          
        </div>
      </div>
    </div>
    <!-- FIM MODAL JUSTIFICATIVA DE CANCELAMENTO -->
  
    <!-- MODAL NOTA CANCELADA -->
    <div class="modal fade" id="modalCancelado" data-backdrop="static" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Enviando para o SEFAZ</h5>
          </div>
          <div class="modal-body">
            <div class="progress progress-lg">
              <div class="progress-bar bg-primary" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">100%</div>
            </div><br>          
            <div class="alert border-success alert-success alert-dismissible fade show" role="alert">
              <div class="d-flex align-items-center">
                <div class="alert-icon">
                  <i class="fal fa-check"></i> 
                </div>
                <div class="flex-1">
                  <span class="h5 m-0 fw-700">Nota cancelada com sucesso</span>
                </div>                      
              </div>
            </div>
            <div class="modal-footer" style="float: right;">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>            
            </div>
          </div>        
        </div>
      </div>
    </div>
    <!-- FIM MODAL NOTA CANCELADA -->
</div>

<swal #deleteConfirmationMessage showDenyButton="true" (deny)="handleDenial()" (confirm)="confirmDelete()" denyButtonText="Cancelar" showConfirmButton="true" confirmButtonText="Confirmar" title="Excluir?" text="Tem certeza que deseja excluir este registro?" icon="question"></swal>
<swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde." icon="error"></swal>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#2196f3" type = "ball-running-dots" [fullScreen] = "true"><p style="color: white" > {{msgSpinner}} </p></ngx-spinner>

