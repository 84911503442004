import {NgModule} from '@angular/core';
import {NFeListComponent } from './list/nfe.list.component'
import {NFeEditorComponent } from './editor/nfe.editor.component'
import {routing} from './nfe.routing';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    routing,
  ],
  declarations: [
    NFeListComponent,
    NFeEditorComponent
  ],
  providers: [],
})
export class FaturamentoModule {

}
