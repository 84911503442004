import { CertificadoModule } from './certificado.module';

import {ModuleWithProviders} from "@angular/core"
import { Routes, RouterModule } from '@angular/router';
import {CertificadoComponent} from "./certificado.component"


export const certificadoRoutes: Routes = [{
  path: '',
  component: CertificadoComponent
}];

export const certificadoRouting = RouterModule.forChild(certificadoRoutes);