import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AuthToken } from './model/authToken';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { Empresa, EmpresaResponse } from './model/empresa';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {
  constructor(
    private httpClient: HttpClient,
    private loginService: LoginService,
    private router: Router
   
    ) { }

  servicePath: string = '/empresa'

  url:string = environment.apiUri + this.servicePath;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-access-token': this.loginService.getToken() })
  }

  get() : Observable<EmpresaResponse> {
    return this.httpClient.get<EmpresaResponse>(this.url, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  getById(id:number) : Observable<EmpresaResponse> {
    return this.httpClient.get<EmpresaResponse>(this.url+"/"+id, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  // async getById(id:number): Promise<any> {
  //   return await this.httpClient.post(this.url + '/'+id, this.httpOptions).toPromise()
  //     .catch(this.handleError);
  // }


  update(entity:Empresa) : Observable<any> {
    return this.httpClient.put(this.url + '/' + entity.id,entity, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  };
  
 async validarCertificado(files: Set<File>, header:any): Promise<any> {
  var httpHeader = {
      headers: new HttpHeaders({ 
                                 'x-access-token': this.loginService.getToken(),
                                 'x-idEmpresa': header.idEmpresa,
                                 'x-senha': header.senha,
                                 'x-tipo': header.tipo })
    };
    const formData = new FormData();
    files.forEach(file => formData.append('file', file, file.name));
    return await this.httpClient.post(this.url + '/validarcertificado',formData, httpHeader).toPromise()
      .catch(this.handleError);
  }

  async uploadCertificado(files: Set<File>, header:any): Promise<any> {
    var httpHeader = {
        headers: new HttpHeaders({ 
                                   'x-access-token': this.loginService.getToken(),
                                   'x-idEmpresa': header.idEmpresa,
                                   'x-senha': header.senha,
                                   'x-typeFile': 'cert' })
      };
      const formData = new FormData();
      files.forEach(file => formData.append('file', file, file.name));
      return await this.httpClient.post(this.url + '/uploadcertificado',formData, httpHeader).toPromise()
        .catch(this.handleError);
    }

  async uploadLogo(files: Set<File>, header:any): Promise<any> {
    var httpHeader = {
        headers: new HttpHeaders({ 'x-access-token': this.loginService.getToken(),
                                   'x-typeFile': 'logo', 
                                   'x-idEmpresa': header.idEmpresa})
      };
      const formData = new FormData();
      files.forEach(file => formData.append('file', file, file.name));
      return await this.httpClient.post(this.url + '/logo',formData, httpHeader).toPromise()
        .catch(this.handleError);
    }

    handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    return throwError(error);
  };

}
