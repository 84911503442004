import {NgModule} from '@angular/core';
import { ArtigoListComponent } from './list/artigo.list.component'
import { ArtigoEditorComponent } from './editor/artigo.editor.component'
import { routing} from './artigo.routing';
import { SharedModule } from '../../../shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    routing,
  ],
  declarations: [
    ArtigoListComponent,
    ArtigoEditorComponent
  ],
  providers: [],
})
export class ArtigoModule {

}
