 <!-- BEGIN Left Aside -->
 <aside class="page-sidebar">
  <div class="page-logo">
    <a
      href="#"
      class="page-logo-link press-scale-down d-flex align-items-center position-relative"
    >
      <img src="img/logoNovo.png" alt="Kiron" aria-roledescription="logo" />
    </a>
  </div>
  <!-- BEGIN PRIMARY NAVIGATION -->
  <nav id="js-primary-nav" class="primary-nav" role="navigation">
    <!-- <div class="info-card">
      <img
        src="img/demo/avatars/avatar-admin.png"
        class="profile-image rounded-circle"
        alt="Dr. Codex Lantern"
      />
      <div class="info-card-text">
        <a class="d-flex align-items-center text-white">
          <span class="text-truncate text-truncate-sm d-inline-block">
            Dr. Codex Lantern
          </span>
        </a>
        <span class="d-inline-block text-truncate text-truncate-sm"
          >Toronto, Canada</span
        >
      </div>
      <img
        src="img/card-backgrounds/cover-2-lg.png"
        class="cover"
        alt="cover"
      />
    </div> -->
    <ul id="js-nav-menu" class="nav-menu">
      <li>
        <a routerLink="/dashboard" title="Dashboard">
          <i class="fal fa-tachometer-alt"></i>
          <span class="nav-link-text" data-i18n="nav.application_intel">Dashboard</span>
        </a>
      </li>
      <li>
        <a href="#" title="Cadastros" data-filter-tags="">
          <i class="fal fa-copy"></i>
          <span class="nav-link-text" data-i18n="nav.theme_settings">Cadastros</span>
        </a>
        <ul>
          <li>
            <a routerLink="/cadastros/clientes" title="Clientes">
              <span class="nav-link-text">Clientes</span>
            </a>
          </li>
          <li>
            <a routerLink="/cadastros/fornecedores" title="Fornecedores">
              <span class="nav-link-text">Fornecedores</span>
            </a>
          </li>
          <li>
            <a routerLink="/cadastros/transportadoras" title="Transportadoras">
              <span class="nav-link-text">Transportadoras</span>
            </a>
          </li>
          <li>
            <a routerLink="/cadastros/produtos" title="Produtos">
              <span class="nav-link-text">Produtos</span>
            </a>
          </li>
           <li>
            <a routerLink="/cadastros/ncm" title="NCM">
              <span class="nav-link-text">NCM</span>
            </a>
          </li>
          <!-- <li>
            <a routerLink="/cadastros/vendedores" title="Vendedores">
              <span class="nav-link-text">Vendedores</span>
            </a>
          </li>
          <li>
            <a routerLink="/cadastros/bancos" title="Bancos">
              <span class="nav-link-text">Bancos</span>
            </a>
          </li> -->
          <li>
            <a routerLink="/cadastros/cfop" title="cfop">
              <span class="nav-link-text">Naturezas (Cfop)</span>
            </a>
          </li>
          <li>
            <a routerLink="/cadastros/artigo" title="artigo">
              <span class="nav-link-text">Obs. Automático</span>
            </a>
          </li>
          <li>
            <a routerLink="/cadastros/fatura" title="fatura">
              <span class="nav-link-text">Faturamento Mensal</span>
            </a>
          </li>
            </ul>
      </li>
      <!-- <li>
        <a href="#" title="Financeiro" data-filter-tags="">
          <i class="fal fa-file-invoice-dollar"></i>
          <span class="nav-link-text" data-i18n="nav.theme_settings"
            >Financeiro</span
          >
        </a>
        <ul>
          <li>
            <a href="#" title="Clientes/Fornecedores">
              <span class="nav-link-text">Contas a pagar</span>
            </a>
          </li>
          <li>
            <a href="#" title="Produtos">
              <span class="nav-link-text">Contas a receber</span>
            </a>
          </li>
        </ul>
      </li> -->
      <!-- <li>
        <a href="#" title="Estoque" data-filter-tags="">
          <i class="fal fa-boxes"></i>
          <span class="nav-link-text" data-i18n="nav.theme_settings"
            >Estoque</span
          >
        </a>
        <ul>
          <li>
            <a href="#" title="Lançamento Inicial">
              <span class="nav-link-text">Lançamento Inicial</span>
            </a>
          </li>
          <li>
            <a href="#" title="Produtos">
              <span class="nav-link-text">Entrada de produtos</span>
            </a>
          </li>
          <li>
            <a href="#" title="Saída produtos">
              <span class="nav-link-text">Saída de produtos</span>
            </a>
          </li>
          <li>
            <a href="#" title="Saldo estoque dos produtos">
              <span class="nav-link-text">Saldo estoque dos produtos</span>
            </a>
          </li>
        </ul>
      </li> -->
      <li>
        <a routerLink="/faturamento" title="Nota Fiscal">
          <i class="fal fa-file-invoice"></i>
          <span class="nav-link-text">Nota Fiscal</span>
        </a>
      </li>
      <li>
        <a href="#" title="Relatórios" data-filter-tags="">
          <i class="fal fa-analytics"></i>
          <span class="nav-link-text" data-i18n="nav.theme_settings">Relatórios</span>
        </a>
        <ul>
          <li>
            <a routerLink="relatorio1" title="Clientes">
              <span class="nav-link-text">Relação NF-e por data</span>
            </a>
          </li>
        </ul>
      </li>
      <!-- <li>
        <a href="#" title="Comercial" data-filter-tags="">
          <i class="fal fa-users"></i>
          <span class="nav-link-text">Comercial</span>
        </a>
        <ul>
          <li>
            <a href="#" title="Orçamento de venda">
              <span class="nav-link-text">Orçamento de venda</span>
            </a>
          </li>
          <li>
            <a href="#" title="Venda">
              <span class="nav-link-text">Venda</span>
            </a>
          </li>
        </ul>
      </li> -->
    </ul>
    <div class="filter-message js-filter-message bg-success-600"></div>
  </nav>
  <!-- END PRIMARY NAVIGATION -->
</aside>
<!-- END Left Aside -->
