<!-- <ol class="breadcrumb page-breadcrumb">
  <li class="breadcrumb-item"><a href="" routerLink="/">Kiron</a></li>
  <li class="breadcrumb-item">{{title}}</li>
  <li class="breadcrumb-item active">Edição</li>
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date"></span>
  </li>
</ol> -->
<div class="subheader">
  <h1 class="subheader-title">
    <i class="subheader-icon fal fa-hand-holding-usd"></i> {{title}}
    <small>
      Cadastro de {{title}}
    </small>
  </h1>
</div>
<div id="panel-1" class="panel">
  <div class="panel-hdr">
    <h2>CFOP<span class="fw-300"></span></h2>
    <div class="panel-toolbar">

    </div>
  </div>
  <div class="panel-container show">
    <div class="panel-content">
      <div class="row">
        <div class="form-group col-sm-2">
          <label class="form-label" for="cfop">CFOP <span class="text-danger">*</span></label>
          <div class="input-group">
              <input type="text" [(ngModel)]="cfop.cfop" class="form-control" placeholder="" id="cfop">
          </div>
        </div>
        <div class="form-group col-sm-10">
          <label class="form-label" for="descricao">Descrição <span class="text-danger">*</span></label>
          <div class="input-group">
            <input type="text" [(ngModel)]="cfop.nome" class="form-control" placeholder="" id="descricao">
          </div>
        </div>
      </div>
      <div class="row">
         <div class="form-group col-sm-2">
            <label class="form-label" for="status">Status <span class="text-danger">*</span></label>
            <div class="input-group">
              <select class="js-hide-search form-control" id="status" required>
              </select>                        
            </div>
          </div>
          <div class="form-group col-sm-3">
            <label class="form-label" for="creditoSN">Permite crédito do Simples nacional? <span class="text-danger">*</span></label>
            <div class="input-group">
              <select class="js-hide-search form-control" id="creditoSN">
              </select>                        
            </div>
          </div> 
          <div class="form-group col-sm-3">
            <label class="form-label" for="creditoSN">Soma no faturamento? <span class="text-danger">*</span></label>
            <div class="input-group">
              <select class="js-hide-search form-control" id="somaFat">
              </select>                        
            </div>
          </div> 
       </div>
    </div>
  </div>
</div>

<div class="accordion accordion-outline" id="js_demo_accordion-4" style="background-color: #fff; box-shadow: 0 3px 3px -1px #e2e2e2;">
 <div class="card" style="background-color: #fff;">
   <div class="card-header" style="background-color: #fff;">
     <a href="javascript:void(0);" class="card-title" data-toggle="collapse" data-target="#js_demo_accordion-4a" aria-expanded="false">        
      
      <span class="fw-100" style="color: rgb(10, 10, 10);">Configurações avançadas</span>
      <span class="ml-auto">          
        <span class="collapsed-hidden">
          <i class="fal fa-plus fs-xl"></i>
        </span>
        <span class="collapsed-reveal">
          <i class="fal fa-minus fs-xl"></i>
        </span>
      </span>
        
      </a>
    </div>
    <div id="js_demo_accordion-4a" class="collapse hide" data-parent="#js_demo_accordion-4" style="background-color: #fff;">
      <div class="card-body" style="background-color: #fff;">
        <div class="panel-content" style="background-color: #fff;">
          <div class="row">
            <div class="col-sm-12">
              <ul class="nav nav-tabs nav-fill" role="tablist">
                <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#tab_justified-1" role="tab">ICMS</a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab_justified-2" role="tab">IPI</a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab_justified-3" role="tab">PIS</a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab_justified-4" role="tab">COFINS</a></li>
              </ul>
              <div class="tab-content border border-top-0 p-3">
                <div class="tab-pane fade show active" id="tab_justified-1" role="tabpanel">
                  <div id="panel-2" class="panel">
                    <div class="panel-hdr">
                      <h2>ICMS <span class="fw-300"></span></h2>
                    </div>
                    <div class="panel-container show">
                      <div class="panel-content">
                          <div class="row">
  
                            <div class="form-group col-sm-3">
                              <label class="form-label" for="icmsSituacaoTributaria">{{labelSituacaoTributaria}}</label>
                              <select class="select2 form-control w-100" id="icmsSituacaoTributaria">
                              </select>
                            </div>
                            <div class="form-group col-sm-3">
                              <label class="form-label" for="modalidadeBC">Modalidade de base de cálculo</label>
                              <select class="select2 form-control w-100" id="modalidadeBC">
                              </select>
                            </div>
                            <div class="form-group col-sm-3">
                              <label class="form-label" for="icmsEsquema">Esquema de Cálculo</label>
                              <select class="select2 form-control w-100" id="icmsEsquema">
                              </select>
                            </div>       
                            <div class="form-group col-sm-3">
                              <label class="form-label" for="icmsTipoAliquota">Tipo de alíquota</label>
                              <select class="select2 form-control w-100" id="icmsTipoAliquota">
                              </select>
                            </div>                    
                          </div>
                          <div class="row">
                            <div class="form-group col-sm-6">
                              <label class="form-label" for="modalidadeBCST">Modalidade Substituição Tributária</label>
                              <select class="select2 form-control w-100" id="modalidadeBCST">
                              </select>
                            </div>
                            <div class="form-group col-sm-6">
                              <label class="form-label" for="icmsEsquemaST">Esquema de Cálculo Substituição Tributária</label>
                              <select class="select2 form-control w-100" id="icmsEsquemaST">
                              </select>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab_justified-2" role="tabpanel">
                  <div id="panel-21" class="panel">
                    <div class="panel-hdr">
                      <h2>IPI <span class="fw-300"></span></h2>                        
                    </div>
                    <div class="panel-container show">
                      <div class="panel-content">
                        <div class="row">
                          <div class="form-group col-sm-3">
                            <label class="form-label" for="ipiSituacaoTributaria">CST</label>
                            <select class="select2 form-control w-100" id="ipiSituacaoTributaria">
                            </select>
                          </div>
                          <div class="form-group col-sm-3">
                            <label class="form-label" for="ipiEsquema">Esquema de Cálculo</label>
                            <select class="select2 form-control w-100" id="ipiEsquema">
                            </select>
                          </div>
                          <div class="form-group col-sm-3">
                            <label class="form-label" for="ipiTipoAliquota">Tipo de alíquota</label>
                            <select class="select2 form-control w-100" id="ipiTipoAliquota">
                            </select>
                          </div>                    

                          <div class="form-group col-sm-3">
                            <label class="form-label" for="cfop">Código de Enquadramento</label>
                            <div class="input-group">
                                <input type="text" [(ngModel)]="cfopIpi.cEnq" class="form-control" placeholder="" id="cfop">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab_justified-3" role="tabpanel">
                  <div id="panel-22" class="panel">
                    <div class="panel-hdr">
                      <h2>PIS <span class="fw-300"></span></h2>                        
                    </div>
                    <div class="panel-container show">
                      <div class="panel-content">
                        <div class="row">
                          <div class="form-group col-sm-4">
                            <label class="form-label" for="pisSituacaoTributaria">CST</label>
                            <select class="select2 form-control w-100" id="pisSituacaoTributaria">
                            </select>
                          </div>
                          <div class="form-group col-sm-4">
                            <label class="form-label" for="pisEsquema">Esquema de Cálculo</label>
                            <select class="select2 form-control w-100" id="pisEsquema">
                            </select>
                          </div>
                          <div class="form-group col-sm-4">
                            <label class="form-label" for="pisTipoAliquota">Tipo de alíquota</label>
                            <select class="select2 form-control w-100" id="pisTipoAliquota">
                            </select>
                          </div>                    

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab_justified-4" role="tabpanel">
                  <div id="panel-23" class="panel">
                    <div class="panel-hdr">
                      <h2>COFINS <span class="fw-300"></span></h2>                        
                    </div>
                    <div class="panel-container show">
                      <div class="panel-content">
                        <div class="row">
                          <div class="form-group col-sm-4">
                            <label class="form-label" for="cofinsSituacaoTributaria">CST</label>
                            <select class="select2 form-control w-100" id="cofinsSituacaoTributaria">
                            </select>
                          </div>
                          <div class="form-group col-sm-4">
                            <label class="form-label" for="cofinsEsquema">Esquema de Cálculo</label>
                            <select class="select2 form-control w-100" id="cofinsEsquema">
                            </select>
                          </div>
                          <div class="form-group col-sm-4">
                            <label class="form-label" for="cofinsTipoAliquota">Tipo de alíquota</label>
                            <select class="select2 form-control w-100" id="cofinsTipoAliquota">
                            </select>
                          </div>                    

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>      
    </div>    
  </div>  
</div>
</div>

<button (click)="save()" style="float:right; margin: 10px;" class="btn btn-primary ml-auto" type="button">Salvar</button>
<button (click)="cancel()" type="button" style="float:right; margin: 10px;" class="btn btn-secondary">Cancelar</button>

<swal #deleteConfirmationMessage showDenyButton="true" (deny)="handleDenial()" (confirm)="confirmDelete()" denyButtonText="Cancelar" showConfirmButton="true" confirmButtonText="Confirmar" title="Excluir?" text="Tem certeza que deseja excluir este registro?" icon="warning"></swal>
<swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde." icon="error"></swal>
<swal #successMessage text="Boa, registro salvo com sucesso!" showConfirmButton=true icon="success"></swal>
<swal #validationMessage text="Ops, ainda faltam alguns campos para preencher, vamos lá?" showConfirmButton=true icon="error"></swal>
