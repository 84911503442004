import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { EmpresaService } from "src/app/services/empresa.service";
import { DashboardService } from "src/app/services/dashboard.service";
import { ActivatedRoute, Router } from "@angular/router";
import { EntidadeAdmService } from "src/app/services/entidadeAdm.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $:any;
declare var localStorage:any;
@Component({
  selector: 'sa-dashboard',
  templateUrl: './dashboard.component.html',

})

export class DashboardComponent implements OnInit {
  isShow : boolean = true;
  qtdEmpresas: number = 0;
  idEmpresa: number = 0;
  dashboard = {
    clienteAtivo: 0,
    ticketMedio: 0,
    acumulado: 0,
    faturaMensal: 0
  };

  constructor(
       private loginService: LoginService,
    //   private empresaService: EmpresaService,
       private dashboardService: DashboardService,
    //   private router: Router,
    //   private entidadeAdmService: EntidadeAdmService,
     
  ) {}

  ngOnInit() {
    var self = this;
        this.dashboardService.dashboard(localStorage.getItem('idEmpresa')).subscribe((res: any)=>{
            this.dashboard = res;
        }, error => {
            if(error.status == 401){
                //  this.router.navigate(['/login']);
                self.loginService.tokenExpired();
            }
        });
 //   localStorage.setItem("modal", false);
    
    
    //Desabitilitado dia 11/04/22
    // if(localStorage.getItem('idEmpresa') == null) { 
    //   this.fillEmpresas();
    // }
    // this.selecionarEmpresa();

    var dataTargetProfit = [
        [1354586000000, 153],
        [1364587000000, 658],
        [1374588000000, 198],
        [1384589000000, 663],
        [1394590000000, 801],
        [1404591000000, 1080],
        [1414592000000, 353],
        [1424593000000, 749],
        [1434594000000, 523],
        [1444595000000, 258],
        [1454596000000, 688],
        [1464597000000, 364]
    ]
    var dataProfit = [
        [1354586000000, 53],
        [1364587000000, 65],
        [1374588000000, 98],
        [1384589000000, 83],
        [1394590000000, 980],
        [1404591000000, 808],
        [1414592000000, 720],
        [1424593000000, 674],
        [1434594000000, 23],
        [1444595000000, 79],
        [1454596000000, 88],
        [1464597000000, 36]
    ]
    var dataSignups = [
        [1354586000000, 647],
        [1364587000000, 435],
        [1374588000000, 784],
        [1384589000000, 346],
        [1394590000000, 487],
        [1404591000000, 463],
        [1414592000000, 479],
        [1424593000000, 236],
        [1434594000000, 843],
        [1444595000000, 657],
        [1454596000000, 241],
        [1464597000000, 341]
    ]
    
  
    $(document).ready(function()
      {
          /* flot area */
  
          var flotVisit = $.plot('#flotVisit', [
            {
                data: [
                    [3, 0],
                    [4, 1],
                    [5, 3],
                    [6, 3],
                    [7, 10],
                    [8, 11],
                    [9, 12],
                    [10, 9],
                    [11, 12],
                    [12, 8],
                    [13, 5]
                ],
                color: '#4897FF'
            },
            {
              //   data: [
              //       [1, 0],
              //       [2, 0],
              //       [3, 1],
              //       [4, 2],
              //       [5, 2],
              //       [6, 5],
              //       [7, 8],
              //       [8, 12],
              //       [9, 9],
              //       [10, 11],
              //       [11, 5]
              //   ],
                
            }],
            {
                series:
                {
                //   label: "Faturamento Diário",
                  data: dataProfit,
                  color: '#FAE176',
                    shadowSize: 0,
                    lines:
                    {
                        show: true,
                        lineWidth: 2,
                        fill: true,
                        fillColor:
                        {
                            colors: [
                            {
                                opacity: 0.0
                            },
                            {
                                opacity: 0.3
                            }]
                        }
                    }
                },
                shadowSize: 0,
                points:
                {
                    show: true
                },
                grid:
                {
                  hoverable: true,
                  clickable: true,
                  tickColor: 'rgba(0,0,0,0.05)',
                  borderWidth: 1,
                  borderColor: 'rgba(0,0,0,0.05)'
                },
                tooltip: true,
                tooltipOpts:
                {
                    cssClass: 'tooltip-inner',
                    defaultTheme: false
                },
                yaxis:
                {
                    min: 0,
                    max: 15,
                    tickColor: 'rgba(0,0,0,0.05)',
                    ticks: [
                        [0, ''],
                        [5, 'R$ 1.000'],
                        [10, 'R$ 2.000'],
                        [15, 'R$ 3.000']
                    ],
                    font:
                    {
                        color: '#444',
                        size: 10
                    }
                },
                xaxis:
                {
        
                    tickColor: 'rgba(0,0,0,0.05)',
                    ticks: [
                        [2, ''],
                        [3, ''],
                        [4, ''],
                        [5, ''],
                        [6, ''],
                        [7, ''],
                        [8, ''],
                        [9, ''],
                        [10, ''],
                        [11, ''],
                        [12, ''],
                        [13, '']
                    ],
                    font:
                    {
                        color: '#999',
                        size: 9
                    }
                }
            });
          var flotVisit = $.plot('#teste', [
          //   {
          //       data: [
          //           [3, 0],
          //           [4, 1],
          //           [5, 3],
          //           [6, 3],
          //           [7, 10],
          //           [8, 11],
          //           [9, 12],
          //           [10, 9],
          //           [11, 12],
          //           [12, 8],
          //           [13, 5]
          //       ],
          //       // color: color.success._200
          //   },
            {
                data: [
                    [1, 0],
                    [2, 0],
                    [3, 1],
                    [4, 2],
                    [5, 2],
                    [6, 5],
                    [7, 8],
                    [8, 12],
                    [9, 9],
                    [10, 11],
                    [11, 5]
                ],
                color: '#56b59f'
            }],
            {
                series:
                {
                    // label: "Faturamento Mês",
                    data: dataSignups,
                    color: '#FAE176',
                    shadowSize: 0,
                    lines:
                    {
                        show: true,
                        lineWidth: 2,
                        fill: true,
                        fillColor:
                        {
                            colors: [
                            {
                                opacity: 0.0
                            },
                            {
                                opacity: 0.4
                            }]
                        }
                    }
                },              
                shadowSize: 0,
                  points:
                  {
                      show: true
                  },
  
                grid:
                {
                  hoverable: true,
                  clickable: true,
                  tickColor: 'rgba(0,0,0,0.05)',
                  borderWidth: 1,
                  borderColor: 'rgba(0,0,0,0.05)'
                    
                },
                tooltip: true,
                    tooltipOpts:
                    {
                        cssClass: 'tooltip-inner',
                        defaultTheme: false
                    },
                yaxis:
                {
                    min: 0,
                    max: 20,
                    tickColor: 'rgba(0,0,0,0.05)',
                    ticks: [
                        [0, ''],
                        [5, 'R$ 5.000'],
                        [10, 'R$ 10.000'],
                        [15, 'R$ 20.000'],
                        [20, 'R$ 30.000']
                    ],
                    font:
                    {
                        color: '#444',
                        size: 10
                    }
                },
                xaxis:
                {
        
                    tickColor: 'rgba(0,0,0,0.05)',
                    ticks: [
                        [2, ''],
                        [3, ''],
                        [4, ''],
                        [5, ''],
                        [6, ''],
                        [7, ''],
                        [8, ''],
                        [9, ''],
                        [10, ''],
                        [11, ''],
                        [12, ''],
                        [13, '']
                    ],
                    font:
                    {
                        color: '#999',
                        size: 9
                    }
                }
            });
        
        
        });
  
        $(document).ready(function()
        {
  
            /* init datatables */
            $('#dt-basic-example').dataTable(
            {
                responsive: true,
                dom: "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                buttons: [
                    {
                        extend: 'colvis',
                        text: 'Column Visibility',
                        titleAttr: 'Col visibility',
                        className: 'btn-outline-default'
                    },
                    {
                        extend: 'csvHtml5',
                        text: 'CSV',
                        titleAttr: 'Generate CSV',
                        className: 'btn-outline-default'
                    },
                    {
                        extend: 'copyHtml5',
                        text: 'Copy',
                        titleAttr: 'Copy to clipboard',
                        className: 'btn-outline-default'
                    },
                    {
                        extend: 'print',
                        text: '<i class="fal fa-print"></i>',
                        titleAttr: 'Print Table',
                        className: 'btn-outline-default'
                    }
  
                ],
                columnDefs: [
                    {
                        targets: -1,
                        title: '',
                        orderable: false,
                        render: function(data, type, full, meta)
                        {
  
                            /*
                            -- ES6
                            -- convert using https://babeljs.io online transpiler
                            return `
                            <a href='javascript:void(0);' class='btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1' title='Delete Record'>
                                <i class="fal fa-times"></i>
                            </a>
                            <div class='dropdown d-inline-block dropleft '>
                                <a href='#'' class='btn btn-sm btn-icon btn-outline-primary rounded-circle shadow-0' data-toggle='dropdown' aria-expanded='true' title='More options'>
                                    <i class="fal fa-ellipsis-v"></i>
                                </a>
                                <div class='dropdown-menu'>
                                    <a class='dropdown-item' href='javascript:void(0);'>Change Status</a>
                                    <a class='dropdown-item' href='javascript:void(0);'>Generate Report</a>
                                </div>
                            </div>`;
                                
                            ES5 example below:	
  
                            */
                            return "\n\t\t\t\t\t\t<a href='javascript:void(0);' class='btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1' title='Delete Record'>\n\t\t\t\t\t\t\t<i class=\"fal fa-times\"></i>\n\t\t\t\t\t\t</a>\n\t\t\t\t\t\t<div class='dropdown d-inline-block dropleft'>\n\t\t\t\t\t\t\t<a href='#'' class='btn btn-sm btn-icon btn-outline-primary rounded-circle shadow-0' data-toggle='dropdown' aria-expanded='true' title='More options'>\n\t\t\t\t\t\t\t\t<i class=\"fal fa-ellipsis-v\"></i>\n\t\t\t\t\t\t\t</a>\n\t\t\t\t\t\t\t<div class='dropdown-menu'>\n\t\t\t\t\t\t\t\t<a class='dropdown-item' href='javascript:void(0);'>Change Status</a>\n\t\t\t\t\t\t\t\t<a class='dropdown-item' href='javascript:void(0);'>Generate Report</a>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>";
                        },
                    },
  
                ]
  
            });
  
  
            /* flot toggle example */
            var flot_toggle = function()
            {
  
                var data = [
                {
                    label: "Target Profit",
                    data: dataTargetProfit,
                  //   color: color.info._400,
                    bars:
                    {
                        show: true,
                        align: "center",
                        barWidth: 30 * 30 * 60 * 1000 * 80,
                        lineWidth: 0,
                        /*fillColor: {
                            colors: [color.primary._500, color.primary._900]
                        },*/
                        fillColor:
                        {
                            colors: [
                            {
                                opacity: 0.9
                            },
                            {
                                opacity: 0.1
                            }]
                        }
                    },
                    highlightColor: 'rgba(255,255,255,0.3)',
                    shadowSize: 0
                },

                {
                    label: "Mês Passado",
                    data: dataSignups,
                    color: '#FAE176',
                    lines:
                    {
                        show: true,
                        lineWidth: 2,
                        fill: true,
                        fillColor:
                        {
                            colors: [
                            {
                                opacity: 0.1
                            },
                            {
                                opacity: 0.3
                            }]
                        }
                    },
                    shadowSize: 0,
                    points:
                    {
                        show: true
                    }
                }]
  
                var options = {
                    grid:
                    {
                        hoverable: true,
                        clickable: true,
                        tickColor: 'rgba(0,0,0,0.05)',
                        borderWidth: 1,
                        borderColor: 'rgba(0,0,0,0.05)'
                    },
                    tooltip: true,
                    tooltipOpts:
                    {
                        cssClass: 'tooltip-inner',
                        defaultTheme: false
                    },
                    xaxis:
                    {
                        mode: "time",
                        tickColor: 'rgba(0,0,0,0.05)',
                    },
                    yaxes:
                    {
                        tickColor: 'rgba(0,0,0,0.05)',
                        tickFormatter: function(val, axis)
                        {
                            return "$" + val;
                        },
                        max: 1200
                    }
  
                };
  
                var plot2 = null;
  
                function plotNow()
                {
                    var d = [];
                    $("#js-checkbox-toggles").find(':checkbox').each(function()
                    {
                        if ($(this).is(':checked'))
                        {
                            d.push(data[$(this).attr("name").substr(4, 1)]);
                        }
                    });
                    if (d.length > 0)
                    {
                        if (plot2)
                        {
                            plot2.setData(d);
                            plot2.draw();
                        }
                        else
                        {
                            plot2 = $.plot($("#flot-toggles"), d, options);
                        }
                    }
  
                };
  
                $("#js-checkbox-toggles").find(':checkbox').on('change', function()
                {
                    plotNow();
                });
                plotNow()
            }
            flot_toggle();
          });

//    $('.close').click(function(event){
//       $('#modalEmpresa').fadeOut();
//       event.preventDefault();
//    });
   
//    $(document).ready(function() {
//       var ls = localStorage.getItem("modal");
//     //   if((!ls) && self.qtdEmpresas > 1){
//        if(!ls){
//         $('#modalEmpresa').modal('show');
//       }
//    });

//    $('#modalEmpresa').on('shown.bs.modal', function(){
//       localStorage.setItem("modal", false);
//    });

    // $('#modalEmpresa').on('select2:select', function (e) {
    //    var data = e.params.data;
    //   localStorage.setItem('isEmpresaAtiva', data.isEmpresaAtiva);
    //   localStorage.setItem('dtValidadeChave', data.dtValidadeChave);
    //   localStorage.setItem('diasValidade', data.diasValidade);
 
    //  });
};

//   fillEmpresas() {
//       var self = this;
//       var idEmp;
//       var isEmpresaAtiva,dtValidadeChave,diasValidade;
//       var finalData = $.map(this.loginService.getEmpresas(), function (obj) {
//         idEmp = obj.entidadeAdm.id;
//         isEmpresaAtiva =  obj.entidadeAdm.isAtivo;
//         dtValidadeChave = obj.entidadeAdm.dtValidadeChave;
//         diasValidade =  obj.entidadeAdm.diasValidade;


//         obj.id = obj.entidadeAdm.id,// replace pk with your identifier
//         obj.text = obj.entidadeAdm.nome
//          return obj;
//       });
//       self.qtdEmpresas = finalData.length;
//       $('#empresas').select2({
//         data: finalData,
//         placeholder: 'Empresas',
//         dropdownParent: $('#modalEmpresa'),
//       });
//       if(self.idEmpresa > 1){
//         localStorage.setItem("modal", true);
//       }
//       localStorage.setItem('idEmpresa',idEmp);
//       localStorage.setItem('isEmpresaAtiva', isEmpresaAtiva);
//       localStorage.setItem('dtValidadeChave', dtValidadeChave);
//       localStorage.setItem('diasValidade', diasValidade);
//      };
    
   
//   async selecionarEmpresa(){
//      var id;
//   //    alert('selecionarEmpresa');
//     if(this.qtdEmpresas > 1) {
//        localStorage.setItem('idEmpresa', $('#empresas').val());
//     };
//     id = localStorage.getItem('idEmpresa');
    
//     await this.empresaService.getById(id).subscribe(res => {
//         //  localStorage.setItem('isSimples',  res.data.isSimples == 'S' ? true : false ); // cuidado boolean não funciona no localStorage
//          localStorage.setItem('isSimples',  res.data.isSimples );
//          localStorage.setItem('UF',  res.data.uf );
//          localStorage.setItem('tpAmbiente', res.data.tpAmb);
//          localStorage.setItem('vUnCom', res.data.vUnCom);
//          console.log('res.data: ', res.data);
//          //  document.location.reload(true); 
//     });
//     await this.entidadeAdmService.getById(id).subscribe(res => {
//          localStorage.setItem('tipoCertificado',  res.data.certTipo );
//      });
    
//     await this.dashboardService.dashboard(id).subscribe((res: any)=>{
//         this.dashboard = res;
//     }, error => {
//         if(error.status == 401){
//             //  this.router.navigate(['/login']);
//             // self.loginService.tokenExpired();
//         }
//     });
//   }
}
