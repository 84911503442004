import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { relatorio1Routing } from './relatorio1.routing';
import { Relatorio1Component } from "./relatorio1.component";
// import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    // SharedModule,
    relatorio1Routing
  ],
  declarations: [Relatorio1Component]
})
export class Relatorio1Module { }