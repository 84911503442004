import {NgModule} from '@angular/core';
import {ClientesFornecedoresListComponent } from './list/clientesfornecedores.list.component'
import {ClientesFornecedoresEditorComponent } from './editor/clientesfornecedores.editor.component'
import {routing} from './clientesfornecedores.routing';
import { SharedModule } from '../../../shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    routing,
  ],
  declarations: [
    ClientesFornecedoresListComponent,
    ClientesFornecedoresEditorComponent
  ],
  providers: [],
})
export class ClientesFornecedoresModule {

}
