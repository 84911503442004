<!-- <div class="row">
	<div class="modal fade" data-backdrop="static" tabindex="-1" id="modalEmpresa" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">
						Selecione a Empresa
						<small class="m-0 text-muted">
						Selecione com qual empresa gostaria de logar
						</small>
					</h4>

				</div>
				<div class="modal-body">
					<div class="input-group">
						<select class="select2 form-control w-100" id="empresas">
						</select>
					  </div>
				</div>
				<div class="modal-footer">
					<button id="close" type="button" class="btn btn-primary" (click)="selecionarEmpresa()" data-dismiss="modal">Entrar</button>
			   </div>
			</div>
		</div>
	</div>
</div> -->


<div id="panel-1" class="panel">
  	<div class="panel-container show">
		<div class="panel-content">
			<div class="row">
				<div class="col-sm-6 col-xl-6">
					<div class="p-3 rounded overflow-hidden position-relative text-white mb-g" style="background-color: '#0673BA';">
						<div class="">
							<h3 class="display-4 d-block l-h-n m-0 fw-500">
								{{dashboard.clienteAtivo}}
								<small class="m-0 l-h-n">Clientes ativos no mês</small>
							</h3>
						</div>
						<i class="fal fa-users position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n1" style="font-size:6rem"></i>
					</div>
				</div>
				<div class="col-sm-6 col-xl-6">
					<div class="p-3 rounded overflow-hidden position-relative text-white mb-g" style="background-color: '#f7c548';">
						<div class="">
							<h3 class="display-4 d-block l-h-n m-0 fw-500" >
							{{dashboard.ticketMedio | currency:'BRL':true}} 
								<small class="m-0 l-h-n">Ticket médio do mês</small>
							</h3>
						</div>
						<i class="fal fa-ticket-alt position-absolute pos-right pos-bottom opacity-15  mb-n1 mr-n4" style="font-size: 6rem;"></i>
					</div>
				</div>
				<div class="col-sm-6 col-xl-6">
					<div class="p-3 rounded overflow-hidden position-relative text-white mb-g" style="background-color: '#ea4d2c';">
						<div class="">
							<h3 class="display-4 d-block l-h-n m-0 fw-500">
								{{dashboard.acumulado | currency:'BRL':true}}
								<small class="m-0 l-h-n">Vendas acumuladas do mês</small>
							</h3>
						</div>
						<i class="fal fa-gem position-absolute pos-right pos-bottom opacity-15 mb-n5 mr-n6" style="font-size: 8rem;"></i>
					</div>
				</div>
				<div class="col-sm-6 col-xl-6">
					<div class="p-3 rounded overflow-hidden position-relative text-white mb-g" style="background-color: '#56b59f';">
						<div class="">
							<h3 class="display-4 d-block l-h-n m-0 fw-500">
                                {{dashboard.faturaMensal | currency:' ':true}} %
								<small class="m-0 l-h-n">Faturamento do mês</small>
							</h3>
						</div>
						<i class="fal fa-chart-area position-absolute pos-right pos-bottom opacity-15 mb-n1 mr-n4" style="font-size: 6rem;"></i>
					</div>
				</div>
            </div>
            <!-- <div class="row">
                <div class="col-lg-6">
                    <div id="panel-3" class="panel" >
                        <div class="panel-hdr">
                            <h2>
                                Faturamento <span class="fw-300"><i>Diario</i></span>
                            </h2>
                        </div>
                        <div class="panel-container show">
                            <div class="panel-content poisition-relative">
                                <div id="flotVisit" style="width:100%; height:208px;"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div id="panel-4" class="panel" data-panel-sortable data-panel-collapsed data-panel-close>
                        <div class="panel-hdr">
                            <h2>
                                Faturamento <span class="fw-300"><i>Mensal</i></span>
                            </h2>
                        </div>
                        <div class="panel-container show">
                            <div class="panel-content poisition-relative">
                                <div id="teste" style="width:100%; height:208px;"></div>
                            </div>
                        </div>
                    </div>
                </div>               
            </div> -->
        </div>
    </div>
</div>
