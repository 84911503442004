import {NgModule} from '@angular/core';
import {LoginComponent } from './login.component'
import {routing} from './login.routing';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    routing,
  ],
  declarations: [
    LoginComponent
  ],
  providers: [],
})
export class LoginModule {

}
