<div class="subheader">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-file-exclamation"></i> {{title}}
      <small>
        Cadastro de {{title}}
      </small>
    </h1>
  </div>
  <div id="panel-1" class="panel">
    <div class="panel-hdr">
      <h2>Artigo<span class="fw-300"></span></h2>
      <div class="panel-toolbar">
  
      </div>
    </div>
    <div class="panel-container show">
      <div class="panel-content">
        <div class="row">
            <div class="form-group col-sm-3">
                <label class="form-label" for="status">Status <span class="text-danger">*</span></label>
                <div class="input-group">
                    <select class="js-hide-search form-control" id="status" required>
                    </select>                        
                </div>
            </div>
          <div class="form-group col-sm-9">
            <label class="form-label" for="nome">Nome <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="artigo.nome" placeholder="" id="nome">
            </div>
          </div>

          <div class="form-group col-sm-12">
            <label class="form-label" for="obs">Descrição <span class="text-danger">*</span></label>
            <div class="input-group">
                <textarea class="form-control" rows="6" [(ngModel)]="artigo.obs" id="obs"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <button (click)="save()" style="float:right; margin: 10px;" class="btn btn-primary ml-auto" type="button">Salvar</button>
  <button (click)="cancel()" type="button" style="float:right; margin: 10px;" class="btn btn-secondary">Cancelar</button>
  
  <swal #deleteConfirmationMessage showDenyButton="true" (deny)="handleDenial()" (confirm)="confirmDelete()" denyButtonText="Cancelar" showConfirmButton="true" confirmButtonText="Confirmar" title="Excluir?" text="Tem certeza que deseja excluir este registro?" icon="warning"></swal>
  <swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde." icon="error"></swal>
  <swal #successMessage text="Boa, registro salvo com sucesso!" showConfirmButton=true icon="success"></swal>
  <swal #validationMessage text="Ops, ainda faltam alguns campos para preencher, vamos lá?" showConfirmButton=true icon="error"></swal>
  