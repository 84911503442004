import { Component, OnInit, AfterViewInit,ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import Swal from 'sweetalert2/dist/sweetalert2.js';

import { DataTableDirective } from "angular-datatables";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { ProdutoService } from "src/app/services/produto.service";
import { LoginService } from "src/app/services/login.service";
import { Produto } from "src/app/services/model/produto";
import { routerTransition } from "../../../../util/animations";
import { OrigemProdutoService } from "src/app/services/origemproduto.service";

declare var $:any

@Component({
  selector: "cp-editor",
  templateUrl: "./produtos.editor.component.html",
  styles: [],
  animations: [routerTransition]
})

export class ProdutosEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private produtoService: ProdutoService,
    private loginService: LoginService,
    private spinnerService: NgxSpinnerService,
    private origemProdutoService: OrigemProdutoService,
  ) {}

  title: string;
  description:string;
  dtOptions: DataTables.Settings = {};
  currentId: number = -1;
  produto: Produto = {} as Produto;
  msgErroServer: string = "Ops, aconteceu algum imprevisto no servidor. ";
  
  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  @ViewChild('successMessage')
  public readonly successMessage!: SwalComponent;
  @ViewChild('validationMessage')
  public readonly validationMessage!: SwalComponent;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtTrigger: Subject<any> = new Subject();

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    var self = this;

    const id: number = parseInt(this.route.snapshot.paramMap.get('id'));
    this.currentId = id;

    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);

    this.fillOrigemProduto();
    this.fillStatus();
    $('#status').val('A').change();

    $('#precoVenda').inputmask({ alias:'currency', radixPoint: ",", prefix:'R$'});
    $('#ncm').inputmask({mask: "9999.99.99"});

    self.dtOptions = {
      searching:false,
      lengthChange: false
      /* render dynamic buttons */
    };

    this.route.data.subscribe(params => {
    
      if(id) 
      {
        this.spinnerService.show();
        this.produtoService.getById(id).subscribe(response=> {
          this.spinnerService.hide();
          this.produto = response.data;
          //this.updateDataTable();
          $('#origemproduto').val(this.produto.idOripro).change();
          if(this.produto.isAtivo == 'S') {
            $('#status').val('A').change();
         } else {
            $('#status').val('I').change();
         };
        }, error => {
          if(error.status == 401){
            self.loginService.tokenExpired();
          }
        })
      } else {
        this.ultimoId();
      } 
    });
    
    this.title = 'Produtos';
    this.description = 'Cadastro de produtos'

//    $('.select2').select2();


  }

  async ultimoId(){
      await this.produtoService.getUltimoid().subscribe(response=> {
          const codigo = response.id;
          this.produto.codigo = codigo.toString();
          console.log(codigo);
        });
      
  }
  
  async fillOrigemProduto() {
    await this.origemProdutoService.get().subscribe(data=> {
      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.id || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        if(obj.id == undefined){ obj.id = 0};
        return obj;
      });
      $('#origemproduto').select2({
        data: finalData,
        placeholder: 'Origem do produto'
      });
    })
  };

  fillStatus(){
    var statusData = [
      {'id': "A", 'text': 'Ativo'},
      {'id': "I", 'text': 'Inativo'},
     ];

      
    $('#status').select2({
      data: statusData,
      allowClear: true,
      minimumResultsForSearch: -1
    });
  }
  
  editEntity(data) {
    var self = this;
  }

  deleteEntity(data) {
     this.deleteConfirmationMessage.fire();
  }

  confirmDelete() {
   // this.updateDataTable();
  }

  ngOnDestroy() {
    window.functions = null;
    this.dtTrigger.unsubscribe();
  }

  // updateDataTable() {

  //   var self = this;
    
  //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
  //     // Destroy the table first
  //     dtInstance.destroy();
  //     // Call the dtTrigger to rerender again
  //     this.dtTrigger.next();
  //   });
  // }

  cancel() {
      this.router.navigate(['/cadastros/produtos']);    
  }

  save() {
    var self = this;
   
    if( (this.produto.codigo == '') || (this.produto.nome == '') ||
        (this.produto.codigoUnimed == '') || (this.produto.ncm == '')) {
      this.validationMessage.fire();
    } else {

        this.spinnerService.show();
        this.produto.preco = $('#precoVenda').val().replace(/[^\d]+/g,'')/100;
        this.produto.ncm = $('#ncm').val().replace(/[^\d]+/g,'');
        this.produto.idOripro = $('#origemproduto').val();
        this.produto.isAtivo = ($('#status').val() == 'A' ? 'S': 'N');


        if(this.produto.id > 0) {
          this.produtoService.update(this.produto).subscribe(response => {
            this.spinnerService.hide();
            this.successMessage.fire();
            this.router.navigate(['/cadastros/produtos']);
          }, error => {
            Swal.fire({
                title:  "Erro!",
                text: this.msgErroServer + error.error.message,
                icon: 'error'
            });
          });
        } else {
          this.produtoService.create(this.produto).subscribe(id=> {
            this.spinnerService.hide();
            this.successMessage.fire();
            this.router.navigate(['/cadastros/produtos']);
        }, error => {
            this.errorMessage.fire();
          });
        }
      }
  }

  handleDenial() {

  }

  validacao() {
    window.addEventListener('load', function() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation');
    // Loop over them and prevent submission
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener('button', function(event) {
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add('was-validated');
      }, false);
    });
    }, false);
  };
}
