// import { InutilizarModule } from './inutilizar.module';

import {ModuleWithProviders} from "@angular/core"
import { Routes, RouterModule } from '@angular/router';
import {InutilizarComponent} from "./inutilizar.component"


export const inutilizarRoutes: Routes = [{
  path: '',
  component: InutilizarComponent
}];

export const inutilizarRouting = RouterModule.forChild(inutilizarRoutes);