import { Injectable } from "@angular/core";
import Swal from 'sweetalert2/dist/sweetalert2.js';


declare var localStorage:any;
@Injectable({
    providedIn: 'root'
  })

 
export class VerificarChave {
 
    constructor() {}

    isEmpresaAtiva(): boolean {
         if(localStorage.getItem('isEmpresaAtiva') === 'N') { 
            Swal.fire({
                title:  "Ops!",
                text: 'A empresa está desativada em nossa base de dados, acesso negado.',
                icon: 'error',
            });
            return false;
        } else {
            return true;
        }
    };

    isVencida(): boolean {
        const now = new Date(); // Data de hoje
        const past = new Date(localStorage.getItem('dtValidadeChave')); // Outra data no passado
        const diff = Math.abs(now.getTime() - past.getTime()); // Subtrai uma data pela outra
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).
        if(days < 0){
    //    if(localStorage.getItem('diasValidade') < 0) { 
            Swal.fire({
                title:  "Ops!",
                text: 'Chave de acesso está vencida.',
                icon: 'error',
            });
            return true;
      } else {
          return false;
      }

    }
    
};
