import { Component, OnInit, AfterViewInit, ViewChild} from "@angular/core";
import {Location} from '@angular/common';
import { EmpresaService } from "src/app/services/empresa.service";
import { Empresa } from './../../services/model/empresa';
import { EntidadeAdmService } from "src/app/services/entidadeAdm.service";
import { EntidadeAdm } from './../../services/model/entidadeAdm';
import { ActivatedRoute, Router } from "@angular/router";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { MSKironService } from './../../services/NFe/msKironA3.service';

declare var $:any;
declare var localStorage:any;

@Component({
  selector: 'sa-certificado',
  templateUrl: './certificado.component.html',
})
export class CertificadoComponent implements OnInit {

  empresa: Empresa = {} as Empresa;
  entidadeAdm: EntidadeAdm = {} as EntidadeAdm;
  uploadForm: FormGroup;  
  // formData = new FormData();
  files: Set<File>;
  selectedFiles: FileList;
  nomeArquivoPFX: string = 'Selecionar arquivo';

  constructor( private empresaService: EmpresaService,
               private _location: Location,
               private route: ActivatedRoute,
               private formBuilder: FormBuilder,
               private entidadeAdmService: EntidadeAdmService,
               private router: Router,
               private loginService: LoginService,
               private msKiron: MSKironService
             ) {}


  ngOnInit() {
    var self = this;
    const id: number = localStorage.getItem('idEmpresa');
    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
 

    this.fillTipo();
    // $(document).ready(function() {

        $('#tipoCertificado').on('change',function(){
            var SelectValue='#'+$(this).val();
            $('#DivPai div').hide();
            $('#A1').hide();
            $('#A3').hide();
            $(SelectValue).show();
        });
      // });

      this.route.data.subscribe(params => {
        if(id) 
            {
            this.entidadeAdmService.getById(id).subscribe(response=> {
              this.entidadeAdm = response.data;
              this.nomeArquivoPFX = response.data.certRazao+'.pfx';
              $('#tipoCertificado').val(this.entidadeAdm.certTipo).change();
            }, error => {
              if(error.status == 401){
                 self.loginService.tokenExpired();
              }
            })
          } 
    });

  }

  onFileSelected(event)  {
    this.selectedFiles = event.target.files;
    this.nomeArquivoPFX = event.target.files;

  }

  fillTipo(){
    var data = [
      {'id': "A1", 'text': 'A1 (Arquivo)'},
      {'id': "A3", 'text': 'A3 (Cartão / Token)'},
     ];

     
    $('#tipoCertificado').select2({
      data: data,
      minimumResultsForSearch: -1
    });
  }

  cancel() {
    this._location.back();
  }

async validarCertificado(){
    if($('#tipoCertificado').val() == 'A3'){
      this.validarA3();
    } else {
       this.validarA1();
    }
};

async validarA3(){
  await this.msKiron.testarCertificadoA3({}).then((res: any) => {
    if(res.status == 'OK') {
      this.entidadeAdm.certCnpj = res.cnpj;
      this.entidadeAdm.certRazao = res.razao;
      this.entidadeAdm.certSenha = res.senha;
      this.entidadeAdm.certDtVencto = res.vencto;
      this.entidadeAdm.certTipo = $('#tipoCertificado').val();
      Swal.fire({
        text: 'Certificado validado com sucesso.',
        icon: 'success',
      });
    } else {
        Swal.fire({
          title:  "Mensagem",
          text: 'Ops, ' + res.message,
          icon: 'error'
      });
    }
  });
}

async validarA1(){
  if($('#senha').val() == ''){
    Swal.fire({
      title:  "Erro",
      text: 'Ops, falta informar a senha.',
      icon: 'error'
     });
  }
  var header = {
    senha: $('#senha').val(),//16479093
    idEmpresa: localStorage.getItem('idEmpresa'),
    tipo: $('#tipoCertificado').val()
  }

  this.files = new Set();
  for( let i = 0; i < this.selectedFiles.length; i++ ){
    this.files.add(this.selectedFiles[i]);
  }
  await this.empresaService.validarCertificado(this.files,header).then((res: any) => {
    if(res.status == 'OK') {
        this.entidadeAdm.certCnpj = res.cnpj;
        this.entidadeAdm.certRazao = res.razao;
        this.entidadeAdm.certSenha = $('#senha').val();
        this.entidadeAdm.certDtVencto = res.vencto;
        this.entidadeAdm.certTipo = $('#tipoCertificado').val();
        Swal.fire({
          text: 'Certificado validado com sucesso.',
          icon: 'success',
        });
      } else {
          Swal.fire({
            title:  "Mensagem",
            text: 'Ops, ' + res.message,
            icon: 'error'
        });
      }
  });
}

async save(){
  if($('#tipoCertificado').val() == 'A3'){
      this.saveA3();
  } else {
      this.saveA1();
  }

};
  
async saveA3(){

  await this.entidadeAdmService.update(this.entidadeAdm).subscribe(response => {
    Swal.fire({
      text: "Boa, certificado salvo com sucesso!",
      icon: 'success'
     });
     this._location.back();
    }), error => {
      Swal.fire({
        title:  "Erro!",
        text: error.error.message,
        icon: 'error'
      });
    };
};

async saveA1(){
  var header = {
    senha: $('#senha').val(),//16479093
    idEmpresa: localStorage.getItem('idEmpresa'),
    tipo: $('#tipoCertificado').val()
  };
  this.files = new Set();
  for( let i = 0; i < this.selectedFiles.length; i++ ){
    console.log(this.selectedFiles[i]);
    this.files.add(this.selectedFiles[i]);
  }
  await this.empresaService.uploadCertificado(this.files,header).then((res: any) => {});
  this.entidadeAdmService.update(this.entidadeAdm).subscribe(response => {
    Swal.fire({
      text: "Boa, certificado salvo com sucesso!",
      icon: 'success'
     });
     this._location.back();
    }), error => {
      Swal.fire({
        title:  "Erro!",
        text: error.error.message,
        icon: 'error'
      });
    };
    Swal.fire({
      text: "Boa, certificado salvo com sucesso!",
      icon: 'success'
     });
     this._location.back();

}
};


