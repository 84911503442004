<!-- <ol class="breadcrumb page-breadcrumb">
    <li class="breadcrumb-item"><a href="" routerLink="/">Kiron</a></li>
    <li class="breadcrumb-item">{{title}}</li>
    <li class="breadcrumb-item active">Edição</li>
    <li class="position-absolute pos-top pos-right d-none d-sm-block">
      <span class="js-get-date"></span>
    </li>
  </ol> -->
  <div class="subheader">
    <h1 class="subheader-title">
      <i class="subheader-icon fal fa-shopping-basket"></i> {{title}}
      <small>
        Cadastro de {{title}}
      </small>
    </h1>
  </div>
  <div id="panel-1" class="panel">
    <div class="panel-hdr">
      <h2>Dados cadastrais<span class="fw-300"></span></h2>
     
    </div>


    <div class="panel-container show" >
       <div class="panel-content">
        <!-- <div class="row">
          <div class="form-group col-sm-2">
            <div>
              <label class="form-label" for="inscricaoCheck">É ativo?</label><br>
            </div>
            <div class="custom-control custom-switch">              
              <input type="checkbox" class="custom-control-input" id="customSwitch2" checked="">
              <label class="custom-control-label" for="customSwitch2">Sim</label>
            </div>             
          </div>
          <br>
        </div> -->
        <div class="row">
          <div class="form-group col-sm-2">
            <label class="form-label" for="codigo">Código <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text"  [(ngModel)]="produto.codigo" class="form-control" placeholder="" id="codigo" required>
              </div>
          </div>
        
          <div class="form-group col-sm-6">
            <label class="form-label" for="descricao">Descrição <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text"  [(ngModel)]="produto.nome" class="form-control" placeholder="" id="descricao">
            </div>
          </div>
          <div class="form-group col-sm-2">
            <label class="form-label" for="codigoBarras">Código de Barras</label>
            <div class="input-group">
                <input type="text" [(ngModel)]="produto.cEAN" class="form-control" placeholder="" id="codigoBarras">
            </div>
          </div>
          <div class="form-group col-sm-2">
            <label class="form-label" for="status">Status <span class="text-danger">*</span></label>
            <div class="input-group">
              <select class="js-hide-search form-control" id="status" required>
              </select>                        
           </div>
          </div>
      </div>
    <div class="form-row">
          
          <div class="form-group col-sm-6">
            <label class="form-label" for="origemproduto">Origem <span class="text-danger">*</span></label>
            <div class="input-group">
              <select class="select2 form-control w-100" id="origemproduto">
              </select>
            </div>
          </div>
          
          <div class="form-group col-sm-2">
            <label class="form-label" for="unidadeMedida">Unidade de medida <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" [(ngModel)]="produto.codigoUnimed" class="form-control" maxlength="6" placeholder="" id="unidadeMedida">
            </div>
          </div>
          <div class="form-group col-sm-2">
            <label class="form-label" for="ncm">NCM <span class="text-danger">*</span></label>
            <div class="input-group">
                <input type="text" [(ngModel)]="produto.ncm" class="form-control" placeholder="" id="ncm">
            </div>
          </div>
          <div class="form-group col-sm-2">
            <label class="form-label" for="precoVenda">Preço de venda</label>
            <div class="input-group">
                <input type="text" [(ngModel)]="produto.preco" class="form-control" placeholder="" id="precoVenda" value="0">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  
<button (click)="save()" style="float:right; margin: 10px;" class="btn btn-primary ml-auto" type="button">Salvar</button>
<button (click)="cancel()" type="button" style="float:right; margin: 10px;" class="btn btn-secondary">Cancelar</button>
<swal #deleteConfirmationMessage showDenyButton="true" (deny)="handleDenial()" (confirm)="confirmDelete()" denyButtonText="Cancelar" showConfirmButton="true" confirmButtonText="Confirmar" title="Excluir?" text="Tem certeza que deseja excluir este registro?" icon="warning"></swal>
<swal #errorMessage title="Erro!" text="Ops, aconteceu algum imprevisto no servidor. Por favor tente novamente mais tarde."  icon="error"></swal>
<swal #successMessage text="Boa, registro salvo com sucesso!" showConfirmButton=true icon="success"></swal>
<swal #validationMessage text="Ops, ainda faltam alguns campos para preencher, vamos lá?" showConfirmButton=true icon="error"></swal>
