import {NgModule} from '@angular/core';
import {routing} from './ncm.routing';
import { SharedModule } from '../../../shared/shared.module';
import { NcmListComponent } from './list/ncm.list.component';
import { NcmEditorComponent } from './edit/ncm.editor.component';


@NgModule({
  imports: [
    SharedModule,
    routing,
  ],
  declarations: [
    NcmEditorComponent,
    NcmListComponent

  ],
  providers: [],
})
export class NcmModule {

}
