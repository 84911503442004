import {ModuleWithProviders} from "@angular/core"
import {Routes, RouterModule} from '@angular/router';
import {ArtigoListComponent} from './list/artigo.list.component'
import {ArtigoEditorComponent} from './editor/artigo.editor.component'


export const routes: Routes = [
  {
    path: '', redirectTo: 'list', pathMatch: 'full'
  },
  {
    path: 'list',
    component:ArtigoListComponent,
  },
  {
    path: 'edit',
    component:ArtigoEditorComponent,
  },
  {
    path: 'edit/:id',
    component:ArtigoEditorComponent,
  }

];

export const routing = RouterModule.forChild(routes);
