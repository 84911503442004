import {ModuleWithProviders} from "@angular/core"
import {Routes, RouterModule} from '@angular/router';
import {NFeListComponent} from './list/nfe.list.component'
import {NFeEditorComponent} from './editor/nfe.editor.component'


export const routes: Routes = [
  {
    path: '', redirectTo: 'list', pathMatch: 'full'
  },
  {
    path: 'list',
    component:NFeListComponent,
  },
  {
    path: 'edit',
    component:NFeEditorComponent,
  },
  {
    path: 'edit/:id',
    component:NFeEditorComponent,
  }
];

export const routing = RouterModule.forChild(routes);
