import { Empresa } from './../../services/model/empresa';
import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DataTableDirective } from "angular-datatables";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { CidadeService } from "src/app/services/cidade.service";
import { EmpresaService } from "src/app/services/empresa.service";
import { EstadoService } from "src/app/services/estado.service";
import { LoginService } from "src/app/services/login.service";
import {Location} from '@angular/common';

import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $:any;
declare var localStorage:any;

@Component({
  selector: 'sa-empresa',
  templateUrl: './empresa.component.html',
  styles: [],
})




export class EmpresaComponent implements OnInit, OnDestroy {

  empresa: Empresa = {} as Empresa;
  files: Set<File>;
  selectedFiles: FileList;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private empresaService: EmpresaService,
    private loginService: LoginService,
    private estadoService: EstadoService,
    private cidadeService : CidadeService,
    private spinnerService: NgxSpinnerService,
    private _location: Location,

  ) {}
  currentId: number = -1;

  dtTrigger: Subject<any> = new Subject();

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {
    var self = this;
    // const id: number = parseInt(this.route.snapshot.paramMap.get('id'));
    const id: number = localStorage.getItem('idEmpresa');
    this.currentId = id;
    this.fillEstado();
    $('#estado').val('SP').change();
    $('#simplesNacional').val('S').change();

    this.fillSimples();
    
    this.route.data.subscribe(params => {
        if(id) 
            {
            this.spinnerService.show();
            this.empresaService.getById(id).subscribe(response=> {
              this.spinnerService.hide();
              this.empresa = response.data;
              $('#estado').val(this.empresa.uf).change();
              // alert($('#estado').val());
              self.fillCidade(this.empresa.uf);
              $('#cidade').val(this.empresa.codigoCidade).change();
              $('#simplesNacional').val(this.empresa.isSimples).change();
              //this.updateDataTable();
            }, error => {
              if(error.status == 401){
                self.loginService.tokenExpired();
              }
            })
          } 
    });

    // Estado
    $('#estado').on("change", function() {
      self.fillCidade($(this).val());
    });
  }

  ngOnDestroy() {
    window.functions = null;
    this.dtTrigger.unsubscribe();
  }

  onFileSelected(event)  {
    this.selectedFiles = event.target.files;
  }
  
  cancel() {
    this._location.back();
  }
  
  async save() {
    this.empresa.uf = $('#estado').val();
    this.empresa.codigoCidade = $('#cidade').val();
    this.empresa.cidade = $('#cidade').find(":selected").text();
    this.empresa.isSimples = $('#simplesNacional').val();

    await this.empresaService.update(this.empresa).subscribe(response => {
        var header = {
          idEmpresa: localStorage.getItem('idEmpresa')
        }
        if($('#customFile').val() != '') {
          this.files = new Set();
          for( let i = 0; i < this.selectedFiles.length; i++ ){
            this.files.add(this.selectedFiles[i]);
          };
          this.empresaService.uploadLogo(this.files,header).then((res: any) => {});
        };
        Swal.fire({
          text: "Boa, registro salvo com sucesso! Por favor, faça o login novamente para que sejam atualizadas as configurações.",
          icon: 'success'
        });
        this.router.navigate(['/login']);
        //  this._location.back();
    });
  }
  
  async fillEstado() {
    await this.estadoService.get().subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.uf || obj.pk; // replace pk with your identifier
        obj.text = obj.uf;
        return obj;
      });
      $('#estado').empty();
      $('#estado').select2({
        data: finalData,
        placeholder: 'Estado'
      });
    });
    $('#estado').val(this.empresa.uf).change();
  }

  
  async fillCidade(uf:string) {
    await this.cidadeService.getByUf(uf).subscribe(data=> {
      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.codigo || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        return obj;
      });

      $('#cidade').empty();
      $('#cidade').select2({
        data: finalData,
        placeholder: 'Cidade',
        allowClear: true
      });
      $('#cidade').val(this.empresa.codigoCidade).change();

    })
  }
  fillSimples(){
    var data = [
      {'id': "S", 'text': 'Sim'},
      {'id': "N", 'text': 'Não'},
     ];

      
    $('#simplesNacional').select2({
      data: data,
      allowClear: true,
      minimumResultsForSearch: -1
    });
  }
}
