import { Component } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { Router } from '@angular/router';
import { LoginService } from './services/login.service'

declare var $:any;

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  title = 'sa';
  
  constructor(
    private loginService: LoginService,
    private bnIdle: BnNgIdleService,
    private router: Router) { // initiate it in your component constructor
       this.bnIdle.startWatching(18000).subscribe((res) => {
        // this.bnIdle.startWatching(60).subscribe((res) => {
          if(res) {
          console.log("session expired");
          this.bnIdle.resetTimer;
          this.router.navigate(['/login']);
      };
    });
  }
  
  //novo ir direto pra tela de login qdo iniciar
  ngOnInit() {
    // this.router.navigate(['/login']);
    //    this.router.navigate(['/dashboard']); //nao pode tirar esta linha senão não funciona multiempresa
    //    this.loginService.refreshToken().subscribe(loginData => {
    //     // alert('refrestoken');
    //       localStorage.setItem('authToken', JSON.stringify(loginData))
    //    });

    }

}
