import {NgModule} from '@angular/core';
import { FaturaListComponent } from './list/fatura.list.component'
import { FaturaEditorComponent } from './editor/fatura.editor.component'
import { routing} from './fatura.routing';
import { SharedModule } from '../../../shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    routing,
  ],
  declarations: [
    FaturaListComponent,
    FaturaEditorComponent
  ],
  providers: [],
})
export class FaturaModule {

}
