import { GenericValidator } from '../../../../services/validaCpfCnpj.service';
import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { DataTableDirective } from "angular-datatables";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import { CidadeService } from "src/app/services/cidade.service";
import { EntidadeService } from "src/app/services/entidade.service";
import { EstadoService } from "src/app/services/estado.service";
import { LoginService } from "src/app/services/login.service";
import { Contato, Entidade } from "src/app/services/model/entidade";
import { PaisService } from "src/app/services/pais.service";
import { PerfilEmpresaService } from "src/app/services/perfilEmpresa.service";
import { VendedorService } from "src/app/services/vendedor.service";
import { routerTransition } from "../../../../util/animations";
import Swal from 'sweetalert2/dist/sweetalert2.js';

declare var $:any
// const selectStates = document.querySelector('#states');

@Component({
  selector: "cf-editor",
  templateUrl: "./clientesfornecedores.editor.component.html",
  styles: [],
  animations: [routerTransition]
})


export class ClientesFornecedoresEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entidadeService: EntidadeService,
    private loginService: LoginService,
    private paisService: PaisService,
    private estadoService: EstadoService,
    private cidadeService : CidadeService,
    private perfilEmpresaService : PerfilEmpresaService,
    private vendedorService: VendedorService,
    private spinnerService: NgxSpinnerService,
    private genericValidator: GenericValidator,
  ) {}

  isCheckedIE = 'Não';
  isCheckedAtivo = 'Sim';

  title: string;
  description:string;
  dtOptions: DataTables.Settings = {};
  hasInscricao: boolean;
  hasAtivo: boolean;
  
  currentId: number = -1;
  entidade: Entidade = {} as Entidade;
  brasilId:number;
  currentContact:Contato = {} as Contato;
  contactsToRemove:Contato[] = [];
  contactList: Contato [] = [];
  msgErroServer: string = "Ops, aconteceu algum imprevisto no servidor. ";
  labelCnpjCpf: string;
  
  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  @ViewChild('errorCnpj')
  public readonly errorCnpj!: SwalComponent;
  @ViewChild('errorCpf')
  public readonly errorCpf!: SwalComponent;
  @ViewChild('successMessage')
  public readonly successMessage!: SwalComponent;
  @ViewChild('validationMessage')
  public readonly validationMessage!: SwalComponent;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtTrigger: Subject<any> = new Subject();


  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnInit() {

    var self = this;

    const id: number = parseInt(this.route.snapshot.paramMap.get('id'));
    this.currentId = id;

    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);
    
    //$('#cnpjcpf').inputmask({ regex: "([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})", greedy: false });
    $('#cep').inputmask({mask: "99999-999"});

    this.fillPessoa();
    this.fillStatus();
    this.fillIE();
    this.fillConsumidorFinal();
    this.fillPais();
    this.fillEstado(); //tirei daqui pq esta com erro na uf AC
    
    //Estado
    $('#estado').on("change", function() {
      self.fillCidade($(this).val());
    });

    //Pessoa
    $('#pessoa').on("change", function() {
      if($(this).val() == 'J') {
         self.labelCnpjCpf = 'CNPJ';
         $('#cnpjcpf').inputmask({mask: "99.999.999/9999-99"});
         $('#cnpjcpf').prop( "disabled", false );
         $('#cep').prop( "disabled", false );
         $('#inscEstadual').prop( "disabled", false );
         $('#consumidorFinal').val(0).change();
        } else if($(this).val() == 'F') {
        self.labelCnpjCpf = 'CPF';
        $('#cnpjcpf').inputmask({mask: "999.999.999-99"});
        $('#cnpjcpf').prop( "disabled", false );
        $('#cep').prop( "disabled", false );
        $('#inscEstadual').prop( "disabled", true );
        $('#consumidorFinal').val(1).change();
      } else if($(this).val() == 'E') {
        self.labelCnpjCpf = 'Sem documentação para estrangeiro';
        $('#cnpjcpf').prop( "disabled", true );
        $('#estado').val('EX').change();
        $('#cep').val('').change();
        $('#cidade').val('9999999').change();
        $('#cep').prop( "disabled", true );
        $('#consumidorFinal').val(0).change();
      }; 
    });

    $('#pessoa').val('J').change();
    $('#status').val('A').change();

    
    // $('#isFornecedor').on("change", function() {
    //   this.hasFornecedor = $('#isFornecedor').is(":checked");
    // //  $('#isFornecedor').val( this.hasFornecedor).change();
    //   alert( this.hasFornecedor );
    // });

    $('#inscEstadual').on("change", function() {
      if($('#inscEstadual').val() == 'S') {
         $('#hasInscricao').show();
      } else { 
         $('#inscricaoEstadual').val('').change();
         $('#hasInscricao').hide();
      }
    });
    $('#inscEstadual').val('N').change();
    $('#consumidorFinal').val(0).change();

    self.dtOptions = {
      searching:false,
      lengthChange:false,
      columns : [
        { "data": "nome" },
        { "data": "email" },
        { "data": "dtAniversario" },
        { "data": "fone" },
        { "data": "cargo" },
        {
          "data": "id",
          render: (data, type, full) => {
            return `<div class='text-center'><a href='javascript:void(0);' onclick=\"functions.editEntity('${data}')\" class='btn btn-sm btn-icon btn-outline-primary rounded-circle mr-1' title='Editar contato'><i class='fal fa-pen'></i></a><a href='javascript:void(0);' onclick=\"functions.deleteEntity('${data}')\" class='btn btn-sm btn-icon btn-outline-danger rounded-circle mr-1' title='Deletar contato'><i class='fal fa-times'></i></a></div>`;
          }
        },
      ],
      // columnDefs: [{
      //   targets: 2,
      //   render: $.fn.dataTable.render.moment().format('MMMM Do YYYY')
      // }]
      /* render dynamic buttons */
    };

    $('#isCliente').prop('checked',(false));
    $('#isFornecedor').prop('checked',(false));
    $('#isTransportadora').prop('checked',(false));
    this.route.data.subscribe(params => {
      if(params.type == 'cliente') {
        this.title = 'Clientes';
        this.description = 'Cadastro de clientes'
        $('#isCliente').prop('checked',(true));
      } else if(params.type == 'fornecedor') {
        this.title = 'Fornecedores';
        this.description = 'Cadastro de fornecedores';
        $('#isFornecedor').prop('checked',(true));
      } else if(params.type == 'transportadora') {
        this.title = 'Transportadoras';
        this.description = 'Cadastro de transportadoras';
        $('#isTransportadora').prop('checked',(true));
      };
      
     
      if(id)
      {
        this.spinnerService.show();
        this.entidadeService.getById(id).subscribe(response=> {
          this.spinnerService.hide();
          this.entidade = response.data;
          this.contactList = response.contatos;
        //  this.updateDataTable();

          $('#pessoa').val(this.entidade.pessoa).change();
          if(this.entidade.isAtivo == 'S') {
             $('#status').val('A').change();
          } else {
             $('#status').val('I').change();
          };

          if(this.entidade.cnpj != '') {
            $('#cnpjcpf').val(this.entidade.cnpj).change();
          } else {
            $('#cnpjcpf').val(this.entidade.cpf).change();
          };

          $('#isCliente').prop('checked',(this.entidade.isCliente == 'S'));
          $('#isFornecedor').prop('checked',(this.entidade.isFornecedor == 'S'));
          $('#isTransportadora').prop('checked',(this.entidade.isTransportadora == 'S'));

          // if(this.entidade.idPerfil) {
          //   $('#perfilEmpresa').val(this.entidade.idPerfil).change();
          // }
          // if(this.entidade.idVendedor) {
          //   $('#vendedor').val(this.entidade.idVendedor).change();
          // }

          if(this.entidade.inscEst != ''){
            $('#inscEstadual').val('S').change();
          } else {
            $('#inscEstadual').val('N').change();
          };
          this.fillEstado();
          this.fillCidade(this.entidade.uf);
          $('#pais').val(this.entidade.idPais).change();
          $('#estado').val(this.entidade.uf).change();       
          $('#cidade').val(this.entidade.codigoCidade).change();
          $('#consumidorFinal').val(this.entidade.indFinal).change();
        }, error => {
          this.spinnerService.hide();
          if(error.status == 401){
            self.loginService.tokenExpired();
         }
        })
      }
    })
  }

  fillPais() {
    var self = this;
    this.paisService.get().subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.id || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        if(obj.text == 'BRASIL') {
          self.brasilId = obj.id;
        }
        return obj;
      });

      $('#pais').select2({
        data: finalData,
        placeholder: 'País',
        allowClear: true
      });

      $('#pais').val(this.brasilId).change();

    })
  }

  fillPerfil() {
    this.perfilEmpresaService.get().subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.id || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        return obj;
      });

      $('#perfilEmpresa').select2({
        data: finalData,
        placeholder: 'Perfil Empresa'
      });
    })
  }

  fillVendedor() {
    this.vendedorService.get().subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.id || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        return obj;
      });

      $('#vendedor').select2({
        data: finalData,
        placeholder: 'Vendedor'
      });
    })
  }

  async fillEstado() {
    await this.estadoService.get().subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.uf || obj.pk; // replace pk with your identifier
        obj.text = obj.uf;
        return obj;
      });
      $('#estado').empty();
      $('#estado').select2({
        data: finalData,
        placeholder: 'Estado'
      });
      $('#estado').val(this.entidade.uf).change();  // seta o estado assim q mondar o select2
    });
    //$('#estado').val(this.entidade.uf).change();

  }

  async fillCidade(uf:string) {
    await this.cidadeService.getByUf(uf).subscribe(data=> {

      var finalData = $.map(data.data, function (obj) {
        obj.id = obj.codigo || obj.pk; // replace pk with your identifier
        obj.text = obj.nome;
        return obj;
      });

      $('#cidade').empty();
      $('#cidade').select2({
        data: finalData,
        placeholder: 'Cidade',
        allowClear: true
      });
      $('#cidade').val(this.entidade.codigoCidade).change();

    })
  }

  fillStatus(){
    var statusData = [
      {'id': "A", 'text': 'Ativo'},
      {'id': "I", 'text': 'Inativo'},
     ];

      
    $('#status').select2({
      data: statusData,
      allowClear: true,
      minimumResultsForSearch: -1
    });
  }

  fillPessoa(){
    var tipoPessoa = [
      {'id': "J", 'text': 'Jurídica'},
      {'id': "F", 'text': 'Física'},
      {'id': "E", 'text': 'Estrangeira'}
     ];
    $('#pessoa').select2({
      data: tipoPessoa,
      placeholder: 'Selecione o tipo de pessoa',
      allowClear: true,
      minimumResultsForSearch: -1
      
    });
  }

  fillIE(){
    var ieData = [
      {'id': "N", 'text': 'Não'},
      {'id': "S", 'text': 'Sim'},
     ];

      
    $('#inscEstadual').select2({
      data: ieData,
      allowClear: true,
      minimumResultsForSearch: -1
    });
  }
  
  fillConsumidorFinal(){
    var ieData = [
      {'id': 0, 'text': 'Não'},
      {'id': 1, 'text': 'Sim'},
     ];

      
    $('#consumidorFinal').select2({
      data: ieData,
      allowClear: true,
      minimumResultsForSearch: -1
    });
  }
  validarCpfCnpj(){
    var cnpjcpf = $('#cnpjcpf').val().replace(/[^\d]+/g,'');

    // if(( $('#pessoa').val() == 'J') && (!this.currentId)) {
      if( $('#pessoa').val() == 'J') {
        if(this.genericValidator.isValidCnpj(cnpjcpf)){
             if (!this.currentId) {
               this.entidadeService.getCnpj(cnpjcpf).subscribe(response=> {
                if(response.data.nome != '') {
                   this.entidade.nome = response.data.nome;
                } else {
                  this.entidade.nome = response.data.razao;
                }
                this.entidade.razao = response.data.razao;
                this.entidade.endereco = response.data.endereco;
                this.entidade.bairro = response.data.bairro;
                this.entidade.numero = response.data.numero;
                this.entidade.complemento = response.data.complemento;
                this.entidade.cep = response.data.cep;
                this.entidade.uf = response.data.uf;
                $('#estado').val(this.entidade.uf).change();
                this.entidade.cidade =  response.data.cidade.toUpperCase();
                if(response.data.codigoCidade > 0) {
                   this.entidade.codigoCidade =  response.data.codigoCidade;
                }
                $('#cep').val(this.entidade.cep).change();
                this.validarCep();
                this.entidade.cnpj = cnpjcpf;
              });
             }
          } else {
             this.errorCnpj.fire();
           }
       } else if(( $('#pessoa').val() == 'F') ){
           this.entidade.cnpj = '';
           this.entidade.cpf = cnpjcpf;
            if(!this.genericValidator.isValidCpf(cnpjcpf)){
                this.errorCpf.fire();
            }
       }
 }

 validarCep() {
      var cep = $('#cep').val().replace(/[^\d]+/g,'');
      if( cep != '') {
          if( cep.length == 8) {
            this.entidadeService.getCep(cep).subscribe(response=> {
              this.entidade.endereco = response.data.endereco;
              this.entidade.bairro = response.data.bairro;
              this.entidade.uf = response.data.uf;
              $('#estado').val(this.entidade.uf).change();
              this.entidade.cidade =  response.data.cidade.toUpperCase();
              this.entidade.codigoCidade =  response.data.codigoCidade;
            });
       };  
    };
  }



  adicionarContato(){
    var self = this;

    $('#contatodtAniversario').inputmask({ alias: "datetime", inputFormat: "dd/mm/yyyy"});
    $('#contatoTelefone').inputmask({mask: "(99) 999999999" });

    self.currentContact = null;
//    self.currentContact.id = (self.contactList.length+1)*-1;
    // $('#contatoNome').val('');
    // $('#contatoEmail').val('');
    // $('#contatodtAniversario').val('');
    // $('#contatoTelefone').val('');
    // $('#contatoCargo').val('');
     $('#modalContato').modal('show');
  }

  editEntity(data) {
    var self = this;
    self.currentContact = self.contactList.filter(item => item.id == data)[0];

    //$('#contatodtAniversario').inputmask({ alias: "datetime", inputFormat: "dd/mm/yyyy"});
    $('#contatoTelefone').inputmask({mask: "(99) 999999999" });

    //hack pra obrigar a modal a abrir já com os dados preenchidos
    $('#contatoNome').val(self.currentContact.nome);
    $('#contatoEmail').val(self.currentContact.email);
    $('#contatodtAniversario').val(self.currentContact.dtAniversario);
    $('#contatoTelefone').val(self.currentContact.fone);
    $('#contatoCargo').val(self.currentContact.cargo);
    $('#modalContato').modal('show');
  }

  deleteEntity(data) {
    this.currentContact = this.contactList.filter(item => item.id == data)[0];
    this.deleteConfirmationMessage.fire();
  }

  confirmDelete() {
    this.contactList.splice(this.contactList.indexOf(this.currentContact), 1);
    if(this.currentContact.id > 0)
    {
      this.contactsToRemove.push(this.currentContact);
    }
    this.updateDataTable();
  }

  handleDenial() {

  }

  saveContact() {
    if(!this.currentContact.id) {
      this.currentContact.id = 0;
    }
    this.currentContact.nome = $('#contatoNome').val();
    this.currentContact.fone = $('#contatoTelefone').val().replace(/[^\d]+/g,'');
    this.currentContact.dtAniversario = $('#contatodtAniversario').val();
    this.currentContact.email = $('#contatoEmail').val();
    this.currentContact.cargo = $('#contatoCargo').val();

    if(this.currentContact.id > 0) {
      this.updateDataTable();
    } else {
      console.log(this.currentContact);
      this.contactList.push(this.currentContact);
      this.updateDataTable();
    }
   
    $('#modalContato').modal('hide');
  }

  ngOnDestroy() {
    window.functions = null;
    this.dtTrigger.unsubscribe();
  }

  updateDataTable() {

    var self = this;
    
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      console.log(this.contactList);
      self.dtOptions.data = self.contactList;
      
      this.dtTrigger.next();
    });
  }

  cancel() {
    if(this.title == 'Clientes') {
      this.router.navigate(['/cadastros/clientes']);
    } else if(this.title == 'Fornecedores') {
      this.router.navigate(['/cadastros/fornecedores']);
    } else if(this.title == 'Transportadoras') {
      this.router.navigate(['/cadastros/transportadoras']);
    }
  }

  save() {
    var self = this;
    this.entidade.pessoa = $('#pessoa').val();
    if( this.entidade.pessoa == 'J') {
      this.entidade.cnpj = $('#cnpjcpf').val().replace(/[^\d]+/g,'');
      this.entidade.cpf = '';
    } else if( this.entidade.pessoa == 'F') {
      this.entidade.cnpj = '';
      this.entidade.cpf = $('#cnpjcpf').val().replace(/[^\d]+/g,'');
    } else if( this.entidade.pessoa == 'E') {
      this.entidade.cnpj = '';
      this.entidade.cpf = '';
    }
    this.entidade.isAtivo = ($('#status').val() == 'A' ? 'S': 'N');
    this.entidade.isCliente =  ( $('#isCliente').is(":checked") ? 'S': 'N');;
    this.entidade.isFornecedor = ( $('#isFornecedor').is(":checked") ? 'S': 'N');
    this.entidade.isTransportadora =  ( $('#isTransportadora').is(":checked") ? 'S': 'N');

    this.entidade.idPais = $('#pais').val();
    this.entidade.uf = $('#estado').val();
    this.entidade.codigoCidade = $('#cidade').val();
    this.entidade.cidade = $('#cidade').find(":selected").text();
    this.entidade.indFinal = $('#consumidorFinal').val();
    
    this.entidade.idPerfil = $('#perfilEmpresa').val();
    this.entidade.idVendedor = $('#vendedor').val();
    this.entidade.cep = $('#cep').val().replace(/[^\d]+/g,'');
    this.entidade.email = $('#email').val();
    this.entidade.inscEst = $('#inscricaoEstadual').val().replace(/[^\d]+/g,'');

    //Validação
    if( (( this.entidade.cnpj == '') && (this.entidade.cpf == '') && (this.entidade.pessoa != 'E')) ||
        (this.entidade.razao == '')  ||
        ((this.entidade.cep == '') && (this.entidade.pessoa != 'E')) || 
        (this.entidade.idPais == 0) ||
        (this.entidade.codigoCidade == 0) || 
        (this.entidade.uf == '') ||
        (this.entidade.endereco == '') ||
        (this.entidade.numero == '') ||
        ((this.entidade.isCliente == 'N') && (this.entidade.isFornecedor =='N') && (this.entidade.isTransportadora == 'N')) ) {
            this.validationMessage.fire();
     } else {
       // this.spinnerService.show();
        if(this.entidade.id > 0) {
          this.entidadeService.update(this.entidade).subscribe(response => {
            self.contactList.forEach(function (item) {
              if(item.id == 0) {
                self.entidadeService.createContato(self.entidade.id,item).subscribe();
              } else {
                self.entidadeService.updateContato(self.entidade.id,item).subscribe();
              }

            });
            // self.contactsToRemove.forEach(function (item) {
            //   self.entidadeService.deleteContato(self.entidade.id,item.id).subscribe();
            // });
            this.successMessage.fire();
            this.pageList();
          }, error => {
            this.spinnerService.hide();
            Swal.fire({
              title:  "Erro!",
              text: this.msgErroServer + error.error.message,
              icon: 'error'
            });
          });
        } else {
          this.entidadeService.create(this.entidade).subscribe(id=> {
            self.contactList?.forEach(function (item) {
              self.entidadeService.createContato(id.id,item).subscribe();
            });
     //       this.spinnerService.hide();
            this.successMessage.fire();
            this.pageList();

          }, error => {
     //       this.spinnerService.hide();
            Swal.fire({
              title:  "Erro!",
              text: this.msgErroServer + error.error.message,
              icon: 'error'
            });
          });
          
        };
      };
  }

  pageList(){
    if(this.title == 'Clientes') {
      this.router.navigate(['/cadastros/clientes']);
    } else if(this.title == 'Fornecedores') {
      this.router.navigate(['/cadastros/fornecedores']);
    } else if(this.title == 'Transportadoras') {
      this.router.navigate(['/cadastros/transportadoras']);
    }
  }
  
}
