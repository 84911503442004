import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { routerTransition } from '../../../../util/animations';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { EntidadeService } from 'src/app/services/entidade.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cf-list',
  templateUrl: './clientesfornecedores.list.component.html',
  styles: [],
  animations: [routerTransition],
})
export class ClientesFornecedoresListComponent
  implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private entidadeService: EntidadeService
  ) {}

  dtOptions: DataTables.Settings = {};
  title: string;
  description:string;
  currentPage: number = 0;
  currentPageSize:number = 10;
  filterIsAtivo: boolean = true;
  currentRecord: number;
  search: string = '';
  msgErroServer: string = "Ops, aconteceu algum imprevisto no servidor. ";
  tipoCadastro: string;
  


  @ViewChild('deleteConfirmationMessage')
  public readonly deleteConfirmationMessage!: SwalComponent;
  @ViewChild('errorMessage')
  public readonly errorMessage!: SwalComponent;
  @ViewChild('ativarDesativarConfirmationMessage')
  public readonly ativarDesativarConfirmationMessage!: SwalComponent;
  
  ngAfterViewInit(): void {

  }

  ngOnInit() {

    window.functions = window.functions || {};
    window.functions.editEntity = this.editEntity.bind(this);
    window.functions.deleteEntity = this.deleteEntity.bind(this);
    window.functions.ativarDesativarEntity = this.ativarDesativarEntity.bind(this);

    var self = this;
    this.route.data.subscribe(params => {
      if(params.type == 'cliente') {
        this.title = 'Clientes';
        this.description = 'Lista de clientes ativos e inativos';
        this.tipoCadastro = 'C';
      } else if(params.type == 'fornecedor') {
        this.title = 'Fornecedores';
        this.description = 'Lista de fornecedores ativos e inativos';
        this.tipoCadastro = 'F';
      } else if(params.type == 'transportadora') {
        this.title = 'Transportadoras';
        this.description = 'Lista de transportadoras ativos e inativos';
        this.tipoCadastro = 'T';
      }
    });

    this.dtOptions = {
      processing:true,
      serverSide:true,
      ajax: {
      //  url: 'https://kironwebapi.azurewebsites.net/entidade',
        url: environment.apiUri + '/entidade',
        data: function (d) {
          var queryData = {
            page: d['start'] / d['length'] + 1,
            pageSize:d['length'],
            tipo: self.tipoCadastro,
            status: self.filterIsAtivo ? 'A' : 'I',
            search: self.search
          }
          self.currentPage = queryData.page;
          self.currentPageSize = queryData.pageSize;
          return queryData;
        },
        async: true,
        error: function (xhr, error, code)
          {
            if((xhr.status == 400) || (xhr.status == 401)) {
              self.loginService.tokenExpired();
            }
          },
        dataSrc : function(json) : any {
          json.recordsTotal = json.count;
          json.recordsFiltered = json.count
          return json.data;
        },
        headers: { 'x-access-token': this.loginService.getToken() }
      },
      columns : [
        { "data": "nome" },
        { "data": "razao" },
        { "data": "cidadeuf" },
        { "data": "cnpjcpf"},
        //{ "data": "cpf" },
        {
          "data": "id",
          render: (data, type, full) => {
            return `<div class='text-center'><a href='javascript:void(0);' onclick=\"functions.editEntity('${data}')\" class='btn btn-primary btn-sm btn-icon rounded-circle' title='Editar registro'><i class='fal fa-pen'></i></a>
                    <a href='javascript:void(0);' onclick=\"functions.ativarDesativarEntity('${data}')\" class='btn btn-primary btn-sm btn-icon rounded-circle' title='Ativar/Desativar'><i class='fal fa-check'></i></a>
                    <a href='javascript:void(0);' onclick=\"functions.deleteEntity('${data}')\" class='btn btn-danger btn-sm btn-icon rounded-circle' title='Deletar registro'><i class='fal fa-times'></i></a></div>`;
          }
        }
    ], 
      /* render dynamic buttons */
    };
      //Exibe tela de editar no evento onclick na tabela
      // $('#tableList').on( 'click', 'tr', function () {
      //   var id = $('#tableList').DataTable().cell( this, 4 ).data();
      //   self.editEntity( id );
      // } );
  } 

    
  
  searchLista() {
    $('#tableList').DataTable().ajax.reload();
  }

  ngOnDestroy() {
    window.functions = null;
  }

  changeAtivoFilter(data:boolean) {
    this.filterIsAtivo = data;
    $('#tableList').DataTable().ajax.reload();
  }

  new() {
    // window.open('file:\\\C:\Windows\System32\calc.exe');

    if(this.title == 'Clientes')
    {
      this.router.navigate(['/cadastros/clientes/edit'])
    } else  if(this.title == 'Fornecedores') {
      this.router.navigate(['/cadastros/fornecedores/edit'])
    } else  if(this.title == 'Transportadoras') {
      this.router.navigate(['/cadastros/transportadoras/edit'])
    }

  }

  editEntity(data) {
    if(this.title == 'Clientes')
    {
      this.router.navigate(['/cadastros/clientes/edit', data])
    } else  if(this.title == 'Fornecedores') { 
      this.router.navigate(['/cadastros/fornecedores/edit', data])
    } else  if(this.title == 'Transportadoras') { 
      this.router.navigate(['/cadastros/transportadoras/edit', data])
    }
  }

  deleteEntity(data) {
    this.currentRecord = data;
    this.deleteConfirmationMessage.fire();
  }

  confirmDelete() {
    this.entidadeService.delete(this.currentRecord).subscribe( () => {
      $('#tableList').DataTable().ajax.reload();
      this.currentRecord = -1;
    }, error => {
      Swal.fire({
        title:  "Erro!",
        text: this.msgErroServer + error.error.message,
        icon: 'error'
    });      this.currentRecord = -1;
    });
  }

  handleDenial() {

  }

 
ativarDesativarEntity(data) {
  var msgAtivo: string = this.filterIsAtivo ? 'desativar' : 'ativar';
  this.currentRecord = data;
  Swal.fire({
    title:  "",
    text: 'Tem certeza que deseja ' + msgAtivo + ' este cadastro?',
    icon: "question",
    showDenyButton: true,
    showConfirmButton:true,
    denyButtonText:"Cancelar",
    confirmButtonText:"Confirmar",
    }).then((result) => {
       if (result.value) { 
          this.confirmAtivarDesativar();
       }
   });
  this.ativarDesativarConfirmationMessage.fire();
}

confirmAtivarDesativar() {
  var isAtivo: string = this.filterIsAtivo ? 'N' : 'S';

  this.entidadeService.getById(this.currentRecord).subscribe( response=> {
    response.data.isAtivo = isAtivo;
    this.entidadeService.update(response.data).subscribe( response=> {
         $('#tableList').DataTable().ajax.reload();
    } , error => {
      Swal.fire({
        title:  "Erro!",
        text: this.msgErroServer + error.error.message,
        icon: 'error'
    });
    this.currentRecord = -1;
  });
     this.currentRecord = -1;
  });}

}
